import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {EmployeeInfo, Feature, OrderConfig, UserLoginInfo} from "../types/User";
import {CustomerInfo} from "shared/src/types/CustomerInfo";

export interface UserState {
  user: EmployeeInfo | undefined;
  account: CustomerInfo | undefined;
  parentAccount?: CustomerInfo | undefined;
  enabled: boolean;
  orderConfigs?: OrderConfig[];
  loading?: boolean;
  features: Feature[];
  loadingUserError: string | undefined;
}

const createDefaultUserState = (): UserState => ({
  user: undefined,
  account: undefined,
  enabled: false,
  orderConfigs: [],
  loading: false,
  features: [],
  loadingUserError: undefined,
});

const defaultUserState = createDefaultUserState();

const slice = createSlice({
  name: 'user',
  initialState: defaultUserState,
  reducers: {
    loadingUser: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setUser: (state, action: PayloadAction<UserLoginInfo>) => {
      state.user = action.payload.user;
      state.account = action.payload.account;
      state.parentAccount = action.payload.parentAccount;
      state.enabled = action.payload.enabled;
      state.orderConfigs = action.payload.orderConfigs;
      state.features = action.payload.features;
    },
    logoutUser: (state) => {
      const newState = createDefaultUserState();
      state.user = newState.user;
      state.account = newState.account;
      state.parentAccount = newState.parentAccount;
      state.enabled = newState.enabled;
      state.orderConfigs = newState.orderConfigs;
      state.features = newState.features;
      state.loading = newState.loading;
      state.loadingUserError = newState.loadingUserError;
    },
    setLoadingUserError: (state, action: PayloadAction<string | undefined>) => {
      state.loadingUserError = action.payload
    }
  }
})

export default slice.reducer;

export const {
  loadingUser,
  setUser,
  logoutUser,
  setLoadingUserError
} = slice.actions;
