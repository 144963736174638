import PollworkerVLSwimlane from "./PollworkerVLSwimlane";
import React, { createRef, RefObject, useEffect, useMemo, useRef } from "react";
import { useAppSelector } from "../../../hooks";

export default function PollworkerVLSwimlaneScheduler() {
  const votingLocations = useAppSelector(state => state.pollworker.scheduler.filteredVotingLocations);
  const scrollToLocation = useAppSelector(state => state.pollworker.scheduler.scrollToLocation);
  const loadingPollworkerData = useAppSelector(state => state.pollworker.grid.loadingPollworkerData);
  const parties = useAppSelector(state => state.pollworker.scheduler.partiesById);
  const swimlaneContainerRef = useRef<HTMLDivElement>(null);

  const refs = useMemo(() => {
    if (!votingLocations.length) return {};

    return votingLocations.reduce((acc: { [votingLocationCode: string]: RefObject<HTMLDivElement> }, votingLocation) => {
      acc[votingLocation.locationCode] = createRef<HTMLDivElement>();
      return acc;
    }, {});
  }, [votingLocations]);

  useEffect(() => {
    if (!scrollToLocation) return;

    const scrollPadding = -10; // a little padding so the element isn't pressed up against the viewport
    const bbox = refs[scrollToLocation].current?.offsetLeft || 0;
    const containerBbox = swimlaneContainerRef.current?.offsetLeft || 0;

    swimlaneContainerRef.current?.scrollTo({
      top: 0,
      left: bbox - containerBbox + scrollPadding,
      behavior: 'smooth',
    });
  }, [scrollToLocation]);

  return (
    <div ref={swimlaneContainerRef} className="h-full flex items-center space-x-5 overflow-x-scroll px-5 pb-5 pt-4">
      {
        votingLocations.map((votingLocation) => {
          return (
            <div key={`lane_${votingLocation.id}`} ref={refs[votingLocation.locationCode]} className='h-full'>
              <PollworkerVLSwimlane parties={parties} votingLocation={votingLocation} loading={loadingPollworkerData} />
            </div>
          );
        })
      }
    </div>
  )
}
