import {ConsumableItem} from "../../types/ConsumableItem";
import React, {Fragment, Suspense, useState} from "react";
import {Transition} from "@headlessui/react";
import {XMarkIcon} from "@heroicons/react/24/outline";
import DateFormatter from "../../../../shared/src/components/DateFormatter";
import StoreItemImage, {StoreItemImageFallback} from "./StoreItemImage";
import StoreItemAddToCartForm from "./StoreItemAddToCartForm";
import AdminOnly from "../../components/AdminOnly";

type StoreItemDetailsSlideUpProps = {
  showItemDetails: ConsumableItem | undefined;
  onShow: Function;
}

export default function StoreItemDetailsSlideUp({showItemDetails, onShow}: StoreItemDetailsSlideUpProps) {
  return (
    <Transition.Root  show={!!showItemDetails} as={Fragment}>
      <Transition.Child
        as={Fragment}
        enter="transform transition ease-in-out duration-300"
        enterFrom="translate-y-full"
        enterTo="translate-y-0"
        leave="transform transition ease-in-out duration-300"
        leaveFrom="translate-y-0"
        leaveTo="translate-y-full"
      >
        <div className="w-screen fixed z-40 bottom-0 left-0 sm:max-h-96">
          <div className="flex h-full flex-col bg-white border-t-2 border-gray-500 py-6 shadow-xl">
            <div className="px-4 sm:px-6">
              <div className="flex items-start justify-between">
                <div className="flex items-center w-full justify-between text-base font-semibold leading-3 text-gray-900">
                  <span className="text-lg">{showItemDetails?.equipTypeName}</span>
                  <div className="flex items-center space-x-2">
                    <StoreItemAddToCartForm item={showItemDetails} />
                  </div>
                </div>
                <div className="absolute -top-10 right-5 ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ev-red focus:ring-offset-2"
                    onClick={() => onShow(false)}
                  >
                    <span className="sr-only">Close panel</span>
                    <XMarkIcon className="h-8 w-8 font-bold" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div className="sm:flex sm:justify-start mt-6 px-4 sm:px-6 max-h-96 sm:overflow-hidden overflow-y-scroll">
              <div className="flex items-center justify-start bg-gray-200 rounded-md shadow-sm sm:mr-20">
                <Suspense fallback={<StoreItemImageFallback />}>
                  <StoreItemImage
                    item={showItemDetails}
                    className="w-full sm:w-96 h-56 object-scale-down sm:mr-20 flex-none group-hover:opacity-75"
                  />
                </Suspense>
              </div>
              <div className="sm:shrink w-full mt-8 sm:mt-0">
                <div className="flex justify-start w-full">
                  <div className="sm:flex justify-evenly w-full">
                    <div className="w-1/2 space-y-2 text-base font-semibold overflow-y-scroll leading-6 text-gray-900">
                      <AdminOnly>
                        <div className="text-xs text-gray-600">ID[Admin Only]</div>
                        <div className="text-xs text-gray-900">{showItemDetails?.id}</div>
                      </AdminOnly>
                      <div>
                        <div className="text-xs text-gray-600">Name</div>
                        <div className="text-xs text-gray-900">{showItemDetails?.equipTypeName}</div>
                      </div>
                      <div>
                        <div className="text-xs text-gray-600">Created</div>
                        <div className="text-xs text-gray-900"><DateFormatter dateString={showItemDetails?.createdAt} /></div>
                      </div>
                      <div>
                        <div className="text-xs text-gray-600">In Stock / Count on Hand</div>
                        <div className="text-xs text-gray-900">{showItemDetails?.countOnHand}</div>
                      </div>
                      <div>
                        <div className="text-xs text-gray-600">Refill notification threshold</div>
                        <div className="text-xs text-gray-900">{showItemDetails?.countOnHandReorderThreshold}</div>
                      </div>
                    </div>
                    <div className="w-1/2 space-y-2 text-base font-semibold overflow-y-scroll leading-6 text-gray-900 pl-5">
                      <div>
                        <div className="text-xs text-gray-600">Testing Required?</div>
                        <div className="text-xs text-gray-900 font-bold">{showItemDetails?.needsTesting ? 'Yes' : 'No'}</div>
                      </div>
                      <div>
                        <div className="text-xs text-gray-600">Days between Test Cycles</div>
                        <div className="text-xs text-gray-900 font-bold">{showItemDetails?.daysBetweenTests}</div>
                      </div>
                      <div>
                        <div className="text-xs text-gray-600">Days between Election</div>
                        <div className="text-xs text-gray-900 font-bold">{showItemDetails?.daysBeforeElection}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition.Child>
    </Transition.Root>
  )
}
