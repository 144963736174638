import React, { type ReactNode } from "react";
import { Flexor } from "shared/src/components";
import { Button } from "shared/src/components/ui";
import { SectionSubSubHeading } from "shared/src/components/SectionHeading";
import Loading from "./Loading";
import { PlusCircleIcon } from "@heroicons/react/16/solid";

type PanelHeaderProps = {
  title: string,
  addButtonText?: string,
  loading: boolean,
  reload: () => void,
  onAdd?: () => void,
  children?: ReactNode[]
}

export default function PanelHeader({
  loading,
  title,
  addButtonText = 'Add',
  reload,
  onAdd,
  children,
}: PanelHeaderProps) {
  return (
    <Flexor className="px-2 border-b border-gray-200 pb-2 -mt-1">
      <Flexor className='space-x-3'>
        <SectionSubSubHeading>{title}</SectionSubSubHeading>
        <Loading reload={reload} loading={loading} />
        {children}
      </Flexor>
      {
        onAdd ? (
          <Button size='sm' className="flex items-center space-x-1" onClick={onAdd}>
            <PlusCircleIcon className='h-4 w-4' />
            <span>{addButtonText}</span>
          </Button>
        ) : null
      }
    </Flexor>
  )
}
