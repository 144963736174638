import {
  ArchiveBoxIcon,
  CheckIcon, ChevronDownIcon, ExclamationTriangleIcon, FunnelIcon, NoSymbolIcon,
  PaperClipIcon, PencilIcon, QuestionMarkCircleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { Flexor } from "shared/src/components"
import {Order, OrderHistory} from "../../types/Order";
import {getOrderStatusName} from "../../utils/getters";
import {getOrderId} from "../../utils/getters";
import DateFormatter from "shared/src/components/DateFormatter";
import {classNames} from "shared/src/utils/classNames";
import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import {ConsumableItem} from "../../types/ConsumableItem";
import StoreItemImage, {StoreItemImageLazy} from "../store/StoreItemImage";
import {getConsumable, getInventoryItemDetails, getInventoryLevels} from "../../fetchers";
import ItemDetails from "./ItemDetails";
import {Input, Button} from "shared/src/components/ui";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import Loading from "shared/src/components/Loading";
import {ModalPackingListReadOnly} from "../../components/modals/ModalPackingList";
import Tippy from "@tippyjs/react";
import TippyContent from "../../../../shared/src/components/TippyContent";
import {useSearchParam} from "react-use";
import EasyVoteButton from "../../components/admin/EasyVoteButton";
import useNativeMessageListenerEffect from "shared/src/hooks/useNativeMessageListenerEffect";

type ConsumableWithOrderCount = ConsumableItem & {
  orderCount: number;
};

const LOW_INVENTORY_LEVEL = 30;

function getItemInventoryLevel(item: any): [string, string, number] {
  let level = Math.floor(item.countOnHand / (item.countOnHandReorderThreshold * 2) * 100);

  if (isNaN(level) || level === Math.abs(Infinity) || (!level && level !== 0)) return ['N/A', 'bg-gray-200 text-gray-600 border-gray-600', -1];

  let color = 'bg-green-200 text-green-600 border-green-600';

  if (level > 100) level = 100;

  if (level < 75) {
    color = 'bg-yellow-200 text-yellow-600 border-yellow-600';
  }

  if (level < LOW_INVENTORY_LEVEL) {
    color = 'bg-red-200 text-red-600 border-red-600';
  }

  return [`${level}%`, color, level];
}

const filterOptions = [
  {
    value: 'withOrders',
    label: 'Has orders',
    predicate: (item: ConsumableWithOrderCount) => item.orderCount > 0
  },
  {
    value: 'isActive',
    label: 'Active',
    predicate: (item: ConsumableWithOrderCount) => item.active,
  },
  {
    value: 'notIsActive',
    label: 'Inactive',
    predicate: (item: ConsumableWithOrderCount) => !item.active,
  },
  {
    value: 'lowInventory',
    label: 'Low inventory levels',
    predicate: (item: ConsumableWithOrderCount) => {
      return item.countOnHand <= item.countOnHandReorderThreshold && item.countOnHandReorderThreshold > 0
    }
  },
  {
    value: 'missingReorderThreshold',
    label: 'Missing reorder threshold',
    predicate: (item: ConsumableWithOrderCount) => {
      return !item.countOnHandReorderThreshold || item.countOnHandReorderThreshold === 0;
    }
  }
];

function InventoryLevelBadge({level, color}: { level?: string, color?: string }) {
  if (!level) return null;

  return (
    <div className="inline-flex flex-col items-center justify-center">
      <div className={classNames(color, "rounded text-xs border py-0.5 p-1")}>{level}</div>
    </div>
  )
}

type InventoryLevelItems = {[key: string]: ConsumableWithOrderCount[]};

export default function InventoryLevels() {
  const inventoryItemDetailsController = useRef<{controller?: AbortController}>({controller: undefined});
  const [selectedItemIndex, setSelectedItemIndex] = useState<[string, number]>();
  const [showItemDetails, setShowItemDetails] = useState<any>();
  const [itemSearch, setItemSearch] = useState<string>();
  const [filter, setFilter] = useState<any>();
  const [itemDetails, setItemDetails] = useState<any>([]);
  const [loadingItemDetails, setLoadingItemDetails] = useState<any>([]);
  const [inventoryItems, setInventoryItems] = useState<InventoryLevelItems>({});
  const [showOrder, setShowOrder] = useState<Order | OrderHistory>();
  const filterParam = useSearchParam('filter');

  const selectedItem = useMemo(() => {
    return selectedItemIndex ? inventoryItems[selectedItemIndex[0]][selectedItemIndex[1]] : undefined;
  }, [selectedItemIndex, inventoryItems]);

  useNativeMessageListenerEffect((from: string, eventName: string, _eventValue: string, _payload: string) => {
    if (!selectedItem) return;

    if (from === 'Dialog' && eventName === 'EditEquipmentDialog') {
      console.log('Refreshing item', selectedItem?.id)
      updateConsumableItem();
    }
  }, [inventoryItems, selectedItem]);

  useEffect(() => {
    filterParam && setFilter(filterOptions.find(f => f.value === filterParam));
  }, [filterParam]);

  useEffect(getInventory, []);

  function updateConsumableItem() {
    if (!selectedItem) return;

    getConsumable(selectedItem?.id).then((r) => {
      const updatedItem = JSON.parse(atob(r.data));
      setInventoryItems((inventoryItems: InventoryLevelItems) => {
        const key = getConsumableItemIndexKey(updatedItem);
        const index = inventoryItems[key].findIndex((i) => i.id === updatedItem.id);
        console.log(inventoryItems[key][index], updatedItem);
        inventoryItems[key][index] = {...inventoryItems[key][index], ...updatedItem};
        return {...inventoryItems};
      });
    });
  }

  function getItemDetails(itemId: string) {
    setLoadingItemDetails(true);

    inventoryItemDetailsController.current.controller?.abort('item details changed');

    const [promise, controller] = getInventoryItemDetails(itemId);

    inventoryItemDetailsController.current.controller = controller;

    promise.then((resp) => {
      const details = JSON.parse(atob(resp.data));
      setItemDetails(details);
    })
    .catch(console.error) // catch abort errors
    .finally(() => {
      setLoadingItemDetails(false);
    });
  }

  function getConsumableItemIndexKey(item: ConsumableWithOrderCount): string {
    return item.equipTypeName[0].toUpperCase();
  }

  function getInventory() {
    getInventoryLevels().then((r) => {
      const items = JSON.parse(atob(r.data))
      .map(({equipType, count}: {equipType: ConsumableWithOrderCount, count: number}) => {
        equipType.orderCount = count;
        return equipType;
      })
      .sort((left: ConsumableWithOrderCount, right: ConsumableWithOrderCount) => {
        return left.equipTypeName > right.equipTypeName ? 1 : -1;
      })
      .reduce((acc: {[key: string]: ConsumableWithOrderCount[]}, item: ConsumableWithOrderCount) => {
        const key = getConsumableItemIndexKey(item);
        return {...acc, [key]: [...(acc[key] || []), item]};
      }, {});

      setInventoryItems(items);
    });
  }

  function onEditSlideoutClose() {
    updateConsumableItem();
    setShowItemDetails(null);
  }

  const [selectedItemLevel, selectedItemLevelColor] = useMemo(() => {
    return selectedItem ? getItemInventoryLevel(selectedItem) : [];
  }, [selectedItem]);

  return (
    <>
      <ModalPackingListReadOnly onClose={() => setShowOrder(undefined)} orderId={showOrder?.id} />
      <Transition.Root show={!!showItemDetails} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={onEditSlideoutClose}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                            {selectedItem?.equipTypeName}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-ev-red focus:ring-offset-2"
                              onClick={onEditSlideoutClose}
                            >
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6">
                        {showItemDetails ? <ItemDetails enablePrint={false} params={{itemId: showItemDetails?.id, onClose: onEditSlideoutClose}}/> : null}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <div className="fixed pt-16 inset-y-0 z-20 flex w-full flex-col">
        <div className="h-full w-full flex justify-between">
          <nav className="h-full shrink-0 w-72 max-w-72 overflow-y-auto border-r border-gray-200" aria-label="Directory">
            <Flexor className="p-1 sticky top-0 bg-white z-30 space-x-0">
              <Input
                id="search"
                type="text"
                name="search"
                placeholder="Filter items"
                className="block w-full rounded-l-md rounded-r-none border py-1.5 my-0 grow text-gray-900 shadow-sm border-r-0 ring-0 border-inset border-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                onChange={({target: {value}}) => setItemSearch(value)}
              />
              <Menu as="div">
                <Menu.Button as={Button} className="h-full inline-flex w-full justify-center items-center gap-x-1.5 rounded-r-md rounded-l-none bg-white px-3 py-2 space-x-0 text-sm font-semibold text-gray-700 shadow-sm border border-inset border-gray-300 hover:bg-gray-50">
                  <FunnelIcon className={classNames(!!filter ? 'text-ev-red' : '', '-mr-1 h-5 w-5')} aria-hidden="true" />
                  <ChevronDownIcon className='size-4' />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {
                        filterOptions.map((filterOption) => {
                          return (
                            <Menu.Item key={`inventory_levels_filter_${filterOption.value}`}>
                              {({ active }) => (
                                <a
                                  href="#"
                                  onClick={() => setFilter(filterOption)}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  <Flexor className="space-x-2">
                                    <span>{filterOption.label}</span>
                                    {filter && filterOption.value === filter.value ? (<CheckIcon className="h-5 w-5" />) : null}
                                  </Flexor>
                                </a>
                              )}
                            </Menu.Item>
                          )
                        })
                      }
                      <hr className='my-1.5' />
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            onClick={() => setFilter(undefined)}
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                          >
                            Clear
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </Flexor>
            {
              !Object.keys(inventoryItems).length ? (
                <div className="flex flex-col items-center justify-center">
                <div className="mx-auto mt-32">
                  <Loading loadingMessage="Fetching inventory items..." />
                </div>
              </div>
              ) : null
            }
            {
              Object.keys(inventoryItems).map((itemGroupKey) => (
                <div key={`item_group_${itemGroupKey}`} className="relative">
                  <div className="bg-white sticky top-11 z-0 border-y border-b-gray-200 border-t-gray-100 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900">
                    <Flexor>
                      <h3>{itemGroupKey}</h3>
                      <Flexor>
                        <ArchiveBoxIcon className="h-4 w-4 mr-1" />
                        <h3 className="font-normal">{inventoryItems[itemGroupKey].length}</h3>
                      </Flexor>
                    </Flexor>
                  </div>
                  <ul role="list" className="divide-y divide-gray-100 cursor-pointer">
                    {
                      inventoryItems[itemGroupKey].map((item, itemIndex) => {
                        if (itemSearch && !item.equipTypeName.toLowerCase().startsWith(itemSearch.toLowerCase())) return null
                        if (filter && !filter.predicate(item)) return null;

                        const [level, color] = getItemInventoryLevel(item);

                        return (
                          <li
                            onClick={() => {
                              setSelectedItemIndex([itemGroupKey, itemIndex]);
                              getItemDetails(item.id);
                              setItemDetails([]);
                            }}
                            key={item.id}
                            className={classNames(selectedItem?.id === item.id ? 'border-rose-400': 'border-transparent', "flex gap-x-4 px-3 py-5 justify-between border-r-4")}
                          >
                            <Flexor>
                              <StoreItemImageLazy item={item} className='h-12 w-12 flex-none rounded-lg bg-gray-50 mr-4' />
                              <div className="min-w-0">
                                <div className="text-sm font-semibold leading-6 text-gray-900 flex items-center">
                                  {
                                    !item.countOnHandReorderThreshold ? (
                                      <Tippy content={(<TippyContent>A reorder threshold has not be set</TippyContent>)}>
                                        <ExclamationTriangleIcon className="h-4 w-4 text-ev-red mr-1.5" />
                                      </Tippy>
                                    ) : null
                                  }
                                  {item.equipTypeName}
                                </div>
                                <p className="text-sm leading-6 text-gray-700">{item.orderCount} orders</p>
                              </div>
                            </Flexor>
                            <InventoryLevelBadge level={level} color={color} />
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>
              ))
            }
          </nav>
          <div className="w-full h-full mx-auto py-5 overflow-y-scroll">
            {
              selectedItem ? (
                <div className="mx-5">
                  <div className="flex items-center justify-between mb-8">
                    <div className="flex items-center gap-x-6">
                      <StoreItemImage item={selectedItem} className='h-16 w-16 flex-none rounded-lg bg-gray-50' />
                      <h1>
                        <div className="mt-1 text-base font-semibold leading-6 text-gray-900">{selectedItem?.equipTypeName}</div>
                        <div className={classNames("2/5 line-clamp-1 text-sm leading-6 text-gray-500", selectedItem?.description ? '' : 'italic')}>
                          {selectedItem?.description || 'No description'}
                        </div>
                      </h1>
                    </div>
                    <div className="flex items-center gap-x-4 sm:gap-x-6">
                      <EasyVoteButton eventToSend={`Dialog:EditEquipmentType:Open:${selectedItem.id}`} />
                      <Button onClick={() => setShowItemDetails(selectedItem)}>
                        <PencilIcon className="size-4" />
                        <span>Edit</span>
                      </Button>
                    </div>
                  </div>
                  <SectionSubSubHeading className="">Details</SectionSubSubHeading>
                  <div className="pt-3 border-gray-300 relative">
                    <dl className="text-sm leading-6 flex justify-between items-center divide-x divide-gray-200">
                      <div className="sm:pr-4">
                        <dt className="inline text-gray-500">Type</dt>
                        <br />
                        <dd className="font-semibold inline text-gray-700">
                          Consumable
                        </dd>
                      </div>
                      <div className="mt-2 sm:mt-0 sm:pl-4">
                        <dt className="inline text-gray-500">
                          <div className="inline-flex items-center">
                            <span>Status</span>
                            <Tippy content={(<TippyContent><strong>Active</strong> means that the item is visible in the store.</TippyContent>)}>
                              <QuestionMarkCircleIcon className="h-4 w-4 ml-2" />
                            </Tippy>
                          </div>
                        </dt>
                        <br />
                        <dd className="font-semibold inline text-gray-700">
                          {selectedItem.active ? 'Active' : 'Inactive'}
                        </dd>
                      </div>
                      <div className="mt-2 sm:mt-0 sm:pl-4">
                        <dt className="inline text-gray-500">In stock</dt>
                        <br />
                        <dd className="font-semibold inline text-gray-700">
                          {selectedItem.countOnHand}
                        </dd>
                      </div>
                      <div className="mt-2 sm:mt-0 sm:pl-4">
                        <dt className="inline text-gray-500">Reorder Threshold</dt>
                        <br />
                        <dd className="font-semibold inline text-gray-700">
                          {selectedItem.countOnHandReorderThreshold}
                        </dd>
                      </div>
                      <div className="mt-2 sm:mt-0 sm:pl-4 shrink-0 w-32">
                        <dt className="inline text-gray-500">
                          <div className="inline-flex items-center">
                            <span>Level</span>
                            <Tippy content={(<TippyContent>The <strong>Level</strong> is calculated as a percentage of twice the reorder threshold and the current amount in stock</TippyContent>)}>
                              <QuestionMarkCircleIcon className="h-4 w-4 ml-2" />
                            </Tippy>
                          </div>
                        </dt>
                        <br />
                        <dd className="font-semibold inline text-gray-700">
                          <InventoryLevelBadge level={selectedItemLevel} color={selectedItemLevelColor} />
                        </dd>
                      </div>
                    </dl>
                    <hr className="my-5" />
                    <Flexor>
                      <SectionSubSubHeading className="my-2">Order History</SectionSubSubHeading>
                      {itemDetails.length ? (
                        <SectionSubSubHeading className="my-2 font-normal text-sm">{itemDetails.length} Orders</SectionSubSubHeading>
                      ) : null}
                    </Flexor>
                    {
                      loadingItemDetails ? (
                        <div className="mt-0">
                          <Loading loadingMessage={"Loading order history..."} />
                        </div>
                      ) : (
                        <div className='overflow-x-scroll'>
                          {
                            itemDetails.length ? (
                              <div className="inline-block min-w-full py-2 align-middle">
                                <div className="overflow-hidden">
                                  <table className="min-w-full divide-y divide-gray-300">
                                    <thead className="">
                                    <tr>
                                      <th scope="col" className="text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        <span>Notes</span>
                                      </th>
                                      <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        <span>ID</span>
                                      </th>
                                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                        <span>User</span>
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <span>County</span>
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <span>Count</span>
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <span>Status</span>
                                      </th>
                                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        <span>Date</span>
                                      </th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                    {
                                      itemDetails.length ? itemDetails.map((order: Order) => {
                                        return (
                                          <tr onClick={() => setShowOrder(order)} className="hover:bg-gray-100 cursor-pointer" key={order.id}>
                                            <td title={!!order.notes ? 'This order has notes attached' : ''} className="truncate whitespace-nowrap py-4 text-sm text-gray-500 pl-6">
                                              <span>{!!order.notes ? (<PaperClipIcon className="h-5 w-5" />) : null}</span>
                                            </td>
                                            <td className="truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                                              <span className="w-1/6">{getOrderId(order)}</span>
                                            </td>
                                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                              {order.user?.firstName} {order.user?.lastName}
                                            </td>
                                            {/* @ts-ignore */}
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">{order.customer.customerName}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{order.orderItems.length}</td>
                                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium">{getOrderStatusName(order.status)}</td>
                                            <td className="whitespace-nowrap text-right px-3 py-4 text-sm text-gray-500">
                                              <DateFormatter dateFormats={{ year: "numeric", month: "numeric", day: "numeric" }} withTime={false} dateString={order.updatedAt} />
                                            </td>
                                          </tr>
                                        )
                                      }) : null
                                    }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            ) : (
                              <div className="flex flex-col items-center text-center justify-center mt-5 border-2 border-dashed border-gray-400 text-gray-500 rounded-lg p-10">
                                <NoSymbolIcon className="h-10 w-10 mb-2" />
                                <div>No orders found</div>
                              </div>
                            )
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              ) : (
                <div className="flex flex-col items-center h-full justify-center">
                  <div>
                    <Flexor justify="center">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
                      </svg>
                    </Flexor>
                    <h3 className="mt-2 text-lg text-center font-semibold text-gray-900">No item selected</h3>
                    <p className="mt-1 text-md text-gray-500">Get started by selecting an item on the left.</p>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}
