import { PollworkerWorkHistory, VotingLocation } from 'admin/src/types';
import { AnySlot, NodeType } from "./pollworkerVotingLocationDetailsUtils";
import { useDispatch, useSelector } from "react-redux";
import { getModuleInfo, getPollworkerSelector } from "../../../selectors/pollworker";
import { AppState } from 'admin/src/store';
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { assignToElection, deletePollworkerSchedule } from "../../../fetchers";
import { setShowPollworkerDetailsId } from "../../../reducers/pollworker/grid";
import dayjs from "dayjs";
import { classNames } from "shared/src/utils";
import { Flexor, TippyContent } from "shared/src/components";
import Tippy from "@tippyjs/react";
import { ArrowPathIcon, CakeIcon, ChevronRightIcon, IdentificationIcon, InformationCircleIcon, UserCircleIcon, UsersIcon } from "@heroicons/react/24/outline";
import VotingLocationImage from "./PollworkerVLImage";
import { Button, Popover, PopoverContent, PopoverTrigger } from "shared/src/components/ui";
import PollworkerVotingLocationPicker from "./PollworkerVotingLocationPicker";
import { SectionSubHeading } from "shared/src/components/SectionHeading";
import ScheduleDateAdorners from "./SchedulerAdorners";
import { PollworkerRequirements } from "./types";
import { Link, useParams } from "wouter";
import Spinner from "shared/src/components/Spinner";
import { addToSchedule } from "./utils/pollworkerScheduler";
import PollworkerVotingLocationNoSchedules from "./PollworkerVotingLocationNoSchedules";
import useVotingLocationData from "../../../hooks/useVotingLocationData";
import { WorkerLevel } from "../../enums/WorkerLevelEnum";
import useHandlePollworkers from "../../../hooks/useHandlePollworkers";
import VotingLocationAdorners from "./PollworkerVotingLocationAdorners";
import { useElectionDates } from "../../../hooks/useElectionDates";
import { useAppSelector } from "../../../hooks";

const PARTY_CODE_TO_COLOR_TRADITIONAL: { [code: string]: string } = {
  R: 'border-red-500 bg-red-100 text-red-700',
  D: 'border-blue-500 bg-blue-100 text-blue-700',
  I: 'border-yellow-500 bg-yellow-100 text-yellow-700',
};

export default function PollworkerVLTraditionalScheduler() {
  const [loadingPollworkers, , handlePollworkers] = useHandlePollworkers();
  const [selectedLocation, setSelectedLocation] = useState<VotingLocation>();
  const [pickerRequirements, setPickerRequirements] = useState<PollworkerRequirements>({});
  const [showPollworkerPicker, setShowPollworkerPicker] = useState<string>();
  const votingLocations = useAppSelector(state => state.pollworker.scheduler.filteredVotingLocations);
  const {onlyShowEarlyVotingLocations} = useSelector((state: AppState) => state.pollworker.scheduler.filters);
  const parties = useAppSelector(state => state.pollworker.scheduler.partiesById);
  const partiesByCode = useAppSelector(state => state.pollworker.scheduler.partiesByCode);
  const moduleInfo = useSelector(getModuleInfo);
  const pollworkers = useSelector(getPollworkerSelector);
  const electionDates = useElectionDates();
  const [loadingVotingLocationData, hasLoaded, scheduleDates, getVotingLocationData] = useVotingLocationData(parties, selectedLocation?.id);
  const dispatch = useDispatch();
  const {votingLocationId} = useParams();

  const pollworkersByEVUserId = useMemo(() => {
    return pollworkers.reduce((acc: {[evUserId: string]: PollworkerWorkHistory}, pollworker: PollworkerWorkHistory) => {
      acc[pollworker.keyEVUserId] = pollworker;
      return acc;
    }, {});
  }, [pollworkers]);

  useEffect(() => {
    if (onlyShowEarlyVotingLocations && !selectedLocation?.usedForEarlyVoting) {
      const firstEarlyVotingSite = votingLocations.find((vl) => vl.usedForEarlyVoting);
      setSelectedLocation(firstEarlyVotingSite);
    }
  }, [votingLocations, selectedLocation, onlyShowEarlyVotingLocations]);

  useEffect(() => {
    if (!votingLocationId || !votingLocations.length) return setSelectedLocation(undefined);

    const votingLocation = votingLocations.find(vl => vl.id === votingLocationId);

    if (!votingLocation) return setSelectedLocation(undefined);

    setSelectedLocation(votingLocation);
  }, [votingLocationId, votingLocations]);

  useEffect(() => {
    if (!selectedLocation) return;

    getVotingLocationData();
  }, [selectedLocation]);

  function handleShowPollworkerPicker(slot: AnySlot, date: string, slideoutKey: string) {
    dispatch(setShowPollworkerDetailsId(undefined));

    setPickerRequirements({
      scheduleDate: new Date(date).toISOString(),
      roleInfoId: slot.role.id,
      party: partiesByCode[slot.party],
    });

    setShowPollworkerPicker(slideoutKey);
  }

  async function handleAddPollworkerSchedule(votingLocationId: string, isSwap: boolean, slot: AnySlot, pollworker: PollworkerWorkHistory, startTime: string, endTime: string, notes: string, assignPollworkerToElection: boolean) {
    if (!moduleInfo?.ElectionId) return;

    if (isSwap) {
      await deletePollworkerSchedule(slot.schedule);
    }

    if (assignPollworkerToElection) {
      await assignToElection({
        electionId: moduleInfo.ElectionId,
        pollworkerIdList: [pollworker.id],
        schedules: [{
          endTime,
          startTime,
          userDefaultRole: false,
          precinctSelected: false,
          defaultPrecinctSelected: false,
          selectedLocationId: votingLocationId,
          workDates: [slot.schedule.workDate!],
        }],
        selectedLevel: WorkerLevel.Pollworker.id.toString(),
        selectedLocationId: votingLocationId,
        selectedPollworkerRoleId: slot.role.id,
        useDefaultPollworkerRole: false,
        useDefaultPrecinct: false,
      });
    }

    await addToSchedule(votingLocationId, moduleInfo.ElectionId, pickerRequirements.roleInfoId, pickerRequirements.scheduleDate, pollworker, startTime, endTime, notes);

    handlePollworkers();
    getVotingLocationData();
    setShowPollworkerPicker(undefined);
  }

  if (!moduleInfo) return null;

  return (
    <div className="flex flex-col justify-start h-full">
      <Flexor className='bg-white border-b border-gray-300 px-4 py-1.5'>
        <div>
          <div className="font-semibold">{moduleInfo?.Election?.ElectionName}</div>
          {
            moduleInfo.Election && !selectedLocation ? (
              <div className="text-xs text-gray-800">
                {dayjs(electionDates[0]).format('ddd, MMM D, YYYY')} - {dayjs(electionDates[electionDates.length - 1]).format('ddd, MMM D, YYYY')}
              </div>
            ) : null
          }
        </div>
      </Flexor>
      <div className='overflow-y-auto flex grow divide-x divide-gray-300'>
        {
          selectedLocation ? (
            <>
              <div className='flex-[33_1_0%] shrink-0 overflow-y-auto divide-y h-full bg-white'>
                {
                  votingLocations.map((votingLocation) => {
                    const isSelected = selectedLocation?.id === votingLocation.id;

                    if (onlyShowEarlyVotingLocations && !votingLocation.usedForEarlyVoting) return null;

                    return (
                      <Link to={`/scheduler/traditional/${votingLocation.id}`} key={`voting_loation_${votingLocation.id}`}>
                        <div className={classNames(isSelected ? 'bg-blue-100 sticky top-0' : 'hover:bg-gray-100', "cursor-pointer font-semibold truncate px-3 py-3 transition-colors w-full flex items-center justify-between space-x-2")}>
                          <Flexor justify='start' className='space-x-1 w-3/4'>
                            <VotingLocationImage votingLocation={votingLocation}/>
                            <div className='truncate'>{votingLocation.locationName}</div>
                          </Flexor>
                          <Flexor justify='start' className="space-x-2">
                            <VotingLocationAdorners votingLocation={votingLocation}/>
                          </Flexor>
                        </div>
                      </Link>
                    )
                  })
                }
              </div>
              <div className="overflow-y-auto pl-0 p-5 flex-[80_1_0%]">
                <div>
                  <div className="space-y-4 pl-4">
                    <div className="flex grow items-center justify-between">
                      <Flexor className='space-x-2'>
                        <div className='flex items-center space-x-1'>
                          <div className="w-full font-semibold">{selectedLocation.locationName}</div>
                          <ArrowPathIcon data-testid='reload-location-data' onClick={getVotingLocationData} className={classNames(loadingVotingLocationData && hasLoaded ? 'animate-spin' : '', 'size-5')} />
                        </div>
                        <VotingLocationAdorners votingLocation={selectedLocation} />
                      </Flexor>
                      {
                        !loadingVotingLocationData ? (
                          <div className={classNames(!scheduleDates.length ? 'border-red-500 bg-red-100 text-red-700' : 'border-blue-500 bg-blue-100 text-blue-700', 'shrink-0 rounded-full border px-3 py-0.5 text-xs')}>{scheduleDates.length} days total</div>
                        ) : null
                      }
                    </div>
                    <PollworkerVotingLocationNoSchedules show={!loadingVotingLocationData && !scheduleDates.length} refresh={getVotingLocationData} />
                    {
                      loadingVotingLocationData && !hasLoaded ? (
                        <div className="w-full h-56 flex items-center justify-center space-x-2">
                          <Spinner/>
                          <div>Loading schedules...</div>
                        </div>
                      ) : null
                    }
                    {
                      scheduleDates.map(([dateString, schedulesByRole]) => {
                        const date = dayjs(dateString);
                        const votingLocation = selectedLocation;
                        const schedulesByRoleRolesEntries = Object.entries(schedulesByRole.roles);
                        const {
                          isUnassignedDate,
                          overProvisionedIndex,
                          hasUnrequestedParty,
                        } = schedulesByRole.meta;
                        const containsError = isUnassignedDate || hasUnrequestedParty || (overProvisionedIndex && overProvisionedIndex >= 0);

                        return (
                          <div
                            key={`${votingLocation.id}_${dateString}`}
                            className={classNames(hasLoaded && loadingVotingLocationData ? 'opacity-50' : '', 'space-y-2 transition-opacity')}
                          >
                            <div className="mb-1.5 flex items-center justify-between text-gray-700">
                              <div className="flex items-center space-x-2">
                                <span className={classNames(containsError ? 'text-red-500' : '')}>{date.format('ddd, MMM D, YYYY')}</span>
                                <ScheduleDateAdorners schedulesByRole={schedulesByRole} />
                              </div>
                              <div>{schedulesByRoleRolesEntries.length} roles</div>
                            </div>
                            {
                              schedulesByRoleRolesEntries.map(([roleName, schedules]) => {
                                const data = schedules;
                                const allowedPartyCodes = Object.keys(data.workerCountByDateByRoleByParty);

                                return (
                                  <Fragment key={`${date}_${votingLocation.id}_${roleName}`}>
                                    <div className="mb-1.5 flex items-center justify-between text-gray-700">
                                      <Flexor className='space-x-1'>
                                        <div>{roleName}</div>
                                        {
                                          data.meta?.isGlobalRole ? (
                                            <Tippy content={<TippyContent>This role is added when a date isn't specified in "Voting Location Positions".</TippyContent>}>
                                              <InformationCircleIcon className="size-4"/>
                                            </Tippy>
                                          ) : null
                                        }
                                      </Flexor>
                                      <Flexor className='space-x-1'>
                                        <>
                                          {
                                            allowedPartyCodes.map((code) => {
                                              if (!code || code === 'undefined') return null;

                                              const count = data.workerCountByDateByRoleByParty?.[code];
                                              const partyName = partiesByCode[code]?.partyName;
                                              const bgColor = PARTY_CODE_TO_COLOR_TRADITIONAL[code];

                                              return (
                                                <Tippy key={`party-counts-${date}-${count}_${code}`} content={<TippyContent>{count} {partyName}</TippyContent>}>
                                                  <div className={classNames(bgColor ? bgColor : 'border-gray-500 bg-gray-100 text-gray-700', "shrink-0 rounded-full border px-1.5 py-0.5 text-xs")}>{count} {code}</div>
                                                </Tippy>
                                              );
                                            })
                                          }
                                          {
                                            data.workerCountByRole[roleName] ? (
                                              <Tippy content={<TippyContent>Total number of positions allowed</TippyContent>}>
                                                <div className="rounded py-0.5 px-1 flex items-center space-x-1">
                                                  <UsersIcon className="size-4"/>
                                                  <span>{data.workerCountByRole[roleName]}</span>
                                                </div>
                                              </Tippy>
                                            ) : null
                                          }
                                        </>
                                      </Flexor>
                                    </div>
                                    <div className='divide-y divide-gray-300 rounded-lg border border-gray-300 bg-white shadow-sm'>
                                      {
                                        schedules.slots.map((slot, slotIdx) => {
                                          const slideoutKey = [votingLocation.id, date, roleName, slotIdx].join('-');
                                          const showSlideout = slideoutKey === showPollworkerPicker;
                                          const isEmpty = slot.nodeType !== NodeType.scheduled;
                                          const assigneeParty = partiesByCode[slot.assigneeParty as string]?.partyName;
                                          const slotRequiredParty = partiesByCode[slot.party]?.partyName;
                                          const key = `${votingLocation.id}_${date}_slot_${slotIdx}`;

                                          if (slot.nodeType === NodeType.overprovisioned) {
                                            return (
                                              <div key={key} className="h-0 w-full mx-auto flex justify-center relative z-50">
                                                <span className="text-xs bg-white px-2 -mt-[8px] z-20">Over-provisioned</span>
                                                <div className="h-0.5 w-full absolute -top-0.5 bg-ev-red z-0"/>
                                              </div>
                                            )
                                          }

                                          return (
                                            <div key={key} className="relative flex items-center space-x-3 px-3 py-3 hover:border-gray-400">
                                              <div className="item-center flex min-w-0 flex-1 justify-between">
                                                {
                                                  slot.nodeType === NodeType.assigned ? (
                                                    <div className="flex items-center space-x-1 font-medium text-sm">
                                                      {slotRequiredParty ? <div>Assign a <span className='font-semibold'>{slotRequiredParty}</span></div> : <div>Unassigned, all parties accepted</div>}
                                                    </div>
                                                  ) : (
                                                    <div className="focus:outline-none text-sm text-gray-900">
                                                      <div className={classNames(!!pollworkersByEVUserId[slot?.keyEVUserId] ? '' : 'text-yellow-700', loadingPollworkers ? 'opacity-50' : '', 'flex items-center space-x-1')}>
                                                        <UserCircleIcon className='size-4' />
                                                        <Tippy content={<TippyContent>Details cannot be shown, this user is not assigned to the current election.</TippyContent>} disabled={!!pollworkersByEVUserId[slot?.keyEVUserId]}>
                                                          <span data-testid={`pollworker-${slot.pollworkerInfo?.id}`}>{[slot.pollworkerInfo?.firstName, slot.pollworkerInfo?.lastName].filter(n => !!n).join(' ')}</span>
                                                        </Tippy>
                                                        {
                                                          !!pollworkersByEVUserId[slot?.keyEVUserId] ? (
                                                            <IdentificationIcon data-testid={`show-details-pollworker-evuserid-${pollworkersByEVUserId[slot?.keyEVUserId]}`} className='size-4' onClick={(event) => {
                                                              event.stopPropagation();

                                                              if (isEmpty) return;

                                                              const pollworker = pollworkers.find((pw: PollworkerWorkHistory) => pw.keyEVUserId === slot?.keyEVUserId);

                                                              dispatch(setShowPollworkerDetailsId(pollworker?.id));
                                                            }} />
                                                          ) : null
                                                        }
                                                      </div>
                                                      <div className="mt-2 flex items-center space-x-3">
                                                        <div className="flex items-center space-x-1 truncate">
                                                          <CakeIcon className='size-4' />
                                                          <span className={!assigneeParty ? 'italic' : ''}>{assigneeParty || 'No party' }</span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                }
                                                <div className="flex justify-center space-x-5 text-xs text-gray-500">
                                                  <div className="flex items-center">
                                                    <Popover open={showSlideout} onOpenChange={(open) => {
                                                      if (!open) {
                                                        setShowPollworkerPicker(undefined);
                                                        return;
                                                      }

                                                      handleShowPollworkerPicker(slot, dateString, slideoutKey);
                                                    }}>
                                                      <PopoverTrigger asChild>
                                                        <Button variant="quaternary" className="p-0">{isEmpty ? 'Assign' : 'Swap'}</Button>
                                                      </PopoverTrigger>
                                                      <PopoverContent side='left' className='relative max-h-[400px] w-[350px] overflow-y-auto overflow-x-hidden p-0 bg-white'>
                                                        <PollworkerVotingLocationPicker
                                                          partiesByCode={partiesByCode}
                                                          locationAssignments={data}
                                                          onAdd={(pollworker: PollworkerWorkHistory, startTime: string, endTime: string, notes: string, assignToElection) => {
                                                            handleAddPollworkerSchedule(votingLocation.id, !isEmpty, slot, pollworker, startTime, endTime, notes, assignToElection);
                                                          }}
                                                          requirements={pickerRequirements}
                                                          votingLocation={votingLocation}
                                                        />
                                                      </PopoverContent>
                                                    </Popover>
                                                    {!isEmpty ? (
                                                      <Button onClick={async () => {
                                                        if (!window.confirm(`Are you sure you want to remove ${slot.pollworkerInfo?.firstName} ${slot.pollworkerInfo?.lastName} from the ${slot.role.roleName} role on ${dateString}?`)) return;
                                                        await deletePollworkerSchedule(slot.schedule);
                                                        getVotingLocationData();
                                                        setShowPollworkerPicker(undefined);
                                                      }} variant="quaternary" className="p-0">
                                                        Remove
                                                      </Button>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })
                                      }
                                    </div>
                                  </Fragment>
                                )
                              })
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className='m-5 mt-2 space-y-2 w-full'>
              {
                votingLocations.length ? (
                  <>
                    <SectionSubHeading>Voting Locations</SectionSubHeading>
                    <div className="grow overflow-hidden rounded-lg border border-gray-300 divide-y divide-gray-300">
                      {
                        votingLocations.map((votingLocation) => {
                          return (
                            <Link to={`/scheduler/traditional/${votingLocation.id}`} key={votingLocation.id} className="w-full">
                              <div>
                                <div className="bg-white hover:bg-gray-100 transition-colors w-full">
                                  <div className="relative flex items-center space-x-3 p-3 hover:border-gray-400">
                                    <VotingLocationImage votingLocation={votingLocation}/>
                                    <div className="item-center flex min-w-0 flex-1 justify-between">
                                      <div className="focus:outline-none text-gray-900 text-sm">
                                        <div className="font-semibold">
                                          <span>{votingLocation.locationName} · {votingLocation.locationCode}</span>
                                        </div>
                                        <div className="text-xs text-gray-700">{votingLocation.address1}{votingLocation.address2 ? ` ${votingLocation.address2},` : null}, {votingLocation.city}, {votingLocation.state}, {votingLocation.zip}</div>
                                      </div>
                                      <div className="flex justify-center space-x-5 text-xs text-gray-500">
                                        <div className="flex items-center space-x-2">
                                          <VotingLocationAdorners votingLocation={votingLocation}/>
                                        </div>
                                        <div className="flex flex-col justify-center">
                                          <ChevronRightIcon className="size-5"/>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })
                      }
                    </div>
                  </>
                ) : (
                  <div className="w-full h-56 flex items-center justify-center space-x-2">
                    <Spinner/>
                    <div>Loading voting locations...</div>
                  </div>
                )
              }
            </div>
          )
        }
      </div>
    </div>
  )
}
