import { loadElections, setLoading, setElections } from 'admin/src/reducers/electionsApp';
import { getElectionsForCustomer } from 'admin/src/fetchers';
import { call, put, takeEvery } from "redux-saga/effects";
import type { DataburstResponse } from "../types/DataburstResponse";

export default function* electionsAppSaga() {
  yield takeEvery(loadElections.type, handleLoadElections);
}

export function* handleLoadElections() {
  try {
    yield put(setLoading(true));
    const resp: DataburstResponse<string> = yield call(getElectionsForCustomer);
    const elections = JSON.parse(atob(resp.data));
    yield put(setElections(elections));
  } finally {
    yield put(setLoading(false));
  }
}
