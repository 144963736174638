import {combineReducers} from "redux";
import electionReducer from "./election";
import {currentPollworkerUserReducer} from "./currentUser";
import {currentCustomerReducer} from "./currentCustomer";
import {
  CookieAlert,
  PollworkerPublicCustomer,
  PollworkerPublicUser
} from "../../types";
import featureFlagReducer from '../featureFlag';
import timeclockReducer from '../timeclock';
import scheduleReducer from '../schedule';

export type PollworkerPublicState = {
  electionId: string,
  election: {
    electionId: string,
  },
  workScheduleId: string,
  currentPollworkerUser: PollworkerPublicUser
  currentCustomer: PollworkerPublicCustomer
  cookieAlert: CookieAlert,
}

export default combineReducers({
  currentCustomer: currentCustomerReducer,
  currentPollworkerUser: currentPollworkerUserReducer,
  election: electionReducer,
  featureFlags: featureFlagReducer,
  schedule: scheduleReducer,
  timeclock: timeclockReducer
});
