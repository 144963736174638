import { all } from 'redux-saga/effects'
import { desktopAuthSaga, webAuthSaga } from './auth';
import { easyOrderSaga } from './easyOrder';
import electionsAppSaga from "./elections";
import { featureFlagsSaga } from './featureFlags';

export function* webRootSaga() {
  yield all([
    easyOrderSaga(),
    webAuthSaga(),
    electionsAppSaga(),
    featureFlagsSaga(),
  ])
}

export function* desktopRootSaga() {
  yield all([
    easyOrderSaga(),
    desktopAuthSaga(),
    featureFlagsSaga(),
  ])
}
