import {AnyAction} from "@reduxjs/toolkit";

import {CONSUMABLE_ACTION_TYPES} from "../actions/consumable";
import {ConsumableState} from "../types/ConsumableItem";

const defaultConsumableState = {
  data: [],
  fetchStatus: { loading: false, error: '' }
};

export default function consumablesReducer(state: ConsumableState = defaultConsumableState, action: AnyAction): ConsumableState {
  switch (action.type) {
    case CONSUMABLE_ACTION_TYPES.LOADING_CONSUMABLES:
      return {...state, fetchStatus: {...state.fetchStatus, loading: true}};
    case CONSUMABLE_ACTION_TYPES.LOADED_CONSUMABLES:
      return {...state, fetchStatus: {...state.fetchStatus, loading: false}};
    case CONSUMABLE_ACTION_TYPES.CLEAR:
      return {...state, data: []};
    case CONSUMABLE_ACTION_TYPES.ADD_CONSUMABLE_ITEMS:
      return {...state, data: action.payload};
    default:
      // console.log('Missed action', action);
      return state;
  }
}
