import {configureStore, createListenerMiddleware} from "@reduxjs/toolkit";
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import {setPollworkerSettings} from "./reducers/pollworker/settings";

const listenerMiddleware = createListenerMiddleware()

listenerMiddleware.startListening({
  actionCreator: setPollworkerSettings,
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState() as any;
    const settingsJson = JSON.stringify(state.pollworker.settings);
    window.localStorage.setItem('pollworker:settings', settingsJson);
  },
})

export const createStore = (preloadedState = {}) => {
  const middleware: any[] = [];
  const sagaMiddleware = createSagaMiddleware();

  if (process.env.NODE_ENV !== 'production') {
    middleware.unshift(require('redux-immutable-state-invariant').default());
  }

  middleware.unshift(listenerMiddleware.middleware);
  middleware.unshift(sagaMiddleware);

  const newStore = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(middleware),
    preloadedState
  });

  return { store: newStore, sagaMiddleware };
}

const { store, sagaMiddleware } = createStore();

export default { store, sagaMiddleware };

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
