import React, {Fragment, Suspense, useCallback, useEffect, useRef, useState} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArchiveBoxIcon, ArrowUturnLeftIcon,
  CalculatorIcon, ChevronLeftIcon,
  ChevronRightIcon,
  ClipboardDocumentListIcon, ExclamationTriangleIcon,
  PencilSquareIcon,
  PrinterIcon, TrashIcon, XMarkIcon,
} from '@heroicons/react/24/outline';
import Spinner from "../../../../shared/src/components/Spinner";
import { Button } from "shared/src/components/ui";
import StoreItemImage, {StoreItemImageFallback} from "../../screens/store/StoreItemImage";
import { Blink, Flexor } from "shared/src/components";
import {Order, OrderItem} from "../../types/Order";
import {useDispatch, useSelector} from "react-redux";
import EasyVoteButton from "../admin/EasyVoteButton";
import {getOrderId, getOrderStatusName} from "../../utils/getters";
import {SectionHeading, SectionSubSubHeading} from "shared/src/components/SectionHeading";
import {
  getOrderById,
  updateOrderAsShipped,
  updateOrderStatus
} from "../../fetchers";
import {classNames} from "shared/src/utils/classNames";
import {OrderItemQuantityEditForm} from "../../screens/store/OrderItemQuantityEditForm";
import {updateOrderItemQuantity} from "../../actions/admin/order";
import { AppState } from 'admin/src/store';
import TippyContent from "../../../../shared/src/components/TippyContent";
import Tippy from "@tippyjs/react";
import ReactToPrint from "react-to-print";
import useCountyConfig from "../../hooks/useCountyConfig";
import DeprecatedModal from "shared/src/components/DeprecatedModal";

type ModalPackingListProps = {
  orderId?: string;
  show: boolean;
  onClose: Function;
  isClosed: Function;
}

type ModalPackingListChecklistProps = {
  order?: Order;
  allPacked: Function;
  mustCancelOrder: Function;
  readOnly?: boolean;
};

export function ModalPackingListChecklist({order, allPacked, mustCancelOrder, readOnly = false}: ModalPackingListChecklistProps) {
  const [packedItems, setPackedItems] = useState<OrderItem[]>([]);
  const [orderItemToUpdate, setOrderItemToUpdate] = useState<OrderItem>();
  const user = useSelector((state: AppState) => state.user);
  const reactToPrintContentRef = useRef(null);
  const countyConfig = useCountyConfig(order);
  const dispatch = useDispatch();

  const reactToPrintContent = useCallback(() => {
    return reactToPrintContentRef.current;
  }, [reactToPrintContentRef.current]);

  const reactToPrintTrigger = useCallback(() => {
    return (
      <Button variant='secondary'>
        <PrinterIcon className="size-4"/>
        <span>Print List</span>
      </Button>
    );
  }, []);

  useEffect(() => {
    allPacked(order?.orderItems.length === packedItems.length);
  }, [packedItems, order]);

  useEffect(() => {
    if (!order?.orderItems.find((oi) => !oi.deleted)) {
      mustCancelOrder(true);
    } else {
      mustCancelOrder(false);
    }
  }, [order?.orderItems]);

  useEffect(() => {
    packAndRemoveItemsWithDeletedInventoryItems(order);
  }, [order?.orderItems]);

  function packAndRemoveItemsWithDeletedInventoryItems(order?: Order) {
    order?.orderItems
    .filter(oi => oi.inventoryEquipType.deleted && !oi.deleted)
    .forEach((oi) => {
      packItem(oi);
      removeItemFromOrder(order, oi);
    });
  }

  function packItem(item: OrderItem) {
    setPackedItems([...packedItems, item]);
  }

  function removePackedItem(item: OrderItem) {
    setPackedItems(packedItems.filter((i: OrderItem) => i.id !== item.id));
  }

  function removeItemFromOrder(order: Order, item: OrderItem) {
    dispatch(updateOrderItemQuantity({
      orderId: order.id,
      orderItemId: item.id,
      key: 'deleted',
      value: true,
    }));
  }

  if (!order) {
    return (
      <Flexor className="space-x-1 mt-15" justify="center">
        <Spinner />
        <span>Loading the latest order information...</span>
      </Flexor>
    );
  }

  return (
    <div className="mt-2">
      <div className="hidden">
        <div ref={reactToPrintContentRef}>
          <div className="w-full flex justify-between">
            <SectionHeading>Packing List</SectionHeading>
            <div className="w-1/2 pl-10">
              <div className="border-2">
                <p className="font-bold bg-blue-500 text-white text-center">Ship To</p>
                <Flexor className="divide-gray-300 divide-x border-b">
                  <span className="w-1/3 font-bold">Ordered By</span>
                  <span className="w-1/2 text-right">{[order.user.firstName, order.user.lastName].join(' ')}</span>
                </Flexor>
                <Flexor className="divide-gray-300 divide-x border-b">
                  <span className="w-1/3 font-bold">Address</span>
                  <span className="w-1/2 text-right">{[[countyConfig?.shippingAddress, countyConfig?.shippingAddress2].join(', '), [countyConfig?.shippingCity, countyConfig?.shippingState].join(', '), countyConfig?.shippingZipCode].join("\n")}</span>
                </Flexor>
                <Flexor className="divide-gray-300 divide-x border-b">
                  <span className="w-1/3 font-bold">Order ID</span>
                  <span className="w-1/2 text-right">{order && getOrderId(order)}</span>
                </Flexor>
                <Flexor className="divide-gray-300 divide-x border-b">
                  <span className="w-1/3 font-bold">FulFilled By</span>
                  <span className="w-1/2 text-right">{user?.user?.firstName} {user?.user?.lastName}</span>
                </Flexor>
              </div>
            </div>
          </div>
          <br />
          <br />
          <table className="border w-full p-10">
            <thead className="font-bold">
              <tr>
                <td className="border text-left truncate w-1/6">Picture</td>
                <td className="border text-center">Item Name</td>
                <td className="border text-center">Ordered Quantity</td>
                <td className="border text-center">Fulfilled Quantity</td>
                <td className="border text-center">Complete</td>
              </tr>
            </thead>
            <tbody>
            {
              order.orderItems.map((item) => {
                return (
                  <Fragment key={`checklist_order_items_${order.id}_${item.id}`}>
                    <tr key={`print_order_item_${order.id}`} className={item.deleted ? 'line-through' : ''}>
                      <td className="border p-2"><StoreItemImage className="w-2/3" item={item.inventoryEquipType} /></td>
                      <td className="border p-2">{item.inventoryEquipType.equipTypeName}</td>
                      <td className="border p-2">{item.quantity}</td>
                      <td className="border p-2">
                        {
                          item.deleted ? (
                            <div className="w-1/2 mx-auto border-b flex items-center border-black mt-8">
                              <XMarkIcon className='h-8 w-8' />
                              <XMarkIcon className='h-8 w-8' />
                              <XMarkIcon className='h-8 w-8' />
                            </div>
                          ) : (
                            <div className="w-1/2 mx-auto border-b border-black mt-8"></div>
                          )
                        }
                      </td>
                      <td className="border p-2">
                      {
                          item.deleted ? (
                            <XMarkIcon className='w-8 h-8' />
                          ) : (
                            <input type="checkbox" className="w-8 h-8 border rounded-md mx-auto block "/>
                          )
                        }
                      </td>
                    </tr>
                    <tr className="break-after">
                      <td colSpan={5} className="font-bold text-center">Notes</td>
                    </tr>
                    <tr>
                      <td colSpan={5} className="font-bold text-center">
                        <div className="m-2 bg-gray-100 h-24"></div>
                      </td>
                    </tr>
                  </Fragment>
                )
              })
            }
            </tbody>
          </table>
        </div>
      </div>
      <div className="overflow-hidden bg-white shadow sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {order.orderItems.map((item: OrderItem) => (
            <li key={item.id} className={classNames(item.deleted || item.inventoryEquipType.deleted ? 'line-through' : '')}>
              <div className="block hover:bg-gray-50 cursor-pointer">
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="flex min-w-0 flex-1 items-center">
                    <div className="flex-shrink-0">
                      <Suspense fallback={<StoreItemImageFallback/>}>
                        <StoreItemImage item={item.inventoryEquipType} className="h-12 w-12 rounded-full"/>
                      </Suspense>
                    </div>
                    <div className="min-w-0 flex-1 px-4 grid grid-cols-3 text-md font-bold text-gray-900">
                      <div className="flex items-center text-md text-gray-800">
                        <ArchiveBoxIcon className="mr-1.5 h-5 w-5 flex-shrink-0" aria-hidden="true"/>
                        <span className=''>{item.inventoryEquipType.equipTypeName}</span>
                      </div>
                      <div className="relative flex items-center justify-center">
                        <div>
                          <p className="text-md font-bold text-gray-900">
                            { item.inventoryEquipType.deleted ? 'Deleted' : 'In Stock' }
                          </p>
                          {
                            !readOnly ? (
                              <>
                                {!orderItemToUpdate || orderItemToUpdate?.id !== item.id ? (
                                  <p onDoubleClick={() => setOrderItemToUpdate(item)} className="mt-2 flex items-center text-md text-gray-800 font-bold">
                                    <CalculatorIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-600" aria-hidden="true" />
                                    {item.quantity}
                                    {
                                      !item.inventoryEquipType.deleted ? (
                                        <PencilSquareIcon
                                          role='button'
                                          onClick={() => setOrderItemToUpdate(item)}
                                          className="hover:text-gray-800 transition-colors ml-1.5 h-4 w-4 flex-shrink-0 text-gray-500"
                                          aria-hidden="true"
                                        />
                                      ) : null
                                    }
                                  </p>
                                ) : (
                                  <>
                                    <OrderItemQuantityEditForm
                                      item={orderItemToUpdate}
                                      className='-mt-1 -ml-3 relative z-10 scale-75'
                                      onBlur={({target: {value}}) => {
                                        setOrderItemToUpdate(undefined);
                                        dispatch(updateOrderItemQuantity({
                                          orderId: order.id,
                                          orderItemId: orderItemToUpdate.id,
                                          key: 'quantity',
                                          value: Number.parseInt(value),
                                        }));
                                      }}
                                    />
                                  </>
                                )}
                              </>
                            ) : null
                          }
                        </div>
                      </div>
                      <Flexor justify="end" className="hidden md:flex">
                        <EasyVoteButton disabled={item.inventoryEquipType.deleted} eventToSend={`Dialog:EditEquipmentType:Open:${item.keyInventoryItemId}`}/>
                      </Flexor>
                    </div>
                  </div>

                  {
                    !readOnly ? (
                      <>
                        <Tippy content={<TippyContent>Check this box once it has been placed in the shipping container</TippyContent>}>
                          <input
                            type="checkbox"
                            disabled={item.inventoryEquipType.deleted}
                            checked={packedItems.some(pi => pi.id === item.id)}
                            onChange={({target: {checked}}) => (checked ? packItem(item) : removePackedItem(item))}
                            className="disabled:opacity-50 h-7 w-7 rounded border-gray-300 text-green-600 focus:ring-green-600"
                          />
                        </Tippy>
                        {
                          item.deleted ? (
                            <Tippy content={<TippyContent>Restore this item to the order.</TippyContent>}>
                              <button disabled={item.inventoryEquipType.deleted} className='disabled:pointer-events-none disabled:opacity-50'>
                                <ArrowUturnLeftIcon
                                  onClick={() => {
                                    dispatch(updateOrderItemQuantity({
                                      orderId: order.id,
                                      orderItemId: item.id,
                                      key: 'deleted',
                                      value: false,
                                    }));
                                  }}
                                  className="h-7 w-7 text-gray-600 hover:text-green-500 transition-colors ml-2"
                                />
                              </button>
                            </Tippy>
                          ) : (
                            <Tippy content={<TippyContent>This will remove the item from the order</TippyContent>}>
                              <TrashIcon onClick={() => removeItemFromOrder(order, item)} className="h-7 w-7 text-gray-600 hover:text-ev-red transition-colors ml-2" />
                            </Tippy>
                          )
                        }
                      </>
                    ) : null
                  }
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <div className="w-full flex justify-end mt-4">
        <ReactToPrint
          content={reactToPrintContent}
          documentTitle={`PackingList-${order.id}`}
          removeAfterPrint
          trigger={reactToPrintTrigger}
        />
      </div>

      <div className="w-auto -mt-7 mb-6">
        <span className="text-gray-600 text-sm">Order notes</span>
        {order.notes && <p className="text-gray-800 text-md border-l border-gray-300 my-1 p-2">{order.notes}</p>}
        {!order.notes && <p className="italic text-gray-800 text-md my-1 pt-2">No notes submitted</p>}
      </div>
      {
        !readOnly ? (
          <>
            <span className="w-auto flex justify-center text-sm px-5 text-gray-700 text-center">
              <span className="bg-gray-100 px-3">
                Check off each item as it's placed in the shipping container to continue
              </span>
            </span>
            <hr className="-mt-2.5"/>
          </>
        ) : null
      }
    </div>
  )
}

function ModalPackingListShipping({orderId, onNoteUpdated}: { orderId?: string, onNoteUpdated: Function }) {
  const order = useSelector((state: AppState) => state.orders.data.find((o) => o.id === orderId));
  const countyConfig = useCountyConfig(order);

  if (!order) return null;

  return (
    <>
      <SectionHeading>Suggested shipping address</SectionHeading>
      <SectionSubSubHeading className="text-sm w-3/4 text-gray-600 mt-2 font-normal">This is the preferred address, though if there is a different one the user has specified, confirm that address and use it instead.</SectionSubSubHeading>
      {
        !countyConfig ? (
          <Flexor justify="center" className="space-x-2 my-5 w-full text-center">
            <Spinner light={false} />
            <span>Loading shipping information...</span>
          </Flexor>
        ) : (
          <>
            <div className="mt-5 bg-white border rounded-lg overflow-hidden divide-gray-200 divide-y">
              <div key='shippingAddress' className="flex-col p-4">
                <Flexor className="">
                  <div>Address 1</div>
                  <div>{countyConfig.shippingAddress || <i className="text-gray-500">Not listed</i>}</div>
                </Flexor>
              </div>
              <div key='shippingAddress2' className="flex-col p-4">
                <Flexor className="">
                  <div>Address 2</div>
                  <div>{countyConfig.shippingAddress2 || <i className="text-gray-500">Not listed</i>}</div>
                </Flexor>
              </div>
              <div key='shippingCity' className="flex-col p-4">
                <Flexor className="">
                  <div>City</div>
                  <div>{countyConfig.shippingCity || <i className="text-gray-500">Not listed</i>}</div>
                </Flexor>
              </div>
              <div key='shippingState' className="flex-col p-4">
                <Flexor className="">
                  <div>State</div>
                  <div>{countyConfig.shippingState || <i className="text-gray-500">Not listed</i>}</div>
                </Flexor>
              </div>
              <div key='shippingZipCode' className="flex-col p-4">
                <Flexor className="">
                  <div>Zip Code</div>
                  <div>{countyConfig.shippingZipCode || <i className="text-gray-500">Not listed</i>}</div>
                </Flexor>
              </div>
            </div>
            <br/>
            <label className="text-md text-gray-800 font-medium ml-1" htmlFor="comment">Send a note to the person who placed this order</label>
            <textarea
              rows={4}
              name="comment"
              id="comment"
              placeholder="Optional"
              className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
              defaultValue={''}
              onChange={({target: {value}}) => onNoteUpdated(value)}
            />
          </>
        )
      }
    </>
  )
}

export default function ModalPackingList({show, orderId, onClose, isClosed}: ModalPackingListProps) {
  const [canShowShipping, setCanShowShipping] = useState<boolean>(false);
  const [showShipping, setShowShipping] = useState<boolean>(false);
  const [mustCancel, setMustCancel] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [shippingNote, setShippingNote] = useState<string>('');
  const cancelButtonRef = useRef(null);
  const order = useSelector((state: AppState) => state.orders.data.find((o) => o.id === orderId));

  useEffect(() => {
    if (!order) resetToStart();
  }, [order]);

  function allPacked(isAllPacked: boolean) {
    setCanShowShipping(isAllPacked);
  }

  function close(open: boolean) {
    onClose(open);
  }

  function renderConfirmationButton() {
    let bgColor = 'bg-ev-blue';
    let state = (
      <>
        <Spinner light/>
        <span>Pack all items</span>
      </>
    );

    if (canShowShipping && !showShipping) {
      state = (
        <>
          <span className="animate-pulse">Continue to Shipping</span>
          <ChevronRightIcon className="h-4 w-4 text-white"/>
        </>
      );
    }

    if (showShipping) {
      bgColor = 'bg-ev-red';

      state = (
        <>
          <span className="animate-pulse">Item has shipped</span>
          <ChevronRightIcon className="h-4 w-4 text-white"/>
        </>
      );
    }

    if (mustCancel) {
      state = (
        <>
          <ExclamationTriangleIcon className="h-4 w-4 text-white"/>
          <span className="animate-pulse">Must Cancel</span>
        </>
      );
    }

    const disabled = mustCancel || !canShowShipping;

    return (
      <Button
        disabled={disabled}
        className={classNames(bgColor, 'transition-all inline-flex justify-center rounded-md space-x-1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ev-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outlibg-ev-blue sm:col-start-2')}
        onClick={() => {
          if (canShowShipping) setShowShipping(true);
          if (showShipping && order) {
            setSaving(true);
            updateOrderAsShipped(order, shippingNote)
            .then((response) => {
              console.log('Order shipped', JSON.parse(atob(response.data)))
            })
            .finally(() => {
              setTimeout(() => {
                setSaving(false);
                onClose();
              }, 3000);
            });
          }
        }}
      >
        {state}
      </Button>
    )
  }

  function resetToStart() {
    setShowShipping(false);
    setCanShowShipping(false);
  }

  return (
    <Transition.Root afterLeave={() => isClosed(true)} afterEnter={() => isClosed(false)} show={show} as={Fragment}>
      <Dialog as="div" className="relative z-30" initialFocus={cancelButtonRef} onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
        </Transition.Child>

        <div className="fixed inset-0 z-40 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-gray-100 px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div
                  className={`${classNames(saving ? 'opacity-75' : 'opacity-0 hidden')} transition-all absolute top-0 left-0 h-full w-full z-20 bg-gradient-to-br from-ev-blue to-pink-800 animate-pulse flex items-center justify-center`}>
                  <div className="flex flex-col items-center space-y-2">
                    <Spinner large light/>
                    <div className="text-white text-sm">Packaging order...</div>
                  </div>
                </div>
                <div>
                  <div className="absolute right-6 top-2.5 text-xs text-gray-600 flex justify-end w-full">
                    <Blink on={!order}><span className="ml-2 text-gray-500 text-xs">ID: {order && getOrderId(order)}</span></Blink>
                  </div>
                  <div className="mt-3">
                    <Dialog.Title as="h2" className="text-lg flex items-center justify-between mb-4 font-semibold leading-3 text-gray-900 text-center">
                      <Flexor items="center" justify="start" className="space-x-2">
                        <ClipboardDocumentListIcon className="h-7 w-7 text-gray-800" aria-hidden="true"/>
                        <span>Order Packing List</span>
                      </Flexor>
                    </Dialog.Title>
                    {!showShipping && <ModalPackingListChecklist mustCancelOrder={(value: boolean) => setMustCancel(value)} order={order} allPacked={allPacked}/>}
                    {showShipping && <ModalPackingListShipping orderId={orderId} onNoteUpdated={(value: string) => setShippingNote(value)} />}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex items-center justify-between">
                  <Flexor>
                    <Button
                      variant='tertiary'
                      onClick={() => {
                        if (showShipping) return resetToStart();
                        close(false);
                      }}
                      ref={cancelButtonRef}
                    >
                      {showShipping ? (
                        <Flexor>
                          <ChevronLeftIcon className="h-5 w-5 mr-1 -ml-1" />
                          <span>Back</span>
                        </Flexor>
                      ) : 'Close'}
                    </Button>
                    {
                      !showShipping && (
                        <Button
                          variant='secondary'
                          ref={cancelButtonRef}
                          onClick={async () => {
                            if (order && window.confirm('Are you sure you want to cancel this order?')) {
                              await updateOrderStatus(order.id, 4);
                              resetToStart();
                              close(false);
                            }

                          }}
                        >
                          Cancel Order
                        </Button>
                      )
                    }
                  </Flexor>

                  {renderConfirmationButton()}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export function ModalPackingListReadOnly({orderId, onClose}: {orderId?: string, onClose: () => void}) {
  const controllerRef = useRef<{controller?: AbortController}>({controller: undefined});
  const [order, setOrder] = useState<Order>();

  useEffect(() => {
    if (!orderId) return;

    controllerRef.current?.controller?.abort();

    const [promise, controller] = getOrderById(orderId);

    controllerRef.current.controller = controller;

    promise.then((resp) => {
      const order = JSON.parse(atob(resp.data));
      order.orderItems = order.orderItems.map((oi: any) => ({...oi, inventoryEquipType: oi.inventoryEquipmentType}));
      setOrder(order);
    });
  }, [orderId]);

  function clearOrder() {
    setOrder(undefined);
  }

  return (
    <DeprecatedModal onClose={onClose} title={
      <Flexor justify="start" className="space-x-2">
        <ClipboardDocumentListIcon className="h-7 w-7 text-gray-800" aria-hidden="true"/>
        <div>Packing List</div>
      </Flexor>
    } size='2xl' className="bg-gray-100" onIsClosed={clearOrder} open={!!orderId}>
      {
        order ? (
          <div className="my-5">
            <span className="mr-2 rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-2 ring-blue-700/10">
              <span className="ml-1">{order ? `#${getOrderId(order)}` : null}</span>
            </span>
            <span className="rounded-md bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-2 ring-blue-700/10">
              <span>{order ? getOrderStatusName(order.status) : null}</span>
            </span>
          </div>
        ) : null
      }
      <div className={classNames(!order ? 'my-20' : 'mt-2')}>
        <ModalPackingListChecklist order={order} allPacked={() => {}} mustCancelOrder={() => {}} readOnly={true} />
      </div>
    </DeprecatedModal>
  );
}
