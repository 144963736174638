import React, {ReactNode} from 'react';
import { Router, useLocation, useRouter} from 'wouter';

export default function NestedRoutes(props: { base: string, children: ReactNode }) {
    const router = useRouter();
    const [parentLocation] = useLocation();

    const nestedBase = `${router.base}${props.base}`;

    // don't render anything outside of the scope
    if (!(router.base + parentLocation).startsWith(nestedBase)) return null;

    // we need key to make sure the router will remount when base changed
    return (
      <Router base={nestedBase} key={nestedBase}>
        {props.children}
      </Router>
    );
  };
