import React, {useEffect, useState} from "react";
import AddToCartButton from "../../components/user/AddToCartButton";
import {ConsumableItem} from "../../types/ConsumableItem";
import {addToCart} from "../../fetchers";
import {useDispatch, useSelector} from "react-redux";
import {getItemInCart} from "../../selectors/selectors";
import UserOnly from "../../components/UserOnly";
import EditItemQuantityForm from "../user/EditItemQuantityForm";
import { showToast } from "admin/src/actions/toast";
import { CART_ACTION_TYPES } from "admin/src/actions/cart";
import { fetchConsumables } from "admin/src/actions";

type StoreItemAddToCartFormProps = {
  item: ConsumableItem | undefined;
  mini?: boolean;
  onItemUpdated?: Function,
};

export default function StoreItemAddToCartForm({item, mini, onItemUpdated}: StoreItemAddToCartFormProps) {
  // TODO: Kinda funky, could just pass in the CartItem item?
  const cartItem = useSelector(getItemInCart(item?.id));
  const [selectedQuantity, setSelectedQuantity] = useState<number>(cartItem?.quantity || 0);
  const [disableUpdateButton, setDisableUpdateButton] = useState<boolean>();
  const dispatch = useDispatch();

  useEffect(() => {
    setSelectedQuantity(cartItem?.quantity || 0);
  }, [cartItem]);

  function handleCartAddition() {
    if (!selectedQuantity) return;
    // @ts-ignore
    addToCart(item, selectedQuantity).then((data => {
      let isUpdate = !!data.updatedAt;

      dispatch(showToast({content: `You have ${isUpdate ? 'updated' : 'added'} an item in the cart.`, undoAction: CART_ACTION_TYPES.UNDO}));
      dispatch(fetchConsumables());
    }));
    if (onItemUpdated) onItemUpdated();
  }

  function renderInput(widthClass?: string, autoFocus?: boolean) {
    return (
      <EditItemQuantityForm
        value={selectedQuantity}
        autoFocus={autoFocus}
        max={item?.countOnHand}
        onBlur={() => mini && handleCartAddition()}
        onChange={({target: {value, validity: {valid: isValid}}}) => {
          setSelectedQuantity(Number.parseInt(value));
          setDisableUpdateButton(!isValid);
        }}
        className={widthClass}
      />
    )
  }

  if (!item) return null;

  return (
    <UserOnly>
      {mini ? renderInput('w-[80%] mx-2', true) : renderInput()}
      {!mini && <AddToCartButton disabled={disableUpdateButton || !item.countOnHand} onClick={handleCartAddition}>{ !!cartItem ? 'Update' : 'Add' }</AddToCartButton> }
    </UserOnly>
  )
}
