import {CustomerInfo} from "shared/src/types/CustomerInfo";

export interface ModuleInfoDisplayState {
  BackColor: string,
  CustomerInfo: CustomerInfo,
  ForeColor: string,
  KeyCustomerId: string,
  StateName: string,
  CreatedAt: string,
  id: string,
  UpdatedAt: string,
}

export interface PollworkerModuleInfoRole {
  Active: boolean,
  CustomerInfo: CustomerInfo,
  DailyRate: number,
  DrivingReimburseable: boolean,
  HasReports: boolean,
  HourlyRate: number,
  KeyCustomerId: string,
  MileagePayable: boolean,
  MileageRate: number,
  Notes: string,
  RoleName: string,
  CreatedAt: string,
  id: string,
  UpdatedAt: string,
}

export type Session = {
  address1: string,
  address2: string,
  city: string,
  keyPWTrainingClassId: string,
  locationContactName: string,
  locationName: string,
  notes: string,
  phone: string,
  state: string,
  teacherName: string,
  zip: string,
  createdAt: string,
  id: string,
  updatedAt: string,
  attendeePayAmount: number,
  capacity: number,
  credits: number,
  deleted: boolean,
  keyElectionId: string,
}

export interface TrainingClass {
  certificateOfCompletionTemplateId?: string,
  className?: string,
  contactInfo: never,
  customerInfo: CustomerInfo,
  keyCustomerId?: string,
  keyEVUserContactId?: string,
  passValue?: string,
  notes?: string,
  prerequisiteId?: string,
  createdAt?: string,
  id: string,
  updatedAt?: string,
}

export interface PollworkerModuleInfo {
  DisplayStates: ModuleInfoDisplayState[],
  Election: ElectionFromModuleInfo,
  ElectionId: string,
  Pollworker: {
    DefaultAssignedLocationId?: string,
    DefaultLocationInfo?: string,
  },
  SkillsList: SkillFromModuleInfo[],
}

export type Expense = {
  chargeAmount: number,
  electionInfo: Partial<ElectionFromModuleInfo>,
  mileage: number,
  notes: string,
  pollworkerInfo: Partial<PollworkerModuleInfo>,
  status: number,
  deleted: boolean,
  expenseDate: string,
  keyElectionId: string,
  keyEVUserId: string,
  createdAt: string,
  id: string,
  updatedAt: string,
}

export enum PollworkerReimbursementStatus {
  New = 0,
  Approved = 1,
  Rejected = 2
}

type LowercaseFirstLetter<S extends string> =
  S extends `${infer First}${infer Rest}` ? `${Lowercase<First>}${Rest}` : S;

type LowercaseFirstLetterKeys<T> = {
  [K in keyof T as LowercaseFirstLetter<K & string>]: T[K]
};

export type Election = LowercaseFirstLetterKeys<ElectionFromModuleInfo>;

export type ElectionTypeInfo = {
  Active: boolean,
  CapturePartyVotedFor: boolean,
  CustomerInfo: null,
  IncludePartyInStubNumber: boolean,
  KeyCustomerId: string,
  PartyNeeded: boolean,
  StubNumberPrefix: string,
  TypeName: string,
  CreatedAt: string,
  id: string,
  UpdatedAt: string,
}

export interface ElectionFromModuleInfo {
  CurrentElection: boolean,
  ActualElectionDate: string,
  AdvancedVotingStartDate: string,
  AppCaption: string,
  CustomerInfo: CustomerInfo,
  DateEnd: string,
  DateStart: string,
  ElectionDate: string,
  electionName?: string,
  ElectionName?: string,
  ElectionTypeId: string,
  ElectionTypeInfo: ElectionTypeInfo,
  JurisdictionInfo: JurisdictionInfo,
  KeyCustomerId: string,
  KeyJurisdictionId: string,
  ParentElectionId: null,
  PollworkerCandidateSubmissionDeadline: string,
  RegistrationCutOff: string,
  StorageCycleStartDate: string,
  StorageCycleEndDate: string,
  CreatedAt: string,
  id: string,
  UpdatedAt: string,
  Deleted: boolean,
}

export type JurisdictionInfo = {
  CustomerInfo: null,
  JurisdictionCode: string,
  JurisdictionName: string,
  JurisdictionType: string,
  KeyCustomerId: string,
  CreatedAt: string,
  id: string,
  UpdatedAt: string,
}

export interface SkillFromModuleInfo {
  Active: boolean,
  CustomerInfo: CustomerInfo,
  KeyCustomerId: string,
  RatingType: number,
  SkillName: string,
  CreatedAt: string,
  id: string,
  UpdatedAt: string,
}
