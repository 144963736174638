import { useGridContext } from "./DataGridContext";
import { ReactNode, useEffect } from "react";
import { useLocation } from "wouter";
import { Flexor } from "shared/src/components";
import { useSearchParam } from "react-use";
import DataGridColumnSelector from "./DataGridColumnSelector";
import { DataGridShareButton } from "./DataGridShareButton";

export function DataGridToolbar({children, allowShare = true, persistState = true}: {children: ReactNode, allowShare?: boolean, persistState?: boolean}) {
  const { gridApi } = useGridContext();
  const [location, navigate] = useLocation();
  const searchQuery = useSearchParam('search');
  const filterQuery = useSearchParam('filter');
  const sortQuery = useSearchParam('sort');

  useEffect(() => {
    if (!gridApi) return;

    if (searchQuery) gridApi.setGridOption('quickFilterText', searchQuery);
    if (filterQuery) gridApi.setFilterModel(JSON.parse(filterQuery));
    if (sortQuery) {
      gridApi.applyColumnState({
        state: JSON.parse(sortQuery),
        defaultState: { sort: null },
      });
    }
  }, [gridApi, searchQuery, filterQuery, sortQuery]);

  useEffect(() => {
    if (!gridApi || !persistState) return;

    function persistGridState() {
      if (!gridApi) return;

      const colState = gridApi.getColumnState();
      const sortState = colState
      .filter(function (s) {
        return s.sort != null;
      })
      .map(function (s) {
        return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex };
      });

      const sortJson = JSON.stringify(sortState);
      const filterJson = JSON.stringify(gridApi?.getFilterModel());
      const quickFilter = gridApi.getQuickFilter();

      if (quickFilter) {
        document.title = `${document.title} | ${quickFilter}`;
      }

      const params = new URLSearchParams();
      params.set('sort', sortJson);
      params.set('filter', filterJson);
      params.set('search', quickFilter || '');

      navigate(`${location}?${params.toString()}`);
    }

    gridApi.addEventListener('sortChanged', persistGridState);
    gridApi.addEventListener('filterChanged', persistGridState);

    return () => {
      if (gridApi.isDestroyed()) return;

      gridApi.removeEventListener('sortChanged', persistGridState);
      gridApi.removeEventListener('filterChanged', persistGridState);
    }
  }, [gridApi, persistState]);

  return (
    <div className='w-full sm:flex'>
      {
        allowShare ? (
          <Flexor items='start' className='w-full sm:flex sm:flex-row flex-col sm:justify-between sm:space-y-0 space-y-1 justify-start pt-[1px]'>
            <Flexor justify='start' className='gap-1 flex-wrap grow'>
              {children}
            </Flexor>
            <div className='space-x-1 sm:flex sm:flex-row sm:mt-0 mt-5 sm:border-t-0 border-t w-full sm:w-auto sm:pt-0 pt-3 flex-col'>
              <DataGridColumnSelector />
              <DataGridShareButton />
            </div>
          </Flexor>
        ) : children
      }
    </div>
  );
}
