import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"
import { cn } from "../../utils";
import { XMarkIcon } from "@heroicons/react/24/outline";

const defaultVariant = 'bg-callout-light border-callout-border text-callout-dark';

const alertVariants = cva(
  "relative border-2 px-4 w-full text-xs font-bold py-3 [&>svg]:size-4 [&>svg]:stroke-2 [&>svg~div]:pl-7 last:[&>svg]:right-4 [&>svg]:absolute [&>svg]:top-3",
  {
    variants: {
      variant: {
        default: defaultVariant,
        information: defaultVariant,
        warning: 'bg-warning-light border-warning-border text-warning-dark',
        'warning-text': 'border-warning-border text-warning-dark',
        error: 'border-2 px-4 bg-error-light border-error-border text-error-dark',
        'error-text': 'border-error-border text-error-dark',
        success: 'border-2 px-4 bg-success-light text-success-dark border-success-border',
        'success-text': 'text-success-dark border-success-border',
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof alertVariants> & {onClose?: () => void}
>(({ className, onClose, children, variant, ...props }, ref) => (
  <div
    ref={ref}
    role="alert"
    className={cn(alertVariants({ variant }), className, onClose ? '[&>div]:pr-7' : '')}
    {...props}
  >
    {children}
    {onClose ? (<XMarkIcon onClick={onClose} />) : null}
  </div>
));
Alert.displayName = "Alert";

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("font-bold text-xs", className)}
    {...props}
  />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertDescription };
