import React, { type ChangeEvent } from "react";
import { useMemo, useRef } from "react";
import type { PollingLocation } from "../../../types/PollingLocation";
import { ClientSideRowModelModule, ModuleRegistry } from "ag-grid-enterprise";
import type { ColDef, RowSelectionOptions } from "ag-grid-enterprise";
import { ArrowPathRoundedSquareIcon, EllipsisHorizontalIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Button, Badge, Dialog, DropdownMenuSeparator, DialogContent, DialogBody, DialogTrigger, DialogHeader, DialogTitle, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenu, Toaster, Tabs, TabsTrigger, TabsList } from "shared/src/components/ui/new";
import { DataGrid, DataGridRowDetailSidebar, DataGridSearch, DataGridToolbar, useGridSelectedRow } from "../../../components/ui/DataGrid";
import { Flexor } from "shared/src/components";
import { formatPhoneNumber } from "react-phone-number-input";
import { getFileFromSecureStorage, getLocationImageFromSecureStorage, uploadLocationImage } from "../../../fetchers";
import Spinner from "shared/src/components/Spinner";
import { toast } from 'sonner';
import { useImage } from "../../../hooks";
import { Documents, DocumentsList, DocumentsFilters, DocumentsFilterType, DocumentsFilterUpdatedAt, DocumentsFilterUploadedBy, DocumentsToolbar } from "admin/src/components/ui/Documents";
import { TabsContent } from "@radix-ui/react-tabs";
import { uploadDocument } from "admin/src/utils/documents";

type PollingLocationListProps = {
  loading: boolean,
  locations: PollingLocation[],
  onEditClick: (location: PollingLocation) => void,
  onDeleteClick: (location: PollingLocation) => void,
}

const rowSelection: RowSelectionOptions = { mode: 'singleRow', enableClickSelection: true };

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const defaultColDef = {
  sortable: false,
  resizable: false,
  filter: false,
  suppressHeaderMenuButton: true,
};

export default function PollingLocationGrid({ loading, locations, onEditClick, onDeleteClick }: PollingLocationListProps) {
  const locationImageFileInputRef = useRef<HTMLInputElement>(null);
  const { selectedRow: locationDetails } = useGridSelectedRow<PollingLocation>();
  const {
    loading: imageLoading,
    src: imageSrc,
    reloadImage
  } = useImage(getLocationImageFromSecureStorage, locationDetails?.id);

  const colDef = useMemo(() => ([
    {
      field: 'locationName',
      headerName: 'Name',
      minWidth: 300,
    },
    {
      field: 'locationCode',
      headerName: 'Code',
      minWidth: 100,
    },
    {
      field: 'address',
      minWidth: 400,
      valueGetter: ({data}: {data:PollingLocation}) => {
        return [data.address1, data.address2, data.city, data.state, data.zip].filter(p => !!p).join(', ');
      },
    },
    {
      field: 'manager',
      minWidth: 200,
      valueGetter: (properties: { data: PollingLocation }) => properties.data.pollworkerManagerInfo ? `${properties.data.pollworkerManagerInfo?.firstName} ${properties.data.pollworkerManagerInfo?.lastName}` : ''
    },
    {
      field: 'distributionCenter',
      minWidth: 300,
      headerName: 'Distribution center',
      valueGetter: (properties: { data: PollingLocation }) => `${properties.data.distributionCenterInfo?.centerName || ''}`
    },
    {
      field: 'early',
      minWidth: 100,
      maxWidth: 150,
      headerName: 'Early voting?',
      valueGetter: (properties: { data: PollingLocation }) => properties.data.usedForEarlyVoting ? 'Yes' : 'No',
    },
    {
      field: 'outOfCounty',
      minWidth: 100,
      maxWidth: 150,
      headerName: 'Out of county?',
      valueGetter: (properties: { data: PollingLocation }) => properties.data.outOfCounty ? 'Yes' : 'No',
    },
    {
      field: 'actions',
      headerName: '',
      pinned: 'right',
      minWidth: 150,
      cellRenderer: (properties: { data: PollingLocation }) => (
        <div className="flex h-full items-center justify-end w-full">
          <Button variant="ghost" onClick={() => onEditClick(properties.data)} data-testid={`location-edit-${properties.data.id}`}>
            <PencilIcon />
          </Button>
          <Button variant="ghost" onClick={() => onDeleteClick(properties.data)} data-testid={`location-delete-${properties.data.id}`}>
            <TrashIcon />
          </Button>
        </div>
      ),
    },
  ] as ColDef[]), [onEditClick, onDeleteClick]);

  function renderEmpty() {
    return (
      <span className='italic text-sm text-muted-foreground'>Empty</span>
    );
  }

  async function handleLocationImageFileChange(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];

    if (locationDetails && file) {
      const uploadAndReloadPromise = Promise.all([
        uploadLocationImage(locationDetails.id, file),
        reloadImage(locationDetails.id),
      ]);

      toast.promise(uploadAndReloadPromise, {
        loading: 'Uploading image...',
        success: 'Image uploaded successfully',
        error: 'Image could not be uploaded. Please try again.',
      });
    }
  }

  function addLocationImageFile() {
    if (!locationImageFileInputRef.current) return;

    locationImageFileInputRef.current.click();
  }

  return (
    <>
      <Toaster position='bottom-center' />
      <DataGridToolbar allowShare={false} persistState={false}>
        <DataGridSearch />
      </DataGridToolbar>
      <Flexor className='md:flex block grow min-h-0' items='start'>
        <DataGrid
          className='grow'
          loading={loading}
          gridId="polling-locations-table"
          loadingMessage='Loading polling locations...'
          getRowId={params => params.data.id}
          rowData={locations}
          rowSelection={rowSelection}
          columnDefs={colDef}
          defaultColDef={defaultColDef}
        />
        <DataGridRowDetailSidebar className='ml-4 lg:w-1/4 w-1/2 overflow-y-auto h-full'>
          <Tabs defaultValue='details' className='space-y-3'>
            <Flexor items='start' className='space-x-3'>
              <div className='flex flex-col justify-between space-y-3 w-full'>
                <div className='-space-y-1 w-full'>
                  <Flexor justify="between" items='start' className='space-x-2 relative'>
                    <div className='font-semibold text-lg'>{locationDetails?.locationName}</div>
                    <TabsContent value='details'>
                      <DropdownMenu modal={false}>
                        <DropdownMenuTrigger>
                          <EllipsisHorizontalIcon className='size-6 absolute top-0 right-0 z-10' />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align='end'>
                          <DropdownMenuGroup>
                            <DropdownMenuItem onSelect={() => locationDetails && onEditClick(locationDetails)}>
                              <PencilIcon />
                              Edit
                            </DropdownMenuItem>
                            <DropdownMenuItem onSelect={() => locationDetails && addLocationImageFile()}>
                            <ArrowPathRoundedSquareIcon />
                              Change image
                            </DropdownMenuItem>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem onSelect={() => locationDetails && onDeleteClick(locationDetails)}>
                              <TrashIcon />
                              Delete
                            </DropdownMenuItem>
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </TabsContent>
                  </Flexor>
                  <div className='font-semibold text-muted-foreground text-sm'>{locationDetails?.locationCode}</div>
                </div>
                {
                  locationDetails?.outOfCounty || locationDetails?.usedForEarlyVoting ? (
                    <Flexor className='space-x-1' justify='start'>
                      {locationDetails?.outOfCounty ? <Badge>Out of county</Badge> : null}
                      {locationDetails?.usedForEarlyVoting ? <Badge>Early voting</Badge> : null}
                    </Flexor>
                  ) : null
                }
              </div>
            </Flexor>
            <TabsList className='w-full'>
              <TabsTrigger value='details' className='grow'>Details</TabsTrigger>
              <TabsTrigger value='documents' className='grow'>Documents</TabsTrigger>
            </TabsList>
            <TabsContent value='details'>
              <div data-testid={`detail-sidebar-${locationDetails?.id}`} className='space-y-5 text-sm'>
                {
                  locationDetails ? (
                    <>
                      <input type="file" accept='image/jpg' className="hidden" onChange={handleLocationImageFileChange} ref={locationImageFileInputRef}/>
                      <Dialog>
                        <DialogTrigger className="hidden md:block w-full">
                          <div className='space-y-1'>
                            <div className='text-left text-xs text-muted-foreground'>Image</div>
                            <div className="min-h-20 transition-all max-h-48 w-full relative">
                              {
                                imageLoading ? (
                                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                    <Spinner className="" show/>
                                  </div>
                                ) : null
                              }
                              {!imageLoading && imageSrc ? <img alt={locationDetails?.locationName} className="object-cover max-h-48 w-full rounded-lg transition-all" src={imageSrc}/> : null}
                              {
                                !imageLoading && !imageSrc ? (
                                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                                    <div className="my-10 text-center">No image found</div>
                                  </div>
                                ) : null
                              }
                            </div>
                          </div>
                        </DialogTrigger>
                        <DialogContent>
                          <DialogHeader>
                            <DialogTitle>{locationDetails.locationName}</DialogTitle>
                          </DialogHeader>
                          <DialogBody>
                            <img alt={`Large view of ${locationDetails.locationName}`} className="mt-3 w-full rounded-lg" src={imageSrc}/>
                          </DialogBody>
                        </DialogContent>
                      </Dialog>
                    </>
                  ) : null
                }
                <div className='space-y-3'>
                  <div className='font-semibold'>General</div>
                  <div className="divide-y space-y-3">
                    <div className="space-y-1 pt-3">
                      <div className="text-xs text-muted-foreground">Room name</div>
                      <div>{locationDetails?.roomNumberName ? locationDetails?.roomNumberName : renderEmpty()}</div>
                    </div>
                    <div className="space-y-1 pt-3">
                      <div className="text-xs text-muted-foreground">Room name</div>
                      <div>{locationDetails?.roomNumberName ? locationDetails?.roomNumberName : renderEmpty()}</div>
                    </div>
                    <div className="space-y-1 pt-3">
                      <div className="text-xs text-muted-foreground">Address</div>
                      <div>{[locationDetails?.address1, locationDetails?.address2, locationDetails?.city, locationDetails?.state, locationDetails?.zip].filter(p => !!p).join(', ')}</div>
                    </div>
                    <div className="space-y-1 pt-3">
                      <div className="text-xs text-muted-foreground">Notes</div>
                      <div>{locationDetails?.notes ? locationDetails?.notes : renderEmpty()}</div>
                    </div>
                  </div>
                </div>

                <div className="space-y-3">
                  <div className="font-semibold">Primary contact</div>
                  <div className="divide-y space-y-3">
                    <div className="space-y-1 pt-3">
                      <div className="text-xs text-muted-foreground">Name</div>
                      <Flexor justify='start' className='space-x-1'>
                        <div>{locationDetails?.contactName ? locationDetails?.contactName : renderEmpty()}</div>
                        {locationDetails?.contactType ? <Badge variant='outline'>{locationDetails?.contactType}</Badge> : null}
                      </Flexor>
                    </div>
                    <div className="space-y-1 pt-3">
                      <div className="text-xs text-muted-foreground">Phone</div>
                      <div>
                        {
                          locationDetails?.contactCellPhone ? (
                            <a href={`tel:${locationDetails?.contactCellPhone}`}>
                              {formatPhoneNumber(`+1${locationDetails?.contactCellPhone}`)}
                            </a>
                          ) : renderEmpty()
                        }
                      </div>
                    </div>
                    <div className="space-y-1 pt-3">
                      <div className="text-xs text-muted-foreground">Email</div>
                      {
                        locationDetails?.contactEmail ? (
                          <a href={`mailto:${locationDetails?.contactEmail}`}>
                            {locationDetails?.contactEmail}
                          </a>
                        ) : renderEmpty()
                      }
                    </div>
                  </div>
                </div>
              </div>
            </TabsContent>
            <TabsContent value='documents'>
              <Documents
                upload={uploadDocument}
                download={getFileFromSecureStorage}
                objectId={locationDetails?.id}
              >
                <DocumentsToolbar />
                <DocumentsFilters>
                  <DocumentsFilterType />
                  <DocumentsFilterUpdatedAt />
                  <DocumentsFilterUploadedBy />
                </DocumentsFilters>
                <DocumentsList />
              </Documents>
            </TabsContent>
          </Tabs>
        </DataGridRowDetailSidebar>
      </Flexor>
    </>
  )
}
