import {Dialog, Disclosure, Popover, Transition} from "@headlessui/react";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {
  ArrowDownIcon,
  ArrowPathIcon, ArrowUpIcon, CalculatorIcon, CalendarDaysIcon, ChartBarIcon,
  ChevronDownIcon, GlobeAmericasIcon,
  IdentificationIcon, NoSymbolIcon,
  PaperClipIcon, UserCircleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import {classNames} from "shared/src/utils/classNames";
import { Flexor } from "shared/src/components"
import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import {Button, Label} from "shared/src/components/ui";
import {OrderHistory, OrderHistoryPayload, OrderHistoryState} from "../../types/Order";
import {getOrderId, getOrderStatusName} from "../../utils/getters";
import DateFormatter from "../../../../shared/src/components/DateFormatter";
import {getOrderHistory, getOrderSearchConfig} from "../../fetchers";
import {Filter, FilterModel, SortModel} from "../../types/Filter";
import {ModalPackingListReadOnly} from "../modals/ModalPackingList";
import {AdminDebug} from "../DebugDump";
import Loading from "../../../../shared/src/components/Loading";
import {useDebounce} from "react-use";

export default function OrderHistoryTable() {
  const formRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const [loadingSearchConfig, setLoadingSearchConfig] = useState<boolean>(true);
  const [loadingOrderHistory, setLoadingOrderHistory] = useState<boolean>(true);
  const orderHistoryAbortController = useRef<{controller?: AbortController}>({controller: undefined});
  const [orderHistory, setOrderHistory] = useState<OrderHistoryState>({pageSize: 0, count: 0, orders: [], hasNextPage: false, hasPrevPage: false, totalPages: 0});
  const [filters, setFilters] = useState<Filter[]>([]);
  const [filtersApplied, setFiltersApplied] = useState<{ [key: string]: FilterModel }>();
  const [page, setPage] = useState<number>(0);
  const [showOrder, setShowOrder] = useState<OrderHistory>();
  const [sortApplied, setSortApplied] = useState<{[key: string]: SortModel}>({
    CreatedAt: {
      colId: 'CreatedAt',
      sort: 'desc'
    }
  });

  useDebounce(loadOrderHistory, 1000, [sortApplied, page, filtersApplied, filters]);

  useEffect(() => {
    setLoadingSearchConfig(true);
    getOrderSearchConfig().then(({config}) => {
      const {customers, status, users} = config;

      setFilters([{
        id: 'customers',
        name: 'Customers',
        options: customers.map((c: { value: string, key: string }) => ({value: true, key: c.key, label: c.value,})),
      }, {
        id: 'users',
        name: 'Users',
        options: users.map((u: { value: string, key: string }) => ({value: true, key: u.key, label: u.value})),
      }, {
        id: 'status',
        name: 'Statuses',
        options: Object.entries(status).map(([, value]) => {
          return ({value: true, key: value, label: getOrderStatusName(Number.parseInt(`${value}`))})
        }),
      }]);
    }).finally(() => setLoadingSearchConfig(false));
  }, []);

  useEffect(() => {
    setPage(0);
    setFiltersApplied(() => {
      return filters.reduce((acc, filter) => {
        return {
          ...acc,
          [filter.id]: {
            filterType: 'set',
            values: filter.options.filter(opt => opt.value).map(opt => opt.key)
          }
        }
      }, {});
    });
  }, [filters]);

  function loadOrderHistory() {
    if (!filtersApplied || !Object.keys(filtersApplied).length) return;

    orderHistoryAbortController.current.controller?.abort('current order history load in progress');

    setLoadingOrderHistory(true);

    const [responsePromise, controller] = getOrderHistory(Object.values(sortApplied), filtersApplied, page * orderHistory.pageSize);

    orderHistoryAbortController.current.controller = controller;

    responsePromise.then(({orders, pageSize, count, success}: OrderHistoryPayload) => {
      if (!success) return;
      const totalPages = (orderHistory.count || count) <= (orderHistory.pageSize || pageSize) ? 1 : Math.ceil((orderHistory.count || count) / (orderHistory.pageSize || pageSize))
      setOrderHistory({orders, count, pageSize, totalPages, hasNextPage: page + 1 < totalPages, hasPrevPage: page > 0});
    }).finally(() => {
      setLoadingOrderHistory(false);
    });
  }

  const loading = loadingSearchConfig || loadingOrderHistory;

  return (
    <>
      <ModalPackingListReadOnly onClose={() => setShowOrder(undefined)} orderId={showOrder?.id} />
      <div className="bg-gray-50">
        {/* Mobile filter dialog */}
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                  <div className="flex items-center justify-between px-4">
                    <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                    <button
                      type="button"
                      className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-ev-red"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Filters */}
                  <form className="mt-4">
                    {filters.map((section, sectionIdx) => (
                      <Disclosure as="div" key={section.name} className="border-t border-gray-200 px-4 py-6">
                        {({ open }) => (
                          <>
                            <h3 className="-mx-2 -my-3 flow-root relative">
                              <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                <span className={classNames(section.inUse ? 'ml-5' : '', "font-medium text-gray-900")}>{section.name}</span>
                                { section.inUse ? <span className="absolute left-2 rounded-full w-2 h-2 bg-ev-red text-xs text-gray-700"></span> : null}
                                <span className="ml-6 flex items-center">
                                  <ChevronDownIcon
                                    className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                    aria-hidden="true"
                                  />
                                </span>
                              </Disclosure.Button>
                            </h3>
                            <Disclosure.Panel className="pt-6">
                              <div className="space-y-6">
                                {section.options.map((option, optionIdx) => (
                                  <div key={option.key} className="flex items-center">
                                    <input
                                      id={`filter-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      checked={option.value}
                                      type="checkbox"
                                      onChange={(event) => {
                                        const newFilters = [...filters];
                                        newFilters[sectionIdx].options[optionIdx].value = event.target.checked;
                                        newFilters[sectionIdx].inUse = newFilters[sectionIdx].options.some(opt => !opt.value);
                                        setFilters(newFilters);
                                      }}
                                      className="h-4 w-4 rounded border-gray-300 text-ev-red focus:ring-ev-red"
                                    />
                                    <Label
                                      htmlFor={`filter-${section.id}-${optionIdx}`}
                                      className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                    >
                                      {option.label}
                                    </Label>
                                  </div>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="mx-auto text-center w-full">
          <section aria-labelledby="filter-heading" className="mt-3">
            <h2 id="filter-heading" className="sr-only">
              Order History Filters
            </h2>

            <div className="flex items-center justify-between">
              <SectionSubSubHeading>Orders History</SectionSubSubHeading>
              <div className="flex space-x-4">
                <button
                  type="button"
                  className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
                  onClick={() => setOpen(true)}
                >
                  <Flexor className="relative">
                    { filters.some(f => f.inUse) ? <span className="absolute -left-3.5 rounded-full w-2 h-2 bg-ev-red text-xs font-semibold tabular-nums text-gray-700"></span> : null}
                    <span>Filters</span>
                  </Flexor>
                </button>
                <Popover.Group className=" border-r border-gray-300 pr-5 hidden sm:flex sm:items-baseline sm:space-x-8">
                  {
                    filters.map((section, sectionIdx) => (
                      <Popover
                        as="div"
                        key={section.name}
                        id={`desktop-menu-${sectionIdx}`}
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Popover.Button className="relative text-sm font-medium text-gray-700 hover:text-gray-900">
                            <div className="group inline-flex items-center justify-center">
                              <span>{section.name}</span>
                              {section.inUse ? <span className="absolute -left-3.5 rounded-full w-2 h-2 bg-ev-red text-xs font-semibold tabular-nums text-gray-700"></span> : null }
                              <ChevronDownIcon
                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            </div>
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md min-w-56 bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <form className='space-y-4' ref={formRef}>
                              <div className="flex items-center justify-between">
                                <div className="flex items-center">
                                  <input
                                    id={`filter-${section.id}-all`}
                                    checked={section.options.every(opt => opt.value)}
                                    type="checkbox"
                                    onChange={(event) => {
                                      const newFilters = [...filters];
                                      newFilters[sectionIdx].options = newFilters[sectionIdx].options.map(option => ({...option, value: event.target.checked}));
                                      setFilters(newFilters);
                                    }}
                                    className="disabled:opacity-50 h-4 w-4 rounded border-gray-300 text-ev-red focus:ring-ev-red"
                                  />
                                  <label
                                    htmlFor={`filter-${section.id}-all}`}
                                    className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                  >
                                    All
                                  </label>
                                </div>

                                <span className="whitespace-nowrap text-sm font-medium cursor-pointer text-gray-900" onClick={() => {
                                  if (!filters[sectionIdx].inUse) return;

                                  const newFilters = [...filters];
                                  newFilters[sectionIdx].options = newFilters[sectionIdx].options.map(option => ({...option, value: true}));
                                  newFilters[sectionIdx].inUse = false;
                                  setFilters(newFilters);
                                }}>Reset</span>
                              </div>
                              <hr />
                              <div className='space-y-4 max-h-56 overflow-y-auto'>
                                {section.options.map((option, optionIdx) => (
                                  <div key={option.key} className="flex items-center">
                                    <input
                                      id={`filter-${section.id}-${optionIdx}`}
                                      name={`${section.id}[]`}
                                      checked={option.value}
                                      type="checkbox"
                                      onChange={(event) => {
                                        const newFilters = [...filters];
                                        newFilters[sectionIdx].options[optionIdx].value = event.target.checked;
                                        newFilters[sectionIdx].inUse = newFilters[sectionIdx].options.some(opt => !opt.value);
                                        setFilters(newFilters);
                                      }}
                                      className="h-4 w-4 rounded border-gray-300 text-ev-red focus:ring-ev-red"
                                    />
                                    <label
                                      htmlFor={`filter-${section.id}-${optionIdx}`}
                                      className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                    >
                                      {option.label}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </form>
                          </Popover.Panel>
                        </Transition>
                      </Popover>
                    ))}
                </Popover.Group>
                <Button variant='tertiary' size='zero' className='pr-1' onClick={() => loadOrderHistory()}>
                  <ArrowPathIcon className={`${classNames(loading ? 'animate-spin' : '')} size-4`} />
                  <span>Refresh</span>
                </Button>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="table-fixed min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  <PaperClipIcon title="Has Notes?" className="h-5 w-5" />
                </th>
                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  <Flexor justify="start" className="space-x-1">
                    <IdentificationIcon className="h-5 w-5" />
                    <span>ID</span>
                  </Flexor>
                </th>
                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                  <Flexor justify="start" className="space-x-1">
                    <UserCircleIcon className="h-5 w-5" />
                    <span>User</span>
                  </Flexor>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <Flexor justify="start" className="space-x-1">
                    <GlobeAmericasIcon className="h-5 w-5" />
                    <span>County</span>
                  </Flexor>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <Flexor justify="start" className="space-x-1">
                    <CalculatorIcon className="h-5 w-5" />
                    <span>Count</span>
                  </Flexor>
                </th>
                <th scope="col" className="cursor-pointer underline underline-offset-4 decoration-dotted px-3 py-3.5 text-left text-sm font-semibold text-gray-900 relative" onClick={() => {
                  setPage(0);
                  setSortApplied({Status: {colId: 'Status', sort: sortApplied.Status?.sort === 'desc' ? 'asc' : 'desc'}})
                }}>
                  <Flexor justify="start" className="space-x-1">
                    <ChartBarIcon className="h-5 w-5" />
                    <span>Status</span>
                    {sortApplied.Status ? sortApplied.Status?.sort === 'asc' ? <ArrowUpIcon className="h-4 w-4 absolute -right-1.5" /> : <ArrowDownIcon className="h-4 w-4 absolute -right-1.5" /> : null}
                  </Flexor>
                </th>
                <th scope="col" className="cursor-pointer underline underline-offset-4 decoration-dotted px-3 py-3.5 text-left text-sm font-semibold text-gray-900 relative" onClick={() => {
                  setPage(0);
                  setSortApplied({CreatedAt: {colId: 'CreatedAt', sort: sortApplied.CreatedAt?.sort === 'desc' ? 'asc' : 'desc'}})
                }}>
                  <Flexor justify="start" className="space-x-1">
                    <CalendarDaysIcon className="h-5 w-5" />
                    <span>Submit Date</span>
                    {sortApplied.CreatedAt ? (sortApplied.CreatedAt?.sort === 'asc' ? <ArrowUpIcon className="h-4 w-4 ml-5 block" /> : <ArrowDownIcon className="h-4 w-4 ml-5 block" />) : null}
                  </Flexor>
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                  <span className="sr-only">Edit</span>
                </th>
              </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
              {
                loading && !orderHistory.orders.length ? (
                  <tr>
                    <td colSpan={100} className="bg-white">
                      <div className="flex items-center justify-center my-10">
                        <Loading loadingMessage="Getting order history..." />
                      </div>
                    </td>
                  </tr>
                ) : null
              }
              {
                !loading && !orderHistory.orders.length ? (
                  <tr>
                    <td colSpan={100} className="bg-white">
                      <div className="flex items-center justify-center my-10">
                        <div>
                          <NoSymbolIcon className="h-12 w-12 mx-auto" />
                          <div>No order history found</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : null
              }
              {
                orderHistory.orders.map((order: OrderHistory) => {
                  return (
                    <tr key={order.id} className="relative">
                      <td title={!!order.notes ? 'This order has notes attached' : ''} className="truncate whitespace-nowrap py-4 text-sm text-gray-500 pl-6">
                        <span className={classNames(loading ? 'bg-gray-500 text-gray-500 rounded-full block overflow-hidden animate-pulse' : '')}>
                          <PaperClipIcon className={classNames(!!order.notes ? 'visible' : 'invisible', "h-5 w-5")} />
                        </span>
                      </td>
                      <td className="truncate whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                        <span className={classNames(loading ? 'bg-gray-500 text-gray-500 rounded-full block overflow-hidden animate-pulse' : 'w-1/6')}>{getOrderId(order)}</span>
                      </td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        <span className={classNames(loading ? 'text-gray-500 bg-gray-500 block rounded-full overflow-hidden animate-pulse' : null)}>{order.user?.firstName} {order.user?.lastName}</span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                        <span className={classNames(loading ? 'text-gray-500 bg-gray-500 block rounded-full overflow-hidden animate-pulse' : null)}>{order.customer.customerName}</span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className={classNames(loading ? 'text-gray-500 min-w-10 block bg-gray-500 rounded-full overflow-hidden animate-pulse' : null)}>{order.orderItems.length}</span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium">
                        <span className={classNames(loading ? 'bg-gray-500 text-gray-500 rounded-full block overflow-hidden animate-pulse' : '')}>
                          {getOrderStatusName(order.status)}
                        </span>
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <span className={classNames(loading ? 'text-gray-500 min-w-10 block bg-gray-500 rounded-full overflow-hidden animate-pulse' : null)}>
                          <DateFormatter dateFormats={{ dateStyle: 'short', timeStyle: "short" }} dateString={order.createdAt} />
                        </span>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6" onClick={() => {
                        setShowOrder(order);
                      }}>
                        <Button className='inline' variant='tertiary' size='zero' disabled={loading}>Packing List</Button>
                      </td>
                    </tr>
                  )
                })
              }
              </tbody>
            </table>
            <nav
              className="flex items-center justify-between border-t border-gray-200 bg-white px-6 py-3"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <div className="text-sm text-gray-700">
                  {
                    orderHistory.orders.length ? (
                      <>
                        Page <span className="font-medium">{page + 1}</span> of <span className="font-medium">{orderHistory.totalPages}</span>
                      </>
                    ) : null
                  }
                  <AdminDebug>
                    <div>page: {page} / pageSize: {orderHistory.pageSize} / count: {orderHistory.count} / totalPages: {orderHistory.totalPages}</div>
                  </AdminDebug>
                </div>
              </div>
              <div className="flex flex-1 justify-between sm:justify-end space-x-5">
                <Button variant='tertiary' disabled={!orderHistory.hasPrevPage} onClick={() => setPage(page - 1)}>
                  Previous
                </Button>
                <Button variant='tertiary' disabled={!orderHistory.hasNextPage} onClick={() => setPage(page + 1)}>
                  Next
                </Button>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
