import { createSlice } from "@reduxjs/toolkit";
import { VotingLocation } from 'admin/src/types';
import { PartiesByCode, PartiesById } from "../../components/Pollworker/Panels/pollworkerVotingLocationDetailsUtils";

export type SchedulerState = {
  loadingAllVotingLocations: boolean
  allVotingLocations: VotingLocation[]
  filteredVotingLocations: VotingLocation[]
  scrollToLocation?: string
  partiesById: PartiesById
  partiesByCode: PartiesByCode
  filters: {
    hidePastDates: boolean
    onlyShowEarlyVotingLocations: boolean
    showOnlyElectionDates: boolean
  }
}

const defaultSchedulerReducer: SchedulerState = {
  loadingAllVotingLocations: false,
  allVotingLocations: [],
  filteredVotingLocations: [],
  scrollToLocation: undefined,
  partiesById: {},
  partiesByCode: {},
  filters: {
    hidePastDates: false,
    onlyShowEarlyVotingLocations: false,
    showOnlyElectionDates: false,
  },
};

const schedulerSlice = createSlice({
  name: 'scheduler',
  initialState: defaultSchedulerReducer,
  reducers: {
    setHidePastDates: (state, action) => {
      state.filters.hidePastDates = action.payload;
    },
    setOnlyShowEarlyVotingLocations: (state, action) => {
      state.filters.onlyShowEarlyVotingLocations = action.payload;
      state.filteredVotingLocations = state.allVotingLocations.filter((vl) => action.payload ? vl.usedForEarlyVoting : true);
    },
    setShowOnlyElectionDates: (state, action) => {
      state.filters.showOnlyElectionDates = action.payload;
    },
    clearFilters: (state) => {
      state.filters = defaultSchedulerReducer.filters;
    },
    setAllVotingLocations: (state, action) => {
      state.allVotingLocations = action.payload;
      state.filteredVotingLocations = state.allVotingLocations.filter((vl) => state.filters.onlyShowEarlyVotingLocations ? vl.usedForEarlyVoting : true);
    },
    setLoadingAllVotingLocations: (state, action) => {
      state.loadingAllVotingLocations = action.payload;
    },
    setScrollToLocation: (state, action) => {
      state.scrollToLocation = action.payload;
    },
    setPartiesById: (state, action) => {
      state.partiesById = action.payload;
    },
    setPartiesByCode: (state, action) => {
      state.partiesByCode = action.payload;
    },
  }
});

export const {
  setHidePastDates,
  setOnlyShowEarlyVotingLocations,
  setShowOnlyElectionDates,
  clearFilters,
  setAllVotingLocations,
  setLoadingAllVotingLocations,
  setScrollToLocation,
  setPartiesById,
  setPartiesByCode,
} = schedulerSlice.actions;

export default schedulerSlice.reducer;
