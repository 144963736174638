import * as React from "react"
import { cn } from 'shared/src/utils';
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

export const inputVariants = cva(
  'h-8 w-full rounded-md border border-input focus:border-input bg-background px-3 file:py-1 text-foreground transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
  {
    variants: {
      variant: {
        default: '',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  }
)

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant, ...props }, ref) => {

    return (
      <input
        type={type}
        className={twMerge(cn(inputVariants({ variant, className })))}
        ref={ref}
        {...props}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
