import React from 'react';
import { useIsFeatureFlagEnabled } from '../../hooks';
import { Feature } from 'admin/src/enums';

export default FeatureFlagCheck;

export interface FeatureFlagCheckProps {
  flag?: string | undefined;
  dbFlag?: Feature | undefined;
  ifFlagMissing?: string | (() => JSX.Element) | undefined;
  children: React.ReactNode;
}

function FeatureFlagCheck({ flag, dbFlag, ifFlagMissing, children }: FeatureFlagCheckProps) {
  const enabled = useIsFeatureFlagEnabled(flag, dbFlag);

  if (!enabled && ifFlagMissing && typeof ifFlagMissing === 'function') {
    return <>{ifFlagMissing()}</>;
  } else if (!enabled && ifFlagMissing && typeof ifFlagMissing === 'string') {
    return <>{ifFlagMissing}</>;
  }

  return (
    <>
      {enabled && children}
    </>
  );
}
