import {createSlice} from "@reduxjs/toolkit";

export type SettingsState = {
  showDetailsView?: boolean,
  openNativeDetailsDialogOnDoubleClick?: boolean,
  _defaultGridSize?: number,
};

export const defaultSettingsReducer: SettingsState = {
  showDetailsView: true,
  openNativeDetailsDialogOnDoubleClick: true,
  _defaultGridSize: 75,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: defaultSettingsReducer,
  reducers: {
    setPollworkerSettings: (state, action) => {
      if (!action.payload) return state;
      return {...state, ...action.payload};
    }
  }
})

export const {
  setPollworkerSettings,
} = settingsSlice.actions;

export default settingsSlice.reducer;
