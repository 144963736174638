import { ReactNode } from "react";
import tw from "tailwind-styled-components";
import { cn } from "../utils";

export const HiddenLink = tw.a`
  sr-only
`;

export const ScreenReaderOnly = tw.span`
  sr-only
`;

export const ScreenReaderIgnore = tw.span`
  sr-ignore
`;

export function ScreenReaderHidden({
  children,
  className
}: { children: ReactNode, className?: string }) {
  return (
    <span className={cn(className)} aria-hidden="true">
      {children}
    </span>
  );
}
