import { createContext, useContext } from "react";
import type { Document, DocumentFilter, DocumentFilters, UploadedDocumentFileResponse } from 'admin/src/types';

export type DocumentContextProps = {
  objectId?: string,
  documents?: Document[],
  loadDocuments: () => void,
  loadingDocuments: boolean,
  deleteDocument: (document: Document) => void,
  downloadDocumentToFs: (document: Document) => void,
  onUpload?: (uploadedFiles: UploadedDocumentFileResponse[]) => void,
  upload: (customerId: string, objectId: string, file: File, fileName?: string) => Promise<UploadedDocumentFileResponse>,
  onDownload?: (err: string, document: Document) => void,
  downloadDocument: (document: Document) => Promise<[boolean, string?, string?]>,
  filters?: DocumentFilters,
  setFilter: (filter: DocumentFilter) => void,
  clearFilter: (predicate: keyof DocumentFilters) => void,
  enableSearch: boolean,
  enableUpload: boolean,
  disableToast: boolean,
}

function noop() {}

export const DocumentsContext = createContext<DocumentContextProps>({
  objectId: undefined,
  filters: {},
  documents: [],
  loadingDocuments: false,
  deleteDocument: noop,
  downloadDocument: () => Promise.reject(),
  downloadDocumentToFs: noop,
  loadDocuments: noop,
  setFilter: noop,
  clearFilter: noop,
  onUpload: noop,
  upload: () => Promise.reject(),
  onDownload: noop,
  enableSearch: true,
  enableUpload: true,
  disableToast: false,
});

export function useDocumentsContext() {
  const context = useContext(DocumentsContext);

  if (!context) {
    throw new Error('useDocumentsContext must be used inside <DocumentsContext />');
  }

  return context;
}
