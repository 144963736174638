import React, { lazy, useEffect, useMemo } from 'react';
import reduxStore from "./store";
import { Route } from "wouter";
import EasyOrderLogin from "./screens/user/Login";
import Store from "./screens/store/Store";
import Orders from "./screens/user/Orders";
import Checkout from "./screens/user/Checkout";
import AdminApp from "./screens/AdminApp";
import PollworkerApplicationSubmissionViewer from "./screens/admin/Forms/PollworkerApplicationSubmissionViewer";
import PollworkerModernReports from "./components/Pollworker/PollworkerModernReports";
import DeploymentsDashboard from "./components/Inventory/DeploymentsDashboard";
import EquipmentSummaryTable from "./components/Inventory/EquipmentSummaryTable";
import InventoryReports from "./components/Inventory/Reports";
import CampaignFinance from "./components/CampaignFinance/CampaignFinance";
import FilerImport from "./components/CampaignFinance/FilerImport";
import Pollworker from "./components/Pollworker/Pollworker";
import PollworkerVotingLocationScheduler from "./components/Pollworker/Panels/PollworkerVLScheduler";
import PollworkerTracker from "./screens/admin/Pollworker/PollworkerTracker";
import EasyOrderShell from "./screens/admin/EasyOrderShell";
import ContainerCentered from "./screens/admin/ContainerCentered";
import AdminDashboard from "./screens/admin/Dashboard";
import InventoryLevelsDashboard from "./screens/admin/Inventory";
import ItemDetails from "./screens/admin/ItemDetails";
import Toaster from "./components/Toaster";
import { Provider } from "react-redux";
import { webRootSaga } from "./sagas";
import { NestedRoutes } from 'shared/src/components';
import { withLoadingSuspense } from "./hoc";
import { UserApp } from './screens/UserApp';
import { FeatureFlagCheck } from "./components/ui";

const Playground = lazy(() => import('./screens/admin/Playground'));
const PlaygroundNew = lazy(() => import('./screens/admin/PlaygroundNew'));
const AdministrationApp = lazy(() => import('./screens/admin/Apps/Administration'));
const CampaignFinanceApp = lazy(() => import('./screens/admin/Apps/CampaignFinance'));
const ElectionsApp = lazy(() => import('./screens/admin/Apps/Elections'));

const isDev = process.env.NODE_ENV !== 'production';

export default function WebApp() {
  const store = useMemo(() => reduxStore.store, []);
  const sagaMiddleware = useMemo(() => reduxStore.sagaMiddleware, []);

  useEffect(() => {
    if (sagaMiddleware) {
      sagaMiddleware.run(webRootSaga);
    }
  }, [sagaMiddleware]);

  return (
    <Provider store={store}>
      {
        isDev ? (
          <NestedRoutes base="/dev">
            <Route path="/playground" component={withLoadingSuspense(Playground)} />
            <Route path="/playground/new" component={withLoadingSuspense(PlaygroundNew)} />
            <NestedRoutes base="/campaignfinance">
              {withLoadingSuspense(CampaignFinanceApp)()}
            </NestedRoutes>
            <NestedRoutes base="/elections">
              {withLoadingSuspense(ElectionsApp)()}
            </NestedRoutes>
          </NestedRoutes>
        ) : null
      }
      <Route path="/login" component={EasyOrderLogin} />
      <Route path="/store">
        <UserApp>
          <Store />
        </UserApp>
      </Route>
      <Route path="/orders" component={Orders} />
      <Route path="/checkout" component={Checkout} />
      <FeatureFlagCheck flag="setup-and-admin-web">
        <NestedRoutes base="/admin">
          <NestedRoutes base="/administration">
            {withLoadingSuspense(AdministrationApp)()}
          </NestedRoutes>
          <NestedRoutes base="/elections">
            {withLoadingSuspense(ElectionsApp)()}
          </NestedRoutes>
          <AdminApp>
            <NestedRoutes base="/form_submission">
              <Route path='/:applicantId' component={PollworkerApplicationSubmissionViewer} />
            </NestedRoutes>
            <NestedRoutes base="/inventory">
              <Route path="/" component={DeploymentsDashboard} />
              <Route path="/dashboard" component={DeploymentsDashboard} />
              <Route path="/equipment" component={EquipmentSummaryTable} />
              <Route path="/reports" component={InventoryReports} />
            </NestedRoutes>
            <NestedRoutes base="/campaignfinance">
              <Route path="/" component={CampaignFinance} />
              <Route path="/filerimport" component={FilerImport} />
            </NestedRoutes>
            <NestedRoutes base="/pollworker">
              <Route path="/" component={Pollworker} />
              <Route path="/scheduler/:viewType?/:votingLocationId?" component={PollworkerVotingLocationScheduler} />
              <Route path="/tracker" component={PollworkerTracker} />
            </NestedRoutes>
            <NestedRoutes base="/reports">
              <Route path="/" component={PollworkerModernReports} />
            </NestedRoutes>
            <NestedRoutes base="/easyorder">
              <Route path="/dashboard">
                <EasyOrderShell>
                  <ContainerCentered>
                    <AdminDashboard />
                  </ContainerCentered>
                </EasyOrderShell>
              </Route>
              <Route path="/inventory">
                <EasyOrderShell>
                  <InventoryLevelsDashboard />
                </EasyOrderShell>
              </Route>
              <Route path="/store">
                <EasyOrderShell>
                  <div className="p-4">
                    <Store />
                  </div>
                </EasyOrderShell>
              </Route>
              <Route path="/store/:itemId" component={(props: any) => (
                <div className="px-4 mt-3">
                  <ItemDetails showItemName {...props} />
                </div>
              )} />
            </NestedRoutes>
          </AdminApp>
        </NestedRoutes>
      </FeatureFlagCheck>
      <Toaster />
    </Provider>
  );
}
