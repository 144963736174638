import React, { Suspense } from "react";
import LoadingOverlay from "../components/ui/LoadingOverlay";

export function withLoadingSuspense(Component: React.LazyExoticComponent<() => JSX.Element>) {
  return () => (
    <Suspense fallback={(
      <div className="h-full w-full">
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-center">
          <LoadingOverlay animate infoText="" isPreview={false} />
        </div>
      </div>
    )}>
      <Component />
    </Suspense>
  );
}
