import React, { FC } from "react";
import NavBar, { NavigationItem } from "../components/ui/NavBar";

export function withNavBar(Component: FC, navigation: NavigationItem[]) {
  return () => (
    <div className="flex flex-col justify-start h-full">
      <NavBar navigation={navigation}/>
      <Component/>
    </div>
  );
}
