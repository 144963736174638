import type { PollworkerWorkHistory} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import {useState} from "react";
import {getDocumentsForObject} from "admin/src/fetchers";
import type { Document } from 'admin/src/types';

export default function usePollworkerDocuments(): {
  loadPollworkerDocuments: (selectedPollworker: PollworkerWorkHistory) => Promise<Document[]>,
  loadingPollworkerDocuments: boolean,
  pollworkerDocuments: Document[],
} {
  const [pollworkerDocuments, setPollworkerDocuments] = useState<Document[]>([]);
  const [loadingPollworkerDocuments, setLoadingPollworkerDocuments] = useState<boolean>(false);

  function loadPollworkerDocuments(selectedPollworker: PollworkerWorkHistory) {
    if (!selectedPollworker) return Promise.reject();

    setLoadingPollworkerDocuments(true);

    return getDocumentsForObject(selectedPollworker.pollworkerInfo.id).then(({data}) => {
      const documents = JSON.parse(atob(data));
      setPollworkerDocuments(documents);
      return documents;
    }).finally(() => {
      setLoadingPollworkerDocuments(false);
    });
  }

  return {loadPollworkerDocuments, loadingPollworkerDocuments, pollworkerDocuments};
}
