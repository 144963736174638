import {classNames} from "shared/src/utils/classNames";
import {ReactNode} from "react";

export default function ContainerCentered({children, fullscreen = false}: {children: ReactNode, fullscreen?: boolean}) {
  return (
    <div className={classNames(fullscreen ? '' : "mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5")}>
      {children}
    </div>
  )
}
