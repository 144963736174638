import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { themeBalham, SideBarDef, ColDef } from 'ag-grid-enterprise';
import { DeploymentDashboardEquipmentSummary } from 'admin/src/types/Inventory';
import LoadingOverlay from 'admin/src/components/ui/LoadingOverlay';
import { cn } from 'shared/src/utils';

export interface DeploymentEquipmentSummaryTableProps {
  className?: string | undefined,
  loading: boolean,
  summaries: DeploymentDashboardEquipmentSummary[]
}

const colDef: ColDef[] = [
  {
    field: 'locationName',
    enableRowGroup: true,
    sortable: true,
    filter: true,
  },
  {
    field: 'equipmentTypeName',
    enableRowGroup: true,
    sortable: true,
    filter: true,
  },
  {
    field: 'available'
  },
  {
    field: 'assembled'
  },
  {
    field: 'deploymentOnTruck'
  },
  {
    field: 'delivered'
  },
  {
    field: 'pickupOnTruck'
  },
  {
    field: 'returnedToWarehouse'
  },
  {
    field: 'cocSignedOut'
  },
  {
    field: 'cocSignedIn'
  },
];

const sidebarConfig: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      toolPanel: 'agColumnsToolPanel',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanelParams: {
        suppressPivotMode: true,
      }
    },
    'filters',
  ],
  defaultToolPanel: '',
  position: 'right',
};

const defaultColDef = {
  sortable: false,
  resizable: true,
  filter: false,
  enableCellChangeFlash: false,
  suppressHeaderMenuButton: true,
};

export default function DeploymentEquipmentSummaryTable(props: DeploymentEquipmentSummaryTableProps) {
  const { className, loading, summaries } = props;

  return (
    <div className={cn(className, 'ag-theme-balham h-full w-full')}>
      <AgGridReact
        theme={themeBalham}
        gridId="deployment-equipment-summary-table"
        loading={loading}
        getRowId={params => params.data.locationId + '-' + params.data.equipmentTypeId }
        loadingOverlayComponentParams={{isPreview: false, animate: true, infoText: 'Loading deployment data...'}}
        loadingOverlayComponent={LoadingOverlay}
        allowContextMenuWithControlKey
        animateRows={false}
        rowData={summaries}
        noRowsOverlayComponent={() => (<div>No data found</div>)}
        columnDefs={colDef}
        enableBrowserTooltips
        sideBar={sidebarConfig}
        defaultColDef={defaultColDef}
      />
    </div>
  )
}
