import React, { useEffect, useState } from "react";
import { getForms } from "admin/src/fetchers/setupAndAdmin";
import { classNames } from "shared/src/utils/classNames";
import { Link } from "wouter";
import dayjs from "dayjs";
import { Flexor } from "shared/src/components";
import { CalendarDaysIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { FormStatus } from "shared/src/enums/FormStatus";
import { Button } from "shared/src/components/ui/new";

export default function Forms() {
  return (
    <div className='px-4 sm:px-6 lg:px-8'>
      <h2 className="my-4 text-lg border-b pb-4">Forms</h2>
      <FormsList />
    </div>
  )
}

export function FormsList() {
  const [forms, setForms] = useState<{[key: string]: any}>({});

  useEffect(() => {
    getForms()
    .then((resp) => JSON.parse(atob(resp.data)))
    .then((forms) => {
      const parsedForms = forms.map((f: any) => {
        return {
          ...f,
          createdAt: dayjs(f.createdAt).format('MM-DD-YYYY hh:mm A'),
          updatedAt: dayjs(f.updatedAt).format('MM-DD-YYYY hh:mm A'),
        }
      }).reduce((acc: { [key: string]: any }, form: any) => {
        acc[form.typeName] = form;
        return acc;
      }, {});

      setForms(parsedForms);
    });
  }, []);

  return (
    <>
      {
        Object.keys(forms).map((formTypeName: string) => {
          const form = forms[formTypeName];
          const isPublished = form.status === FormStatus.Published;
          const publishedBy = isPublished ? form.publishedBy : form.parent?.publishedBy;
          const updatedAtFormatted = dayjs(form.updatedAt).format('MM/DD/YY hh:mm A');

          return (
            <div key={form.id}>
              <div className='font-semibold'>{formTypeName}</div>
              <div className='bg-white rounded-md my-2 border border-border'>
                <ul role="list" className="divide-y divide-gray-100 px-4">
                  <li className="flex items-center justify-between gap-x-6 py-3">
                    <div className="min-w-0 space-y-3">
                      <Flexor justify='start' className="gap-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-900 flex space-x-1 items-center">
                          <PencilSquareIcon className='size-4' />
                          <span>{form.title}</span>
                        </p>
                        <span className={classNames(isPublished ? 'text-green-700 bg-green-50 ring-green-600/20' : 'text-orange-700 bg-orange-50 ring-orange-600/20', "capitalize rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset")}>
                          {form.status}
                        </span>
                      </Flexor>
                      <Flexor className='text-sm'>
                        <Flexor justify='end'>
                          {
                            publishedBy ? (
                              <Flexor className='space-x-1'>
                                <CalendarDaysIcon className='size-4' />
                                <span className='space-x-1'>
                                  <span>Last published by</span>
                                  <span className='font-semibold'>{publishedBy.firstName} {publishedBy.lastName}</span>
                                  <span>at</span>
                                  <time className='font-semibold' dateTime={form.updatedAt}>{updatedAtFormatted}</time>
                                </span>
                              </Flexor>
                            ) : (
                              <span className='font-semibold'>Never published</span>
                            )
                          }
                        </Flexor>
                      </Flexor>
                    </div>
                    <div className="flex flex-none items-center gap-x-4">
                      <Link to={`/forms/${form.id}`}>
                        <Button>{isPublished ? 'Open' : 'Edit'}</Button>
                      </Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )
        })
      }
    </>
  );
}
