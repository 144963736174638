import { useDocumentsContext } from "./DocumentsContext";
import { Flexor } from "shared/src/components";
import { DocumentsUpload } from "./DocumentsUpload";
import React from "react";
import { DocumentsSearch } from "./DocumentsFilters";

export function DocumentsToolbar() {
  const { enableSearch, enableUpload } = useDocumentsContext();

  if (!enableSearch && !enableUpload) return null;

  return (
    <Flexor className='space-x-1'>
      {enableSearch ? <DocumentsSearch /> : null}
      {enableUpload ? <DocumentsUpload /> : null}
    </Flexor>
  );
}
