import {v4 as uuidv4} from "uuid";
import {createDocument, uploadFileToStorage} from "admin/src/fetchers";
import type { UploadedDocumentFileResponse, Document } from "admin/src/types";

type ObjectType = 'documents' | 'expenses';

export function readFile(file: File): Promise<string | undefined> {
  return new Promise((resolve, reject) => {

    const reader = new FileReader();

    reader.onload = async (event) => {
      if (!event || !event.target) return;

      const arrayBuffer = event.target.result;

      if (!arrayBuffer) reject();

      resolve(arrayBuffer?.toString());
    }

    reader.onabort = reject;
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export function uploadToStorage(file: File, url: string, doc?: Document): Promise<UploadedDocumentFileResponse> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      if (!event || !event.target) return;

      const arrayBuffer = event.target.result;

      if (!arrayBuffer) return;

      // @ts-ignore
      const uploadedFileResponse = await uploadFileToStorage(url, arrayBuffer.split(',').pop());
      resolve({
        uploadedFileResponse,
        file,
        url,
        doc,
      });
    }

    reader.onabort = reject;
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export function createUploadId(customerId: string, objectType: ObjectType, file: File, uuid?: string) {
  const ext = file.name.split('.').pop();

  // The API dictates that we create our own UUID without the dashes IF we don't have a UUID
  // URL = <KeyCustomerId>/<ObjectType>/<RecordID>.<fileExtension>
  return `${customerId}/${objectType}/${uuid || uuidv4().replaceAll('-', '')}.${ext}`;
}

export async function uploadDocument(customerId: string, objectId: string, file: File, fileName?: string, objectType: ObjectType = 'documents'): Promise<UploadedDocumentFileResponse> {
  if (!customerId) return Promise.reject();

  // The API dictates that we create our own UUID without the dashes
  const url = createUploadId(customerId, objectType, file);

  const doc = await createDocument({
    url,
    objectId,
    documentName: fileName || file.name,
  });

  return uploadToStorage(file, url, doc);
}
