import {PollworkerWorkHistory} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import {ReactElement} from "react";
import {MODAL_ACTION_TYPES, MODALS} from "admin/src/actions/modal";
import {
  deletePollworkerSchedule,
  deletePollworkerWorkHistory,
  getPollworkerSchedulesList
} from "../../../fetchers";
import { clearSelectedRows, setShowPollworkerDetailsId } from "../../../reducers/pollworker/grid";
import { toast } from "react-toastify";
import { PollworkerModuleInfo } from 'admin/src/types';

function openDialog(target: string, payload: string) {
  window.chrome.webview.postMessage(`Dialog:${target}:Open:${payload}`);
}

export type GenericMenuItem = {
  name: string,
  disabled?: boolean,
  action: () => void,
  icon?: string,
  cssClasses?: string[],
} | string;

export type EvMenuItem = Omit<GenericMenuItem, 'icon'> & {
  icon?: ReactElement
};

export default function generateOptionsMenu(selectedRows: PollworkerWorkHistory[], reloadPollworkers: () => void, moduleInfo: PollworkerModuleInfo | null, dispatch: any): GenericMenuItem[] {
  selectedRows = selectedRows.filter(row => !!row);
  const manyRows = selectedRows.length > 1;

  return [
    {
      name: 'View Details',
      disabled: manyRows,
      action: () => {
        const worker = selectedRows[0];
        openDialog('PollworkerDetails', worker.pollworkerInfo.keyEVUserId);
      },
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
        </svg>
      `
    },
    {
      name: 'Edit',
      disabled: manyRows,
      action: () => {
        const worker = selectedRows[0];
        openDialog('WorkerEdit', worker.pollworkerInfo.keyEVUserId);
      },
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">\n' +
        '  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />\n' +
        '</svg>'
    },
    {
      name: 'Edit Mailing Address',
      disabled: manyRows,
      action: () => {
        const worker = selectedRows[0];
        openDialog('MailingAddressEdit', worker.pollworkerInfo.keyEVUserId);
      },
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">\n' +
        '  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />\n' +
        '</svg>'
    },
    {
      name: 'Schedule Worker',
      disabled: manyRows,
      action: () => {
        const worker = selectedRows[0];
        openDialog('ScheduleWork', worker.id);
      },
      icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">\n' +
        '  <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />\n' +
        '</svg>'
    },
    {
      name: 'Send SMS',
      action: () => {
        dispatch({
          type: MODAL_ACTION_TYPES.SHOW,
          payload: MODALS.SEND_SMS,
        });
      },
      icon: manyRows ? `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
          </svg>
        ` : `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 01.778-.332 48.294 48.294 0 005.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
          </svg>
        `
    },
    {
      name: 'Change Password',
      disabled: manyRows,
      action: () => {
        const worker = selectedRows[0];
        openDialog('ChangePassword', worker.pollworkerInfo.id);
      },
      icon: `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 5.25a3 3 0 013 3m3 0a6 6 0 01-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1121.75 8.25z" />
          </svg>
        `
    },
    {
      name: 'Change User ID',
      disabled: manyRows,
      action: () => {
        const worker = selectedRows[0];
        openDialog('ChangeUserId', worker.pollworkerInfo.id);
      },
      icon: `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
          </svg>
        `
    },
    {
      name: 'Print Letter',
      action: () => {
        const workerPollworkerIds = selectedRows.map((worker: PollworkerWorkHistory) => worker.pollworkerInfo.keyEVUserId).join(',');
        openDialog('PrintLetter', workerPollworkerIds);
      },
      icon: `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0110.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0l.229 2.523a1.125 1.125 0 01-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0021 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 00-1.913-.247M6.34 18H5.25A2.25 2.25 0 013 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 011.913-.247m10.5 0a48.536 48.536 0 00-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5zm-3 0h.008v.008H15V10.5z" />
          </svg>
        `
    },
    {
      name: 'Assign Expense',
      action: () => {
        const workerPollworkerIds = selectedRows.map((worker: PollworkerWorkHistory) => worker.pollworkerInfo.keyEVUserId).join(',');
        openDialog('AssignExpense', workerPollworkerIds);
      },
      icon: `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
          </svg>
        `
    },
    {
      name: 'Add to Role',
      action: () => {
        const workerPollworkerIds = selectedRows.map((worker: PollworkerWorkHistory) => worker.pollworkerInfo.keyEVUserId).join(',');
        openDialog('AddToRole', workerPollworkerIds);
      },
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122" />
        </svg>
      `
    },
    {
      name: 'Send Email',
      action: () => {
        const workerPollworkerIds = selectedRows.map((worker: PollworkerWorkHistory) => worker.pollworkerInfo.keyEVUserId).join(',');
        openDialog('SendEmail', workerPollworkerIds);
      },
      icon: `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25" />
          </svg>
        `
    },
    {
      name: 'Reports To',
      disabled: manyRows,
      action: () => {
        const workerPollworkerIds = selectedRows.map((worker: PollworkerWorkHistory) => worker.pollworkerInfo.keyEVUserId).join(',');
        openDialog('ReportsTo', workerPollworkerIds);
      },
      icon: `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
          </svg>
        `
    },
    {
      name: 'Promote',
      action: () => {
        const workerPollworkerIds = selectedRows.map((worker: PollworkerWorkHistory) => worker.pollworkerInfo.keyEVUserId).join(',');
        openDialog('Promote', workerPollworkerIds);
      },
      icon: `
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
          </svg>
        `
    },
    {
      name: 'Set Status',
      action: () => {
        dispatch({
          type: MODAL_ACTION_TYPES.SHOW,
          payload: MODALS.SET_STATUS,
        });
      },
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
        </svg>
      `
    },
    {
      name: 'Set SMS',
      action: () => {
        dispatch({
          type: MODAL_ACTION_TYPES.SHOW,
          payload: MODALS.SET_SMS,
        });
      },
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
        </svg>
      `
    },
    {
      name: 'Set Display Flags',
      action: () => {
        dispatch({
          type: MODAL_ACTION_TYPES.SHOW,
          payload: MODALS.SET_DISPLAY_FLAGS,
        });
      },
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
        </svg>
      `
    },
    'separator',
    {
      name: 'Remove from Election',
      action: async () => {
        const fullName = `${selectedRows[0].userInfo.firstName} ${selectedRows[0].userInfo.lastName}`;
        const { ElectionId: electionId, Election: { ElectionName: electionName } } = (moduleInfo as PollworkerModuleInfo);

        let confirmationMessage = `Are you sure you want to remove ${fullName} from the ${electionName} election?`;

        if (manyRows) {
          confirmationMessage = `Are you sure you want to remove ${selectedRows.length} workers from the ${electionName} election?`;
        }

        if (window.confirm(confirmationMessage)) {
          dispatch(clearSelectedRows(undefined));
          dispatch(setShowPollworkerDetailsId(undefined));

          const toastId = toast.loading(`Removing from ${electionName}...`, {
            position: 'top-center',
          });

          for (const idx in selectedRows) {
            const pollworker = selectedRows[idx];

            toast.update(toastId, { render: `Removing ${Number(idx) + 1} of ${selectedRows.length} from the ${electionName} election` });

            const { data: schedules } = await getPollworkerSchedulesList(pollworker.keyEVUserId, electionId);

            for (const schedule of schedules) {
              await deletePollworkerSchedule(schedule);
            }

            await deletePollworkerWorkHistory(pollworker.id);
          }

          toast.update(toastId, {
            render: manyRows ? `${selectedRows.length} pollworkers have been removed from the ${electionName} election` : `${fullName} has been removed from the ${electionName} election`,
            isLoading: false,
            type: 'success',
            autoClose: 4000,
          });

          reloadPollworkers();
        }
      },
      icon: `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
          <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
      `
    },
    'separator',
    'copy',
    'separator',
    'export',
  ];
}
