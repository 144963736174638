import * as React from "react"

import { Button } from "./Button"
import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "./Command"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "./Popover"
import { cn } from "../../utils";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

type ComboboxProps = {
    name?: string,
    selectedValue?: string,
    placeholder?: string,
    options: { value: string, label: string }[],
    onValueChange: (value: string) => void
}

export function Combobox({ options, placeholder, selectedValue, onValueChange }: ComboboxProps) {
    const [open, setOpen] = React.useState(false)

    return (
        <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
                <Button
                    variant="tertiary"
                    role="combobox"
                    aria-expanded={open}
                    className="min-w-[200px] justify-between"
                >
                    {
                        selectedValue ? (
                            options.find((option) => option.value === selectedValue)?.label
                        ) : placeholder
                    }
                    <ChevronDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
                </Button>
            </PopoverTrigger>
            <PopoverContent className="max-w-[400px] p-0 rounded-none bg-white">
                <Command onValueChange={onValueChange} value={selectedValue}>
                    <CommandInput placeholder="Search option..." className='!focus:outline-0 !border-none' />
                    <CommandList>
                        <CommandEmpty>No options found.</CommandEmpty>
                        <CommandGroup>
                            {options.map((option) => (
                                <CommandItem
                                    key={option.value}
                                    value={option.value}
                                    onSelect={(currentValue) => {
                                        onValueChange(currentValue)
                                        setOpen(false)
                                    }}
                                >
                                    <CheckIcon
                                        className={cn(
                                            "mr-2 h-4 w-4",
                                            selectedValue === option.value ? "opacity-100" : "opacity-0"
                                        )}
                                    />
                                    {option.label}
                                </CommandItem>
                            ))}
                        </CommandGroup>
                    </CommandList>
                </Command>
            </PopoverContent>
        </Popover>
    )
}
