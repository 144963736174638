import { FieldError, FieldErrorsImpl, Merge, useForm, useWatch } from "react-hook-form";
import { Switch, Dropdown, Label, Input, Textarea, Button, TooltipTrigger, TooltipProvider, TooltipContent, Tooltip } from "shared/src/components/ui/new";
import { Flexor, Loading } from "shared/src/components";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useEffect, useRef, useState } from "react";
import { fetchAddEditUserInfo, fetchUserIdIsInUse, saveAddEditUserInfo } from "admin/src/fetchers/setupAndAdmin";
import { ZodSchema, z } from 'zod';
import {Nomenclature} from "../../../components/Nomenclature";
import LoadingAdministration from "./LoadingAdministration";

const isNullOrWhitespace = (input: string | null | undefined) => !input || !input.trim();

export const saveEmployeeInfoDtoSchema: ZodSchema = z.object({
  evUserId: z.string().optional().nullable(),
  userId: z.string().min(1).max(100),
  firstName: z.string().min(1).max(100),
  lastName: z.string().min(1).max(100),
  email: z.string().email().max(300).optional().nullable().or(z.string().max(0).optional().nullable()),
  state: z.string().length(2).optional().nullable().or(z.string().max(0).optional().nullable()),
  securityLevelId: z.string(),
  cellPhone: z.string().regex(/(^$)|(^\d{10}$)/).optional().nullable(),
  okToSendSms: z.boolean().optional(),
  homePhone: z.string().regex(/(^$)|(^\d{10}$)/).optional().nullable(),
  address: z.string().max(100).optional().nullable(),
  address2: z.string().max(100).optional().nullable(),
  city: z.string().max(100).optional().nullable(),
  zipCode: z.string().max(10).regex(/(^$)|(^\d{10}$)|(^\d{5}$)/).optional().nullable(),
  notes: z.string().max(2048).optional().nullable(),
  voterRegistrationNumber: z.string().optional().nullable(),
  employeeId: z.string().optional().nullable(),
  title: z.string().optional().nullable(),
  ssoLoginId: z.string().max(255).optional().nullable(),
  ssoEnabled: z.boolean().optional(),
  enabled: z.boolean().optional(),
}).refine(validateUserIdUniqueAsync, {
  message: 'User ID is already in use',
  path: ['userId']
}).refine(({ ssoEnabled, ssoLoginId }) => !ssoEnabled || !isNullOrWhitespace(ssoLoginId), {
  message: 'SSO Login ID is required when SSO is enabled',
  path: ['ssoLoginId']
});

export type SaveEmployeeInfoDto = z.infer<typeof saveEmployeeInfoDtoSchema>;

export type SecurityLevel = {
  id: string;
  securityLevelName: string;
}

export type UsStateDialogDto = {
  fullName: string;
  abbreviation: string;
}

export type AddEditUserInfoDto = {
  user: SaveEmployeeInfoDto;
  securityLevels: SecurityLevel[];
  states: UsStateDialogDto[];
  ssoAllowed: boolean;
}

async function validateUserIdUniqueAsync(user: SaveEmployeeInfoDto): Promise<boolean>{
  const { evUserId, userId } = user;

  if (isNullOrWhitespace(userId)) {
    return false;
  }

  const isInUse: boolean = await fetchUserIdIsInUse(evUserId, userId);

  return !isInUse;
}

function ErrorLabel({error}: {error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined}) {
  if (!error) return null;

  const message = error?.message as string;

  return (<div className="text-ev-red text-xs">{message}</div>);
}

export default function AddEditUser({ evUserId, onComplete }: { evUserId?: string, onComplete?: Function }) {
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState(null as AddEditUserInfoDto | null);

  useEffect(() => {
    fetchAddEditUserInfo(evUserId)
      .then((dto: AddEditUserInfoDto) => {
        setFormData(dto);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {
        isLoading ? (
          <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
            <LoadingAdministration loadingMessage="Loading user..." />
          </div>
        ) : null
      }
      {!isLoading && <AddEditUserForm dto={formData} onComplete={onComplete} />}
    </>
  );
}

export function AddEditUserForm({ dto, onComplete }: { dto: AddEditUserInfoDto | null, onComplete?: Function }) {
  const formRef = useRef<HTMLFormElement>(null);

  const addEditEmployee = dto?.user;
  const ssoAllowed = dto?.ssoAllowed;

  const securityLevels = dto?.securityLevels
    ?.map((sl: SecurityLevel) => ({label: sl.securityLevelName, value: sl.id}))
    ?? [];

  const usStates = dto?.states
    ?.map((us: UsStateDialogDto) => ({label: us.fullName, value: us.abbreviation}))
    ?? [];

  const { register, handleSubmit, formState: { errors, isSubmitting }, setValue, control } = useForm<SaveEmployeeInfoDto>({
    resolver: zodResolver(saveEmployeeInfoDtoSchema),
    defaultValues: addEditEmployee,
  });

  const enabled = useWatch({ name: 'enabled', control });
  const ssoEnabled = useWatch({ name: 'ssoEnabled', control });
  const okToSendSms = useWatch({ name: 'okToSendSms', control });
  const state = useWatch({ name: 'state', control });
  const securityLevelId = useWatch({ name: 'securityLevelId', control });

  async function save(user: any) {
    if (!user) return;

    saveAddEditUserInfo(user)
      .then(() => {
        if (onComplete) {
          onComplete();
        }
      });
  }

  return (
    <form className='space-y-5 py-5' ref={formRef} onSubmit={handleSubmit((user) => save(user))}>
      <div className='space-y-5'>
        <div className='space-y-3'>
          <h4 className='font-semibold text-sm'>
            General
          </h4>
          <div className="flex justify-between gap-2">
            <div className="w-full">
              <Label htmlFor="first-name">
                First name <span className="text-ev-red">*</span>
              </Label>
              <div>
                <Input
                  type="text"
                  id="first-name"
                  placeholder='First name'
                  {...register('firstName')}
                />
                <ErrorLabel error={errors.firstName}/>
              </div>
            </div>
            <div className="w-full">
              <Label htmlFor="last-name">
                Last name <span className="text-ev-red">*</span>
              </Label>
              <div>
                <Input
                  type="text"
                  id="last-name"
                  placeholder='Last name'
                  {...register('lastName')}
                />
                <ErrorLabel error={errors.lastName}/>
              </div>
            </div>
          </div>

          <div>
            <Label htmlFor="title">
              Title
            </Label>
            <div>
              <Input
                type="text"
                id="title"
                placeholder='Title or prefix'
                {...register('title')}
              />
              <ErrorLabel error={errors.title}/>
            </div>
          </div>

          <div className="flex justify-between gap-2">
            <div className="w-full">
              <Label htmlFor="voter-registration-number">
                <Nomenclature nomenclatureId="VoterRegNumberDisplayName"/>
              </Label>
              <div>
                <Input
                  type="text"
                  id="voter-registration-number"
                  placeholder='Voter registration number'
                  {...register('voterRegistrationNumber')}
                />
                <ErrorLabel error={errors.voterRegistrationNumber}/>
              </div>
            </div>
            <div className="w-full">
              <Label htmlFor="employee-id">
                <Nomenclature nomenclatureId="EmployeeDisplayName"/> ID
              </Label>
              <div>
                <Input
                  type="text"
                  id="employee-id"
                  placeholder='Id'
                  {...register('employeeId')}
                />
                <ErrorLabel error={errors.employeeId}/>
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-2">
            <div className="w-full">
              <Label htmlFor="email-address">
                Email Address
              </Label>
              <div>
                <Input
                  type="email"
                  id="email-address"
                  placeholder='Email address'
                  {...register('email')}
                />
                <ErrorLabel error={errors.email}/>
              </div>
            </div>
          </div>

        </div>

        <div className='space-y-5'>
          <div className="space-y-3">
            <h4 className="font-semibold text-sm">
              <span className="bg-white">System information</span>
            </h4>
            <div className="flex justify-between items-center gap-2">
              <div className="w-full">
                <Label htmlFor="user-id">
                  User ID <span className="text-ev-red">*</span>
                </Label>
                <Input
                  type="text"
                  id="user-id"
                  placeholder="User ID or Login ID"
                  {...register('userId')}
                />
                <ErrorLabel error={errors.userId}/>
              </div>
              <div className="grow-0">
                <div>&nbsp;</div>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Flexor justify="between" className="space-x-2">
                        <Switch
                          checked={enabled}
                          onChange={(value) => setValue('enabled', value)}
                        />
                        <span className="text-sm">Enabled</span>
                      </Flexor>
                    </TooltipTrigger>
                    <TooltipContent>
                      Specifies if account is enabled
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
            {
              ssoAllowed && (
                <div className="flex justify-between items-center gap-2">
                  <div className="w-full">
                    <Label htmlFor="sso-login-id">
                      SSO Login ID {ssoEnabled && <span className="text-ev-red">*</span>}
                    </Label>
                    <Input
                      type="text"
                      id="sso-login-id"
                      placeholder="You must enter a login ID to enable SSO"
                      {...register('ssoLoginId')}
                    />
                    <ErrorLabel error={errors.ssoLoginId}/>
                  </div>
                  <div className="grow-0">
                    <div>&nbsp;</div>
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <Flexor justify="between" className="space-x-2">
                            <Switch
                              checked={ssoEnabled}
                              onChange={(value) => setValue('ssoEnabled', value)}
                            />
                            <span className="text-sm">Enabled</span>
                          </Flexor>
                        </TooltipTrigger>
                        <TooltipContent>
                          Determines if SSO is enabled for this user
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </div>
                </div>
              )
            }
            <div className="flex justify-between items-center gap-2">
              <div className="w-full">
                <Label htmlFor="cell-phone">
                  Cell phone <span className="text-ev-red">*</span>
                </Label>
                <Input
                  type="tel"
                  id="cell-phone"
                  placeholder="Cell phone"
                  {...register('cellPhone')}
                />
                <ErrorLabel error={errors.cellPhone}/>
              </div>
              <div className="grow-0">
                <div>&nbsp;</div>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger>
                      <Flexor justify="between" className="space-x-2">
                        <Switch
                          checked={okToSendSms}
                          onChange={(value) => setValue('okToSendSms', value)}
                        />
                        <span className="text-sm">Enabled</span>
                      </Flexor>
                    </TooltipTrigger>
                    <TooltipContent>
                      Determines if user can receive text messages
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          </div>

          <div className="gap-2">
            <Label htmlFor="security-level">
              Security Level <span className="text-ev-red">*</span>
            </Label>
            <Dropdown
              emptyPlaceholder="Select a security level"
              placeholder="Select a security level"
              options={securityLevels}
              selectedValue={securityLevelId}
              onChange={(event) => setValue('securityLevelId', event.target.value)}
            />
            <ErrorLabel error={errors.securityLevelId}/>
          </div>

        </div>

        <div className='space-y-3'>
          <h4 className="text-sm font-semibold">Communications</h4>
          <div className="flex justify-between gap-4">
            <div className="w-full">
              <Label htmlFor="home-phone">
                Home Phone
              </Label>
              <div>
                <Input
                  type="tel"
                  id="home-phone"
                  placeholder="Home phone"
                  {...register('homePhone')}
                />
                <ErrorLabel error={errors.homePhone}/>
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-2">
            <div className="w-full">
              <Label htmlFor="address">
                Address
              </Label>
              <div>
                <Input
                  type="text"
                  id="address"
                  placeholder='Address'
                  {...register('address')}
                />
                <ErrorLabel error={errors.address}/>
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-2">
            <div className="w-full">
              <Label htmlFor="address2">
                Address 2
              </Label>
              <div>
                <Input
                  type="text"
                  id="address2"
                  placeholder='Address 2'
                  {...register('address2')}
                />
                <ErrorLabel error={errors.address2}/>
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-2">
            <div className="w-full">
              <Label htmlFor="city">
                City
              </Label>
              <div>
                <Input
                  type="text"
                  id="city"
                  placeholder='City'
                  {...register('city')}
                />
                <ErrorLabel error={errors.city}/>
              </div>
            </div>
          </div>

          <div className="flex justify-between gap-2">
            <div className="w-full">
              <Label htmlFor="state">
                State
              </Label>
              <div>
                <Dropdown
                  emptyPlaceholder='Select a state'
                  placeholder="Select a state"
                  options={usStates}
                  selectedValue={state}
                  onChange={(event) => setValue('state', event.target.value)}
                />
                <ErrorLabel error={errors.state}/>
              </div>
            </div>
            <div className="w-full">
              <Label htmlFor="zip-code">
                Zip Code
              </Label>
              <div>
                <Input
                  type="text"
                  id="zip-code"
                  placeholder='Zip code'
                  {...register('zipCode')}
                />
                <ErrorLabel error={errors.zipCode}/>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-3">
          <h4 className='text-sm font-semibold'>Notes</h4>
          <div className="flex justify-between gap-4">
            <div className="w-full">
              <div>
                <Textarea
                  id="notes"
                  placeholder='Add any extra miscellaneous information about this user'
                  {...register('notes')}
                />
                <ErrorLabel error={errors.notes}/>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3">
        <Flexor justify='end'>
          <Button
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Flexor>
      </div>
    </form>
  );
}
