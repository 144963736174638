import {combineReducers} from "redux";

import { PollworkerModuleInfo } from "admin/src/types";
import gridReducer, { GridState } from './grid';
import schedulerReducer, { SchedulerState } from './scheduler';
import moduleInfoReducer from "./moduleInfo";
import settingsReducer, {SettingsState} from "./settings";
import detailsUiStackReducer, { DetailsUiStack } from "./detailsUiStack";
import trackerReducer, { PollworkerTrackerState } from './tracker';

export type PollworkerAppState = {
  grid: GridState,
  scheduler: SchedulerState,
  moduleInfo: PollworkerModuleInfo | null,
  settings: SettingsState,
  detailsUiStack: DetailsUiStack,
  tracker: PollworkerTrackerState
}

export default combineReducers({
  grid: gridReducer,
  scheduler: schedulerReducer,
  settings: settingsReducer,
  moduleInfo: moduleInfoReducer,
  detailsUiStack: detailsUiStackReducer,
  tracker: trackerReducer
});
