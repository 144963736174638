import React, { ReactNode, useState, useMemo, useEffect } from 'react'
import { toast, ToastContainer, ToastOptions } from "react-toastify";
import { Provider } from "react-redux";
import reduxStore from "./store";
import { desktopRootSaga } from './sagas';
import AdminApp, {EASYORDER_STORE_URI} from "./screens/AdminApp";
import NestedRoutes from "shared/src/components/NestedRoutes";
import { Route } from "wouter";
import PollworkerApplicationSubmissionViewer from "./screens/admin/Forms/PollworkerApplicationSubmissionViewer";
import Users from "./screens/admin/SetupAndAdmin/Users";
import RouteRequiredUserType from "./components/RouteRequiredUserType";
import { UserType } from "./enums/userType";
import Forms from "./screens/admin/SetupAndAdmin/Forms";
import FormEditor from "./screens/admin/SetupAndAdmin/FormEditor";
import BasicInfo from "./screens/admin/SetupAndAdmin/BasicInfo";
import Security from "./screens/admin/SetupAndAdmin/Security";
import Nomenclature from "./screens/admin/SetupAndAdmin/Nomenclature";
import PollworkerModernReports from "./components/Pollworker/PollworkerModernReports";
import Playground from "./screens/admin/Playground";
import { FeatureFlagCheck } from "./components/ui";
import { NavigationItem } from "./components/ui/NavBar";
import { Feature } from 'admin/src/enums';
import DeploymentsDashboard from "./components/Inventory/DeploymentsDashboard";
import EquipmentSummaryTable from "./components/Inventory/EquipmentSummaryTable";
import InventoryReports from "./components/Inventory/Reports";
import CampaignFinance from "./components/CampaignFinance/CampaignFinance";
import FilerImport from "./components/CampaignFinance/FilerImport";
import Pollworker from "./components/Pollworker/Pollworker";
import PollworkerVotingLocationScheduler from "./components/Pollworker/Panels/PollworkerVLScheduler";
import PollworkerTracker from "./screens/admin/Pollworker/PollworkerTracker";
import EasyOrderShell from "./screens/admin/EasyOrderShell";
import ContainerCentered from "./screens/admin/ContainerCentered";
import AdminDashboard from "./screens/admin/Dashboard";
import InventoryLevelsDashboard from "./screens/admin/Inventory";
import Store from "./screens/store/Store";
import ItemDetails from "./screens/admin/ItemDetails";
import DistributionCenters from './screens/admin/SetupAndAdmin/DistributionCenters';
import PollingLocations from './screens/admin/SetupAndAdmin/PollingLocations';
import Toaster from "./components/Toaster";
import { AdminContext } from "shared/src/contexts/AdminContext";
import DisabledAppCoverall from "./screens/DisabledAppCoverall";
import { Flexor } from "shared/src/components";
import { Button } from "shared/src/components/ui";
import {
  BuildingOfficeIcon,
  Cog6ToothIcon,
  DocumentIcon,
  InformationCircleIcon,
  KeyIcon,
  LanguageIcon, PlayIcon,
  PresentationChartLineIcon,
  UsersIcon
} from "@heroicons/react/24/outline";
import useNativeMessageListenerEffect from 'shared/src/hooks/useNativeMessageListenerEffect';
import Cache, { imageCacheKey } from './utils/cache';
import { desktopAuth } from 'admin/src/actions';
import { withNavBar } from "./hoc";

const setupAndAdminNavigationItems = [
  {
    icon: <InformationCircleIcon className="size-5"/>,
    name: 'Info',
    href: '/info',
    meta: 'info basic',
    show: () => process.env.NODE_ENV !== 'production',
  },
  {
    icon: <UsersIcon className="size-5"/>,
    href: '/',
    name: 'Users',
    meta: 'users sso auth new',
  },
  {
    icon: <BuildingOfficeIcon className='size-5'/>,
    href: '/distributionCenters',
    name: 'Distribution Centers',
    meta: 'distribution centers',
  },
  {
    icon: <BuildingOfficeIcon className='size-5'/>,
    href: '/pollingLocations',
    name: 'Polling Locations',
    meta: 'polling locations',
  },
  {
    icon: <DocumentIcon className='size-5' />,
    href: '/forms',
    name: 'Forms',
    meta: 'application',
  },
  {
    icon: <KeyIcon className="size-5"/>,
    name: 'Security',
    href: '/security',
    meta: 'security safety key level permissions settings',
    show: () => process.env.NODE_ENV !== 'production',
  },
  {
    icon: <LanguageIcon className="size-5"/>,
    name: 'Nomenclature',
    href: '/nomenclature',
    meta: 'terminology terms naming',
    show: () => process.env.NODE_ENV !== 'production',
  },
  {
    icon: <Cog6ToothIcon className="size-5"/>,
    name: 'Settings',
    href: '/settings',
    show: () => process.env.NODE_ENV !== 'production',
  },
  {
    icon: <PlayIcon className="size-5"/>,
    name: 'Playground',
    href: '/playground',
    show: () => process.env.NODE_ENV !== 'production',
  },
] as NavigationItem[];

const campaignfinanceNavigationItems = [
  {
    icon: <PresentationChartLineIcon className="size-5"/>,
    name: 'Document Filing Report',
    href: '/'
  },
  {
    icon: <UsersIcon className="size-5"/>,
    name: 'New Filer Import',
    href: '/filerimport'
  },
] as NavigationItem[];

export default function DesktopApp() {
  const store = useMemo(() => reduxStore.store, []);
  const sagaMiddleware = useMemo(() => reduxStore.sagaMiddleware, []);

  useEffect(() => {
    if (sagaMiddleware) {
      sagaMiddleware.run(desktopRootSaga);
    }
  }, [sagaMiddleware]);

  useEffect(() => {
    // This will prompt the native app to respond with the System:Auth event, giving us the JWT (zumo) token
    // this is to ensure that if a Zumo token isn't present in the query string, then the native app will
    // respond with to auth token.
    //
    // The native app responds to all messages, first, with the most up-to-date zumo token
    window.chrome.webview.postMessage(`System:Init:WillAckWithAuth`);
  }, []);

  // TODO: Figure out the situations where this arises and fix it.
  // If the containing native Dialog is listening for a close event, and this feature is disabled, this will close
  // the dialog after a set amount of time. It is possible the dialog doesn't have its own close button
  // useEffect(() => {
  //   if (disabledMessage) {
  //     setTimeout(() => {
  //       window.chrome.webview.postMessage(`Dialog:Self:Close`);
  //     }, 3000);
  //   }
  // }, [disabledMessage]);

  useNativeMessageListenerEffect((from: string, eventName: string, eventValue: string, payload: string) => {
    if (from === 'Dialog') {
      if (eventValue === 'Ok') {
        if (payload) {
          Cache.bust(imageCacheKey(payload));
        }
      }
    } else if (from === 'System') {
      switch (eventName) {
        case 'AuthToken':
          store.dispatch(desktopAuth(eventValue));
          break;
      }
    }
  });

  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <AdminContextStore>
          <AdminApp fullscreen className='theme-sa'>
            <NestedRoutes base="/form_submission">
              <Route path='/:applicantId' component={PollworkerApplicationSubmissionViewer} />
            </NestedRoutes>
            <NestedRoutes base="/setupandadmin">
              <Route path="/" component={withNavBar(() => (
                <div className="px-4 py-6 sm:px-6 lg:px-8 bg-white">
                  <Users />
                </div>
              ), setupAndAdminNavigationItems)} />
              <RouteRequiredUserType requiredUserType={UserType.Employee} path="/forms" component={withNavBar(Forms, setupAndAdminNavigationItems)} />
              <Route path="/forms/:formId" component={FormEditor} />
              <Route path="/info" component={withNavBar(BasicInfo, setupAndAdminNavigationItems)} />
              <Route path="/security" component={withNavBar(Security, setupAndAdminNavigationItems)} />
              <Route path="/distributionCenters" component={withNavBar(DistributionCenters, setupAndAdminNavigationItems)}/>
              <Route path="/pollingLocations" component={withNavBar(PollingLocations, setupAndAdminNavigationItems)}/>
              <Route path="/nomenclature" component={withNavBar(Nomenclature, setupAndAdminNavigationItems)} />
              <Route path="/settings" component={withNavBar(() => <div>/settings</div>, setupAndAdminNavigationItems)} />
              <Route path="/playground" component={withNavBar(Playground, setupAndAdminNavigationItems)} />
            </NestedRoutes>
            <NestedRoutes base="/inventory">
              <FeatureFlagCheck dbFlag={Feature.MODERN_INVENTORY} ifFlagMissing={() => (
                <RouteDisabledMessage message={'This account doesn\'t have Modern Inventory enabled. If you’re trying to order supplies, please log in to the store.'} />
              )}>
                <Route path="/" component={DeploymentsDashboard} />
                <Route path="/dashboard" component={DeploymentsDashboard} />
                <Route path="/equipment" component={EquipmentSummaryTable} />
                <Route path="/reports" component={InventoryReports} />
              </FeatureFlagCheck>
            </NestedRoutes>
            <NestedRoutes base="/campaignfinance">
              <Route path="/" component={withNavBar(CampaignFinance, campaignfinanceNavigationItems)} />
              <Route path="/filerimport" component={withNavBar(FilerImport, campaignfinanceNavigationItems)} />
            </NestedRoutes>
            <NestedRoutes base="/pollworker">
              <FeatureFlagCheck dbFlag={Feature.MODERN_POLLWORKER} ifFlagMissing={() => (
                <RouteDisabledMessage message="This account doesn't have Modern PollWorker enabled." />
              )}>
                <Route path="/" component={Pollworker} />
                <Route path="/scheduler/:viewType?/:votingLocationId?" component={PollworkerVotingLocationScheduler} />
                <Route path="/tracker" component={PollworkerTracker} />
              </FeatureFlagCheck>
            </NestedRoutes>
            <NestedRoutes base="/reports">
              <FeatureFlagCheck dbFlag={Feature.MODERN_REPORTS} ifFlagMissing={() => (
                <RouteDisabledMessage message="This account doesn't have Modern Reports enabled." />
              )}>
                <Route path="/" component={PollworkerModernReports} />
              </FeatureFlagCheck>
            </NestedRoutes>
            <NestedRoutes base="/easyorder">
              <FeatureFlagCheck dbFlag={Feature.EASY_ORDER} ifFlagMissing={() => (
                <RouteDisabledMessage message="This account doesn't have Easy Order enabled." />
              )}>
                <Route path="/dashboard">
                  <EasyOrderShell>
                    <ContainerCentered>
                      <AdminDashboard />
                    </ContainerCentered>
                  </EasyOrderShell>
                </Route>
                <Route path="/inventory">
                  <EasyOrderShell>
                    <InventoryLevelsDashboard />
                  </EasyOrderShell>
                </Route>
                <Route path="/store">
                  <EasyOrderShell>
                    <div className="p-4">
                      <Store />
                    </div>
                  </EasyOrderShell>
                </Route>
                <Route path="/store/:itemId" component={(props: any) => (
                  <div className="px-4 mt-3">
                    <ItemDetails showItemName {...props} />
                  </div>
                )} />
              </FeatureFlagCheck>
            </NestedRoutes>
            <Toaster />
          </AdminApp>
        </AdminContextStore>
        {process.env.NODE_ENV === 'development' ? <Route path="/playground" component={withNavBar(Playground, setupAndAdminNavigationItems)} /> : null}
      </Provider>
    </>
  )
}


function AdminContextStore(props: { children: ReactNode }) {
  const [isAdmin, setAdmin] = useState<boolean>(true);

  return (
    <AdminContext.Provider value={{isAdmin, toggleAdmin: () => setAdmin(!isAdmin)}}>
      {props.children}
    </AdminContext.Provider>
  )
}

const storeURL = window.location.protocol + '//' + window.location.host + EASYORDER_STORE_URI;

function RouteDisabledMessage({ message }: { message: string }) {
  return (
    <DisabledAppCoverall message={message}>
      <div className='space-y-2 mt-2'>
        <div>
          If you’re trying to order supplies, please log in to {storeURL}
        </div>
        <Flexor justify='center'>
          <Button variant='primary' onClick={async () => {
            const toastOptions = {autoClose: 3000, position: 'bottom-center'} as ToastOptions;

            try {
              await navigator.clipboard.writeText(storeURL);
              toast.success('Link copied to clipboard', toastOptions);
            } catch (error) {
              toast.warning('Link could not be copied, select text and copy manually', toastOptions);
            }
          }}>
            Copy Link
          </Button>
        </Flexor>
      </div>
    </DisabledAppCoverall>
  )
}
