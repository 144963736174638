import {hideModal, MODALS} from "admin/src/actions/modal";
import { AppState } from 'admin/src/store';
import {useDispatch, useSelector} from "react-redux";
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/24/outline";
import React, {Fragment, useEffect, useState} from "react";
import { Flexor } from "shared/src/components"
import {WorkerStatusByValue} from "admin/src/components/enums/WorkerStatusEnum";
import {workerStatusContent} from "./utils/CellStatusRenderer";
import {Listbox, Transition} from "@headlessui/react";
import {classNames} from "shared/src/utils/classNames";
import {patchBulkUpdatePollworkerStatus} from "admin/src/fetchers";
import {Button, Dialog} from "shared/src/components/ui";
import Spinner from "shared/src/components/Spinner";
import {getSelectedPollworkers} from "admin/src/selectors/pollworker/grid";
import {PollworkerWorkHistory} from "admin/src/types/Pollworker/PollworkerWorkHistory";

export default function DialogPollworkerSetStatus({onSave}: {onSave: () => void}) {
  const open = useSelector((state: AppState) => state.modals.open === MODALS.SET_STATUS);
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<PollworkerWorkHistory[]>([]);
  const [selected, setSelected] = useState<number>(0);
  const [saving, setSaving] = useState<boolean>(false);
  const selectedPollworkers = useSelector(getSelectedPollworkers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) return;

    setFilteredSelectedRows([]);
    setSelected(0);
  }, [open]);

  useEffect(() => {
    setFilteredSelectedRows([...selectedPollworkers]);
  }, [selectedPollworkers, open]);

  function removeIndexFromSelectedRows(idx: number) {
    const newFiltered = [...filteredSelectedRows];
    newFiltered.splice(idx, 1);
    setFilteredSelectedRows(newFiltered);
  }

  function hide() {
    dispatch(hideModal(MODALS.SET_STATUS));
  }

  function saveStatuses() {
    const pollworkerIds = filteredSelectedRows.map((row) => row.keyPollworkerId);

    setSaving(true);

    patchBulkUpdatePollworkerStatus(pollworkerIds, selected)
      .then(({data, success}) => {
        if (success) {
          onSave();
          hide();
        }
      })
      .finally(() => setSaving(false));
  }

  return (
    <Dialog.Dialog open={open} onOpenChange={hide}>
      <Dialog.DialogContent>
        <Dialog.DialogTitle>
          Set Status
        </Dialog.DialogTitle>
        <div className="mt-5">
          <Listbox value={selected} onChange={(value) => setSelected(Number.parseInt(value.toString()))}>
            {({ open }) => {
              const {icon, content} = selected ? workerStatusContent(selected) : {icon: '', content: 'Select a status'};

              return (
                <>
                  <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                    Status
                  </Listbox.Label>
                  <div className="relative mt-2">
                    <Listbox.Button
                      className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-ev-red sm:text-sm sm:leading-6"
                    >
                      <span className="flex items-center">
                        {
                          icon ? (
                            <div className="bg-white h-5 w-5 flex-shrink-0 rounded-full">
                              {icon}
                            </div>
                          ) : null
                        }
                        <span className={classNames(selected ? 'ml-3': '', "block truncate")}>{content}</span>
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                        <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        className="mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {Object.keys(WorkerStatusByValue).map((key: string) => {
                          const status = Number.parseInt(key);
                          const {content, icon} = workerStatusContent(status);

                          return (
                            <Listbox.Option
                              key={key}
                              className={({active}) =>
                                classNames(
                                  active ? 'bg-ev-red text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={key}
                            >
                              {({selected, active}) => (
                                <>
                                  <div className="flex items-center">
                                    <div className="bg-white rounded p-0.5">{icon}</div>
                                    <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'capitalize ml-3 block truncate')}>
                                      {content}
                                    </span>
                                  </div>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active ? 'text-white' : 'text-ev-red',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          )
                        })}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )
            }}
          </Listbox>
        </div>
        <ul role="list" className="divide-y divide-gray-100 overflow-y-auto max-h-80">
          <div className="mt-5 text-sm font-medium leading-6 text-gray-900">
            <Flexor>
              <div>Pollworkers</div>
              <div>{filteredSelectedRows.length}</div>
            </Flexor>
          </div>
          {
            filteredSelectedRows.map((row, idx) => {
              const {icon, content} = workerStatusContent(row.pollworkerInfo.status)

              return (
                <li key={`modal:${row.id}`} className="flex items-center justify-between gap-x-6 py-3">
                  <div className="flex min-w-0 gap-x-4">
                    <Flexor className="h-12 w-12 flex-none border border-gray-200 bg-gray-50 flex-col" justify="center" >
                      <div className="scale-[115%]">{icon}</div>
                    </Flexor>
                    <div className="min-w-0 flex-auto">
                      <p className="text-sm font-semibold leading-6 text-gray-900">
                        {row.pollworkerInfo.userInfo.firstName}
                        {' '}
                        {row.pollworkerInfo.userInfo.lastName}
                      </p>
                      <p className="mt-1 truncate text-xs leading-5 text-gray-500">{content || 'Empty'}</p>
                    </div>
                  </div>
                  <Button onClick={() => removeIndexFromSelectedRows(idx)} variant='tertiary' className="shadow-none">Remove</Button>
                </li>
              )
            })
          }
        </ul>
        <Dialog.DialogFooter>
          <div className="mt-5 sm:mt-6">
            <Button variant='primary' onClick={saveStatuses} disabled={saving}>
              <div className="mx-auto">
                <Flexor justify="center" className="space-x-2">
                  {saving ? <Spinner /> : null }
                  <div className="w-full text-center">Save</div>
                </Flexor>
              </div>
            </Button>
          </div>
        </Dialog.DialogFooter>
      </Dialog.DialogContent>
    </Dialog.Dialog>
  )
}
