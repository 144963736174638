import React, {ReactNode, useEffect, useState} from 'react';
import { useSelector} from "react-redux";
import { AppState } from 'admin/src/store';
import {classNames} from "shared/src/utils/classNames";
import { Loading} from "shared/src/components";
import { Feature } from 'admin/src/enums';
import * as Sentry from 'shared/src/setupSentry';

type AdminAppProps = {
  children: ReactNode,
  checkFeatureEnabled?: Feature,
  featureDisabledMessage?: string,
  fullscreen?: boolean,
  className?: string,
};

export const EASYORDER_STORE_URI = '/app/store';


export default function AdminApp({
  fullscreen = false,
  className,
  children
 }: AdminAppProps) {
  const user = useSelector((state: AppState) => state.user);
  const [showApp, setShowApp] = useState<boolean>(false);

  useEffect(() => {
    if (!user?.account) return;

    setShowApp(true);

    if (user.user) {
      Sentry.identifyLoggedInUser(
        user.user.id,
        user.user.userId,
        user.user.emailAddress,
        user.account.id,
        user.account.customerName,
        user.account.state
      );
    }
  }, [user]);

  if (!user || !showApp) {
    return (
      <div className='h-full w-full flex flex-col justify-center'>
        <div className="mx-auto">
          <Loading loadingMessage="Loading..."/>
        </div>
      </div>
    )
  }

  return (
    <div className={classNames(fullscreen ? 'h-full' : 'mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5', className)}>
      <>{children}</>
    </div>
  );
}

