import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import {useSelector} from "react-redux";
import {getShowPollworkerDetails} from "admin/src/selectors/pollworker";
import React, {useEffect, useState} from "react";
import {getPollworkerMessages} from "admin/src/fetchers";
import {
  ArrowTrendingUpIcon,
  AtSymbolIcon,
  BookmarkSquareIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import {PollworkerMessage} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import {classNames} from "shared/src/utils/classNames";
import { Flexor } from "shared/src/components"
import Spinner from "shared/src/components/Spinner";
import dayjs from "dayjs";
import DeprecatedModal from "shared/src/components/DeprecatedModal";
import {
  CalendarDaysIcon,
  EnvelopeOpenIcon,
} from "@heroicons/react/24/outline";
import DateFormatter from "shared/src/components/DateFormatter";
import PanelHeader from "./ui/PanelHeader";
import { groupBy } from "lodash";

export default function PollworkerMessagesPanel() {
  const [messagesByDate, setMessagesByDate] = useState<{[key: string]: PollworkerMessage[]}>({});
  const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
  const [messageToShow, setMessageToShow] = useState<PollworkerMessage>();
  const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
  const pollworker = useSelector(getShowPollworkerDetails);

  useEffect(loadPollworkerMessages, [pollworker]);

  function loadPollworkerMessages() {
    if (!pollworker) return;

    setLoadingMessages(true);

    getPollworkerMessages(pollworker.keyEVUserId)
    .then((messages) => {
      const messagesByDate = groupBy(messages, (message: PollworkerMessage) => {
        return dayjs(message.dateSent).format('MM-DD-YYYY');
      });

      setMessagesByDate(messagesByDate);
    })
    .finally(() => setLoadingMessages(false));
  }

  function showMessage(message: PollworkerMessage) {
    setMessageToShow(message);
    setShowMessageModal(true);
  }

  const initLoading = loadingMessages && !Object.keys(messagesByDate).length;

  return (
    <div>
      <PanelHeader title='Messages' loading={loadingMessages} reload={loadPollworkerMessages} />
      <DeprecatedModal squared size='3xl' title="Message" open={showMessageModal} onClose={() => setShowMessageModal(false)}>
        <div dangerouslySetInnerHTML={{__html: `${messageToShow?.body}` }} />
      </DeprecatedModal>
      {
        initLoading ? (
          <Flexor justify="center" className="space-x-2 mt-10">
            <Spinner show />
            <SectionSubSubHeading>Loading messages...</SectionSubSubHeading>
          </Flexor>
        ) : (
          <>
            {
              !Object.keys(messagesByDate).length ? (
                <Flexor justify="center" className="space-x-2 mt-10">
                  <NoSymbolIcon className="h-6 w-6" />
                  <SectionSubSubHeading>No messages found</SectionSubSubHeading>
                </Flexor>
              ) : null
            }
            <ul className={classNames(loadingMessages ? 'opacity-50' : '', 'transition-opacity divide-y divide-gray-400')}>
              {
                Object.keys(messagesByDate).map((messageGroupDate: string) => {
                  const messages = messagesByDate[messageGroupDate];

                  return (
                    <li key={messageGroupDate}>
                      <div className="p-2 bg-gray-50 border-b-2 border-gray-100">
                        <SectionSubSubHeading>{dayjs(messageGroupDate).format('ddd, MMM D, YYYY')}</SectionSubSubHeading>
                      </div>
                      <div className="divide-y divide-gray-200">
                        {
                          messages.map((message) => {
                            return (
                              <dl key={message.id} className={classNames("hover:bg-gray-100 pb-2 divide-y pl-2")}>
                                <div className="transition-all w-full">
                                  <div className="w-full flex items-center space-x-2 justify-between py-3 text-sm font-medium max-w-full">
                                    <div className='flex-1 min-w-0'>
                                      <Flexor justify="start" className="space-x-1">
                                        <BookmarkSquareIcon className="h-4 w-4 shrink-0" />
                                        <div title={message.subject} className="truncate">{message.subject}</div>
                                      </Flexor>
                                      <div className="flex items-center space-x-2">
                                        <CalendarDaysIcon className="h-4 w-4 shrink-0" />
                                        <time title={`${message.dateSent}`} className='truncate'>
                                          <DateFormatter dateString={message.dateSent} />
                                        </time>
                                      </div>
                                      <Flexor justify="start" className="space-x-1 w-full truncate break-all">
                                        <AtSymbolIcon className="h-4 w-4 shrink-0" />
                                        <div title={message.from} className="truncate">{message.from}</div>
                                      </Flexor>
                                      <Flexor justify="start" className="space-x-1 w-full truncate break-all">
                                        <ArrowTrendingUpIcon className="h-4 w-4 shrink-0" />
                                        <div title={message.lastStatus} className={classNames(message.lastStatus ? '' : 'italic font-normal', 'truncate')}>{message.lastStatus || 'No status available'}</div>
                                      </Flexor>
                                    </div>
                                    <div className="text-sm text-gray-900 flex items-center shrink-0 justify-end" onClick={() => showMessage(message)}>
                                      <EnvelopeOpenIcon className="h-5 w-5" aria-hidden="true" />
                                    </div>
                                  </div>
                                </div>
                              </dl>
                            )
                          })
                        }
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </>
        )
      }
    </div>
  )
}
