import {useEffect} from "react";

export default function useNativeMessageListenerEffect(callback: (from: string, eventName: string, eventValue: string, payload: string) => void, deps: any[] = []) {
  useEffect(() => {
    const nativeAppPostMessageHandler = (event: {data: string}) => {
      const eventParts = event.data.split(':');
      callback(eventParts[0], eventParts[1], eventParts[2], eventParts[3]);
    }

    window?.chrome?.webview?.addEventListener('message', nativeAppPostMessageHandler);
    return () => window?.chrome?.webview?.removeEventListener('message', nativeAppPostMessageHandler);
  }, deps);
}
