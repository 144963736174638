import {call, take, takeEvery, takeLatest, put, select} from 'redux-saga/effects';

import {
  login,
  setIsLoading,
  setToken,
  logout,
  setUser,
  loadingUser,
  setLoadingUserError,
  desktopAuth,
  checkFeatureFlag, setFeatureFlagCheck
} from 'admin/src/actions';
import {getUser, login as postLogin} from 'admin/src/fetchers';
import {UserLogin} from 'shared/src/types';
import {PayloadAction} from "@reduxjs/toolkit";
import * as Sentry from "shared/src/setupSentry";
import {CurrentUserSettingsDTO, UserLoginInfo, UserLoginSuccessDTO} from "../types/User";
import { handleZumoAuth } from "admin/src/utils/auth";
import {CustomerInfo} from "shared/src/types/CustomerInfo";
import {selectFeatureFlag} from "../selectors";
import {FeatureFlagCheck} from "../types/FeatureFlagCheck";

/**
 * The `desktopAuthSaga` and `webAuthSaga` are functionally almost identical, the only difference being:
 * 1. The Storage interface used to store the user's login information.
 * 2. Post-login, the Web version needs to redirect to the user's final destination (e.g. /store for EasyOrder, and /home or something for others) while the Desktop version does not.
 */
export function* desktopAuthSaga() {
  yield takeLatest(login.type, handleAppLogin, window.sessionStorage, noRedirect);
  yield takeEvery(logout.type, handleLogout, window.sessionStorage);

  yield call(handleExistingAuthInfo, window.sessionStorage, noRedirect);
  yield takeLatest(desktopAuth.type, handleDesktopAuth, window.sessionStorage, noRedirect);
}

export function* handleDesktopAuth(storage: Storage, getRedirectDestination: (currentPath: string, user: UserLoginInfo) => string, action: PayloadAction<string>) {
  yield handleLoginSuccess({ zumoToken: action.payload }, storage, getRedirectDestination);
}

export function* webAuthSaga() {
  yield takeLatest(login.type, handleAppLogin, window.localStorage, determinePostLoginDestinationForWebUser);
  yield takeEvery(logout.type, handleLogout, window.localStorage);

  yield call(handleExistingAuthInfo, window.localStorage, determinePostLoginDestinationForWebUser);
}

export function* handleAppLogin(storageEngine: Storage, getRedirectDestination: (currentPath: string, user: UserLoginInfo) => string, action: PayloadAction<UserLogin>) {
  yield put(setIsLoading(true));
  try {
    const loggedInUser = (yield call(postLogin, action.payload.username, action.payload.password)) as string | UserLoginSuccessDTO;

    if (typeof(loggedInUser) === 'string') {
      yield call(handleLoginFailure, loggedInUser as string, storageEngine);
    } else {
      yield call(handleLoginSuccess, loggedInUser as UserLoginSuccessDTO, storageEngine, getRedirectDestination);
    }
  } catch (error: any) {
    yield call(handleLoginFailure, error.message, storageEngine);
  }

  yield put(setIsLoading(false));
}

export function* handleExistingAuthInfo(storageEngine: Storage, getRedirectDestination: (currentPath: string, user: UserLoginInfo) => string) {
  if (storageEngine.getItem('userId') && storageEngine.getItem('customerId') && storageEngine.getItem('zumo')) {
    const zumoToken = storageEngine.getItem('zumo') as string;
    yield call(handleLoginSuccess, { zumoToken }, storageEngine, getRedirectDestination);
  }
}

export function* handleLoginFailure(errorMessage: string, storageEngine: Storage) {
  storageEngine.removeItem('userId');
  storageEngine.removeItem('customerId');
  storageEngine.removeItem('zumo');
  yield put(setLoadingUserError(errorMessage));
}

export function* handleLoginSuccess(loginSuccess: { zumoToken: string }, storageEngine: Storage, getRedirectDestination: (currentPath: string, user: UserLoginInfo) => string) {
  handleZumoAuth(loginSuccess.zumoToken, storageEngine);

  yield put(setToken(loginSuccess.zumoToken));
  yield put(loadingUser(true));
  yield put(setLoadingUserError(undefined));

  try {
    const currentUserResponse: { success: boolean, data: CurrentUserSettingsDTO } = yield call(getUser);

    if (currentUserResponse.success) {
      const currentUser = currentUserResponse.data;
      currentUser.customerInfo.isState = currentUser.customerInfo.customerType < 3;
      currentUser.customerInfo.isCounty = !currentUser.customerInfo.isState;
      if (currentUser.parentCustomerInfo) {
        currentUser.parentCustomerInfo.isState = currentUser.parentCustomerInfo.customerType < 3;
        currentUser.parentCustomerInfo.isCounty = !currentUser.parentCustomerInfo.isState;
      } else {
        currentUser.parentCustomerInfo = {
          isState: false,
          isCounty: false,
        } as CustomerInfo
      }

      const user: UserLoginInfo = {
        orderConfigs: currentUser.orderConfigs,
        enabled: currentUser.hasFeatureEnabled,
        user: currentUser.userInfo,
        account: currentUser.customerInfo,
        parentAccount: currentUser.parentCustomerInfo,
        features: currentUser.features,
      };

      yield put(setUser(user));

      Sentry.identifyLoggedInUser(
        currentUser.userInfo.id,
        currentUser.userInfo.userId,
        currentUser.userInfo.emailAddress,
        currentUser.customerInfo.id,
        currentUser.customerInfo.customerName,
        currentUser.customerInfo.state
      );

      const dest = getRedirectDestination(window.location.pathname, user);

      yield put(checkFeatureFlag('setup-and-admin-web'));
      yield take(setFeatureFlagCheck.type);
      const flag: FeatureFlagCheck = yield select(selectFeatureFlag('setup-and-admin-web'));

      if (dest) {
        if (flag && !flag.enabled) {
          // If the feature flag is disabled, always go to easyorder home page
          window.location.href = '/app/store';
          return;
        } else {
          window.history.pushState({}, '', dest);
        }
      }
    }
  } catch (err: any) { // For some reason, TypeScript doesn't like the `Error` type here, but this should be an `Error`
    yield put(setLoadingUserError(err.message));
  } finally {
    yield put(loadingUser(false));
  }
}

export function* handleLogout(storageEngine: Storage) {
  storageEngine.removeItem('userId');
  storageEngine.removeItem('customerId');
  storageEngine.removeItem('zumo');
  window.history.pushState({}, '', '/app/login');
}

export function determinePostLoginDestinationForWebUser(currentPath: string, user: UserLoginInfo): string {
  if (currentPath !== '/app/login') {
    return '';
  } else if (user.enabled && user.orderConfigs?.find((config) => config.enabled)) {
    return '/app/store';
  } else if (user.user.userType === 1) {
    if (currentPath === '/app/login')
      return '/app/admin/administration';
  }

  return '';
}

export function noRedirect(currentPath: string, user: UserLoginInfo): string {
  return '';
}
