import { useGridContext } from "./DataGridContext";
import { useEffect, useMemo, useState } from "react";
import type { Column } from 'ag-grid-enterprise';
import { Command, CommandGroup, CommandItem, CommandList, CommandSeparator } from "shared/src/components/ui/Command";
import { classNames } from "shared/src/utils";
import { PopoverTrigger, Popover, PopoverContent } from "shared/src/components/ui/Popover";
import { Button } from "shared/src/components/ui/new/Button";
import { EyeSlashIcon, ViewColumnsIcon } from "@heroicons/react/24/outline";
import DataGridDataTypeIcon from "./DataGridDataTypeIcon";
import * as React from "react";
import { getCellDataType } from "./utils";
import { Badge } from "shared/src/components/ui/new/Badge";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "shared/src/components/ui/new/Tooltip";

type ColumnListData = {
  name: string,
  visible: boolean,
  field?: string,
  columnId?: string,
  dataType?: string | boolean,
};

export default function DataGridColumnSelector() {
  const [columns, _setColumns] = useState<ColumnListData[]>([]);
  const { gridApi } = useGridContext();

  useEffect(() => {
    setColumnVisibilityStatus();
  }, [gridApi]);

  useEffect(() => {
    function onColumnVisibilityChangeListener() {
      setColumnVisibilityStatus();
    }

    gridApi?.addEventListener('columnVisible', onColumnVisibilityChangeListener);

    return () => {
      if (gridApi?.isDestroyed()) return;
      gridApi?.removeEventListener('columnVisible', onColumnVisibilityChangeListener);
    }
  }, [gridApi]);

  function toggleColumnVisibility(columnId?: string) {
    if (!columnId || gridApi?.isDestroyed()) return;

    const column = gridApi?.getColumn(columnId);
    if (!column) return;

    const visible = column.isVisible();

    gridApi?.setColumnsVisible([columnId], !visible);
    gridApi?.sizeColumnsToFit();
  }

  function setColumnVisibilityStatus() {
    if (!gridApi || gridApi?.isDestroyed()) return _setColumns([]);

    const columns = gridApi.getColumns()
      ?.map((col: Column): ColumnListData | null => {
        const colDef = col.getColDef();

        // if a column is pinned, we will assume that it is required, ex: an actions column
        if (colDef.pinned) return null;

        const name = colDef.headerName;

        if (!name) return null;

        return {
          name,
          field: colDef.field,
          columnId: col.getColId(),
          visible: col.isVisible(),
          dataType: getCellDataType(colDef.cellDataType),
        };
      })
      ?.filter((col) => {
        return !!col;
      }) as ColumnListData[];

    _setColumns(columns);
  }

  const hiddenFieldsCount = useMemo(() => {
    return columns.filter(col => !col.visible).length;
  }, [columns]);

  if (!columns?.length) return null;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button size='sm' className={classNames(hiddenFieldsCount ? 'border-enhancetitle border' : '')} variant="outline">
          <ViewColumnsIcon className='h-5' />
          Columns
          {
            hiddenFieldsCount ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Badge className='space-x-1'>
                      <EyeSlashIcon />
                      <span>{hiddenFieldsCount}</span>
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent>{hiddenFieldsCount} hidden columns</TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : null
          }
        </Button>
      </PopoverTrigger>
      <PopoverContent className="p-0 w-[250px]" align="end">
        <Command>
          <CommandList>
            <CommandGroup heading="Hidden">
              <div className='flex flex-col h-full justify-start'>
                {
                  columns.map((col) => {
                    if (col.visible) return null;

                    return (
                      <CommandItem key={col.name} onSelect={() => toggleColumnVisibility(col.columnId)}>
                        <DataGridDataTypeIcon className='h-5' dataType={getCellDataType(col.dataType)} />
                        <span>{col.name}</span>
                      </CommandItem>
                    );
                  })
                }
              </div>
            </CommandGroup>
            <CommandSeparator />
            <CommandGroup heading="Visible">
              {
                columns.map((col) => {
                  if (!col.visible) return null;

                  return (
                    <CommandItem key={col.name} className='space-x-1' onSelect={() => toggleColumnVisibility(col.columnId)}>
                      <DataGridDataTypeIcon className='h-5' dataType={getCellDataType(col.dataType)} />
                      <span>{col.name}</span>
                    </CommandItem>
                  );
                })
              }
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
