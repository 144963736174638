import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import React, { useEffect, useState, Fragment } from "react";
import { toast } from "react-toastify";
import { AlertDescription } from "shared/src/components/ui/Alert";
import { FormStatus } from "shared/src/enums/FormStatus";
import { getHeaders } from "shared/src/fetchers";
import {
  EyeIcon,
  PencilSquareIcon,
  ChevronDownIcon,
  TableCellsIcon,
  TrashIcon, ArrowTopRightOnSquareIcon, ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Flexor } from "shared/src/components";
import {
  Alert,
  Button,
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator, AlertTitle
} from "shared/src/components/ui/new";
import { pollworkerSurveyJsTheme } from "shared/src/components/pollworkerSurveyJsTheme";
import { classNames } from "shared/src/utils/classNames";
import { compileFormSchema, generateAsyncFormQuestionHostname } from "shared/src/utils/forms";
import { Converter } from "showdown";
import { Model, settings } from 'survey-core';
import { Survey } from 'survey-react-ui';
import { Link, useLocation, useParams } from "wouter";
import {
  createChildForm,
  getForms, getPollworkerSettings,
  publishForm, revertDraftForm,
  updateForm
} from "admin/src/fetchers/setupAndAdmin";
import LoadingAdministration from "./LoadingAdministration";
import { SurveyCreatorWidget } from './SurveyCreator';
import 'survey-core/defaultV2.min.css';
import 'survey-creator-core/survey-creator-core.min.css';
import 'shared/src/PollworkerSettings.css';

export default function FormEditor() {
  const [form, setForm] = useState({});
  const [schema, setSchema] = useState();
  const [model, setModel] = useState();
  const [loadingForms, setLoadingForms] = useState(true);
  const [editFormTitle, setEditFormTitle] = useState(false);
  const [pollworkerSettings, setPollworkerSettings] = useState();
  const [userEditableSchema, setUserEditableSchema] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  const [updatingForm, setUpdatingForm] = useState(false);
  const customerId = useSelector((state) => state.user?.account?.id);
  const { formId } = useParams();
  const [, navigate] = useLocation();

  useEffect(() => {
    loadPollworkerSettings();
  }, []);

  useEffect(() => {
    if (!pollworkerSettings || !formId) return;

    loadFormById(formId);
  }, [pollworkerSettings, formId]);

  useEffect(() => {
    settings.web.disableQuestionWhileLoadingChoices = true;
    settings.web.onBeforeRequestChoices = (sender, options) => {
      Object.entries(getHeaders()).forEach(([key, val]) => {
        options.request.setRequestHeader(key, val);
      });
    }
  }, []);

  function onChange(saveNo, updatedSchema) {
    setUpdatingForm(true);

    const newSchema = structuredClone(schema);
    const [firstPage, ...otherPages] = schema.pages;

    setSchema(structuredClone({
      ...newSchema,
      pages: [firstPage, ...updatedSchema.pages, ...otherPages]
    }));

    return updateForm(formId, {
      CompiledFormJson: JSON.stringify(compileFormSchema({...form, json: JSON.stringify(updatedSchema.pages)})),
      Json: JSON.stringify(updatedSchema.pages)
    })
    .then(({ data }) => {
      const updatedForm = { ...form, ...JSON.parse(atob(data)) };

      setForm(updatedForm);
      updateModel(updatedForm, compileFormSchema(updatedForm));
    })
    .finally(() => setUpdatingForm(false));
  }

  function updateModel(form, modelJson) {
    modelJson.questionsOnPageMode = 'singlePage';
    const m = new Model(modelJson);

    settings.web.encodeUrlParams = false;

    m.readOnly = form.status === FormStatus.Published;
    m.showCompletedPage = false;
    m.showCompleteButton = false;
    m.focusFirstQuestionAutomatic = false;
    m.setVariable('pollworkerWebsiteApiHostname', generateAsyncFormQuestionHostname());
    m.setVariable('customerId', customerId);
    m.setVariable('iAgreeCopy', pollworkerSettings.prospectAgreement);
    m.css = pollworkerSurveyJsTheme;

    const converter = new Converter();
    m.onTextMarkdown.add((survey, options) => {
      let str = converter.makeHtml(options.text);
      options.html = str.substring(3, str.length - 4);
    });

    setModel(m);
  }

  function openPollworkerApplication() {
    window.open(`http://${pollworkerSettings.curl}/apply/?formId=${formId}`, '_blank');
  }

  function loadPollworkerSettings() {
    getPollworkerSettings().then(([settings]) => setPollworkerSettings(settings));
  }

  function publish() {
    if (!window.confirm('Are you sure you\'re ready to publish?')) return;

    publishForm(formId).then((resp) => {
      const publishedForm = JSON.parse(atob(resp.data));
      setForm(publishedForm);
    });
  }

  function revert() {
    if (!window.confirm('Are you sure you want to revert your changes?')) return;

    const revertDraftPromise = revertDraftForm(form.id);

    toast.promise(revertDraftPromise, {
      pending: 'Reverting to parent form...',
      success: 'This form has been reverted!',
      error: 'Could not revert form.',
    }, {position: 'top-center'})
    .then(({ data }) => {
      const success = JSON.parse(atob(data))
      if (!success) return;

      setTimeout(() => navigate(`/forms/${form.parentFormId}`, { replace: true }), 2000);
    });
  }

  function editForm(formId) {
    createChildForm(formId)
    .then((resp) => {
      const child = JSON.parse(atob(resp.data));
      navigate(`/forms/${child.id}`, { replace: true });
    })
  }

  function updateFormTitle(newFormTitle) {
    setEditFormTitle(false);

    const toastId = toast.loading('Saving', {position: 'top-center', autoClose: 1000});

    updateForm(formId, {
      Title: newFormTitle,
    })
    .then(({data, success}) => {
      if (!success) {
        toast.update(toastId, { type: 'error', isLoading: false, autoClose: 1000, render: 'Form could not be saved' });
        return;
      }

      toast.update(toastId, { type: 'success', isLoading: false, autoClose: 1000, render: 'Saved' });
      const updatedForm = JSON.parse(atob(data));
      setForm({ ...form, ...updatedForm });
    })
    .catch(() => {
      toast.update(toastId, { type: 'error', render: 'Form could not be saved' });
      loadFormById(formId);
    });
  }

  function loadFormById(id) {
    setLoadingForms(true);

    getForms()
    .then((resp) => JSON.parse(atob(resp.data)))
    .then((forms) => {
      const parsedForm = forms.find(f => f.id === id);

      if (!parsedForm) {
        setErrorMessage(`Could not find form ${formId} -> ${id}`);
        return;
      }

      setForm(parsedForm);

      const workingPages = JSON.parse(parsedForm.json);
      setUserEditableSchema({
        pages: workingPages,
      });

      const schema = compileFormSchema(parsedForm);

      setSchema(schema);
      updateModel(parsedForm, schema);
    })
    .finally(() => {
      setLoadingForms(false);
    });
  }

  if (errorMessage) {
    return (
      <div className='h-full w-full flex flex-col justify-center max-w-3xl mx-auto space-y-5'>
        <Alert variant='destructive' back='~/admin/setupandadmin/forms'>
          <ExclamationCircleIcon className='size-5' />
          <AlertTitle>{errorMessage}</AlertTitle>
          <AlertDescription>{errorMessage}</AlertDescription>
        </Alert>
        <Link to='/forms'>
          <Button variant='outline' className='w-auto'>Back</Button>
        </Link>
      </div>
    )
  }

  if (!model) {
    return (
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
        <LoadingAdministration loadingMessage='Starting form editor...' />
      </div>
    )
  }

  function validateForm() {
    if (!model.validate()) {
      toast.error('There are errors on the form', { position: 'top-center' });
      return;
    }

    toast.success('The form is valid!', { position: 'top-center' });
  }

  const isPublished = form.status === FormStatus.Published;

  return (
    <div className={classNames(loadingForms ? 'opacity-60 pointer-events-none' : '', 'h-full flex flex-col justify-between transition-opacity')}>
      <div className='h-14 justify-between bg-white border-b p-3 flex items-center'>
        <Flexor className='space-x-2'>
          <Link to='/forms' className='text-sm flex items-center space-x-2 border-r pr-2 hover:underline'>
            <ChevronLeftIcon className='size-4' />
            All forms
          </Link>
          <Flexor className='space-x-1'>
            <PencilSquareIcon className='shrink-0 h-4 w-4 text-gray-500' />
            {
              editFormTitle ? (
                <input
                  autoFocus
                  defaultValue={form.title}
                  onBlur={({target: {value: newFormTitle}}) => updateFormTitle(newFormTitle)}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6"
                />
              ) : (
                <span className='text-sm font-semibold' onDoubleClick={() => setEditFormTitle(true)}>{form.title}</span>
              )
            }
            <p className="rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset text-gray-600 bg-gray-50 ring-gray-500/10">#{form.id.split('-')[0]}</p>
          </Flexor>
        </Flexor>
        <Flexor className='space-x-1'>
          {
            isPublished ? (
              <Button onClick={() => editForm(formId)}>
                <TableCellsIcon />
                <span>Edit</span>
              </Button>
            ) : (
              <Button onClick={publish}>
                <EyeIcon />
                <span>Publish Draft</span>
              </Button>
            )
          }
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant='outline'>
                Options
                <ChevronDownIcon/>
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align='end'>
              <DropdownMenuItem onSelect={openPollworkerApplication}>
                <ArrowTopRightOnSquareIcon />
                View application
              </DropdownMenuItem>
              <DropdownMenuItem onSelect={loadPollworkerSettings}>
                <ArrowTopRightOnSquareIcon />
                Refresh poll worker settings
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onSelect={revert}>
                <TrashIcon />
                Revert changes
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </Flexor>
      </div>
      <PanelGroup autoSaveId='form-editor' direction="horizontal" className='flex h-full'>
        <Panel defaultSize={30} minSize={20} className='h-full'>
          <SurveyCreatorWidget readOnly={isPublished} model={userEditableSchema} onChange={onChange} />
        </Panel>
        <PanelResizeHandle className='bg-gray-200 hover:bg-ev-blue w-1 transition-colors' />
        <Panel style={{overflow: 'auto'}} defaultSize={30} minSize={20} className={classNames(updatingForm ? 'opacity-60' : '', 'w-full h-full overflow-y-auto px-2 py-4 transition-opacity')}>
          {model ? (
            <>
              <Survey model={model} />
              <Button className='w-full sticky bottom-0 flex justify-center items-center my-2' onClick={validateForm}>Validate</Button>
            </>
          ) : null}
        </Panel>
      </PanelGroup>
    </div>
  )
}
