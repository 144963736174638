import React from "react";
import { DefaultParams, Path, Route, RouteProps } from "wouter";
import { useSelector } from "react-redux";
import { AppState } from 'admin/src/store';
import { UserType } from "../enums/userType";
import { isUserTypeRestricted } from "../utils/userType";

export default function RouteRequiredUserType<
  T extends DefaultParams | undefined = undefined,
  RoutePath extends Path = Path
>({ requiredUserType, ...props }: RouteProps<T, RoutePath> & { requiredUserType: number }) {
  const userType = useSelector((state: AppState) => state.user?.user?.userType);

  if (requiredUserType === UserType.NoneOrNotSpecified) {
    throw new Error('You cannot require a user type of NoneOrNotSpecified');
  }

  if (isUserTypeRestricted(requiredUserType, userType)) return null;

  return (<Route {...props} />);
}
