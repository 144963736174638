import React, {useEffect, useMemo, useState} from 'react';
import InventoryNavBar from './InventoryNavBar';
import {fetchActiveDeploymentsForCustomer, fetchDeploymentEquipmentSummary, getCountyConfigs} from 'admin/src/fetchers';
import { selectSelectedCounty } from 'admin/src/selectors/inventory/ui';
import { Deployment, DeploymentDashboardEquipmentSummary } from 'admin/src/types/Inventory';
import DeploymentEquipmentSummaryTable from './DeploymentEquipmentSummaryTable';
import DeploymentEquipmentSummaryChart from './DeploymentEquipmentSummaryChart';
import DeploymentChainOfCustodyEquipmentSummaryChart from './DeploymentChainOfCustodyEquipmentSummaryChart';
import {getAccount, selectIsStateUser} from 'admin/src/selectors';
import CustomerDropdown from "./CustomerDropdown";
import {Flexor, Spinner} from 'shared/src/components';
import {MapPinIcon, TruckIcon} from '@heroicons/react/24/outline';
import {Panel, PanelGroup, PanelResizeHandle} from 'react-resizable-panels';
import {Combobox} from "shared/src/components/ui/Combobox";
import {setSelectedCounty} from "admin/src/actions";
import {useAppDispatch, useAppSelector} from "admin/src/hooks";
import {CustomerInfo} from "shared/src/types/CustomerInfo";

export default function DeploymentsDashboard() {
  const dispatch = useAppDispatch();
  const [deployments, setDeployments] = useState<Deployment[]>([]);
  const [deploymentsLoading, setDeploymentsLoading] = useState<boolean>(false);
  const [hasCoCPackages, setHasCoCPackages] = useState<boolean>(false);
  const [hasNonCoCPackages, setHasNonCoCPackages] = useState<boolean>(false);
  const [deploymentSummaries, setDeploymentSummaries] = useState<DeploymentDashboardEquipmentSummary[]>([]);
  const [deploymentSummariesLoading, setDeploymentSummariesLoading] = useState<boolean>(false);
  const [selectedDeployment, setSelectedDeployment] = useState<string>('');
  const selectedCounty = useAppSelector(selectSelectedCounty);
  const isStateUser = useAppSelector(selectIsStateUser);
  const customer = useAppSelector(getAccount) as CustomerInfo;


  const deploymentOptions = useMemo(() => {
    if (!deployments.length) return [];

    return deployments.map((deployment) => ({
      label: deployment.deploymentName,
      value: deployment.id
    }));
  }, [deployments]);

  useEffect(() => {
    if (selectedCounty) {
      setDeploymentsLoading(true);
      fetchActiveDeploymentsForCustomer(selectedCounty)
        .then(activeDeployments => {
          setDeployments(activeDeployments.data);

          if (activeDeployments.data.length > 0) {
            setSelectedDeployment(activeDeployments.data[0].id);
          }
        })
        .finally(() => setDeploymentsLoading(false));
    } else if (!isStateUser) { // If they aren't a state user, we need to set the "selectedCounty" to the current user's
      dispatch(setSelectedCounty(customer.id));
    }
  }, [selectedCounty]);

  useEffect(() => {
    if (selectedDeployment) {
      setDeploymentSummariesLoading(true);
      fetchDeploymentEquipmentSummary(selectedCounty, selectedDeployment)
        .then(selectedDeploymentSummaries => {
          setDeploymentSummaries(selectedDeploymentSummaries);
          setDeploymentSummariesLoading(false);
          setHasCoCPackages(!!selectedDeploymentSummaries.find(summary => summary.requiresChainOfCustody));
          setHasNonCoCPackages(!!selectedDeploymentSummaries.find(summary => !summary.requiresChainOfCustody));
        });
    }
  }, [selectedDeployment]);

  return (
    <Flexor className="h-full flex flex-col">
      <InventoryNavBar />
      <div className="flex flex-col grow w-full">
        <Flexor className="relative z-50 w-full border-y divide-gray-400/80 bg-white border-gray-300">
          <div className="flex px-2 space-x-2">
            {isStateUser && (
              <div className="flex items-center">
                <MapPinIcon className="h-5" />
                <CustomerDropdown />
              </div>
            )}
            <div className='flex items-center pl-3 border-l border-gray-300'>
              <TruckIcon className='h-5' />
              <Combobox
                onValueChange={setSelectedDeployment}
                selectedValue={selectedDeployment}
                placeholder={deploymentsLoading ? 'Loading...' : 'No active deployments found'}
                options={deploymentOptions}
              />
            </div>
          </div>
        </Flexor>
        <PanelGroup autoSaveId='deployment-dashboard' className='h-full' direction={'horizontal'}>
          <Panel defaultSize={40}>
            <div className="relative border-y border-gray-300 h-full shrink-0 overflow-y-auto divide-y divide-gray-300 grid auto-rows-auto">
              {deploymentSummariesLoading && (
                <div className="h-full w-full flex flex-col justify-center items-center">
                  <Flexor className='space-x-2'>
                    <Spinner show />
                    <div>Building charts...</div>
                  </Flexor>
                </div>
              )}
              {!deploymentSummariesLoading && hasNonCoCPackages && (
                <div className="h-full py-4"><DeploymentEquipmentSummaryChart summaries={deploymentSummaries}/></div>
                )}
              {!deploymentSummariesLoading && hasCoCPackages && (
                <div className="h-full pr-5 py-2"><DeploymentChainOfCustodyEquipmentSummaryChart summaries={deploymentSummaries}/></div>
              )}
            </div>
          </Panel>
          <PanelResizeHandle />
          <Panel>
            <DeploymentEquipmentSummaryTable className="grow" loading={deploymentSummariesLoading} summaries={deploymentSummaries} />
          </Panel>
        </PanelGroup>
      </div>
    </Flexor>
  );
}
