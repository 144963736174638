import {createSelector} from "@reduxjs/toolkit";
import {AppState} from 'admin/src/store';
import {
  PollworkerWorkAssignmentConfirmationsForElection,
  PollworkerWorkHistory,
  UserInfo,
  Schedule
} from 'admin/src/types';
import {WorkConfirmationStatus} from "../../components/Pollworker/WorkConfirmationStatusEnum";
import lazyDeepCopy from "../../components/Pollworker/utils/lazyDeepCopy";

export * from './tracker';

export const selectedPollworkerModuleInfo = (state: AppState) => state.pollworker.moduleInfo;

export const getShowPollworkerDetailsId = (state: AppState) => state.pollworker.grid.showPollworkerDetailsId;
export const getReportsTo = (state: AppState) => state.pollworker.grid.reportsTo;
export const getCustomFields = (state: AppState) => state.pollworker.grid.customFields;
const getPollworkerWorkAssignmentConfirmationForElection = (state: AppState) => state.pollworker.grid.pollworkerWorkAssignmentConfirmationsForElection;
const getPollworkerCustomFieldsData = (state: AppState) => state.pollworker.grid.pollworkerCustomFieldsData;
export const getPollworkers = (state: AppState) => state.pollworker.grid.pollworkers;
export const getRoleTypes = (state: AppState) => state.pollworker.grid.roleTypes;
export const getModuleInfo = (state: AppState) => state.pollworker.moduleInfo;

export const getReportsToById = createSelector([getReportsTo], (reportsTo) => {
  return reportsTo.reduce((acc: object, rt: UserInfo) => {
    return {
      ...acc,
      [rt.id]: {...rt, _fullName: `${rt.firstName} ${rt.lastName}`},
    }
  }, {});
});

export const getCustomFieldsByPromptText = createSelector([getCustomFields], (customFields) => {
  return customFields.reduce((acc: any, cf: any) => {
    return {
      ...acc,
      [cf.promptText]: cf,
    }
  }, {});
});

export const getReportsToByIdSelector = createSelector([getReportsToById], reportsTo => reportsTo);

export const getCustomFieldsByPromptTextSelector = createSelector([getCustomFieldsByPromptText], customFieldsByPromptText => customFieldsByPromptText);

export const getCustomFieldsByPromptTextKeysSelector = createSelector([getCustomFieldsByPromptTextSelector], customFieldsByPromptText => Object.keys(customFieldsByPromptText));

export const getPollworkerCustomFieldsDataByPollworkerIdSelector = createSelector([getCustomFieldsByPromptTextKeysSelector, getPollworkerCustomFieldsData], (customFieldsPromptTextKeys, pollworkerCustomFieldsData) => {
  return pollworkerCustomFieldsData.reduce((acc: any, pwCf: any) => {
    if (pwCf.ObjectType !== 'Pollworker') return acc;

    acc[pwCf.id] = customFieldsPromptTextKeys.reduce((acc, key) => {
      return {...acc, [key]: pwCf[key]};
    }, {});

    return acc;
  }, {});
});

export const getPollworkerWorkAssignmentConfirmationsForElectionByPollworkerWorkHistoryIdSelector = createSelector([getPollworkerWorkAssignmentConfirmationForElection], (pollworkerWorkAssignmentConfirmationsForElection) => {
  return pollworkerWorkAssignmentConfirmationsForElection.reduce((acc, confirmation: PollworkerWorkAssignmentConfirmationsForElection) => {
    return {
      ...acc,
      [confirmation.pollworkerWorkHistoryId]: confirmation,
    }
  }, {});
});

export const getPollworkerSelector = createSelector([
  getPollworkerWorkAssignmentConfirmationsForElectionByPollworkerWorkHistoryIdSelector,
  getReportsToByIdSelector,
  getCustomFieldsByPromptTextKeysSelector,
  getCustomFieldsByPromptTextSelector,
  getPollworkerCustomFieldsDataByPollworkerIdSelector,
  getPollworkers,
], (pwWorkAssignmentConfirmationForElectionByPollworkerId: {[key: string]: PollworkerWorkAssignmentConfirmationsForElection}, reportsToById, customFieldsPromptTextKeys, customFieldsByPromptText, pollworkerCustomFieldsDataByPollworkerId, pw) => {
  const pollworkers = lazyDeepCopy(pw);
  return pollworkers.workerList.map((pollworker: PollworkerWorkHistory) => {
    // Start: PollworkerWorkHistoryCollection.cs
    // This mimics some super fun logic from PollworkerWorkHistoryCollection.cs
    const electionDayLocation = pw.locationList.find((location: Schedule) => pollworker.keyEVUserId === location.keyEVUserId);
    const electionDayPrecinct = pw.precinctList.find((pre: Schedule) => pollworker.keyEVUserId === pre.keyEVUserId);

    pollworker.electionDayRole = (electionDayLocation || electionDayPrecinct)?.roleInfo;
    pollworker.electionDayLocation = electionDayLocation?.locationInfo;
    pollworker.electionDayPrecinct = electionDayPrecinct?.precinctInfo;
    // End: PollworkerWorkHistoryCollection.cs

    pollworker._customFields = pollworkerCustomFieldsDataByPollworkerId[pollworker.keyPollworkerId];
    if (!pollworker._customFields) {
      pollworker._customFields = customFieldsPromptTextKeys.reduce((acc, key) => {
        // set default values for boolean, all rows must have the same time for
        // AG Grid to infer the type as a boolean
        // 3 = boolean
        return {
          ...acc,
          [key]: customFieldsByPromptText[key].dataTypeId === 3 ? false : null,
        }
      }, {});
    }

    pollworker._reportsTo = reportsToById[pollworker.reportsToId] || {};

    const workConfirmationForPollworker = pwWorkAssignmentConfirmationForElectionByPollworkerId[pollworker.id];

    let workConfirmationStatus: any = workConfirmationForPollworker?.status;
    if (!workConfirmationStatus || workConfirmationStatus === WorkConfirmationStatus.NoResponse.id) {
      workConfirmationStatus = undefined;
    } else {
      workConfirmationStatus = workConfirmationStatus === WorkConfirmationStatus.Accepted.id;
    }

    pollworker._workAssignmentConfirmation = {
      ...workConfirmationForPollworker,
      value: workConfirmationForPollworker?.status,
      status: workConfirmationStatus,
    };

    pollworker.pollworkerInfo.registeredPrecinctInfo ||= {};

    return pollworker;
  });
});

export const getShowPollworkerDetails = createSelector([getPollworkers, getShowPollworkerDetailsId], (pollworkers, showPollworkerDetailsId) => {
  return pollworkers.workerList.find((pw: PollworkerWorkHistory) => (pw.id === showPollworkerDetailsId) || (pw.keyEVUserId === showPollworkerDetailsId));
});
