import { Bars3BottomLeftIcon, CalculatorIcon, CalendarIcon, CheckIcon } from "@heroicons/react/24/outline";
import * as React from "react";

export default function DataGridDataTypeIcon({ dataType, className }: { dataType: string, className?: string }) {
  return (
    <>
      {
        dataType === 'number' ? (
          <CalculatorIcon className={className} />
        ) : null
      }
      {
        dataType === 'boolean' ? (
          <CheckIcon className={className} />
        ) : null
      }
      {
        dataType === 'date' ? (
          <CalendarIcon className={className} />
        ) : null
      }
      {
        dataType === 'text' ? (
          <Bars3BottomLeftIcon className={className} />
        ) : null
      }
    </>
  );
}
