import React, {Suspense, useEffect, useState} from 'react';
import { SectionSubSubHeading, Text } from "shared/src/components/SectionHeading";
import {ConsumableItem} from "../../types/ConsumableItem";
import {getConsumable, saveConsumable} from "../../fetchers";
import { Flexor } from "shared/src/components"
import { Button, Switch, Input, Label, Textarea, GridCol } from "shared/src/components/ui";
import {classNames} from "shared/src/utils/classNames";
import StoreItemImage, {StoreItemImageFallback} from "../store/StoreItemImage";
import {useSelector} from "react-redux";
import {getConsumableItem} from "../../selectors";
import EasyVoteButton from "../../components/admin/EasyVoteButton";
import TippyContent from "shared/src/components/TippyContent";
import Tippy from "@tippyjs/react";
import Loading from "shared/src/components/Loading";
import { toast } from "react-toastify";

type ItemDetailsProps = {
  enablePrint?: boolean,
  showItemName?: boolean,
  params: { itemId: string,
    enablePostMessage?: boolean,
    onClose?: () => void
  },
};

const DESCRIPTION_MAX_LEN = 500;

export default function ItemDetails({enablePrint = true, showItemName = false, params: {itemId, enablePostMessage = true, onClose}}: ItemDetailsProps): JSX.Element | null {
  const targetItem = useSelector(getConsumableItem(itemId));
  const [item, setItem] = useState<ConsumableItem | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setItem(targetItem);
  }, [targetItem]);

  useEffect(() => {
    getConsumable(itemId)
      .then(({data}) => setItem(JSON.parse(atob(data))))
      .finally(() => setLoading(false));
  }, [itemId]);

  function save() {
    if (!item) return;
    saveConsumable(item).then(close);
  }

  function close() {
    if (enablePostMessage) window.chrome.webview.postMessage('Dialog:Self:Close');
    if (onClose) onClose();
  }

  if (loading) return (<Loading loadingMessage={`Finding your item...`} />);

  if (!item) return null;

  return (
    <div className="mx-auto space-y-3 relative">
      {showItemName ? (
        <SectionSubSubHeading>{item.equipTypeName}</SectionSubSubHeading>
      ) : null }
      <Suspense fallback={<StoreItemImageFallback />}>
        <Flexor>
          <StoreItemImage item={item} className='w-20 object-fill object-center group-hover:opacity-75' />
          {enablePostMessage ? <EasyVoteButton text="Edit Image" className="" eventToSend={`Dialog:EditEquipmentType:Open:${item.id}`} /> : null}
        </Flexor>
      </Suspense>
      <Flexor className='space-x-4 h-20'>
        <div className='w-full space-y-2'>
          <Label htmlFor="countOnHand">
            Count on Hand
          </Label>
          <Input
            value={item?.countOnHand}
            type="number"
            name="countOnHand"
            id="countOnHand"
            onChange={({target: {value}}) => setItem({...item, countOnHand: parseInt(value)})}
            placeholder="1234"
          />
        </div>

        <div className="w-full space-y-2">
          <Label htmlFor="reorderThreshold">
            Reorder Threshold
          </Label>
          <Input
            value={item?.countOnHandReorderThreshold}
            type="number"
            onChange={({target: {value}}) => setItem({...item, countOnHandReorderThreshold: parseInt(value)})}
            name="reorderThreshold"
            id="reorderThreshold"
            placeholder="123"
          />
        </div>

        <div className='space-y-2 shrink'>
          <Label>Active</Label>
          <Tippy content={(<TippyContent>Turning this off will remove it from the store.</TippyContent>)}>
            <Switch onCheckedChange={(checked) => setItem({...item, active: checked})} checked={item.active} />
          </Tippy>
        </div>
      </Flexor>

      <div>
        <SectionSubSubHeading>Details</SectionSubSubHeading>
        <hr />
      </div>
      <div>
        <Label htmlFor="itemDescription">
          <Flexor>
            <span>Description</span>
            <Text size={2} variant={item.description?.length === DESCRIPTION_MAX_LEN ? 'error' : undefined}>{item.description?.length || 0}/500</Text>
          </Flexor>
        </Label>
        <Input
          id="itemDescription"
          name="itemDescription"
          onChange={({target: {value}}) => setItem({...item, description: value})}
          maxLength={DESCRIPTION_MAX_LEN}
          type="text"
          placeholder="Add a description for display in the EasyOrder store..."
          defaultValue={item.description}
        />
      </div>
      <div>
        <SectionSubSubHeading>Low Threshold Alerting</SectionSubSubHeading>
        <hr />
      </div>
      <div className='space-y-2'>
        <Label htmlFor="email_addresses">
          Email addresses to send alerts to - please separate by a semicolon (;)
        </Label>
        <Input
          defaultValue={item?.threshholdAlertEmailList}
          onBlur={({target: {value}}) => setItem({...item, threshholdAlertEmailList: value})}
          type="text"
          name="email_addresses"
          id="email_addresses"
          placeholder="user@example.com;user2@demo.com"
        />
        <Label htmlFor="email_message">
          Alert email body
        </Label>
        <div>
          <Textarea
            id="email_message"
            name="email_message"
            onChange={({target: {value}}) => setItem({...item, threshholdAlertEmailBody: value})}
            rows={5}
            defaultValue={item.threshholdAlertEmailBody}
          />
        </div>
        <Text size={2}>Write the email message you'd like to send when the count on hand falls below the reorder threshold.</Text>
      </div>
      {
        enablePostMessage && (
          <>
            <br />
            <br />
            <br />
          </>
        )
      }
      <Flexor className="px-6 fixed bottom-0 w-full left-0 bg-gray-50 p-3 shadow-md">
        <Button variant='tertiary' onClick={close}>Close</Button>
        <Button onClick={save}>Save</Button>
      </Flexor>
    </div>
  )
}
