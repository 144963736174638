import { zodResolver } from "@hookform/resolvers/zod";
import Tippy from "@tippyjs/react";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import { useWindowSize } from "react-use";
import {
  ArrowRightIcon,
  BuildingOffice2Icon, CheckCircleIcon,
  HandThumbUpIcon,
  HeartIcon, InformationCircleIcon,
  ExclamationCircleIcon
} from "@heroicons/react/24/outline";
import { Flexor, TippyContent } from "shared/src/components";
import {
  Heading,
  Text,
  SectionSubHeading,
  SectionHeading, SectionSubSubHeading
} from "shared/src/components/SectionHeading";
import {
  RadioGroup,
  Select,
  Textarea,
  Input,
  RadioGroupItem,
  Label,
  Button,
  Checkbox,
  Calendar,
  DatePicker,
  Switch,
  Grid,
  GridCol,
  Dialog,
  Form, Alert, AlertDescription,
} from "shared/src/components/ui";
import { z } from "zod"
import {Nomenclature} from "../../components/Nomenclature";

const profileFormSchema = z.object({
  username: z
  .string()
  .min(2, {
    message: "Username must be at least 2 characters.",
  })
  .max(30, {
    message: "Username must not be longer than 30 characters.",
  }),
  email: z.string({
    required_error: "Please select an email to display.",
  }).email(),
  bio: z.string().max(160).min(100),
});

export default function Playground() {
  const [selected, setSelected] = useState([]);
  const [selectedSingle, setSelectedSingle] = useState([]);
  const [selectedMultiple, setSelectedMultiple] = useState([]);
  const [month, setMonth] = useState(new Date());
  const {width, height} = useWindowSize();

  const form = useForm({
    resolver: zodResolver(profileFormSchema),
    defaultValues: {
      username: 'cc',
      bio: 'my bio'
    },
    mode: "onChange",
  });

  return (
    <>
      <ToastContainer />
      <div className='sticky z-50 w-full top-14 bg-white text-sm border-b'>
        <Flexor className='space-x-4 w-full'>
          <Flexor>
            <Button variant='tertiary'>
              <a target='_blank' href='https://www.figma.com/design/iwuZ5lOqfOKrHfxe7GZ7hc/EasyVote_DesignSystem_1.1?node-id=3-5&m=dev' rel="noreferrer">Figma</a>
            </Button>
            <Button variant='tertiary'>
              <a target='_blank' href='https://ui.shadcn.com/docs/components' rel="noreferrer">shadcn ui</a>
            </Button>
            <Button variant='tertiary'>
              <a target='_blank' href='https://www.radix-ui.com/primitives/docs/overview/introduction' rel="noreferrer">Radix UI</a>
            </Button>
            <Button variant='tertiary'>
              <Tippy content={<TippyContent>FontAwesome is not available yet, use Heroicons</TippyContent>}>
                <a target='_blank' href='https://heroicons.com' rel="noreferrer">Icons</a>
              </Tippy>
            </Button>
          </Flexor>
          <Flexor className='space-x-4 font-mono pr-4'>
            <div className='lg:block hidden'>Large</div>
            <div className='lg:hidden md:block hidden'>Medium</div>
            <div className='lg:hidden md:hidden block'>Small</div>
            <Flexor justify='end' className='space-x-2'>
              <div>X: {width}</div>
              <div>Y: {height}</div>
            </Flexor>
          </Flexor>
        </Flexor>
      </div>
      <div className='divide-y space-y-10 px-4 mx-auto w-2/3'>
        <div className='space-y-4 '>
          <Flexor className='mb-5'>
            <code className='block'>Tooltips [soon to be replaced]</code>
            <Button variant='tertiary' asChild>
              <a href='https://github.com/atomiks/tippyjs-react' target='_blank' rel="noreferrer">Docs</a>
            </Button>
          </Flexor>
          <div className='space-y-4'>
            <Tippy content={<TippyContent>I am a tooltip</TippyContent>}>
              <span>A tooltip</span>
            </Tippy>
            <br />
            <Tippy content={<TippyContent>I am a tooltip</TippyContent>}>
              <Button>A Button with a Tooltip</Button>
            </Tippy>
          </div>
        </div>
        <div className='space-y-4 '>
          <Flexor className='mb-5'>
            <code className='block'>Alert</code>
            <Button variant='tertiary' asChild>
              <a href='https://ui.shadcn.com/docs/components/alert' target='_blank'
                 rel="noreferrer">Alert</a>
            </Button>
          </Flexor>
          <div className='space-y-4'>
            <Alert variant='success' onClose={() => console.log('closed')}>
              <InformationCircleIcon/>
              <AlertDescription>Alert with close icon</AlertDescription>
            </Alert>

            <Alert variant='success' onClick={() => console.log('clicked')}>
              <CheckCircleIcon/>
              <Flexor className='space-x-7'>
                <AlertDescription>Alert with a custom trailing icon</AlertDescription>
                <ArrowRightIcon className='size-4 stroke-2'/>
              </Flexor>
            </Alert>

            <Alert variant='error'>
              <ExclamationCircleIcon/>
              <AlertDescription>My description</AlertDescription>
            </Alert>
            <Alert>Alert with no icon</Alert>
            <Alert variant='warning'>A warning</Alert>
          </div>
        </div>
        <div className='space-y-4 '>
          <Flexor className='mb-5'>
            <code className='block'>Toast</code>
            <Button variant='tertiary' asChild>
              <a href='https://fkhadra.github.io/react-toastify/introduction' target='_blank'
                 rel="noreferrer">react-toastify</a>
            </Button>
          </Flexor>
          <Flexor className='mb-5'>
            <Flexor items='start' className='flex-col space-y-4'>
              <Button variant='primary' onClick={() => {
                toast.info('This is a toast', { autoClose: 3000 });
              }}>Show Info Toast</Button>

              <Button variant='primary' onClick={() => {
                toast.success('This is a toast', { autoClose: 3000 });
              }}>Show Success Toast</Button>

              <Button variant='primary' onClick={() => {
                toast.error('This is a toast', { autoClose: 3000 });
              }}>Show Error Toast</Button>

              <Button variant='primary' onClick={() => {
                const promise = new Promise((resolve) => {
                  setTimeout(resolve, 4000);
                });

                toast.promise(promise, {
                  pending: 'Saving data',
                  success: 'Saved data',
                  error: 'Could not save data',
                }, {
                  autoClose: true
                });
              }}>Show Promise (success) Toast</Button>

              <Button variant='primary' onClick={() => {
                const promise = new Promise((_resolve, reject) => {
                  setTimeout(reject, 4000);
                })

                toast.promise(promise, {
                  pending: 'Saving data',
                  success: 'Saved data',
                  error: 'Could not save data',
                }, {
                  autoClose: true
                });
              }}>Show Promise (reject) Toast</Button>

              <Button variant='primary' onClick={() => {
                const toastId = toast.loading('Loading something...', { type: "success" });

                setTimeout(() => toast.update(toastId, {
                  isLoading: true,
                  render: 'Info more'
                }), 4000);
                setTimeout(() => toast.update(toastId, {
                  isLoading: true,
                  render: 'Even more info'
                }), 5000);
                setTimeout(() => toast.update(toastId, {
                  type: 'success',
                  isLoading: false,
                  render: 'Done!',
                  autoClose: 4000
                }), 6000);

              }}>Show Updatable Toast</Button>
            </Flexor>
          </Flexor>
        </div>
        <div className='space-y-4 mx-auto'>
          <Flexor className='mb-5'>
            <code className='block'>Flexor</code>
            <a href='https://tailwindcss.com/docs/flex' target='_blank' rel="noreferrer">
              Flex Docs
            </a>
          </Flexor>
          <Flexor className='bg-white p-2 shadow'>
            <div>Justify</div>
            <div>Between</div>
          </Flexor>
          <Flexor className='bg-white p-2 shadow' justify='start'>
            <div>Justify</div>
            <div>Start</div>
          </Flexor>
          <Flexor className='bg-white p-2 shadow' justify='end'>
            <div>Justify</div>
            <div>End</div>
          </Flexor>
          <Flexor className='bg-white p-2 shadow' justify='around'>
            <div>Justify</div>
            <div>Around</div>
          </Flexor>
          <Flexor className='bg-white h-[150px]'>
            <Flexor className='p-2 shadow'>
              <HandThumbUpIcon className='size-5'/>
              <div>Flex</div>
            </Flexor>
            <div className='bg-blue-50 grow'>
              <Flexor justify='center'>Grow - Centered div</Flexor>
            </div>
          </Flexor>
        </div>
        <div className='space-y-4'>
          <code className='mb-5 block'>Switch</code>
          <Flexor className='w-[200px] space-x-2' justify='start'>
            <Switch defaultChecked={true}/>
            <Label>Switch 1</Label>
          </Flexor>
          <Flexor className='w-[200px] space-x-2' justify='start'>
            <Switch defaultChecked={false} onChange={console.log}/>
            <Label>Switch 1</Label>
          </Flexor>
          <Flexor className='w-[200px] space-x-2' justify='start'>
            <Switch defaultChecked={true}/>
            <Label>Switch 1</Label>
          </Flexor>
        </div>
        <div className='space-y-4'>
          <code className='mb-5 block'>Grid</code>
          <Grid className='bg-white rounded gap-6' cols={12}>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={1} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={2} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={2} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={2} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={2} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={2} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={2} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={4} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={4} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={4} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
            <GridCol span={4}>
              <Grid subgrid cols={6} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'>
                <GridCol span={2} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
                <GridCol span={2} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
                <GridCol span={2} className='bg-pink-400/80 ring-2 ring-inset ring-pink-400 rounded h-20'></GridCol>
              </Grid>
            </GridCol>
          </Grid>
        </div>
        <div className='space-y-4'>
          <Flexor className='mt-5'>
            <code className="block">Form & Input</code>
            <Flexor>
              <Button variant='tertiary' asChild>
                <a href='https://ui.shadcn.com/docs/components/form' target='_blank'
                   rel="noreferrer">shadcn ui</a>
              </Button>
              <Button variant='tertiary' asChild>
                <a href='https://www.radix-ui.com/primitives/docs/components/form' target='_blank'
                   rel="noreferrer">Radix
                  UI</a>
              </Button>
              <Button variant='tertiary' asChild>
                <a href='https://react-hook-form.com/docs' target='_blank' rel="noreferrer">React Hook Form</a>
              </Button>
            </Flexor>
          </Flexor>
          <div className="w-[250px]">
            <Form.Form {...form}>
              <form className='space-y-3'>
                <div>
                  <Form.FormLabel required>Username</Form.FormLabel>
                  <Form.FormField
                    control={form.control}
                    name="username"
                    render={({ field }) => {
                      return (
                        <Form.FormItem>
                          <Form.FormControl>
                            <Input type="text" variant="new" minLength={2}
                                   placeholder="Username here..." {...field} />
                          </Form.FormControl>
                          <Form.FormMessage/>
                        </Form.FormItem>
                      )
                    }}
                  />
                </div>
                <div>
                  <Input
                    variant="new"
                    type="text"
                    name="fullStateName"
                    id="fullStateName"
                    placeholder=""
                  />
                  <Form.FormLabel required>Bio</Form.FormLabel>
                  <Form.FormField
                    control={form.control}
                    name="bio"
                    render={({ field }) => {
                      return (
                        <Form.FormItem>
                          <Form.FormControl>
                            <Textarea variant="new" placeholder="Type your bio" {...field} />
                          </Form.FormControl>
                          <Form.FormMessage/>
                        </Form.FormItem>
                      )
                    }}
                  />
                </div>
                <div>
                  <Form.FormLabel required>State</Form.FormLabel>
                  <Form.FormField
                    name='state'
                    render={({ field }) => {
                      return (
                        <Form.FormItem>
                          <Form.FormControl>
                            <Select variant='new'>
                              {
                                [{
                                  label: 'Hey',
                                  value: 'You'
                                }].map(({
                                  label,
                                  value
                                }) => <option value={value}>{label}</option>)
                              }
                            </Select>
                          </Form.FormControl>
                        </Form.FormItem>
                      )
                    }}
                  >
                  </Form.FormField>
                </div>
                <Button className='mt-4' variant='primary' type='submit'>Submit</Button>
                <Input type='file' minLength={5} variant='new' placeholder='Some placeholder'/>
                <Input disabled type='text' minLength={5} variant='new' placeholder='Some placeholder'/>
              </form>
            </Form.Form>
          </div>
        </div>
        <div className='space-y-4 w-full ml-auto'>
          <Flexor className='mt-5'>
            <code>Pickers</code>
            <Flexor>
              <Button variant='tertiary' asChild>
                <a href='https://ui.shadcn.com/docs/components/date-picker' target='_blank'
                   rel="noreferrer">shadcn ui</a>
              </Button>
              <Button variant='tertiary' asChild>
                <a href='https://react-day-picker.js.org' target='_blank' rel="noreferrer">React Day
                  Picker</a>
              </Button>
            </Flexor>
          </Flexor>
          <div className='w-[150px]'>
            <DatePicker onSelect={console.log}/>
          </div>
        </div>
        <div className='space-y-4'>
          <Flexor className='mt-5'>
            <code>Calendar</code>
            <Flexor>
              <Button variant='tertiary' asChild>
                <a href='https://ui.shadcn.com/docs/components/calendar' target='_blank'
                   rel="noreferrer">shadcn ui</a>
              </Button>
              <Button variant='tertiary' asChild>
                <a href='https://react-day-picker.js.org' target='_blank' rel="noreferrer">React Day
                  Picker</a>
              </Button>
            </Flexor>
          </Flexor>
          <Flexor className='space-x-4'>
            <div>
              <code className="mb-5 block">Single</code>
              <Calendar mode="single" selected={selectedSingle} onSelect={setSelectedSingle}/>
            </div>
            <div>
              <Flexor className='mb-5'>
                <code className="block">Multiple</code>
                <button onClick={() => {
                  month.setMonth(month.getMonth() + 1);
                  setMonth(new Date(month.getFullYear(), month.getMonth(), month.getDay()));
                }}>Next Month
                </button>
              </Flexor>
              <Calendar onMonthChange={setMonth} month={month} mode="multiple"
                        selected={selectedMultiple} onSelect={setSelectedMultiple}/>
            </div>
            <div>
              <code className="mb-5 block">Range</code>
              <Calendar mode="range" selected={selected} onSelect={setSelected}/>
            </div>
          </Flexor>
        </div>
        <div className="space-y-4">
          <Flexor className='mt-5'>
            <code>Checkbox</code>
            <Flexor>
              <Button variant='tertiary' asChild>
                <a href='https://ui.shadcn.com/docs/components/checkbox' target='_blank'
                   rel="noreferrer">shadcn ui</a>
              </Button>
            </Flexor>
          </Flexor>
          <Flexor justify="start">
            <Checkbox value="item1" id="item1"/>
            <Label htmlFor="item1">Item 1</Label>
          </Flexor>
          <Flexor justify="start">
            <Checkbox value="item2" id="item2"/>
            <Label htmlFor="item2">Item 2</Label>
          </Flexor>
          <Flexor justify="start">
            <Checkbox disabled value='item3' id='item3'/>
            <Label htmlFor='item3'>Item 3</Label>
          </Flexor>
          <Flexor justify='start'>
            <Checkbox value='item4' id='item4'/>
            <Label htmlFor='item4'>Item 4</Label>
          </Flexor>
        </div>
        <div className='space-y-4'>
          <Flexor className='mt-5'>
            <code>Radio Group</code>
            <Flexor>
              <Button variant='tertiary' asChild>
                <a href='https://ui.shadcn.com/docs/components/radio-group' target='_blank'
                   rel="noreferrer">shadcn ui</a>
              </Button>
            </Flexor>
          </Flexor>
          <RadioGroup>
            <Flexor justify='start'>
              <RadioGroupItem value='item1' id='item1'/>
              <Label htmlFor='item1'>Item 1</Label>
            </Flexor>
            <Flexor justify='start'>
              <RadioGroupItem value='item2' id='item2'/>
              <Label htmlFor='item2'>Item 2</Label>
            </Flexor>
            <Flexor justify='start'>
              <RadioGroupItem disabled value='item3' id='item3'/>
              <Label htmlFor='item3'>Item 3</Label>
            </Flexor>
            <Flexor justify='start'>
              <RadioGroupItem value='item4' id='item4'/>
              <Label htmlFor='item4'>Item 4</Label>
            </Flexor>
          </RadioGroup>
        </div>
        <div className='space-y-4'>
          <Flexor className='mt-5'>
            <code>Buttons</code>
            <Flexor>
              <Button variant='tertiary' asChild>
                <a href='https://ui.shadcn.com/docs/components/button' target='_blank'
                   rel="noreferrer">shadcn ui</a>
              </Button>
            </Flexor>
          </Flexor>
          <Flexor>
            <Button variant='primary' size='sm'>
              <span>Action Text</span>
            </Button>
            <Button variant='primary'>
              <span>Action Text</span>
            </Button>
            <Button variant='primary' size='lg'>
              <span>Action Text</span>
            </Button>
          </Flexor>
          <Flexor>
            <Button variant='primary' disabled size='sm'>
              <span>Disabled</span>
            </Button>
            <Button variant='primary' disabled>
              <span>Disabled</span>
            </Button>
            <Button variant='primary' disabled size='lg'>
              <span>Disabled</span>
            </Button>
          </Flexor>
          <Flexor>
            <Button variant='secondary' size='sm'>
              <span>Action Text</span>
            </Button>
            <Button variant='secondary'>
              <span>Action Text</span>
            </Button>
            <Button variant='secondary' size='lg'>
              <span>Action Text</span>
            </Button>
          </Flexor>
          <Flexor>
            <Button disabled variant='secondary' size='sm'>
              <span>Disabled</span>
            </Button>
            <Button disabled variant='secondary'>
              <span>Disabled</span>
            </Button>
            <Button disabled variant='secondary' size='lg'>
              <span>Disabled</span>
            </Button>
          </Flexor>
          <Flexor>
            <Button variant='tertiary' size='sm'>
              <HeartIcon className='h-smd'/>
              <span>Action Text</span>
            </Button>
            <Button variant='tertiary'>
              <HeartIcon className='h-md'/>
              <span>Action Text</span>
            </Button>
            <Button variant='tertiary' size='lg'>
              <HeartIcon className='h-lg'/>
              <span>Action Text</span>
            </Button>
          </Flexor>
          <Flexor>
            <Button disabled variant='quaternary' size='sm'>
              <HeartIcon className='h-smd'/>
              <span>Action Text</span>
            </Button>
            <Button disabled variant='quaternary'>
              <HeartIcon className='h-md'/>
              <span>Action Text</span>
            </Button>
            <Button disabled variant='quaternary' size='lg'>
              <HeartIcon className='h-lg'/>
              <span>Action Text</span>
            </Button>
          </Flexor>
          <Flexor>
            <Button variant='quaternary' size='sm'>
              <span>Action Text</span>
            </Button>
            <Button variant='quaternary'>
              <span>Action Text</span>
            </Button>
            <Button variant='quaternary' size='lg'>
              <span>Action Text</span>
            </Button>
          </Flexor>
          <Flexor>
            <Button variant='quaternary' size='sm'>
              <span>Action Text</span>
              <ArrowRightIcon className='h-smd'/>
            </Button>
            <Button variant='quaternary'>
              <span>Action Text</span>
              <ArrowRightIcon className='h-md'/>
            </Button>
            <Button variant='quaternary' className='px-0' size='lg'>
              <span>No padding</span>
              <ArrowRightIcon className='h-lg'/>
            </Button>
          </Flexor>
        </div>
        <div>
          <code className='mb-5 block'>Heading</code>
          <Heading size={1}>Heading 1</Heading>
          <Heading size={2}>Heading 2</Heading>
          <Heading>Heading 3</Heading>
          <Heading size={4}>Heading 4</Heading>
          <Heading size={5}>Heading 5</Heading>
        </div>
        <div>
          <code className='mb-5 block'>Section Heading [former headings]</code>
          <SectionHeading>Heading 1</SectionHeading>
          <SectionSubHeading>Sub Heading 2</SectionSubHeading>
          <SectionSubSubHeading>Sub Heading 3</SectionSubSubHeading>
        </div>
        <div>
          <code className='mb-5 block'>Text</code>
          <Text>My text here</Text>
          <Text variant='success'>My text here</Text>
          <Text variant='warning'>My text here</Text>
          <Text variant='error'>My text here</Text>
          <br/>
          <Text size={2}>My text here</Text>
          <Text size={2} variant='success'>My text here</Text>
          <Text size={2} variant='warning'>My text here</Text>
          <Text size={2} variant='error'>My text here</Text>
          <br/>
          <Text size={3}>My text here</Text>
          <Text size={3} variant='success'>My text here</Text>
          <Text size={3} variant='warning'>My text here</Text>
          <Text size={3} variant='error'>My text here</Text>
        </div>

        <div>
          <Flexor className='mt-5'>
            <code>Dialog</code>
            <Flexor>
              <Button variant='tertiary' asChild>
                <a href='https://ui.shadcn.com/docs/components/dialog' target='_blank'
                   rel="noreferrer">shadcn ui</a>
              </Button>
            </Flexor>
          </Flexor>
          <div className='grid grid-cols-4 gap-2'>
            {
              [
                ['default', 'sm', 'h-[300px]'],
                ['default', 'md', 'h-[300px]'],
                ['default', 'lg', 'h-[300px]'],
                ['default', 'full', 'h-full'],
                ['success', 'sm', 'h-[300px]'],
                ['success', 'md', 'h-[300px]'],
                ['success', 'lg', 'h-[300px]'],
                ['success', 'full', 'h-full'],
                ['warning', 'sm', 'h-[300px]'],
                ['warning', 'md', 'h-[300px]'],
                ['warning', 'lg', 'h-[300px]'],
                ['warning', 'full', 'h-full'],
                ['error', 'sm', 'h-[300px]'],
                ['error', 'md', 'h-[300px]'],
                ['error', 'lg', 'h-[300px]'],
                ['error', 'full', 'h-full'],
              ].map(([variant, size, contentHeight]) => {
                return (
                  <Dialog.Dialog key={`${variant} + ${size}`} variant={variant} size={size}>
                    <Dialog.DialogTrigger asChild>
                      <Button className='w-full'>{variant} + {size}</Button>
                    </Dialog.DialogTrigger>
                    <Dialog.DialogContent>
                      <Dialog.DialogHeader>
                        <Dialog.DialogTitle>
                          <BuildingOffice2Icon/>
                          <Heading>Edit Profile</Heading>
                        </Dialog.DialogTitle>
                        <Dialog.DialogDescription>
                          Make changes to your profile here. Click save when you're done.
                        </Dialog.DialogDescription>
                      </Dialog.DialogHeader>
                      <div className={contentHeight}>
                        I am some custom content!
                      </div>
                      <Dialog.DialogFooter>
                        <Button variant='primary' type="submit">Save changes</Button>
                      </Dialog.DialogFooter>
                    </Dialog.DialogContent>
                  </Dialog.Dialog>
                )
              })
            }
          </div>
        </div>
        <div>
          <Flexor className='mt-5'>
            <code>Nomenclature</code>
          </Flexor>
          <div>
            Instead of 'Voting Location' we use the word '<Nomenclature nomenclatureId="VotingLocationDisplayName"></Nomenclature>'
          </div>
          <div> Instead of 'Precinct' we use the word '<Nomenclature nomenclatureId="PrecinctDisplayName"/>'</div>
          <div> Instead of 'Employee' we use the word '<Nomenclature nomenclatureId="EmployeeDisplayName"/>'</div>
          <div> Instead of 'Task' we use the word '<Nomenclature nomenclatureId="TaskDisplayName"/>'</div>
          <div> Instead of 'Project' we use the word '<Nomenclature nomenclatureId="ProjectDisplayName"/>'</div>
          <div> Instead of 'Inventory' we use the word '<Nomenclature nomenclatureId="InventoryDisplayName"/>'</div>
          <div> Instead of 'Deployment Worker' we use the word '<Nomenclature nomenclatureId="DeploymentWorkerDisplayName"/>'</div>
          <div> Instead of 'PollWorker' we use the word '<Nomenclature nomenclatureId="PollWorkerDisplayName"/>'</div>
          <div> Instead of 'Voter' we use the word '<Nomenclature nomenclatureId="VoterDisplayName"/>'</div>
          <div> Instead of 'L & A' we use the word '<Nomenclature nomenclatureId="LnaDisplayName"/>'</div>
          <div> Instead of 'L & A Test' we use the word '<Nomenclature nomenclatureId="LnaTestDisplayName"/>'</div>
          <div> Instead of 'Ef Project Contact' we use the word '<Nomenclature nomenclatureId="EfProjectContactDisplayName"/>'</div>
          <div> Instead of 'Officer' we use the word '<Nomenclature nomenclatureId="OfficerDisplayName"/>'</div>
          <div> Instead of 'Filing Clerk' we use the word '<Nomenclature nomenclatureId="FilingClerkDisplayName"/>'</div>
          <div> Instead of 'Campaign Finance' we use the word '<Nomenclature nomenclatureId="CampaignFinanceDisplayName"/>'</div>
          <div> Instead of 'Absentee By Mail' we use the word '<Nomenclature nomenclatureId="AbsenteeByMailDisplayName"/>'</div>
          <div> Instead of 'Early Voting' we use the word '<Nomenclature nomenclatureId="EarlyVotingDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Training' we use the word '<Nomenclature nomenclatureId="PwTrainingDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Skills' we use the word '<Nomenclature nomenclatureId="PwSkillsDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Roles' we use the word '<Nomenclature nomenclatureId="PwRolesDisplayName"/>'</div>
          <div> Instead of 'Third Party' we use the word '<Nomenclature nomenclatureId="ThirdPartyDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Election Day' we use the word '<Nomenclature nomenclatureId="PollworkerElectionDayDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Standby' we use the word '<Nomenclature nomenclatureId="PollworkerStandbyDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Trainee' we use the word '<Nomenclature nomenclatureId="PollworkerTraineeDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Prospect' we use the word '<Nomenclature nomenclatureId="PollworkerProspectDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Applicant' we use the word '<Nomenclature nomenclatureId="PollworkerApplicantDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Employee Id' we use the word '<Nomenclature nomenclatureId="PollworkerEmployeeIdDisplayName"/>'</div>
          <div> Instead of 'Poll Worker Online System' we use the word '<Nomenclature nomenclatureId="PollworkerOnlineSystemDisplayName"/>'</div>
          <div> Instead of 'District Combo Number' we use the word '<Nomenclature nomenclatureId="DistrictComboNumberDisplayName"/>'</div>
          <div> Instead of 'Card Style' we use the word '<Nomenclature nomenclatureId="CardStyleDisplayName"/>'</div>
          <div> Instead of 'Equipment Group' we use the word '<Nomenclature nomenclatureId="EquipGroupDisplayName"/>'</div>
          <div> Instead of 'Precinct Code' we use the word '<Nomenclature nomenclatureId="PrecinctCodeDisplayName"/>'</div>
          <div> Instead of 'Precinct Manager' we use the word '<Nomenclature nomenclatureId="PrecinctManagerDisplayName"/>'</div>
          <div> Instead of 'Voter Reg Number' we use the word '<Nomenclature nomenclatureId="VoterRegNumberDisplayName"/>'</div>
          <div> Instead of 'Ballot Accepted' we use the word '<Nomenclature nomenclatureId="BallotAcceptedDisplayName"/>'</div>
          <div> Instead of 'Filer Type' we use the word '<Nomenclature nomenclatureId="FilerTypeDisplayName"/>'</div>
          <div> Instead of 'Candidate' we use the word '<Nomenclature nomenclatureId="CandidateDisplayName"/>'</div>
          <div> Instead of 'CF Voter Reg No' we use the word '<Nomenclature nomenclatureId="CFVoterRegNoDisplayName"/>'</div>
          <div> Instead of 'Committee' we use the word '<Nomenclature nomenclatureId="CommitteeDisplayName"/>'</div>
          <div> Instead of 'Chair Person' we use the word '<Nomenclature nomenclatureId="ChairPersonDisplayName"/>'</div>
          <div> Instead of 'Committee Expiration Date' we use the word '<Nomenclature nomenclatureId="CommitteeExpirationDateDisplayName"/>'</div>
          <div> Instead of 'Office Type' we use the word '<Nomenclature nomenclatureId="OfficeTypeDisplayName"/>'</div>
          <div> Instead of 'Filer Id' we use the word '<Nomenclature nomenclatureId="FilerIdDisplayName"/>'</div>
          <div> Instead of 'PIN' we use the word '<Nomenclature nomenclatureId="PINDisplayName"/>'</div>
          <div> Instead of 'First Election Year' we use the word '<Nomenclature nomenclatureId="FirstElectionYearDisplayName"/>'</div>
          <div> Instead of 'CF Date Approved' we use the word '<Nomenclature nomenclatureId="CFDateApprovedDisplayName"/>'</div>
        </div>
      </div>
    </>
  )
}
