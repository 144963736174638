import React from "react";
import { useDocumentsContext } from "./DocumentsContext";
import { getDocumentExtension } from "./Documents";
import { type DefaultExtensionType, defaultStyles, FileIcon } from "react-file-icon";
import { Flexor } from "shared/src/components";
import { UserAvatar } from "../Shell/ShellHeader";
import dayjs from "dayjs";
import { DocumentsActionMenu } from "./DocumentsActionMenu";
import { cn } from "shared/src/utils";
import { DocumentsLoading } from "./DocumentsLoading";

export function DocumentsList({className}: {className?: string}) {
  const { documents } = useDocumentsContext();

  return (
    <>
      <DocumentsLoading />
      <div className={cn('text-sm divide-y', className)}>
        {
          documents?.map((doc) => {
            const ext = getDocumentExtension(doc) as DefaultExtensionType;

            return (
              <div className='p-3 flex justify-between hover:bg-muted transition-colors' key={doc.id}>
                <Flexor className='space-x-2'>
                  <div className='size-6'>
                    <FileIcon extension={ext} {...(ext ? defaultStyles[ext] : {})} />
                  </div>
                  <div>{doc.documentName}</div>
                </Flexor>
                <Flexor className='space-x-2 flex'>
                  <div className='text-right @lg:block hidden space-y-1'>
                    <Flexor className='space-x-1' justify='end'>
                      <UserAvatar className='size-5 text-xs' firstName={doc.ownerInfo?.firstName} lastName={doc.ownerInfo?.lastName} />
                      <span>{doc.ownerInfo?.firstName} {doc.ownerInfo?.lastName}</span>
                    </Flexor>
                    <div>Added {dayjs(doc.createdAt).fromNow()}</div>
                  </div>
                  <DocumentsActionMenu document={doc} />
                </Flexor>
              </div>
            );
          })
        }
      </div>
    </>
  )
}
