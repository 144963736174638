import React, { useMemo } from "react";
import { Flexor } from "shared/src/components";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useShell } from "./ShellContext";
import AppPicker from "./AppPicker";
import { useAppSelector } from "admin/src/hooks";
import { cn } from "shared/src/utils";
import { Link } from "wouter";

function generateHslColor(str: string, brightness = 50) {
  return `hsl(${Math.round((str.length / 100) * 360)}, 75%, ${brightness}%)`;
}

export default function ShellHeader() {
  const { user, account } = useAppSelector(state => state.user);
  const { title, sidebar: { openMobile, setOpenMobile } } = useShell();

  return (
    <div className="min-h-12 bg-transparent flex justify-between px-2.5 items-center">
      <Flexor justify="start" className="space-x-1 sm:space-x-2 mx-0.5">
        <Bars3Icon className='size-5 sm:hidden' onClick={() => setOpenMobile(!openMobile)} />
        <div className='hidden sm:block'>
          <AppPicker />
        </div>
        <Link to='/'>
          <button type='button' className="whitespace-nowrap text-2xl font-[Heebo] font-[800] capitalize tracking-tighter bg-gradient-to-r from-enhancetitle to-enhancetitle/80 inline-block text-transparent bg-clip-text sm:pl-1 relative z-10">{title}</button>
        </Link>
      </Flexor>
      <Flexor className='text-sm space-x-4 text-gray-700' justify='start'>
        <Flexor className="hover:bg-accent transition-colors px-2 p-1 rounded text-sm space-x-2 text-gray-700">
          <UserAvatar firstName={user?.firstName} lastName={user?.lastName}  />
          <Flexor className="flex-col" items="start">
            <span className="whitespace-nowrap font-semibold">{user?.firstName} {user?.lastName}</span>
            <span className="whitespace-nowrap text-xs">{account?.customerName}</span>
          </Flexor>
        </Flexor>
      </Flexor>
    </div>
  );
}

function getUserFullNameAndInitials(firstName: string, lastName: string): [string, string] {
  const names = [firstName, lastName].filter(n => !!n);
  const fullName = names.join(' ');
  const initials = fullName.split(' ').map(n => n[0]).join('');

  return [fullName, initials];
}

export function UserAvatar({firstName, lastName, className}: {firstName?: string, lastName?: string, className?: string}) {
  const [fullName, initials] = useMemo(() => getUserFullNameAndInitials(firstName || '', lastName || ''), [firstName, lastName]);

  if (!fullName || !initials) return null;

  return (
    <div className={cn('text-center text-white rounded-full relative size-7', className)} style={{ backgroundColor: generateHslColor(fullName) }}>
      <span className='inline-block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 uppercase'>{initials}</span>
    </div>
  );
}
