import {ConsumableItem} from "../../types/ConsumableItem";
import React, {ReactNode, useRef} from "react";
import {useSuspendedImageData} from "../../hooks/useSuspendedImageData";
import {NoSymbolIcon, PhotoIcon, UserCircleIcon} from "@heroicons/react/24/outline";
import {useIntersection} from "react-use";
import {suspensify} from "suspensify";
import {readFile} from "../../utils/documents";

type StoreItemImageProps = {
  item: ConsumableItem | undefined;
  className?: string;
}

type BlobImageProps = {
  id?: string,
  className?: string,
  alt?: string
  imageNotFoundFallback?: ReactNode,
  entityType?: 'employees' | 'equipmenttypes' | 'expenses' | 'votinglocations',
  skipCache?: boolean
  customerId?: string
};

export function StoreItemImageFallback() {
  return (
    <div className="bg-gray-500 animate-pulse flex flex-col items-center justify-center">
      <PhotoIcon className="h-12 w-12 text-gray-800" />
    </div>
  );
}

const useImageFromFileReader = suspensify((attachedReceipt: File) => readFile(attachedReceipt));

export function ImageFromFileReader({file, classes, alt}: {file: File, classes?: string, alt: string}) {
  const imageString = useImageFromFileReader(file);

  if (!imageString) return null;

  return (
    <img className={classes} src={imageString} />
  )
}

export function BlobImage({id, className, alt, imageNotFoundFallback, entityType, customerId, skipCache = false}: BlobImageProps) {
  const image = useSuspendedImageData(id, undefined, customerId, skipCache, entityType);

  if (!image.src) {
    return (
      <>
        {
          imageNotFoundFallback
          ?
            <div>{imageNotFoundFallback}</div>
          :
            <NoSymbolIcon className={`scale-75 text-gray-400 ${className}`} />
        }
      </>
    );
  }

  return (
    <img alt={alt} src={image.src} className={className} />
  )
}

export default function StoreItemImage({item, className}: StoreItemImageProps) {
  const itemImage = useSuspendedImageData(`${item?.id}.jpg`, item?.updatedAt, undefined, false, 'equipmenttypes');

  if (!itemImage?.src) {
    return <NoSymbolIcon className={`scale-50 text-gray-400 ${className}`} />
  }

  return (
    <img
      alt={`Preview image of ${item?.equipTypeName}`}
      src={itemImage.src}
      className={className}
    />
  )
}

export function StoreItemImageLazy(props: StoreItemImageProps) {
  const intersectionRef = useRef<any>(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  });

  let image = null;

  if (intersection && intersection.isIntersecting) {
    image = <StoreItemImage {...props} />;
  }

  return (
    <div className={props.className} ref={intersectionRef}>
      {image}
    </div>
  );
}
