import React, {Fragment, ReactNode} from "react";
import {Transition} from "@headlessui/react";
import { Flexor } from "shared/src/components"
import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import {XMarkIcon} from "@heroicons/react/24/outline";

export default function DialogPanelModal({show, icon, title, children, onClose}: {show: boolean, onClose: Function, icon: ReactNode, title: string, children: ReactNode}) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <div>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed text-white bg-gray-500 bg-opacity-75 z-40 transition-opacity opacity-100 w-full h-full"  onClick={() => onClose()} />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="pointer-events-auto z-40 fixed h-full flex flex-col items-center justify-center">
            <div className="bg-white rounded-md p-3 w-[90%]">
              <Flexor>
                <SectionSubSubHeading>{title}</SectionSubSubHeading>
                <XMarkIcon className="h-6 w-6 text-gray-600" onClick={() => onClose()} />
              </Flexor>
              <hr className="my-2" />
              {children}
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  )
}
