import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useIsFeatureFlagEnabled } from 'admin/src/hooks';
import { Flexor, Spinner, Loading } from 'shared/src/components';
import { Feature } from 'admin/src/enums';
import { Link } from 'wouter';
import {
  CheckIcon
} from '@heroicons/react/16/solid';
import {
  ArrowPathIcon,
  BuildingLibraryIcon,
  CalendarDaysIcon, CalendarIcon, ChevronLeftIcon,
  ChevronUpDownIcon,
  MapPinIcon,
} from '@heroicons/react/24/outline';
import { PanelGroup, Panel, PanelResizeHandle } from 'react-resizable-panels';
import {Menu, Transition} from '@headlessui/react';
import { classNames, cn } from 'shared/src/utils';
import {
  setDisplayedLocationType,
  setPrecinctsForElectionAndDate,
  setSelectedPrecinct,
  setSelectedVotingLocation,
  setVotingLocationsForElectionAndDate,
  setTrackerWorkSchedules,
  updateTrackerWorkSchedule,
} from 'admin/src/actions';
import {
  selectedPollworkerModuleInfo,
  selectPollworkerTrackerLocationTypeDisplay,
  selectVotingLocationsForElectionAndDate,
  selectPrecinctsForElectionAndDate,
  selectSelectedPrecinct,
  selectSelectedVotingLocation,
  selectTrackerWorkSchedules,
  getShowPollworkerDetails,
  selectFeatureFlags,
} from 'admin/src/selectors';
import {
  PrecinctList,
  VotingLocationList
} from 'admin/src/components/Pollworker/tracker';
import ModuleInfoChangeManager from 'admin/src/components/Pollworker/utils/ModuleInfoChangeManager';
import {
  PollworkerModuleInfo,
  PollworkerTimeclockEntry,
  Precinct,
  VotingLocation,
  WorkSchedule
} from 'admin/src/types';
import {
  fetchVotingLocationsForElectionAndWorkDate,
  fetchPrecinctsForElectionAndWorkDate,
  fetchWorkSchedulesForLocationAndDate,
  clockOutSchedule,
  clockInSchedule
} from 'admin/src/fetchers';
import dayjs from 'dayjs';
import PollworkerScheduleList from 'admin/src/components/Pollworker/tracker/PollworkerScheduleList';
import TrackerExportButton from 'admin/src/components/Pollworker/tracker/TrackerExportButton';
import PollworkerDetails from 'admin/src/components/Pollworker/Panels/PollworkerDetailsPanel';
import { setShowPollworkerDetailsId } from 'admin/src/reducers/pollworker/grid';
import useHandlePollworkers from 'admin/src/hooks/useHandlePollworkers';
import DisabledAppCoverall from "../../DisabledAppCoverall";
import { Button } from 'shared/src/components/ui';
import { Calendar, Popover, PopoverContent, PopoverTrigger} from "shared/src/components/ui";
import { FeatureFlagCheck } from 'admin/src/components/ui';
import {Nomenclature} from "../../../components/Nomenclature";

export default PollworkerTracker;

const TIME_CLOCK_FEATURE_FLAG = 'pollworker-timeclock';

function PollworkerTracker() {
  const trackerEnabled = useIsFeatureFlagEnabled(TIME_CLOCK_FEATURE_FLAG);
  const flags = useAppSelector(selectFeatureFlags);
  const dispatch = useAppDispatch();
  const selectedType = useAppSelector(selectPollworkerTrackerLocationTypeDisplay);
  const moduleInfo: PollworkerModuleInfo = useAppSelector(selectedPollworkerModuleInfo);
  const votingLocations = useAppSelector(selectVotingLocationsForElectionAndDate);
  const precincts = useAppSelector(selectPrecinctsForElectionAndDate);
  const selectedPrecinct: Precinct | null = useAppSelector(selectSelectedPrecinct);
  const selectedVotingLocation: VotingLocation | null = useAppSelector(selectSelectedVotingLocation);
  const workSchedules = useAppSelector(selectTrackerWorkSchedules);
  const showPollworkerDetails = useAppSelector(getShowPollworkerDetails);
  const [loadingPollworkerData, , handlePollworkers] = useHandlePollworkers();
  const [loadingSchedules, setLoadingSchedules] = useState<boolean>(false);
  const [loadingPrecincts, setLoadingPrecincts] = useState<boolean>(false);
  const [loadingVotingLocations, setLoadingVotingLocations] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

  const handlePollworkerClick = useCallback((schedule: WorkSchedule) => {
    handlePollworkers(false);
    dispatch(setShowPollworkerDetailsId(schedule.evUserId));
  }, [dispatch, handlePollworkers]);

  const handleClockIn = useCallback((schedule: WorkSchedule) => {
    return clockInSchedule(schedule.id).then((newEntry: PollworkerTimeclockEntry) => {
      updateScheduleWithEntry(schedule, newEntry, dispatch);
    });
  }, [dispatch]);

  const handleClockOut = useCallback((schedule: WorkSchedule) => {
    return clockOutSchedule(schedule.id).then((newEntry: PollworkerTimeclockEntry) => {
      updateScheduleWithEntry(schedule, newEntry, dispatch);
    });
  }, [dispatch]);

  function updateScheduleWithEntry(schedule: WorkSchedule, newEntry: PollworkerTimeclockEntry, _dispatch: typeof dispatch) {
    const newSchedule: WorkSchedule = {
      ...schedule
    }
    const indexToUpdate = newSchedule.timeclockEntries.findIndex(entry => entry.id === newEntry.id);

    if (indexToUpdate === -1) {
      newSchedule.timeclockEntries = [...newSchedule.timeclockEntries, newEntry];
    } else {
      newSchedule.timeclockEntries = [
        ...newSchedule.timeclockEntries.slice(0, indexToUpdate),
        newEntry,
        ...newSchedule.timeclockEntries.slice(indexToUpdate+1)
      ];
    }
    _dispatch(updateTrackerWorkSchedule(newSchedule));
  }

  useEffect(() => {
    if (!!moduleInfo && !!moduleInfo.ElectionId) {
      if (selectedType === 'votingLocation') {
        setLoadingVotingLocations(true);

        fetchVotingLocationsForElectionAndWorkDate(moduleInfo.ElectionId, selectedDate).then((votingLocations: VotingLocation[]) => {
          votingLocations.sort((locationA, locationB) => locationA.locationName.localeCompare(locationB.locationName));
          dispatch(setVotingLocationsForElectionAndDate(votingLocations));
        }).finally(() => {
          setLoadingVotingLocations(false);
        })
      } else { // selectedType == 'precinct'
        setLoadingPrecincts(true);

        fetchPrecinctsForElectionAndWorkDate(moduleInfo.ElectionId, selectedDate).then((precincts: Precinct[]) => {
          precincts.sort((precinctA, precinctB) => precinctA.precinctName.localeCompare(precinctB.precinctName));
          dispatch(setPrecinctsForElectionAndDate(precincts));
        }).finally(() => {
          setLoadingPrecincts(false);
        })
      }
    }
  }, [moduleInfo, selectedType, selectedDate]);

  useEffect(() => {
    if (votingLocations.length > 0) {
      dispatch(setSelectedVotingLocation(votingLocations[0]));
    }
  }, [votingLocations]);

  useEffect(() => {
    if (precincts.length > 0) {
      dispatch(setSelectedPrecinct(precincts[0]));
    }
  }, [precincts]);

  useEffect(() => {
    fetchSchedulesByPrecinct();
  }, [selectedPrecinct, selectedDate]);

  function fetchSchedulesByPrecinct() {
    if (selectedPrecinct !== null) {
      setLoadingSchedules(true);

      fetchWorkSchedulesForLocationAndDate(selectedPrecinct.id, selectedDate).then(schedules => {
        dispatch(setTrackerWorkSchedules(schedules));
      }, () => dispatch(setTrackerWorkSchedules([]))).finally(() => {
        setLoadingSchedules(false);
      });
    }
  }

  useEffect(() => {
    fetchSchedulesByLocation();
  }, [selectedVotingLocation, selectedDate]);

  function fetchSchedulesByLocation() {
    if (selectedVotingLocation !== null) {
      setLoadingSchedules(true);

      fetchWorkSchedulesForLocationAndDate(selectedVotingLocation.id, selectedDate).then(schedules => {
        dispatch(setTrackerWorkSchedules(schedules));
      }, () => dispatch(setTrackerWorkSchedules([]))).finally(() => {
        setLoadingSchedules(false);
      });
    }
  }

  function reloadData() {
    fetchSchedulesByLocation();
    fetchSchedulesByPrecinct();
  }

  function renderNoLocationFound(message: string) {
    return (
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full px-3 space-y-4">
        <BuildingLibraryIcon className="size-7 stroke-1 mx-auto my-auto"/>
        <div className="text-center text-sm">{message}</div>
      </div>
    );
  }

  if (flags[TIME_CLOCK_FEATURE_FLAG]?.loading) {
    return (
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <Loading loadingMessage="Checking subscription..."/>
      </div>
    );
  }

  if (!trackerEnabled) {
    return (<DisabledAppCoverall message='This feature is not enabled for your account. Contact your account manager if you believe this is a mistake.' />);
  }

  return (
    <div className="h-full">
      <ModuleInfoChangeManager />
      <div className="h-full flex flex-col justify-between">
        <div className="flex justify-between relative z-50 w-full border-b-0 divide-x divide-gray-400/80 bg-white border border-gray-400/80 text-sm">
          <Flexor className='space-x-2 px-2'>
            <Link to='/'>
              <button>
                <Flexor className='space-x-2 h-full'>
                  <ChevronLeftIcon className="size-4"/>
                  <span className="text-sm">Pollworker</span>
                </Flexor>
              </button>
            </Link>
          </Flexor>
          <FeatureFlagCheck dbFlag={Feature.MODERN_POLLWORKER_SCHEDULER}>
            <Flexor className='space-x-2 px-2'>
              <Link to='/scheduler'>
                <button>
                  <Flexor className='space-x-2 h-full'>
                    <CalendarDaysIcon className='size-4'/>
                    <span className='text-sm'>Scheduler</span>
                  </Flexor>
                </button>
              </Link>
            </Flexor>
          </FeatureFlagCheck>
          <div className="flex justify-center items-center grow font-semibold py-1.5">
            <span>Poll Worker Tracker</span>
          </div>
          <div className="flex items-center px-2">
            <Popover>
              <PopoverTrigger>
                <Flexor>
                  <CalendarIcon className="size-4 mr-1"/>
                  <span>{dayjs(selectedDate).format('YYYY-MM-DD')}</span>
                </Flexor>
              </PopoverTrigger>
              <PopoverContent sideOffset={-1} className="w-auto p-0 shadow-xl">
                <Calendar
                  disabled={{
                    after: new Date(),
                  }}
                  selected={new Date()}
                  onSelect={(newDate) => {
                    setSelectedDate(dayjs(newDate).format('YYYY-MM-DD'));
                  }}
                  mode="single"
                />
              </PopoverContent>
            </Popover>
          </div>
          <div className="flex items-center px-2">
            <TrackerExportButton electionId={moduleInfo?.ElectionId}/>
          </div>
          <div className="flex items-center px-2">
            <Button size="sm" variant="tertiary" onClick={reloadData}>
              <ArrowPathIcon className={classNames(loadingSchedules ? 'animate-spin' : '', 'size-4')}/>
              <span>Refresh</span>
            </Button>
          </div>
        </div>
        <PanelGroup direction="horizontal">
          <Panel defaultSize={20}>
            <div
              className="flex flex-col h-full w-full bg-white border border-gray-400/80 border-t-0 border-r-0 relative">
              <LocationTypeToggleDropdown className="border-y border-gray-400/80" />
              <div className="h-full">
                {
                  loadingPrecincts || loadingVotingLocations ? (
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                      <Spinner large show />
                    </div>
                  ) : (
                    <div className="divide-y divide-gray-400/80 h-full">
                      {selectedType === 'votingLocation' && !loadingVotingLocations && (
                        <>
                          {
                            !votingLocations.length ? (
                              renderNoLocationFound('No polling places found for this election')
                            ) : (
                              <VotingLocationList locations={votingLocations} onLocationSelected={(newLocation: VotingLocation) => {
                                dispatch(setSelectedVotingLocation(newLocation));
                              }} selectedLocationId={selectedVotingLocation ? selectedVotingLocation.id : null}/>
                            )
                          }
                        </>
                      )}
                      {selectedType === 'precinct' && !loadingPrecincts && (
                        <>
                          {
                            !precincts.length ? (
                              renderNoLocationFound('No precincts found for this election')
                            ) : (
                              <PrecinctList precincts={precincts} onPrecinctSelected={(newPrecinct: Precinct) => {
                                dispatch(setSelectedPrecinct(newPrecinct));
                              }} selectedPrecinctId={selectedPrecinct ? selectedPrecinct.id : null}/>
                            )
                          }
                        </>
                      )}
                    </div>
                  )
                }
              </div>
            </div>
          </Panel>
          <Panel defaultSize={50}>
            <div className="p-1 flex flex-col items-center border border-gray-400/80 w-full h-full relative">
              {
                !loadingSchedules ? (
                  <>
                    {
                      workSchedules.length ? (
                        <PollworkerScheduleList schedules={workSchedules} onPollworkerClick={handlePollworkerClick} onClockIn={handleClockIn} onClockOut={handleClockOut} />
                      ) : (
                        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full'>
                          <Flexor className='flex-col space-y-4'>
                            <CalendarIcon className='size-7 stroke-1' />
                            <div>No work schedules for {selectedPrecinct?.precinctName || selectedVotingLocation?.locationName} {selectedDate}</div>
                          </Flexor>
                        </div>
                      )
                    }
                  </>
                ) : (
                  <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                    <Spinner large show={loadingSchedules} />
                  </div>
                )
              }
            </div>
          </Panel>
          {
            (loadingPollworkerData || showPollworkerDetails) ? (
              <>
                <PanelResizeHandle disabled />
                <Panel collapsible minSize={33.4} maxSize={33.4}>
                  <div className={`overflow-x-hidden h-full overflow-y-auto border-t bg-white border border-gray-400/80 border-l-0 relative`}>
                    {
                      loadingPollworkerData ? (
                        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
                          <Spinner large show />
                        </div>
                      ) : (
                        <PollworkerDetails readOnly />
                      )
                    }
                  </div>
                </Panel>
              </>
            ) : null
          }
        </PanelGroup>
      </div>
    </div>
  );
}

function LocationTypeToggleDropdown({ className }: { className: string }) {
  const dispatch = useAppDispatch();
  const selectedType = useAppSelector(selectPollworkerTrackerLocationTypeDisplay);
  const label = selectedType === 'votingLocation' ? 'Polling Places' : 'Precincts'

  return (
    <Menu as="div" className={cn(className, 'relative inline-block text-lef w-full')}>
      <Menu.Button className="flex items-center justify-between p-2 h-full w-full text-sm space-x-0.5 text-gray-800 px-2">
        <MapPinIcon className="h-4 w-4" />
        <span>{label}</span>
        <ChevronUpDownIcon className="-mr-1 h-4 w-4 text-gray-800" aria-hidden="true" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-[0.25em] w-48 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {() => (
                <a
                  href="#"
                  onClick={() => dispatch(setDisplayedLocationType('votingLocation'))}
                  className={classNames(
                    selectedType === 'votingLocation' ? 'bg-gray-100 font-semibold text-gray-900' : 'text-gray-700',
                    'flex items-center justify-between px-4 py-2 text-sm'
                  )}
                >
                  Polling Places {selectedType === 'votingLocation' && <CheckIcon className="h-3 w-3" />}
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {() => (
                <a
                  href="#"
                  onClick={() => dispatch(setDisplayedLocationType('precinct'))}
                  className={classNames(
                    selectedType === 'precinct' ? 'bg-gray-100 font-semibold text-gray-900' : 'text-gray-700',
                    'flex items-center justify-between px-4 py-2 text-sm'
                  )}
                >
                  <Nomenclature nomenclatureId="PrecinctDisplayName"/> {selectedType === 'precinct' && <CheckIcon className="h-3 w-3" />}
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
