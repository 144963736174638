import {createAction} from "@reduxjs/toolkit";

export const TOAST_ACTION_TYPES = {
  SHOW: 'SHOW_TOAST',
  POP: 'POP_TOAST',
}

export type Toast = {
  position?: 'bottom' | 'top';
  content: string;
  success?: boolean;
  undoAction?: string | undefined;
  timeout?: number | undefined;
}

export const showToast = createAction<Toast>(TOAST_ACTION_TYPES.SHOW);
export const popToast = createAction<Toast | undefined>(TOAST_ACTION_TYPES.POP);
