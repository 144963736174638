import { fetchApi } from 'shared/src/fetchers';
import {
  AddEditUserInfoDto,
  SaveEmployeeInfoDto,
} from "admin/src/screens/admin/SetupAndAdmin/AddEditUser";
import { convertFileToByteArray } from "shared/src/utils/converters";
import { FieldValues } from "react-hook-form";
import { CustomerInfo } from "shared/src/types/CustomerInfo";
import { DataburstResponse } from "admin/src/types/DataburstResponse";
import { EmployeeInfo } from "admin/src/types/User";
import { DistributionCenter } from '../types/DistributionCenter';
import { PollingLocation } from '../types/PollingLocation';
import { SecurityLevel, SecurityLevelPermission } from "../types";

export const fetchEmployees = async () => {
  const response = await fetchApi('/api/EmployeeApi/LoadEmployeesWithSSOInfo', {
    method: 'GET'
  });

  return await response.json();
}

export const fetchAddEditUserInfo: (evUserId: string | null | undefined) => Promise<AddEditUserInfoDto> = async (evUserId) => {
  const endpoint = evUserId
    ? `/api/EmployeeApi/FetchAddEditUserInfo/${encodeURIComponent(evUserId)}`
    : '/api/EmployeeApi/FetchAddEditUserInfo';

  const response = await fetchApi(endpoint, {
    method: 'GET'
  });

  return await response.json();
}

export const saveAddEditUserInfo: (data: SaveEmployeeInfoDto) => Promise<void> = async (data: SaveEmployeeInfoDto) => {
  const json = JSON.stringify(data);

  await fetchApi('/api/EmployeeApi/SaveAddEditUserInfo', {
    method: 'POST',
    body: json
  });
}

export async function deleteUserInfo(evUserId: string) {
  const response = await fetchApi(`/api/EmployeeApi/DeleteUser/${evUserId}`, {
    method: 'DELETE',
  });

  return await response.text();
}

export const fetchUserIdIsInUse: (evUserId: string, userId: string) => Promise<boolean> = async (evUserId: string, userId: string) => {
  const req = {
    notes: userId,
    id: evUserId,
    compress: false,
  };

  const response = await fetchApi('/api/EVUserApi/CheckForDuplicateUserId', {
    method: 'POST',
    body: JSON.stringify(req)
  });

  const { data } = await response.json();

  return JSON.parse(atob(data)) as boolean;
}

export const getPollworkerSettings = async () => {
  const response = await fetchApi('/tables/Setting_Pollworker', {
    method: 'GET',
  });

  return await response.json();
}

export async function printPollWorkerAssignmentStatusReport(locationIds: string[], includeAgeData: boolean, includePartyData: boolean, electionId: string) {
  const req = {
    locationIds,
    includeAgeData,
    includePartyData,
    electionId
  };

  return fetchApi('/di-api/admin/reports/pollworkerassignmentstatusreport', {
    method: 'POST',
    body: JSON.stringify(req)
  }).then(async (resp) => await resp.blob());
}

export async function printPollWorkerSignInSheetReport(locationIds: string[], electionId: string) {
  const req = {
    locationIds,
    electionId
  };

  return fetchApi('/di-api/admin/reports/pollworkersigninsheetreport', {
    method: 'POST',
    body: JSON.stringify(req)
  }).then(async (resp) => await resp.blob());
}

export async function getForms() {
  const response = await fetchApi('/api/FormsApi/All', {
    method: 'GET',
  });

  return await response.json();
}

export async function getForm(formId: string) {
  const response = await fetchApi(`/api/FormsApi/${formId}`, {
    method: 'GET',
  });

  return await response.json();
}

export async function createForm(form: any) {
  const response = await fetchApi('/api/FormsApi', {
    method: 'POST',
    body: JSON.stringify(form),
  });

  return await response.json();
}

export async function updateForm(id: string, form: any) {
  const response = await fetchApi(`/api/FormsApi/${id}`, {
    method: 'PATCH',
    body: JSON.stringify(form),
  });

  return await response.json();
}

export async function publishForm(id: string) {
  const response = await fetchApi(`/api/FormsApi/${id}/publish`, {
    method: 'POST',
  });

  return await response.json();
}

export async function revertDraftForm(id: string) {
  const response = await fetchApi(`/api/FormsApi/${id}/revert`, {
    method: 'DELETE',
  });

  return await response.json();
}

export async function createChildForm(id: string) {
  const response = await fetchApi(`/api/FormsApi/${id}/dup`, {
    method: 'POST',
  });

  return await response.json();
}

export async function getCustomerById(): Promise<CustomerInfo> {
  const response = await fetchApi(`/di-api/v1/customers/current`, {
    method: "GET",
  });

  return (await response.json()) as CustomerInfo;
}

export async function updateCustomer(data: FieldValues): Promise<CustomerInfo> {
  const response = await fetchApi(`/di-api/v1/customers/current`, {
    method: "PUT",
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return await response.json();
}

export async function getPollingLocations(electionId?: string | undefined){
  if (electionId){
    const response = await fetchApi(`/di-api/core/VotingLocations/ByElection/${electionId}`, {
      method: 'GET',
    });
    return await response.json();
  } else {
    const response = await fetchApi(`/di-api/core/VotingLocations`, {
      method: 'GET',
    });
    return await response.json();
  }
}

export async function addOrEditPollingLocation(pollingLocation: PollingLocation) {
  const response = await fetchApi(`/di-api/core/VotingLocations/addOrEditPollingLocation`, {
    method: 'POST',
    body: JSON.stringify(pollingLocation),
  });
  return await response.json();
}

export async function deletePollingLocation(pollingLocationId: string) {
  const response =
    await fetchApi(`/di-api/core/VotingLocations/deletePollingLocation/${pollingLocationId}`, {
    method: 'GET',
  });
  return await response.json();
}

export async function getEmployeeByUserId(userId: string): Promise<EmployeeInfo> {
  const body = {
    id: userId,
  };

  const response = await fetchApi(`/api/EmployeeApi/LoadEmployeeByUserId`, {
    method: "POST",
    body: JSON.stringify(body),
  });

  const data = await response.json();

  return JSON.parse(atob(data.data));
}

export async function uploadCustomerLogo(
  customerId: string,
  file: File,
): Promise<DataburstResponse<boolean>> {
  const destinationUrl = `${customerId}/logo.png`;
  const fileToUpload = await convertFileToByteArray(file);

  const body = {
    id: destinationUrl,
    data: fileToUpload,
  };

  const response = await fetchApi(
    `/api/BlobStorageApi/UploadDataFileToStorage`,
    {
      method: "POST",
      body: JSON.stringify(body),
    },
  );

  return (await response.json()) as DataburstResponse<boolean>;
}

export async function getDistributionCenters(electionId?: string): Promise<DistributionCenter[]> {
  if (electionId){
    const response = await fetchApi(`/di-api/core/DistributionCenters/elections/${electionId}`, {
      method: 'GET',
    });

    return await response.json();
  }

  const response = await fetchApi(`/di-api/core/DistributionCenters`, {
    method: 'GET',
  });
  return await response.json() as DistributionCenter[];
}

export async function addOrEditDistributionCenter(distributionCenter: DistributionCenter): Promise<DistributionCenter> {
  const response = await fetchApi(`/di-api/core/DistributionCenters/addOrEditDistributionCenter`, {
    method: 'POST',
    body: JSON.stringify(distributionCenter),
  });
  return await response.json() as DistributionCenter;
}

export async function deleteDistributionCenter(distributionCenterId: string): Promise<DistributionCenter> {
  const response = await fetchApi(`/di-api/core/DistributionCenters/deleteDistributionCenter/${distributionCenterId}`, {
    method: 'GET',
  });
  return await response.json() as DistributionCenter;
}

export async function getSecurityLevels() {
  const resp = await fetchApi('/di-api/core/SecurityLevels', {
    method: 'GET',
  });
  return await resp.json();
}

export async function getSecurityLevelPermissions() {
  let resp = await fetchApi('/di-api/core/SecurityLevelPermissions', { method: 'GET' });
  return await resp.json();
}

export function createSecurityLevelPermission(keySecurityLevelId: string, permissionLevel: number) {
  return fetchApi('/di-api/core/SecurityLevelPermissions', {
    method: 'POST',
    body: JSON.stringify({
      keySecurityLevelId: keySecurityLevelId,
      module: 8, // always 8
      permissionType: 1, // always 1
      permissionLevel: permissionLevel, // always 0 or 3
    }),
  });
}

export function createSecurityLevel(securityLevelName: string) {
  return fetchApi('/di-api/core/SecurityLevels', {
    method: 'POST',
    body: JSON.stringify({ securityLevelName, active: true }),
  })
  .then((resp) => resp.json());
}

export function updateSecurityLevelPermission(securityLevelPermission: SecurityLevelPermission) {
  return fetchApi(`/di-api/core/SecurityLevelPermissions/${securityLevelPermission?.id}`, {
    method: 'PUT',
    body: JSON.stringify(securityLevelPermission),
  });
}

export function updateSecurityLevel(securityLevel: SecurityLevel) {
  return fetchApi(`/di-api/core/SecurityLevels/${securityLevel.id}`, {
    method: 'PATCH',
    body: JSON.stringify(securityLevel),
  })
  .then((resp) => resp.json());
}

export function deleteSecurityLevel(securityLevelId: string) {
  return fetchApi(`/di-api/core/SecurityLevels/${securityLevelId}`, { method: 'DELETE' });
}

export function getUsersAssignedToLevel(securityLevelId: string) {
  return fetchApi('/api/SecurityLevelApi/LoadUsersAssignedToLevel', { method: 'POST', body: JSON.stringify({ id: securityLevelId }) })
  .then((resp) => resp.json())
}
