import { hideModal, MODALS } from "admin/src/actions/modal";
import { AppState } from 'admin/src/store';
import { useDispatch, useSelector } from "react-redux";
import {
  ChatBubbleLeftRightIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { Flexor } from "shared/src/components";
import { Alert, Button, Dialog, Textarea } from "shared/src/components/ui";
import Spinner from "shared/src/components/Spinner";
import { getSelectedPollworkers } from "admin/src/selectors/pollworker/grid";
import { PollworkerWorkHistory } from "admin/src/types/Pollworker/PollworkerWorkHistory";
import { postBulkSendSms } from "../../fetchers";
import { Label } from "shared/src/components/ui";

export default function DialogPollworkerBulkSendSms() {
  const open = useSelector(
    (state: AppState) => state.modals.open === MODALS.SEND_SMS,
  );
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<
    PollworkerWorkHistory[]
  >([]);
  filteredSelectedRows.sort((a, b) =>
    a.userInfo.firstName > b.userInfo.firstName ? -1 : 1,
  );
  filteredSelectedRows.sort((a, b) =>
    a.userInfo.okToSendSMS > b.userInfo.okToSendSMS ? 1 : -1,
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [messageIsValid, setMessageIsValid] = useState(true);
  const [messageIsEmpty, setMessageIsEmpty] = useState(false);
  const selectedPollworkers = useSelector(getSelectedPollworkers);
  const dispatch = useDispatch();
  const sendDisabled = saving || message.length === 0 || !messageIsValid;

  useEffect(() => {
    if (open) return;

    setFilteredSelectedRows([]);
  }, [open]);

  useEffect(() => {
    setFilteredSelectedRows([...selectedPollworkers]);
  }, [selectedPollworkers, open, messageIsEmpty, messageIsValid]);

  function handleChangeForMessage(message: any) {
    const gsm7RegEx = new RegExp(
      "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ!\"#¤%&'()*+,-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|€]*$",
    );

    if (message.length <= 0) {
      setMessageIsEmpty(true);
    } else {
      setMessageIsEmpty(false);
    }
    if (!gsm7RegEx.test(message)) {
      setMessageIsValid(false);
      return;
    } else {
      setMessageIsValid(true);
    }

    setMessage(message);
  }

  function hide() {
    setMessage("");
    setMessageIsValid(true);
    setMessageIsEmpty(false);
    dispatch(hideModal(MODALS.SEND_SMS));
  }

  function removeIndexFromSelectedRows(idx: number) {
    const newFilteredRows = [...filteredSelectedRows];
    newFilteredRows.splice(idx, 1);
    setFilteredSelectedRows(newFilteredRows);
  }

  function removeInvalidRecipients() {
    setFilteredSelectedRows(
      filteredSelectedRows.filter((row) => row.userInfo.okToSendSMS),
    );
  }

  function sendBulkSms() {
    const phoneNumberList = filteredSelectedRows
      .map((row) => row.userInfo)
      .filter((row) => row.okToSendSMS)
      .map((row) => row.cellPhone);

    if (message.length > 0 && messageIsValid) {
      setSaving(true);
      postBulkSendSms(phoneNumberList, message)
        .then(({ success }) => {
          if (success) {
            hide();
          }
        })
        .finally(() => {
          setSaving(false);
          setMessage("");
        });
    }
  }

  return (
    <Dialog.Dialog open={open} variant={"default"} onOpenChange={() => hide()}>
      <Dialog.DialogContent>
        <Dialog.DialogTitle>Send SMS</Dialog.DialogTitle>
        <div>
          <div className="mt-5">
            {filteredSelectedRows.find((row) => !row.userInfo.okToSendSMS) && (
              <div className="text-base font-semibold text-ev-red mb-5">
                <Alert>
                  One or more of the selected pollworkers have chosen not to
                  receive SMS messages and will not receive communications.
                  <Flexor justify="end">
                    <Button
                      variant="tertiary"
                      onClick={() => removeInvalidRecipients()}
                    >
                      <div className="mx-auto">
                        <div>Remove Invalid Recipients</div>
                      </div>
                    </Button>
                  </Flexor>
                </Alert>
              </div>
            )}

            <div>
              <p className="text-sm text-gray-500">
                Send SMS (text message) communications to the following
                pollworkers
              </p>
            </div>
            <div className="mt-5 text-sm font-medium leading-6 text-gray-900">
              <Flexor>
                <div>Pollworkers</div>
                <div>{filteredSelectedRows.length}</div>
              </Flexor>
            </div>

            <ul className="divide-y divide-gray-100 overflow-y-auto max-h-40 lg:max-h-60">
              {filteredSelectedRows.map((row, idx) => {
                const icon = row.userInfo.okToSendSMS ? (
                  <ChatBubbleLeftRightIcon className="h-5 w-5 text-green-500" />
                ) : (
                  <NoSymbolIcon className="h-5 w-5 text-ev-red" />
                );
                const content = row.userInfo.okToSendSMS
                  ? "Allowed"
                  : "Not Allowed";

                return (
                  <li
                    key={`modal:${row.id}`}
                    className="flex items-center justify-between gap-x-6 py-3 mx-5"
                  >
                    <div className="flex min-w-0 gap-x-4">
                      <Flexor
                        className="h-12 w-12 flex-none border border-gray-200 bg-gray-50 flex-col text-green-500"
                        justify="center"
                      >
                        <div className="scale-[115%]">{icon}</div>
                      </Flexor>
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {row.userInfo.firstName} {row.userInfo.lastName}
                        </p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">
                          {content || "Empty"}
                        </p>
                      </div>
                    </div>
                    <Button
                      onClick={() => removeIndexFromSelectedRows(idx)}
                      variant={"tertiary"}
                    >
                      <div className="mx-auto">
                        <Flexor justify="center" className="space-x-2">
                          {saving ? <Spinner /> : null}
                          <div>Remove</div>
                        </Flexor>
                      </div>
                    </Button>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="mt-5">
            <Label>Message</Label>
            <Textarea
              id="smsMessage"
              data-testid="smsMessage"
              rows={1}
              maxLength={160}
              onChange={(e) => handleChangeForMessage(e.target.value)}
              placeholder="Enter message here"
            />
            {messageIsEmpty && <Alert>Please enter a message to send.</Alert>}
            {!messageIsValid && (
              <Alert>Message contains invalid characters.</Alert>
            )}
            <p className="mt-2 text-sm text-gray-500">
              {message.length} / 160 characters
            </p>
          </div>
          <div className="mt-5 sm:mt-6">
            <Flexor justify="end">
              <Button
                onClick={sendBulkSms}
                disabled={sendDisabled}
                variant="primary"
              >
                <div className="mx-auto">
                  <Flexor justify="center" className="space-x-2">
                    {saving ? <Spinner /> : null}
                    <div className="w-full text-center">Send</div>
                  </Flexor>
                </div>
              </Button>
            </Flexor>
          </div>
        </div>
      </Dialog.DialogContent>
    </Dialog.Dialog>
  );
}
