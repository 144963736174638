import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import {useSelector} from "react-redux";
import {getShowPollworkerDetails} from "admin/src/selectors/pollworker";
import React, {Fragment, useEffect, useState} from "react";
import {
  deletePollworkerRequirement,
  loadRequirementsListForElectionAndUser,
  updatePollworkerRequirement
} from "admin/src/fetchers";
import type { AppState } from 'admin/src/store';
import {
  CheckBadgeIcon,
  ChevronRightIcon,
  NoSymbolIcon,
  PencilSquareIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Flexor } from "shared/src/components"
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import Spinner from "shared/src/components/Spinner";
import {
  type PollworkerRequirement,
  PollworkerRequirementStatus,
  PollworkerRequirementType,
} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import dayjs from "dayjs";
import PanelSlideout, { PanelSlideoutHeader } from "./ui/PanelSlideout";
import {classNames} from "shared/src/utils/classNames";
import {Menu, Transition} from "@headlessui/react";
import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import {AcademicCapIcon, DocumentCheckIcon} from "@heroicons/react/16/solid";
import RequirementsEditor from "./RequirementEditor";
import useRequirementClasses from "./hooks/useRequirementClasses";
import usePollworkerDocuments from "./hooks/usePollworkerDocuments";
import PanelHeader from "./ui/PanelHeader";

export default function PollworkerRequirementsPanel() {
  const [showSlideout, setShowSlideout] = useState<boolean>(false);
  const [requirements, setRequirements] = useState<PollworkerRequirement[]>([]);
  const [requirementToEdit, setRequirementToEdit] = useState<PollworkerRequirement>();
  const [loadingRequirements, setLoadingRequirements] = useState<boolean>(true);
  const [expandedRequirementId, setExpandedRequirementId] = useState<string>();
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const user = useSelector((state: AppState) => state.user);
  const pollworker = useSelector(getShowPollworkerDetails);
  const selectedPollworker = useSelectedPollworker();
  const {loadPollworkerDocuments} = usePollworkerDocuments();
  const {loadRequirementClasses} = useRequirementClasses();

  useEffect(() => {
    if (!moduleInfo || !pollworker) return;

    loadRequirements();
  }, [moduleInfo, selectedPollworker]);

  async function loadRequirements() {
    if (!pollworker || !moduleInfo?.ElectionId) return;

    const pollworkerDocuments = await loadPollworkerDocuments(selectedPollworker);
    const requirementClasses = await loadRequirementClasses(selectedPollworker);

    setLoadingRequirements(true);
    loadRequirementsListForElectionAndUser(pollworker.keyEVUserId, moduleInfo?.ElectionId)
      .then((requirements) => {
        const requirementsSorted = requirements.map((req: PollworkerRequirement) => {
          let reqName = pollworkerDocuments.find((doc) => doc.id === req.requiredObjectId)?.documentName;
          reqName ||= requirementClasses.find((r) => r.id === req.requiredObjectId)?.className;

          req.requirementName = reqName;

          return req;
        }).sort((a: PollworkerRequirement, b: PollworkerRequirement) => {
          return dayjs(a.dateCreated) < dayjs(b.dateCreated) ? 1 : -1;
        });

        setRequirements(requirementsSorted);
      })
      .finally(() => setLoadingRequirements(false));
  }

  function getColorIndicator(requirement: PollworkerRequirement) {
    const dueDate = dayjs(requirement.dateDue);

    // NOTE: Logic from PollworkerRequirementsListControl.cs
    if (requirement.status === PollworkerRequirementStatus.Completed) {
      return 'border-green-500/50';
    } else if (dueDate.toDate() <= dayjs().toDate()) {
      return 'border-red-500/90';
    } else if (dueDate <= dueDate.add(5, 'days')) {
      return 'border-orange-500/70';
    }

    return '';
  }

  function toggleSlideout() {
    setShowSlideout(!showSlideout);
    setRequirementToEdit(undefined);
  }

  const initLoadingRequirements = loadingRequirements && !requirements;

  return (
    <div>
      <PanelHeader title='Requirements' loading={loadingRequirements} reload={loadRequirements} onAdd={() => setShowSlideout(true)} />
      <PanelSlideout show={showSlideout}>
        <PanelSlideoutHeader close={toggleSlideout}>
          <div className="space-y-1">
            <SectionSubSubHeading>{requirementToEdit ? 'Edit Requirement' : 'Add Requirement'}</SectionSubSubHeading>
            <p className="text-sm text-gray-500">
              Select the requirement type and enter the necessary information below
            </p>
          </div>
        </PanelSlideoutHeader>
        <RequirementsEditor
          requirement={requirementToEdit}
          onSave={() => {
            loadRequirements();
            toggleSlideout();
          }}
        />
      </PanelSlideout>
      {
        initLoadingRequirements ? (
          <Flexor justify="center" className="space-x-2 mt-10">
            <Spinner show />
            <SectionSubSubHeading>Loading requirements...</SectionSubSubHeading>
          </Flexor>
        ) : (
          <>
            {
              !requirements.length ? (
                <Flexor justify="center" className="space-x-2 mt-10">
                  <NoSymbolIcon className="h-6 w-6" />
                  <SectionSubSubHeading>No requirements found</SectionSubSubHeading>
                </Flexor>
              ) : null
            }
            <ul className={classNames(loadingRequirements ? 'opacity-50' : '', 'transition-opacity')}>
              {
                requirements.map((requirement) => {
                  const expanded = requirement.id === expandedRequirementId;
                  const dueDate = dayjs(requirement.dateDue);
                  const daysUntilDueDate = Math.abs(Math.ceil(dayjs(dueDate).diff(dayjs(), 'hours') / 24));

                  return (
                    <div key={requirement.id} className={classNames(getColorIndicator(requirement), requirement.status === PollworkerRequirementStatus.Canceled ? 'line-through' : '', "py-4 border-l-4 hover:bg-gray-100 pb-2 pl-2")}>
                      <Flexor justify="start" className="space-x-2 w-full" items="start">
                        <div onClick={() => setExpandedRequirementId(expanded ? undefined : requirement.id)}>
                          <ChevronRightIcon className={classNames(expanded ? 'rotate-90' : '', "transition-transform h-4 w-4")} />
                        </div>
                        <Flexor justify='start' className='w-full text-sm space-x-2 truncate'>
                          <div>
                            <div className='flex space-x-1'>
                              { requirement.requirementType === PollworkerRequirementType.TrainingClass ? <AcademicCapIcon className="shrink-0 h-4 w-4" /> : null }
                              { requirement.requirementType === PollworkerRequirementType.Document ? <DocumentCheckIcon className="shrink-0 h-4 w-4" /> : null }
                              <span className='truncate'>{requirement.requirementName}</span>
                            </div>
                            <div className="flex items-center space-x-2 text-xs">
                              <span className="font-normal">{requirement.createdByInfo.firstName} {requirement.createdByInfo.lastName}</span>
                              <span>·</span>
                              {
                                requirement.satisfiedById ? (
                                  <span className="font-semibold text-green-500">Satisfied</span>
                                ) : (
                                  <time
                                    className={classNames(daysUntilDueDate < 0 ? 'text-ev-red' : 'text-gray-700', "block font-normal")}
                                    title={dueDate.format('MM-DD-YYYY hh:MM A')}>{daysUntilDueDate >= 0 ? `Due in ${daysUntilDueDate} days` : `Past due from ${daysUntilDueDate} days ago`}
                                  </time>
                                )
                              }
                            </div>
                            {
                              expanded ? (
                                <div className="space-y-2">
                                  <div className="text-sm">
                                    <span className="leading-6 text-gray-800 font-semibold">Description</span>
                                    <div className="border-l-2 border-gray-400 pl-2">{requirement.description}</div>
                                  </div>
                                  <div className="text-sm">
                                    <span className="leading-6 text-gray-800 font-semibold">Notes</span>
                                    {
                                      requirement.notes ? (
                                        <div className="border-l-2 border-gray-400 pl-2">{requirement.notes}</div>
                                      ) : (
                                        <div className='italic text-gray-500'>No notes</div>
                                      )
                                    }
                                  </div>
                                </div>
                              ) : null
                            }
                          </div>
                        </Flexor>
                        <Menu as="div" className="">
                          <Menu.Button className="block py-1 px-2.5 text-gray-500 hover:text-gray-900">
                            <span className="sr-only">Open options</span>
                            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                          </Menu.Button>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-5 z-10 mt-0 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'flex items-center space-x-2 block px-3 py-1 text-sm leading-6 text-gray-900'
                                    )}
                                    onClick={() => {
                                      setRequirementToEdit(requirement);
                                      setShowSlideout(true);
                                    }}
                                  >
                                    <PencilSquareIcon className="h-5 w-5" />
                                    <span>Edit<span className="sr-only">, {requirement.requirementName}</span></span>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-green-500' : 'text-gray-800',
                                      'flex items-center space-x-2 block px-3 py-1 text-sm leading-6'
                                    )}
                                    onClick={() => {
                                      if (!window.confirm('Are you sure you want to complete and satisfy this requirement?')) return;

                                      updatePollworkerRequirement({
                                        ...requirement,
                                        satisfiedById: user?.user?.id,
                                        status: PollworkerRequirementStatus.Completed,
                                      }).finally(loadRequirements);
                                    }}
                                  >
                                    <CheckBadgeIcon className="h-5 w-5" />
                                    <span>Satisfy?<span className="sr-only">, {requirement.requirementName}</span></span>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-gray-100 text-red-500' : 'text-gray-800',
                                      'flex items-center space-x-2 block px-3 py-1 text-sm leading-6'
                                    )}
                                    onClick={() => {
                                      if (!window.confirm('Are you sure you want to cancel this requirement?')) return;

                                      updatePollworkerRequirement({
                                        ...requirement,
                                        satisfiedById: user?.user?.id,
                                        status: PollworkerRequirementStatus.Canceled,
                                      }).finally(loadRequirements);
                                    }}
                                  >
                                    <XCircleIcon className="h-5 w-5" />
                                    <span>Cancel<span className="sr-only">, {requirement.requirementName}</span></span>
                                  </a>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active ? 'bg-ev-red text-white' : 'bg-gray-50 text-gray-900',
                                      'flex items-center space-x-2 block px-3 py-1 text-sm leading-6'
                                    )}
                                    onClick={() => {
                                      if (!window.confirm('Are you sure you want to delete this requirement forever?')) return;
                                      deletePollworkerRequirement(requirement.id).finally(loadRequirements)
                                    }}
                                  >
                                    <XCircleIcon className="h-5 w-5" />
                                    <span>Delete<span className="sr-only">, {requirement.requirementName}</span></span>
                                  </a>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </Flexor>
                    </div>
                  )
                })
              }
            </ul>
          </>
        )
      }
    </div>
  )
}
