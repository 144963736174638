import {takeLatest, put, call, select} from 'redux-saga/effects';

import { checkFeatureFlag as checkFeatureFlagAction, setFeatureFlagCheck } from 'admin/src/actions';
import { checkFeatureFlag } from 'admin/src/fetchers';
import { PayloadAction } from "@reduxjs/toolkit";
import { selectFeatureFlags } from "../selectors";
import { FeatureFlagCheck } from "../types/FeatureFlagCheck";

export function* featureFlagsSaga() {
  yield takeLatest(checkFeatureFlagAction.type, handleCheckFeatureFlag);
}

export function* handleCheckFeatureFlag(action: PayloadAction<string>)  {
  const flagName = action.payload;

  const currentFlags: { [flag: string]: FeatureFlagCheck } = yield select(selectFeatureFlags);

  if (currentFlags[flagName] && !currentFlags[flagName].loading) {
    return;
  }

  try {
    const enabled: boolean = yield call(checkFeatureFlag, flagName);

    yield put(setFeatureFlagCheck({
      loading: false,
      enabled,
      flag: flagName
    }));
  } catch (e) {
    yield put(setFeatureFlagCheck({
      loading: false,
      enabled: false,
      flag: flagName
    }));
  }
}
