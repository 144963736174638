import React from "react";
import Login from "shared/src/components/Login";
import { useAppDispatch, useAppSelector } from "admin/src/hooks";
import { login } from 'admin/src/actions';

export default function EasyOrderLogin() {
  const dispatch = useAppDispatch();
  const errorMessage = useAppSelector(appState => appState.user.loadingUserError);

  const loginHandler = (username: string, password: string) => {
    return new Promise<void>((resolve, reject) => {
      dispatch(login({ username, password }));
      resolve();
    });
  }

  return (
    <Login errorMessage={errorMessage} login={loginHandler} />
  );
}
