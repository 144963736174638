import React, {useEffect, useRef, useState} from 'react';
import {
  selectInventoryEquipTypeSummariesForCustomer,
  selectSelectedCounty
} from 'admin/src/selectors/inventory';
import {setInventoryEquipmentTypeSummaries, setSelectedCounty} from 'admin/src/actions';
import { useAppDispatch, useAppSelector } from 'admin/src/hooks';
import { InventoryEquipment, InventoryEquipTypeSummary } from 'admin/src/types/Inventory';
import {fetchEquipmentTypeSummaries, fetchInventoryEquipmentByType} from 'admin/src/fetchers';
import { AgGridReact } from "ag-grid-react";
import LoadingOverlay from "../ui/LoadingOverlay";
import CustomerDropdown from "./CustomerDropdown";
import {getAccount, selectIsStateUser} from 'admin/src/selectors';
import InventoryNavBar from "./InventoryNavBar";
import {Flexor} from "shared/src/components";
import {MapPinIcon} from "@heroicons/react/24/outline";
import {CustomerInfo} from "shared/src/types/CustomerInfo";
import { SideBarDef, themeBalham, MasterDetailModule, ModuleRegistry, ClientSideRowModelModule } from "ag-grid-enterprise";

ModuleRegistry.registerModules([ClientSideRowModelModule, MasterDetailModule]);

export default EquipmentSummaryTable;

const ColDef = [
  {
    field: 'equipmentTypeName',
    enableRowGroup: true,
    cellRenderer: 'agGroupCellRenderer'
  },
  {
    field: 'spareCount'
  },
  {
    field: 'inUseCount'
  },
  {
    field: 'damagedCount'
  },
  {
    field: 'retiredCount'
  },
  {
    field: 'otherCount'
  }
];

const sidebarConfig: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      toolPanel: 'agColumnsToolPanel',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanelParams: {
        suppressPivotMode: true,
      }
    },
    'filters',
  ],
  defaultToolPanel: '',
  position: 'left',
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  enableCellChangeFlash: false,
};

function EquipmentSummaryTable() {
  const dispatch = useAppDispatch();
  const [equipmentSummariesLoading, setEquipmentSummariesLoading] = useState<boolean>(false);
  const gridRef = useRef<AgGridReact>(null);

  const isStateUser = useAppSelector(selectIsStateUser);
  const selectedCustomer: string = useAppSelector(selectSelectedCounty);
  const summaries: InventoryEquipTypeSummary[] = useAppSelector(state => selectInventoryEquipTypeSummariesForCustomer(state, selectedCustomer)) || [];
  const customer = useAppSelector(getAccount) as CustomerInfo;

  useEffect(() => {
    if (!!selectedCustomer) {
      setEquipmentSummariesLoading(true);

      fetchEquipmentTypeSummaries(selectedCustomer).then(summaries => {
        dispatch(setInventoryEquipmentTypeSummaries(selectedCustomer, summaries));
      }).finally(() => {
        setEquipmentSummariesLoading(false);
      });
    } else if (!isStateUser) { // If they aren't a state user, we need to set the "selectedCounty" to the current user's
      dispatch(setSelectedCounty(customer.id));
    }
  }, [dispatch,  selectedCustomer]);

  const detailCellRendererParams = {
    detailGridOptions: {
      columnDefs: [
        { field: 'ownerName' },
        { field: 'equipmentBarcode' },
        { field: 'equipmentName' },
        { field: 'serialNumber' },
        { field: 'manufacturer' },
        { field: 'earlyVotingMachine' },
        { field: 'firmwareVersion' },
        { field: 'model' },
        {
          field: 'status',
          cellRenderer: ({ data }: { data: InventoryEquipment }) => mapStatusToText(data.status)
        },
        {
          field: 'datePurchased',
          cellDataType: 'dateString',
          hide: true
        },
        {
          field: 'dateRetired',
          cellDataType: 'dateString',
          hide: true
        },
        {
          field: 'dateLastTested',
          cellDataType: 'dateString',
          hide: true
        },
        {
          field: 'inRotation',
          hide: true
        }
      ],
      defaultColDef: {
        sortable: true,
        filter: true
      },
      sideBar: sidebarConfig
    },
    getDetailRowData: (params: any) => {
      fetchInventoryEquipmentByType(selectedCustomer, params.data.equipmentTypeId).then((equipment: InventoryEquipment[]) => {
        params.successCallback(equipment);
      });
    }
  }

  return (
    <div data-testid="equipment-summary-table" className="ag-theme-balham h-full w-full">
      <InventoryNavBar />
      {isStateUser && (
        <Flexor className="relative z-50 w-full border-y divide-gray-400/80 bg-white border-gray-300">
          <div className="flex px-2 space-x-2">
            <div className="flex items-center">
              <MapPinIcon className="h-5" />
              <CustomerDropdown className="m-4" />
            </div>
          </div>
        </Flexor>
      )}
      <AgGridReact
        ref={gridRef}
        theme={themeBalham}
        gridId="deployment-equipment-summary-table"
        loading={equipmentSummariesLoading}
        getRowId={params => params.data.equipmentTypeId}
        loadingOverlayComponentParams={{isPreview: false, animate: true, infoText: 'Loading deployment data...'}}
        loadingOverlayComponent={LoadingOverlay}
        allowContextMenuWithControlKey
        animateRows={false}
        rowData={summaries}
        masterDetail
        noRowsOverlayComponent={() => (<div>No data found</div>)}
        columnDefs={ColDef}
        enableBrowserTooltips
        sideBar={sidebarConfig}
        defaultColDef={defaultColDef}
        detailCellRendererParams={detailCellRendererParams}
      />
    </div>
  );
}

function mapStatusToText(status: number): string {
  switch (status) {
    case 0:
      return 'Spare';
    case 1:
      return 'Active';
    case 2:
      return 'Damaged';
    case 3:
      return 'Retired';
    case 4:
    default:
      return 'Other';
  }
}
