import { fetchApi } from 'shared/src/fetchers';

export {
  checkFeatureFlag
}

/*
 * This function checks if a feature flag is enabled for the current user in our third-party feature flagging service (StatSig at time of writing).
 * You probably don't want to be calling this yourself. You should be using the `useIsFeatureFlagEnabled` hook instead, the <FeatureFlagCheck /> component, or
 * in a few cases you may want to use the `checkFeatureFlag` action creator from admin/src/actions.
 */
async function checkFeatureFlag(flagName: string) {
  const response = await fetchApi(`/di-api/v1/customers/featureFlags/${flagName}`, {});
  const enabled: boolean = await response.json();

  return enabled;
}
