import { combineReducers, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import type { Election } from 'admin/src/types';

type ElectionsAppState = {
  isLoading: boolean
  elections: Election[]
  electionsByActiveStatus: { active: Election[], inactive: Election[] }
}

const initialState: ElectionsAppState = {
  isLoading: false,
  elections: [],
  electionsByActiveStatus: { active: [], inactive: [] }
}

const electionsAppSlice = createSlice({
  name: 'electionsApp',
  initialState,
  reducers: {
    loadElections: (state) => {
      state.isLoading = true;
    },
    setElections: (state, action: PayloadAction<Election[]>) => {
      const electionsByActiveStatus = action.payload.reduce((acc: ElectionsAppState['electionsByActiveStatus'], election: Election) => {
        if (election.currentElection) acc.active.push(election);
        if (!election.currentElection) acc.inactive.push(election);

        return acc;
      }, {active: [], inactive: []});

      state.elections = action.payload;
      state.electionsByActiveStatus = electionsByActiveStatus;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  },
});

export const {
  setElections,
  setLoading,
  loadElections,
} = electionsAppSlice.actions;

export default combineReducers({
  elections: electionsAppSlice.reducer,
});
