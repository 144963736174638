import React, {Fragment, useEffect, useState} from "react";
import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import dayjs from "dayjs";
import {classNames} from "shared/src/utils/classNames";
import { Flexor, TippyContent } from "shared/src/components"
import {
  CalendarDaysIcon, CheckBadgeIcon,
  ChevronRightIcon,
  MapPinIcon, NoSymbolIcon,
  PencilSquareIcon, TicketIcon, TrashIcon,
  UserCircleIcon
} from "@heroicons/react/24/outline";
import {Menu, Transition} from "@headlessui/react";
import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import {useSelector} from "react-redux";
import Spinner from "shared/src/components/Spinner";
import ScheduleEditor from "./ScheduleEditor";
import { Schedule } from "../../../../types/Pollworker/PollworkerWorkHistory";
import useSelectedPollworker from "../../../../hooks/useSelectedPollworker";
import { deletePollworkerSchedule, getPollworkerSchedulesList } from "../../../../fetchers";
import { PollworkerReimbursementStatus } from "../../../../types/Pollworker/PollworkerModuleInfo";
import { AppState } from 'admin/src/store';
import DateFormatter from "shared/src/components/DateFormatter";
import PanelHeader from "../ui/PanelHeader";
import PanelSlideout, { PanelSlideoutHeader } from "../ui/PanelSlideout";
import { groupBy } from "lodash";
import Tippy from "@tippyjs/react";
import { Edit } from "lucide-react";

enum PollworkerScheduleStatus {
  Scheduled = 0,
  Approved = 1,
  Rejected = 3,
  Cancelled = 2,
}

enum UserConfirmationStatus {
  NotResponded = 0,
  Confirmed = 1,
  Rejected = 2
}

export default function PollworkerSchedulesPanel({onSave}: {onSave?: () => void}) {
  const [schedules, setSchedules] = useState<{past: Schedule[], future: Schedule[]}>({past: [], future: []});
  const [expandedScheduleId, setExpandedScheduleId] = useState<string>();
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const [loadingSchedules, setLoadingSchedules] = useState<boolean>(false);
  const [scheduleToEdit, setScheduleToEdit] = useState<Schedule>();
  const [showScheduleEditor, setShowScheduleEditor] = useState<boolean>(false);
  const pollworker = useSelectedPollworker();

  useEffect(loadPollworkerSchedules, [moduleInfo, pollworker]);

  function loadPollworkerSchedules() {
    if (!moduleInfo || !pollworker) return;

    setLoadingSchedules(true);
    getPollworkerSchedulesList(pollworker.keyEVUserId, moduleInfo.ElectionId)
      .then((resp) => {
        const today = dayjs();
        const schedules = groupBy(resp.data, ((schedule: Schedule) => schedule.workDate));

        const schedulesPastAndFuture = Object.keys(schedules).reduce((acc: {past: Schedule[], future: Schedule[]}, scheduleDate: string) => {
          const date = dayjs(scheduleDate);

          if (date.isAfter(today) || date.isSame(today)) {
            acc.future.push(...schedules[scheduleDate]);
          } else {
            acc.past.push(...schedules[scheduleDate]);
          }

          return acc;
        }, {past: [], future: []});

        schedulesPastAndFuture.past = schedulesPastAndFuture.past.sort((scheduleA: Schedule, scheduleB: Schedule) => {
          const aDay = dayjs(scheduleA.workDate);
          const bDay = dayjs(scheduleB.workDate);

          return (aDay.isBefore(bDay)) ? 1 : -1;
        });

        schedulesPastAndFuture.future = schedulesPastAndFuture.future.sort((scheduleA: Schedule, scheduleB: Schedule) => {
          const aDay = dayjs(scheduleA.workDate);
          const bDay = dayjs(scheduleB.workDate);

          return (aDay.isBefore(bDay)) ? -1 : 1;
        });

        setSchedules(schedulesPastAndFuture);
      })
      .finally(() => setLoadingSchedules(false));
  }

  function renderUserConfirmationStatus(schedule: Schedule) {
    let status = <div>No response</div>;

    switch (schedule.userConfirmationStatus) {
      case UserConfirmationStatus.Confirmed:
        status = <div className=' text-green-500'>Confirmed</div>;
        break;
      case UserConfirmationStatus.Rejected:
        status = <div className=' text-red-500'>Rejected</div>;
        break;
    }

    return status;
  }

  function getStatusColor(schedule: Schedule) {
    switch (schedule.status) {
      case PollworkerScheduleStatus.Scheduled:
        return 'bg-blue-50 text-blue-700 ring-blue-600/20';
      case PollworkerScheduleStatus.Approved:
        return 'bg-green-50 text-green-700 ring-green-600/20';
      case PollworkerScheduleStatus.Cancelled:
      case PollworkerScheduleStatus.Rejected:
        return 'bg-red-50 text-red-700 ring-red-600/20';
    }
  }

  function deleteSchedule(schedule: Schedule) {
    deletePollworkerSchedule(schedule).finally(() => {
      loadPollworkerSchedules();
      onSave && onSave();
    });
  }

  function closeScheduleEditor() {
    setShowScheduleEditor(false);
    loadPollworkerSchedules();
    setScheduleToEdit(undefined);
    onSave && onSave();
  }

  function renderSchedules(schedules: Schedule[]) {
    return schedules.map((schedule, idx) => {
      const showDate = !(idx > 0 && schedules[idx - 1].workDate === schedule.workDate);
      const expanded = schedule.id === expandedScheduleId;
      const locationName = [schedule.precinctInfo?.precinctName, schedule.locationInfo?.locationName].filter(p => p).join(' ');
      const { userConfirmationStatusReason, userConfirmationStatus } = schedule;
      const isApproved = PollworkerReimbursementStatus.Approved === schedule.status;

      const EditMenuOption = ({ active }: { active: boolean }) => (
        isApproved ? <Tippy content={isApproved && <TippyContent>Approved work assignments cannot be edited. Please reject expenses to edit.</TippyContent>}>
          <a
            href="#"
            className={classNames(
              active ? 'bg-gray-100 text-gray-800' : 'text-gray-700',
              'flex items-center space-x-2 px-3 py-1 text-sm leading-6'
            )}
          >
            <PencilSquareIcon className="h-5 w-5" />
            <span>Edit<span className="sr-only">, {schedule.id}</span></span>
          </a>
        </Tippy> : <a
          href="#"
          className={classNames(
            active ? 'bg-gray-100 text-gray-800' : 'text-gray-700',
            'flex items-center space-x-2 px-3 py-1 text-sm leading-6'
          )}
          onClick={() => {
            setScheduleToEdit(schedule);
            setShowScheduleEditor(true);
          }}
        >
          <PencilSquareIcon className="h-5 w-5" />
          <span>Edit<span className="sr-only">, {schedule.id}</span></span>
        </a>
      );

      return (
        <div key={schedule.id} className={classNames("hover:bg-gray-100 pb-2")}>
          {
            showDate ? (
              <SectionSubSubHeading className="p-2 border-l-4 border-b border-gray-200">
                <DateFormatter dateString={schedule.workDate} withTime={false} />
              </SectionSubSubHeading>
            ) : null
          }
          <div className="flex justify-start items-start space-x-2 p-2">
            <div className="pt-2" onClick={() => setExpandedScheduleId(expanded ? undefined : schedule.id)}>
              <ChevronRightIcon className={classNames(expanded ? 'rotate-90' : '', "transition-transform h-4 w-4")} />
            </div>
            <div className="w-full">
              <div className="flex items-center justify-between py-2 text-sm font-medium">
                <dt className="text-xs space-y-1 font-medium text-gray-700 w-full">
                  <Flexor justify="start" className="space-x-2 w-full truncate break-all">
                    <UserCircleIcon className="size-4" />
                    {schedule.roleInfo?.roleName ? <div>{schedule.roleInfo?.roleName}</div> : <div className="italic">No role info</div>}
                  </Flexor>
                  <Flexor justify="start" className="space-x-2 w-full truncate break-all">
                    <MapPinIcon className="size-4" />
                    {locationName ? <div>{locationName}</div> : <div className='italic'>No location</div>}
                  </Flexor>
                  <Flexor justify="start" className="space-x-2 w-full truncate break-all font-semibold">
                    <CheckBadgeIcon className="size-4" />
                    {renderUserConfirmationStatus(schedule)}
                  </Flexor>
                  <div className="flex items-center space-x-2">
                    <CalendarDaysIcon className="h-4 w-4" />
                    <time
                      className={classNames('text-gray-700', "block font-normal")}
                      title={`${schedule.startTime} to ${schedule.endTime}`}
                    >
                      {schedule.startTime} to {schedule.endTime}
                    </time>
                  </div>
                </dt>
                <span className={classNames(getStatusColor(schedule), "inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset")}>
                  {PollworkerScheduleStatus[schedule.status]}
                </span>
                <Menu as="div" className="relative">
                  <Menu.Button className="block p-2.5 text-gray-500 hover:text-gray-900">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-5 z-10 mt-0 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item disabled={isApproved}>
                        {({ active }) => (
                          <EditMenuOption active={active} />
                        )}
                      </Menu.Item>
                      <Menu.Item disabled={PollworkerReimbursementStatus.New !== schedule.status}>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-ev-red text-white' : 'bg-gray-50 text-gray-900',
                              'flex items-center space-x-2 px-3 py-1 text-sm leading-6'
                            )}
                            onClick={() => deleteSchedule(schedule)}
                          >
                            <TrashIcon className="h-5 w-5" />
                            <span>Delete<span className="sr-only">, {schedule.id}</span></span>
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              {
                expanded ? (
                  <div className="space-y-2">
                    <div className="text-xs">
                      <span className="leading-6 text-gray-800 font-semibold">Confirmation reason</span>
                      {
                        userConfirmationStatusReason ? (
                          <div className="border-l-2 border-gray-400 pl-2 whitespace-pre">{userConfirmationStatusReason}</div>
                        ) : (
                          <div className="italic">
                            {
                              userConfirmationStatus === UserConfirmationStatus.NotResponded ? (
                                'Pollworker has not responded'
                              ) : (
                                'No reason given'
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                    <div className="text-xs">
                      <span className="leading-6 text-gray-800 font-semibold">Notes</span>
                      {
                        schedule.notes ? (
                          <div className="border-l-2 border-gray-400 pl-2 whitespace-pre">{schedule.notes}</div>
                        ) : (
                          <div className="italic">No notes</div>
                        )
                      }
                    </div>
                  </div>
                ) : null
              }
            </div>
          </div>
        </div>
      )
    })
  }

  const showInitLoading = loadingSchedules && !schedules.past && !schedules.future;

  return (
    <>
      <PanelSlideout show={showScheduleEditor}>
        <PanelSlideoutHeader close={closeScheduleEditor}>
          <div className="flex items-start justify-between space-x-3">
            <div className="space-y-1">
              <SectionSubSubHeading>{scheduleToEdit ? 'Edit' : 'Add'} Schedule for {pollworker.userInfo.firstName} {pollworker.userInfo.lastName}</SectionSubSubHeading>
              <Flexor justify="start" className="space-x-2" items="center">
                <TicketIcon className="h-4 w-4" />
                <span className="text-xs font-semibold">{moduleInfo?.Election?.ElectionName}</span>
              </Flexor>
            </div>
          </div>
        </PanelSlideoutHeader>
        <ScheduleEditor schedule={scheduleToEdit} toggle={() => closeScheduleEditor()} />
      </PanelSlideout>
      <div>
        <PanelHeader title='Schedules' loading={loadingSchedules} reload={loadPollworkerSchedules} onAdd={() => setShowScheduleEditor(true)} />
        {
          showInitLoading ? (
            <Flexor justify="center" className="space-x-2 mt-10">
              <Spinner show />
              <SectionSubSubHeading>Loading data...</SectionSubSubHeading>
            </Flexor>
          ) : (
            <>
              {
                !Object.keys(schedules).length ? (
                  <Flexor justify="center" className="space-x-2 mt-10">
                    <NoSymbolIcon className="h-6 w-6" />
                    <SectionSubSubHeading>No data found...</SectionSubSubHeading>
                  </Flexor>
                ) : (
                  <div className={classNames(loadingSchedules ? 'opacity-50' : '', 'transition-opacity')}>
                    <div className="p-2 bg-gray-100 border-b-2 border-gray-200">
                      <SectionSubSubHeading>Upcoming</SectionSubSubHeading>
                    </div>
                    <div className="divide-y divide-gray-200">
                      {renderSchedules(schedules.future)}
                    </div>
                    <div className="p-2 bg-gray-100 border-b-2 border-gray-200">
                      <SectionSubSubHeading>Past</SectionSubSubHeading>
                    </div>
                    <div className="divide-y divide-gray-200">
                      {renderSchedules(schedules.past)}
                    </div>
                  </div>
                )
              }
            </>
          )
        }
      </div>
    </>
  )
}
