import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "shared/src/utils"
import { twMerge } from "tailwind-merge";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none",
  {
    variants: {
      variant: {
        default: 'rounded-md flex space-x-2 items-center bg-ev-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ev-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ev-red disabled:bg-gray-300',
        primary: "text-white bg-gradient-to-r from-action-gradient-from to-action-gradient-to hover:to-action-hover hover:from-action-hover disabled:to-state-disabled disabled:from-state-disabled disabled:text-white",
        secondary: "bg-white border-2 border-action text-action hover:text-action-hover hover:border-action-hover disabled:border-state-disabled disabled:text-state-disabled",
        tertiary: "text-action hover:text-action-hover disabled:text-state-disabled px-[1px]",
        quaternary: "text-action hover:text-action-hover disabled:text-state-disabled underline-offset-4 underline px-[0px]",
        icon: "size-2xl p-[0px] rounded-full text-white bg-gradient-to-r from-action-gradient-from to-action-gradient-to hover:to-action-hover hover:from-action-hover disabled:to-state-disabled disabled:from-state-disabled disabled:text-white",
      },
      size: {
        sm: "h-ml text-xs px-base py-smd space-x-1",
        default: "h-2xl px-smd py-base space-x-1.5 ring-inset",
        zero: "h-auto p-0 space-x-1.5 ring-inset",
        lg: "h-5xl text-xl px-md py-smd space-x-2 ring-inset",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean,
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
    className,
    variant,
    size,
    asChild = false,
    ...props
  }, ref) => {
    const Comp = asChild ? Slot : "button"

    return (
      <Comp
        className={twMerge(cn(buttonVariants({ variant, size, className })))}
        ref={ref}
        {...props}
      />
    )
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
