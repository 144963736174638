import { DataburstResponse } from "../types/DataburstResponse";
import { useEffect, useState } from "react";

export function useImage(fetcher: (id: string) => Promise<DataburstResponse<{data: string}>>, id?: string) {
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState<string>();
  const [imageSrc, setImageSrc] = useState<string>();

  useEffect(() => {
    setImageSrc(undefined);

    if (id) getImage(id);
  }, [id]);

  async function getImage(id: string) {
    setLoading(true);
    setImageError(undefined);

    return fetcher(id)
    .then(({data}) => {
      if (!data.data) return setImageError('No image was found');

      setImageSrc(`data:image/jpg;base64,${data.data}`);
      setImageError('No image was found');
    })
    .catch(() => {
      setImageError('No image was found');
    })
    .finally(() => setLoading(false));
  }

  function reloadImage(id: string) {
    setLoading(true);
    setImageError(undefined);
    setImageSrc(undefined);
    return getImage(id);
  }

  return {loading, src: imageSrc, error: imageError, reloadImage};
}
