import { createContext, useContext } from "react";
import type { NavItem } from "./ShellNav";

export type ShellTheme = 'cf' | 'sa' | 'el' | 'pw';

type ShellContextProps = {
  title?: string,
  nav: NavItem[],
  activeNav?: NavItem,
  theme: ShellTheme,
  sidebar: {
    open: boolean,
    setOpen: (open: boolean) => void,
    openMobile: boolean,
    setOpenMobile: (open: boolean) => void,
  }
  help: {
    enabled: boolean,
    open: boolean,
    setOpen: (open: boolean) => void,
  }
}

const noop = () => {};

const ShellContext = createContext<ShellContextProps>({
  nav: [],
  title: '',
  theme: 'sa',
  sidebar: {open: true, setOpen: noop, openMobile: false, setOpenMobile: noop},
  help: {enabled: false, open: false, setOpen: noop},
});

export function useShell() {
  const context = useContext(ShellContext);

  if (!context) {
    throw new Error("useShell must be used within a ShellContext.");
  }

  return context;
}

export default ShellContext;
