import React, {
  forwardRef,
  InputHTMLAttributes,
  Suspense,
  useEffect, useMemo,
  useRef,
  useState,
} from "react";
import {
  getCustomerById,
  getEmployeeByUserId,
  updateCustomer,
  uploadCustomerLogo,
} from "admin/src/fetchers/setupAndAdmin";
import { useAppSelector } from "admin/src/hooks";
import {
  BlobImage,
  ImageFromFileReader,
} from "admin/src/screens/store/StoreItemImage";
import { Flexor, Spinner } from "shared/src/components";
import {
  Label,
  Button,
  Input,
  Dropdown,
  TooltipTrigger,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  Toaster, toaster as toast, Alert, AlertTitle, AlertDescription,
} from "shared/src/components/ui/new";
import { STATES } from "shared/src/utils/data/usStates";
import PhoneInput from "react-phone-number-input/input";
import { FieldValues, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "shared/src/components/ui/Form";
import { CustomerInfo } from "shared/src/types/CustomerInfo";
import { phoneNumberFormatRemover } from "shared/src/utils";
import { EmployeeInfo } from "../../../types/User";
import { ArrowPathIcon, ExclamationTriangleIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import LoadingAdministration from "./LoadingAdministration";

const basicInfoSchema = z.object({
  customerName: z
    .string()
    .min(1, { message: "Customer name is required" })
    .default(""),
  address1: z
    .string()
    .min(1, { message: "Street address is required" })
    .default(""),
  address2: z.string().optional().default(""),
  city: z.string().min(1, { message: "City is required" }).default(""),
  state: z.string().min(1, { message: "State is required" }).default(""),
  zip: z
    .string()
    .min(1, { message: "Zip is required" })
    .length(5, { message: "Zip must be 5 digits" })
    .default(""),
  phone: z.string().optional().default(""),
  twilioPhone: z.string().optional().default(""),
  fax: z.string().optional().default(""),
  fullStateName: z.string().optional().default(""),
  timeZone: z.string().optional().default(""),
  defaultFromEmailAddress: z.string().optional().default(""),
  customerType: z.number().optional().default(0),
});

interface TimeZone {
  value: string;
  label: string;
}

const TimeZones: TimeZone[] = [
  { value: "-5", label: "Eastern Time" },
  { value: "-6", label: "Central Time" },
  { value: "-7", label: "Mountain Time" },
  { value: "-8", label: "Pacific Time" },
  { value: "-9", label: "Alaska" },
  { value: "-10", label: "Hawaii" },
];

export default function BasicInfo() {
  const { user } = useAppSelector((state) => state.user);
  const [logoFile, setLogoFile] = useState<File>();
  const logoFileRef = useRef<HTMLInputElement>(null);
  const [saving, setSaving] = useState(false);
  const [customerInfo, setCustomerInfo] = useState<CustomerInfo>();
  const [loadingCustomerInfo, setLoadingCustomerInfo] = useState(true);
  const [permissionToEdit, setPermissionToEdit] = useState(false);
  const [subscriptions, setSubscriptions] = useState({
    easyFileClient: false,
    easyInventoryClient: false,
    easyPollWorkerClient: false,
  });

  const { keyCustomerId, id: userId } = useMemo((): EmployeeInfo => user || ({} as EmployeeInfo), [user]);

  const form = useForm<z.infer<typeof basicInfoSchema>>({
    resolver: zodResolver(basicInfoSchema),
    defaultValues: async () => {
      setLoadingCustomerInfo(true);
      let customerInfo: CustomerInfo = await getCustomerById().finally(() => setLoadingCustomerInfo(false));

      if (!customerInfo) {
        toast.error("Failed to load customer information. Please try again.");
      }

      setCustomerInfo(customerInfo);

      setSubscriptions({
        easyFileClient: customerInfo?.easyFileClient,
        easyInventoryClient: customerInfo?.easyInventoryClient,
        easyPollWorkerClient: customerInfo?.easyPollWorkerClient,
      });

      return {
        customerName: customerInfo?.customerName,
        address1: customerInfo?.address1,
        address2: customerInfo?.address2,
        city: customerInfo?.city,
        state: customerInfo?.state,
        zip: customerInfo?.zip,
        phone: customerInfo?.phone,
        twilioPhone: customerInfo?.twilioPhone,
        fax: customerInfo?.fax,
        timeZone: customerInfo?.timeZone,
        defaultFromEmailAddress: customerInfo?.defaultFromEmailAddress,
        fullStateName: customerInfo?.fullStateName,
        customerType: customerInfo?.customerType,
      };
    },
  });

  useEffect(() => {
    if (!userId) return;

    getEmployeeByUserId(userId)
      .then((response) => {
        if (!response) return setPermissionToEdit(false);
        setPermissionToEdit(response.securityLevelInfo.systemAdmin);
      })
      .catch(() => {
        return setPermissionToEdit(false);
      });
  }, [keyCustomerId]);

  function submit(data: FieldValues) {
    setSaving(true);

    data.phone = phoneNumberFormatRemover(data.phone);
    data.twilioPhone = phoneNumberFormatRemover(data.twilioPhone);
    data.fax = phoneNumberFormatRemover(data.fax);

    const updateUserPromise = updateCustomer(data)
      .then((resp) => {
        if (!resp) throw new Error();
        return resp;
      })
      .finally(() => {
        setSaving(false);
      });

    toast.promise(updateUserPromise, {
      loading: 'Saving account information...',
      success: 'Account information saved!',
      error: 'Failed to save, please try again',
    });
  }

  useEffect(() => {
    if (keyCustomerId && logoFile) {
      const uploadLogoPromise = uploadCustomerLogo(keyCustomerId + '123', logoFile)
        .then((response) => {
          if (!response) throw new Error();
        }).catch(() => {}); // toast.promise will handle this

      toast.promise(uploadLogoPromise, {
        loading: 'Saving logo...',
        success: 'Logo saved!',
        error: 'Failed to save logo, please try again',
      });
    }
  }, [logoFile, keyCustomerId]);

  if (loadingCustomerInfo || !customerInfo) {
    if (loadingCustomerInfo) {
      return (
        <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'>
          <Toaster position='bottom-center'/>
          <LoadingAdministration loadingMessage='Loading...'/>
        </div>
      );
    }

    return (
      <div className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-5'>
        <h2 className='font-semibold text-lg'>We couldn't load the account information</h2>
        <div className='flex justify-center'>
          <Button onClick={() => window.location.reload()}>
            <ArrowPathIcon />
            Try again
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div>
      <Toaster position='bottom-center' />
      <div className="relative">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(submit)}>
            <div className="py-5 justify-self-center space-y-5 sm:max-w-3xl w-full divide-y">
              <div className="justify-self-center w-full">
                {
                  !permissionToEdit ? (
                    <Alert className='space-y-1 my-5'>
                      <ExclamationTriangleIcon className='size-4' />
                      <AlertTitle>Read only</AlertTitle>
                      <AlertDescription>You only have permission to view account data but not to edit.</AlertDescription>
                    </Alert>
                  ) : null
                }
                <h2 className='font-semibold'>Customer information</h2>
                <h4 className='text-sm text-muted-foreground'>Use this form to keep your organizations information up-to-date</h4>
                <div className="mt-10 grid gap-x-6 gap-y-4 sm:grid-cols-6">
                  <div className="col-span-full max-h-48 flex justify-between items-center w-full">
                    <Label htmlFor="name">Logo</Label>
                    <Flexor>
                      <Button
                        onClick={() => logoFileRef.current?.click()}
                        size="sm"
                        type="button"
                        variant='link'
                      >
                        Edit
                      </Button>
                      <Suspense fallback={<Spinner large={true} show/>}>
                        {
                          logoFile ? (
                            <ImageFromFileReader
                              alt="Select image to upload"
                              classes="size-12 object-contain"
                              file={logoFile}
                            />
                          ) : (
                            <BlobImage
                              alt="Customer Logo"
                              className="size-12 object-contain"
                              customerId={user?.keyCustomerId}
                              id="logo.png"
                              skipCache
                            />
                          )
                        }
                      </Suspense>
                      <input
                        disabled={!permissionToEdit}
                        onChange={({ target: { files } }) => {
                          if (files?.length) {
                            setLogoFile(files[0]);
                          }
                        }}
                        ref={logoFileRef}
                        accept="image/*"
                        className="hidden"
                        data-testid="logoFile"
                        type="file"
                      />
                    </Flexor>
                  </div>

                  <div className="sm:col-span-6">
                    <FormLabel htmlFor="customerName">
                      Organization name
                    </FormLabel>
                    <FormField
                      control={form.control}
                      name="customerName"
                      render={() => {
                        return (
                          <FormItem>
                            <FormControl>
                              <Input
                                disabled={!permissionToEdit}
                                className="w-full"
                                id="customerName"
                                placeholder="Organization name"
                                type="text"
                                {...form.register("customerName")}
                              />
                            </FormControl>
                            <FormMessage/>
                          </FormItem>
                        );
                      }}
                    />
                  </div>

                  <div className="sm:col-span-6">
                    <FormLabel htmlFor="address1">Street address</FormLabel>
                    <FormField
                      control={form.control}
                      name="address1"
                      render={() => {
                        return (
                          <FormItem>
                            <FormControl>
                              <Input
                                disabled={!permissionToEdit}
                                autoComplete="address-level1"
                                className="w-full"
                                id="address1"
                                placeholder="Enter Address Line 1 Here"
                                type="text"
                                {...form.register("address1")}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <FormLabel htmlFor="address2">Street address 2</FormLabel>
                    <FormField
                      control={form.control}
                      name="address2"
                      render={() => {
                        return (
                          <FormItem>
                            <FormControl>
                              <Input
                                disabled={!permissionToEdit}
                                autoComplete="address-level2"
                                className="w-full"
                                id="address2"
                                type="text"
                                placeholder="Enter Address Line 2 Here"
                                {...form.register("address2")}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        );
                      }}
                    />
                  </div>

                  <div className="sm:col-span-6 grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <FormLabel htmlFor="city">City</FormLabel>
                      <FormField
                        control={form.control}
                        name="city"
                        render={() => {
                          return (
                            <FormItem>
                              <FormControl>
                                <Input
                                  disabled={!permissionToEdit}
                                  autoComplete="city"
                                  className="w-full"
                                  id="city"
                                  placeholder="City"
                                  type="text"
                                  {...form.register("city")}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <FormLabel htmlFor="state">State</FormLabel>
                      <FormField
                        control={form.control}
                        name="state"
                        render={() => {
                          return (
                            <FormItem>
                              <FormControl>
                                <Dropdown
                                  disabled={!permissionToEdit}
                                  onChange={(e) =>
                                    form.setValue("state", e.target.value)
                                  }
                                  options={STATES.map((state) => ({
                                    label: `${state.state} (${state.abbr})`,
                                    value: state.abbr,
                                  }))}
                                  selectedValue={form.watch("state")}
                                  autoComplete="state"
                                  className="w-full"
                                  emptyPlaceholder={"Select a state"}
                                  id="state"
                                  placeholder="State"
                                  variant="new"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-1">
                      <FormLabel htmlFor="zip">Zip</FormLabel>
                      <FormField
                        control={form.control}
                        name="zip"
                        render={() => {
                          return (
                            <FormItem>
                              <FormControl>
                                <Input
                                  disabled={!permissionToEdit}
                                  autoComplete="postal-code"
                                  className="w-full"
                                  id="zip"
                                  maxLength={5}
                                  minLength={5}
                                  placeholder="12345"
                                  type="number"
                                  {...form.register("zip")}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6 grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-2">
                      <FormLabel htmlFor="phone">Main phone</FormLabel>
                      <FormField
                        control={form.control}
                        name="phone"
                        render={() => {
                          return (
                            <FormItem>
                              <FormControl>
                                <PhoneInput
                                  disabled={!permissionToEdit}
                                  className="w-full"
                                  country="US"
                                  id="phone"
                                  placeholder="(___)-___-____"
                                  withCountryCallingCode={false}
                                  {...form.register("phone")}
                                  onChange={() => form.register("phone")}
                                  inputComponent={forwardRef<
                                    HTMLInputElement,
                                    InputHTMLAttributes<HTMLInputElement>
                                  >((props, ref) => {
                                    return (
                                      <Input
                                        ref={ref}
                                        {...props}
                                      />
                                    );
                                  })}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <FormLabel htmlFor="twilioPhone">Cell phone</FormLabel>
                      <FormField
                        control={form.control}
                        name="twilioPhone"
                        render={() => {
                          return (
                            <FormItem>
                              <FormControl>
                                <PhoneInput
                                  disabled={!permissionToEdit}
                                  className="w-full"
                                  country="US"
                                  id="twilioPhone"
                                  placeholder="(___)-___-____"
                                  withCountryCallingCode={false}
                                  {...form.register("twilioPhone")}
                                  onChange={() => form.register("twilioPhone")}
                                  inputComponent={forwardRef<
                                    HTMLInputElement,
                                    InputHTMLAttributes<HTMLInputElement>
                                  >((props, ref) => {
                                    return (
                                      <Input
                                        ref={ref}
                                        {...props}
                                      />
                                    );
                                  })}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-2">
                      <FormLabel htmlFor="faxPhone">Fax</FormLabel>
                      <FormField
                        control={form.control}
                        name="fax"
                        render={() => {
                          return (
                            <FormItem>
                              <FormControl>
                                <PhoneInput
                                  disabled={!permissionToEdit}
                                  className="w-full"
                                  country="US"
                                  id="faxPhone"
                                  placeholder="(___)-___-____"
                                  withCountryCallingCode={false}
                                  {...form.register("fax")}
                                  onChange={() => form.register("fax")}
                                  inputComponent={forwardRef<
                                    HTMLInputElement,
                                    InputHTMLAttributes<HTMLInputElement>
                                  >((props, ref) => {
                                    return (
                                      <Input
                                        ref={ref}
                                        {...props}
                                      />
                                    );
                                  })}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-6 grid grid-cols-6 gap-6">
                    <div className="sm:col-span-3 col-span-6">
                      <FormLabel htmlFor="fullStateName">
                        Full state name
                      </FormLabel>
                      <FormField
                        control={form.control}
                        name="fullStateName"
                        render={() => {
                          return (
                            <FormItem>
                              <FormControl>
                                <Input
                                  disabled={!permissionToEdit}
                                  className="w-full"
                                  id="fullStateName"
                                  placeholder="Full name of your US state"
                                  type="text"
                                  {...form.register("fullStateName")}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>

                    <div className="sm:col-span-3 col-span-6">
                      <Flexor justify='start' className='space-x-1'>
                        <FormLabel htmlFor="defaultFromEmailAddress">
                          Default from email address
                        </FormLabel>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <QuestionMarkCircleIcon className='size-4' />
                            </TooltipTrigger>
                            <TooltipContent>
                              Specify the email you would like to have EasyVote send emails from
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </Flexor>
                      <FormField
                        control={form.control}
                        name="defaultFromEmailAddress"
                        render={() => {
                          return (
                            <FormItem>
                              <FormControl>
                                <Input
                                  disabled={!permissionToEdit}
                                  className="w-full"
                                  id="defaultFromEmailAddress"
                                  placeholder="john@example.com"
                                  type="text"
                                  {...form.register("defaultFromEmailAddress")}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>

                    <div className="sm:col-span-6 col-span-6">
                      <FormLabel htmlFor="timeZone">Timezone</FormLabel>
                      <FormField
                        control={form.control}
                        name="timeZone"
                        render={() => {
                          return (
                            <FormItem>
                              <FormControl>
                                <Dropdown
                                  disabled={!permissionToEdit}
                                  onChange={(e) =>
                                    form.setValue("timeZone", e.target.value)
                                  }
                                  options={TimeZones.map((timeZones) => ({
                                    label: timeZones.label,
                                    value: timeZones.value,
                                  }))}
                                  autoComplete="timeZone"
                                  emptyPlaceholder='Select a time zone'
                                  id="timeZone"
                                  placeholder="Time zone"
                                  selectedValue={form.watch('timeZone')}
                                  variant="new"
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="space-y-5 pt-5">
                <div>
                  <h2 className="font-semibold">Subscriptions</h2>
                  <h4 className="text-sm text-muted-foreground">View the products you are and aren't subscribed to</h4>
                </div>

                <div className='space-y-3'>
                  <Flexor justify="between">
                    <>
                      <span>EasyCampaignFinance</span>
                      {
                        subscriptions.easyFileClient ? (
                          <Flexor>
                            <div className="mr-0.5 ring-4 ring-green-100 size-2 bg-green-500 rounded-full"></div>
                            <span className="ml-2 text-sm" data-testid="campaignFinanceSubscribed">
                              Subscribed
                            </span>
                          </Flexor>
                        ) : (
                          <Button
                            onClick={() => {
                              window.open("https://www.easyvotesolutions.com/solutions/campaign-finance-management/?ref=desktopapp", "_blank");
                            }}
                            size="sm"
                            data-testid="campaignFinanceLearnMore"
                            type="button"
                            variant="outline"
                          >
                            Learn More
                          </Button>
                        )
                      }
                    </>
                  </Flexor>
                  <Flexor justify="between">
                    <span>EasyPollWorker</span>
                    {
                      subscriptions.easyPollWorkerClient ? (
                        <Flexor>
                          <div className="mr-0.5 ring-4 ring-green-100 size-2 bg-green-500 rounded-full"></div>
                          <span
                            className="ml-2 text-sm"
                            data-testid="pollWorkerSubscribed"
                          >
                            Subscribed
                          </span>
                        </Flexor>
                      ) : (
                        <Button
                          onClick={() => {
                            window.open("https://www.easyvotesolutions.com/solutions/poll-worker-management/?ref=desktopapp", "_blank");
                          }}
                          data-testid="pollWorkerLearnMore"
                          size="sm"
                          type="button"
                          variant="outline"
                        >
                          Learn More
                        </Button>
                      )
                    }
                  </Flexor>
                  <Flexor justify="between">
                    <span>EasyInventory</span>
                    {
                      subscriptions.easyInventoryClient ? (
                        <Flexor>
                          <div className="mr-0.5 ring-4 ring-green-100 size-2 bg-green-500 rounded-full"></div>
                            <span
                              className="ml-2 text-sm"
                              data-testid="inventorySubscribed"
                            >
                              Subscribed
                            </span>
                        </Flexor>
                      ) : (
                        <Button
                          onClick={() => {
                            window.open("https://www.easyvotesolutions.com/solutions/inventory-management/?ref=desktopapp", "_blank")
                          }}
                          data-testid="inventoryLearnMore"
                          size="sm"
                          type="button"
                          variant="outline"
                        >
                          Learn More
                        </Button>
                      )
                    }
                  </Flexor>
                </div>
              </div>
              <div className="flex items-center justify-end gap-x-6 sticky left-0 py-5 bg-white bottom-0 w-full">
                <Button
                  onClick={() => form.reset()}
                  type="button"
                  variant="link"
                >
                  Reset changes
                </Button>
                <Button
                  disabled={saving}
                  id="submitButton"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}
