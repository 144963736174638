import React, { forwardRef } from 'react';
import { Input } from "shared/src/components/ui";
import { IntlCurrencyInput, IntlFormatterConfig } from "./IntlCurrencyInput";

const currencyConfig: IntlFormatterConfig = {
  locale: "en-US",
  formats: {
    number: {
      USD: {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 3,
        maximumFractionDigits: 2,
      },
    },
  },
};

function noop() {}

export const CurrencyInput = ({onChange, value, disabled = false}: {onChange: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void, value: number, disabled: boolean}) => {
  return(
    <IntlCurrencyInput
      currency='USD'
      autoReset={false}
      autoSelect={false}
      max={100_000}
      autoFocus={true}
      inputRef={null}
      onBlur={noop}
      onFocus={noop}
      onKeyPress={noop}
      value={value}
      defaultValue={value}
      config={currencyConfig}
      component={forwardRef((props, ref) => {
        return <Input ref={ref} disabled={disabled} {...props} />
      })}
      onChange={onChange}
    />
  );
}
