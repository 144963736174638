import {forwardRef, InputHTMLAttributes} from "react";

const EditItemQuantityForm = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
  return (
    <input
      ref={ref}
      type="number"
      min={0}
      placeholder="0"
      {...props}
      onChange={(event) => {
        if (props.onChange && event.target.checkValidity()) props.onChange(event);
      }}
      value={props.value || ''}
      className={`${props.className} bg-white out-of-range:bg-ev-red out-of-range:text-white out-of-range:bg-opacity-80 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-red sm:text-sm sm:leading-6`}
    />
  );
});

export default EditItemQuantityForm;
