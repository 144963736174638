import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { WorkSchedule } from 'pollworker/src/types';
import { AppState } from './store';

export interface WorkScheduleState {
    currentUserSchedules: WorkSchedule[];
    selectedWorkSchedule: WorkSchedule | null;
}

const initialState: WorkScheduleState = {
    currentUserSchedules: [],
    selectedWorkSchedule: null
};

const slice = createSlice({
    name: 'schedule',
    initialState,
    reducers: {
        setCurrentUserWorkSchedules: (state, action: PayloadAction<WorkSchedule[]>) => {
            state.currentUserSchedules = action.payload;
        },
        setSelectedWorkSchedule: (state, action: PayloadAction<WorkSchedule>) => {
            state.selectedWorkSchedule = action.payload;
        }
    }
});

export const {
    setCurrentUserWorkSchedules,
    setSelectedWorkSchedule
} = slice.actions;

export const selectCurrentUserWorkSchedules = (state: AppState) => state.schedule.currentUserSchedules;
export const selectSelectedWorkSchedule = (state: AppState) => state.schedule.selectedWorkSchedule;
export const selectCurrentUserIsManagerInElection = createSelector(
  selectCurrentUserWorkSchedules,
  (workSchedules: WorkSchedule[]) => {
      return workSchedules.length > 0 && workSchedules.find(schedule => schedule.HasReports)
  }
);

export default slice.reducer;
