import { useEffect, useState } from "react";
import PollingLocationGrid from "./PollingLocationGrid";
import type { PollingLocation } from "admin/src/types/PollingLocation";
import PollingLocationForm from "admin/src/screens/admin/SetupAndAdmin/PollingLocationForm";
import { addOrEditPollingLocation, deletePollingLocation, getPollingLocations } from "admin/src/fetchers/setupAndAdmin";
import { PlusIcon } from "@heroicons/react/24/outline";
import { DataGridProvider } from "../../../components/ui/DataGrid";
import { Button, Sheet, SheetContent, SheetHeader, SheetTitle } from "shared/src/components/ui/new";

export default function PollingLocations() {
  const [showAdd, setShowAdd] = useState(false);

  return (
    <>
      <div className="my-2">
        <div className="flex justify-between items-center text-md px-2">
          <div className='font-semibold'>Polling Locations</div>
          <Button size='sm' data-testid='addButton' onClick={() => setShowAdd(true)}>
            <PlusIcon className='size-4' />
            Add
          </Button>
        </div>
        <div className="mt-1 h-[1px] bg-gray-300 w-full" />
      </div>
      <div className='h-full p-2'>
        <PollingLocationList showAdd={showAdd} onHideAdd={() => setShowAdd(false)} />
      </div>
    </>
  );
}

export function PollingLocationList({ electionId, showAdd, onHideAdd }: { electionId?: string, showAdd: boolean, onHideAdd: () => void }) {
  const [pollingLocations, setPollingLocations] = useState<PollingLocation[]>([]);
  const [plLoading, setPlLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedLocation, setSelectedLocation] = useState<PollingLocation | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchPollingLocations = async () => {
    setPlLoading(true);
    try {
      const locations = await getPollingLocations(electionId);
      setPollingLocations(locations);
    } catch (error) {
      console.error("Error fetching polling locations:", error);
    } finally {
      setPlLoading(false);
    }
  };

  useEffect(() => {
    if (!showAdd) return;

    setSelectedLocation(null);
    setIsOpen(true);
  }, [showAdd]);

  useEffect(() => {
    if (!isOpen) onHideAdd();
  }, [isOpen, onHideAdd]);

  useEffect(() => {
    fetchPollingLocations();
  }, []);

  const handleDeleteApiCall = async (id: string) => {
    setLoading(true);
    try {
      await deletePollingLocation(id);
      await fetchPollingLocations();
    } catch (error) {
      console.error("Error deleting polling locaion:", error);
    } finally {
      setLoading(false);
    }
  };

  function handleEditPollingLocation(location: PollingLocation) {
    if (location) {
      setSelectedLocation(location);
      setIsOpen(true);
    }
  }

  const handleDeletePollingLocation = (location: PollingLocation) => {
    if (window.confirm(`Are you sure you want to delete ${location.locationName}`)) {
      handleDeleteApiCall(location.id);
    }
  };

  const handleSubmitApiCall = async (pl: PollingLocation | null) => {
    if (pl) {
      setLoading(true);
      try {
        await addOrEditPollingLocation(pl);
        await fetchPollingLocations();
      } catch (error) {
        console.error("Error saving polling location:", error);
      } finally {
        setLoading(false);
        setIsOpen(false);
      }
    }
  };

  return (
    <>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>{selectedLocation ? 'Edit' : 'Add'} polling location</SheetTitle>
          </SheetHeader>
          <PollingLocationForm
            pollingLocation={selectedLocation}
            onSubmit={handleSubmitApiCall}
          />
        </SheetContent>
      </Sheet>

      <DataGridProvider className='space-y-2'>
        <PollingLocationGrid
          loading={plLoading || loading}
          locations={pollingLocations}
          onEditClick={(location) => handleEditPollingLocation(location)}
          onDeleteClick={(location) => handleDeletePollingLocation(location)}
        />
      </DataGridProvider>
    </>
  )
}
