import React, { Fragment, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import LoadingOverlay from "../ui/LoadingOverlay";
import { Menu, Transition } from "@headlessui/react";
import {ChevronUpDownIcon, ClipboardDocumentListIcon, DocumentMagnifyingGlassIcon} from "@heroicons/react/24/outline";
import { classNames } from "shared/src/utils/classNames";
import { Flexor } from "shared/src/components";
import {getCFDocuments, getDocumentFilingProgressReport} from "../../fetchers/campaignFinance";
import { themeBalham, SideBarDef } from "ag-grid-enterprise";

const ColDef = [
  {
    field: 'filerStatus',
    enableRowGroup: true,
  },
  {
    field: 'userID',
  },
  {
    field: 'firstName',
  },
  {
    field: 'lastName',
  },
  {
    field: 'filerTypeName',
    headerName: 'Status',
    enableRowGroup: true,
  },
  {
    field: 'officeName',
    headerName: 'Department Board Commission',
    enableRowGroup: true,
  },
  {
    field: 'chairPerson',
    headerName: 'Job Position',
    enableRowGroup: true,
  },
  {
    field: 'committeeName',
    headerName: 'Job Title',
    enableRowGroup: true,
  },
  {
    field: 'address',
  },
  {
    field: 'address2',
  },
  {
    field: 'city',
    enableRowGroup: true,
  },
  {
    field: 'state',
  },
  {
    field: 'zip',
    enableRowGroup: true,
  },
  {
    field: 'email',
  },
  {
    field: 'cellPhone',
  },
  {
    field: 'contactPhone',
  },
  {
    field: 'hasFilingAccepted',
    enableRowGroup: true,
  },
  {
    field: 'lastFilingStatus',
    enableRowGroup: true,
  },
  {
    field: 'acceptedDate',
  },
  {
    field: 'creationDate',
  },
  {
    field: 'acceptedAmendments',
  },
  {
    field: 'inProgressAmendments',
  },
];

const sidebarConfig: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      toolPanel: 'agColumnsToolPanel',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanelParams: {
        suppressPivotMode: true,
      }
    },
    'filters',
  ],
  defaultToolPanel: '',
  position: 'left',
};

const defaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  enableCellChangeFlash: true,
  suppressHeaderMenuButton: true,
};

type DocumentSelection = {
  documentName: string;
  id: string;
}

export default function CampaignFinance() {
  const gridRef = useRef<AgGridReact>(null);
  const [rowData, setRowData] = useState<any>([]);
  const [documentSelections, setDocumentSelections] = useState<DocumentSelection[]>([]);
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>("");
  const [selectedDocTypeName, setSelectedDocTypeName] = useState<string>('Select a document type...');
  const [loadingData, setLoadingData] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    if (!gridRef.current) return;
    loadDocumentSelections();
    loadReportData(selectedDocumentId);
  }, [gridRef, selectedDocumentId]);

  useEffect(() =>{
    if (!gridRef.current || !gridRef.current.api) return;
    setRowCount(gridRef.current.api.getDisplayedRowCount());
  }, [rowData])

  function gridFilterChanged() {
    if (!gridRef.current || !gridRef.current.api) return;
    setRowCount(gridRef.current.api.getDisplayedRowCount());
  }

  function loadReportData(documentId: string) {
    if (!documentId) return;

    setLoadingData(true);

    getDocumentFilingProgressReport(documentId)
    .then((response) => {
      const data = JSON.parse(atob(response.data));
      setRowData(data);
    })
    .finally(() => setLoadingData(false));
  }

  function loadDocumentSelections() {
    getCFDocuments()
    .then((response) => {
      const data = JSON.parse(atob(response.data));
      setDocumentSelections(data);
    })
    .catch(console.error);
  }

  function onSelectionDropdownClick(selection: DocumentSelection) {
    setSelectedDocumentId(selection.id);
    setSelectedDocTypeName(selection.documentName);
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <Flexor className="pl-3">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="flex items-center justify-between min-w-48 p-3 h-full text-md space-x-0.5 text-gray-800 px-2">
              <Flexor className="space-x-2">
                <DocumentMagnifyingGlassIcon className="h-5 w-5"/>
                <span>{selectedDocTypeName}</span>
              </Flexor>
              <ChevronUpDownIcon className="-mr-1 h-4 w-4 text-gray-800" aria-hidden="true"/>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className="absolute left-2 top-10 z-10 mt-0 w-48 origin-top-left bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {
                  documentSelections.map((selection: DocumentSelection) =>
                    <Menu.Item key={`document_selection_${selection.id}`}>
                      {({ active }) => (
                        <button
                          onClick={() => onSelectionDropdownClick(selection)}
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'block px-4 py-2 text-sm w-full text-left'
                          )}
                        > {selection.documentName} </button>
                      )}
                    </Menu.Item>
                  )
                }
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
        <Flexor className='text-sm px-2 space-x-2'>
          <ClipboardDocumentListIcon className="h-5 w-5 text-gray-700"/>
          <div>
            {
              rowCount === 1 ?
                <>{rowCount} record</> :
                <>{rowCount} records</>
            }
          </div>
        </Flexor>

      </Flexor>
      <div className="ag-theme-balham w-full h-full grow">
        <AgGridReact
          ref={gridRef}
          theme={themeBalham}
          gridId="filingsProgressReport"
          getRowId={params => params.data.id}
          loadingOverlayComponentParams={{isPreview: false, animate: true, infoText: 'Loading filings data...'}}
          loadingOverlayComponent={LoadingOverlay}
          loading={loadingData}
          cellSelection
          allowContextMenuWithControlKey
          animateRows={false}
          rowData={rowData}
          noRowsOverlayComponent={() => (<div>No data to display, choose a document type</div>)}
          columnDefs={ColDef}
          enableBrowserTooltips
          sideBar={sidebarConfig}
          defaultColDef={defaultColDef}
          onFilterChanged={gridFilterChanged}
        />
      </div>
    </div>
  );
}
