import { forwardRef } from "react";
import DropdownOld, { DropdownProps } from "../Dropdown";
import { inputVariants } from "./Input";
import { cn } from "shared/src/utils";

export const Dropdown =  forwardRef<HTMLSelectElement, DropdownProps>((props, ref) => {
  return (
    <DropdownOld {...props} className={cn(inputVariants(), 'py-0')} {...ref} />
  );
});
