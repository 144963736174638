import type { ColDef, RowSelectedEvent } from "ag-grid-enterprise";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import type { ReactNode, RefObject } from "react";
import type { AgGridReact } from "ag-grid-react";
import type { GridApi } from "ag-grid-enterprise";
import { cn } from "shared/src/utils";

type DataGridFilter = {};

type DataGridContextValueProp = {
  filters: DataGridFilter[],
  colDefs: ColDef[],
  gridApi?: GridApi,
  setGridApi: (api: GridApi) => void,
  agGridRef: RefObject<AgGridReact>,
}

const DataGridContext = createContext<DataGridContextValueProp>({
  agGridRef: { current: null },
  gridApi: undefined,
  setGridApi: (api: GridApi) => {},
  filters: [],
  colDefs: [],
});

export function useGridContext() {
  const context = useContext(DataGridContext);

  if (!context) {
    throw new Error('useGridContext can only be used as a child of <DataGrid>');
  }

  return context;
}

export function useGridSelectedRow<T>(): {selectedRow: T | undefined, deselectRow: () => void} {
  const { gridApi } = useGridContext();
  const [selectedRow, setSelectedRow] = useState<T>();

  useEffect(() => {
    if (!gridApi) return;

    const [row] = gridApi.getSelectedRows();
    setSelectedRow(row);
  }, [gridApi]);

  useEffect(() => {
    if (!gridApi) return;

    function onViewDetails(params: RowSelectedEvent<T>) {
      const [row] = gridApi?.getSelectedRows() || [];

      setSelectedRow(row);

      if (params.node.isSelected()) {
        setSelectedRow(params.data);
      }
    }

    function onRowDataUpdated() {
      const [selectedRow] = gridApi?.getSelectedRows() || [];

      setSelectedRow(selectedRow);
    }

    gridApi.addEventListener('rowDataUpdated', onRowDataUpdated);
    gridApi.addEventListener('rowSelected', onViewDetails);

    return () => {
      if (gridApi.isDestroyed()) return;

      gridApi.removeEventListener('rowDataUpdated', onRowDataUpdated);
      gridApi.removeEventListener('rowSelected', onViewDetails);
    }
  }, [gridApi]);

  function deselectRow() {
    gridApi?.deselectAll();
  }

  return {selectedRow, deselectRow};
}

export function DataGridProvider({ children, className }: { children: ReactNode, className?: string }) {
  const agGridRef = useRef(null);
  const [gridApi, setGridApi] = useState<GridApi>();

  return (
    <DataGridContext.Provider value={{
      agGridRef,
      gridApi,
      setGridApi,
      filters: [],
      colDefs: [],
    }}>
      <div className={cn('flex flex-col h-full w-full space-y-4 overflow-hidden', className)}>
        {children}
      </div>
    </DataGridContext.Provider>
  );
}
