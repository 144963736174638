import React, {ReactNode, useEffect, useState} from "react";
import {useCopyToClipboard} from "react-use";
import {useDispatch} from "react-redux";
import {showToast} from "admin/src/actions/toast";
import { Flexor } from "shared/src/components"
import {DocumentDuplicateIcon} from "@heroicons/react/24/outline";
import {classNames} from "shared/src/utils/classNames";

export default function DataDisplayWithClickToCopy({children, data, enableCopy = true}: {children?: (...any: any[]) => ReactNode, data: any, enableCopy?: boolean}) {
  const [options, setOptions] = useState<boolean>(false);
  const [{value}, copy] = useCopyToClipboard();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!value) return;

    dispatch(showToast({ content: 'Copied!' }));
  }, [value]);

  const canCopy = options && !!data;

  const renderedChildren = children ? children({show: options, data}) : null

  return (
    <div onMouseOver={() => setOptions(true)} onMouseOut={() => setOptions(false)}>
      <Flexor justify="start" className="space-x-1">
        <Flexor className='space-x-2'>
          {canCopy ? <DocumentDuplicateIcon onClick={() => copy(data)} className="opacity-100 cursor-pointer transition h-5 w-5" /> : null}
          {
            renderedChildren && options ? (
              <div className={classNames(options ? 'opacity-100 cursor-pointer' : 'opacity-0', "transition h-5 w-5")}>
                { renderedChildren }
              </div>
            ) : null
          }
        </Flexor>
        <span>{data ? data : 'No data'}</span>
      </Flexor>
    </div>
  )
}
