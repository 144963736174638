
export function fetchApi(uri: string, args: RequestInit) {
  const argsWithHeaders = {
    ...args,
    headers: {
      ...getHeaders(),
      ...args.headers,
    }
  }
  return fetch(`${process.env.REACT_APP_DOMAIN}${uri}`, argsWithHeaders).then((request) => {
    const url = window.location.href;
    // TODO: We need to direct admin users to an error page indicating that their auth is expired or somehow invalid
    if (request.status === 401 && !url.includes('/admin') && !url.includes('/app/login')) {
      window.location.href = '/app/login'; // No need to return here (navigation), TS will throw a fit
    }

    return request;
  });
}

export function fetchApiWithAbort(uri: string, args: RequestInit): [Promise<Response>, AbortController] {
  const controller = new AbortController();
  return [fetchApi(uri, {...args, signal: controller.signal}), controller];
}

export function getHeaders(): HeadersInit {
  return {
    'Content-Type': 'application/json',
    'ZUMO-API-VERSION': '2.0.0',
    'X-ZUMO-AUTH': `${window.sessionStorage.getItem('zumo') || window.localStorage.getItem('zumo')}`,
  };
}
