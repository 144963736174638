import { Popover, PopoverContent, PopoverTrigger } from "shared/src/components/ui";
import { Bars3Icon, BookmarkSquareIcon, CurrencyDollarIcon, UsersIcon, WrenchScrewdriverIcon } from "@heroicons/react/24/outline";
import { Flexor } from "shared/src/components";
import React, { ReactNode } from "react";
import { Link } from "wouter";
import { ShellTheme } from "./ShellContext";

type AppItem = {
  name: string,
  description: string,
  path: string,
  icon: ReactNode,
  theme: ShellTheme,
}

export const moreApps: AppItem[] = [{
  name: 'EasyPollWorker',
  description: 'Streamlined poll worker management, training, and scheduling',
  icon: <UsersIcon />,
  theme: 'pw',
  path: '/',
}];

export const installedApps: AppItem[] = [{
  name: 'EasyCampaignFinance',
  description: 'Automated financial reporting & eligibility filing',
  icon: <CurrencyDollarIcon />,
  theme: 'cf',
  path: '~/app/admin/campaignfinance',
}, {
  name: 'Elections',
  description: 'Manage elections, locations and docs',
  icon: <BookmarkSquareIcon />,
  theme: 'el',
  path: '~/app/admin/elections',
}, {
  name: 'Administration',
  description: 'Configure EasyVote apps and settings',
  icon: <WrenchScrewdriverIcon />,
  theme: 'sa',
  path: '~/app/admin/administration',
}];

export function App({path, icon, theme, description, name}: AppItem) {
  return (
    <Link to={path}>
      <Flexor justify='start' className={`group theme-${theme} text-enhancetitle space-x-3 p-2 hover:bg-muted rounded transition-colors`}>
        <div className='sm:[&>svg]:size-6 [&>svg]:size-4 bg-enhance p-1 sm:p-3 group-hover:ring-2 ring-1 ring-enhancetitle rounded'>
          {icon}
        </div>
        <div className='flex flex-col justify-between space-y-1'>
          <div className='font-semibold sm:text-md text-sm'>{name}</div>
          <span className='text-xs sm:inline hidden'>{description}</span>
        </div>
      </Flexor>
    </Link>
  );
}

function AppList({ apps }: { apps: AppItem[] }) {
  return (
    <>
      {
        apps.map((app) => {
          return (
            <App {...app} />
          );
        })
      }
    </>
  )
}

export function Apps() {
  return (
    <div className='sm:p-0 p-2'>
      <AppList apps={installedApps} />
      <div className="font-semibold px-2 mt-4 mb-2">More from EasyVote</div>
      <AppList apps={moreApps} />
    </div>
  )
}

export default function AppPicker() {
  return (
    <>
      <Popover>
        <PopoverTrigger>
          <div className='scale-125 p-0.5 rounded hover:ring-enhancetitle hover:ring-1 -mb-1 -ml-1'>
            <Bars3Icon className="size-4 transition-all" />
          </div>
        </PopoverTrigger>
        <PopoverContent align="start" alignOffset={-5} className="-ml-1 mt-1 bg-background rounded-xl w-full md:w-96 text-sm font-normal p-2">
          <Apps />
        </PopoverContent>
      </Popover>
    </>
  )
}
