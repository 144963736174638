import { type ButtonHTMLAttributes, type ChangeEvent, forwardRef, useRef, useState } from "react";
import { useAppSelector } from "../../../hooks";
import { useDocumentsContext } from "./DocumentsContext";
import { toast } from "sonner";
import { uploadDocument } from "admin/src/utils/documents";
import { Button } from "shared/src/components/ui/new";
import { Spinner } from "shared/src/components";
import { PlusIcon } from "@heroicons/react/24/outline";
import { EXTENSION_TO_MIME_TYPE } from "./Documents";
import { cn } from "shared/src/utils";

const acceptedMimeTypes = Object.values(EXTENSION_TO_MIME_TYPE).join(',');

export const DocumentsUpload = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>(({ className }, ref) => {
  const fileUploadInputRef = useRef<HTMLInputElement>(null);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const { user } = useAppSelector(state => state.user);
  const { objectId, loadDocuments, onUpload, upload, disableToast } = useDocumentsContext();

  async function uploadFile({target: {files}}: ChangeEvent<HTMLInputElement>) {
    if (!files?.length) return;

    const uploadingFilePromises = Array.from(files).map(uploadDocumentFile);

    setUploadingFiles(true);

    const uploadAllPromise = Promise.all(uploadingFilePromises)
    .then((uploadedFiles) => {
      if (onUpload) onUpload(uploadedFiles.filter(uf => !!uf));

      return uploadedFiles;
    })
    .finally(() => {
      loadDocuments();
      setUploadingFiles(false);
    });

    if (!disableToast) {
      toast.promise(uploadAllPromise, {
        loading: `Uploading ${files.length > 1 ? `${files.length} files...` : '1 file...'}`,
        success: `${files.length > 1 ? `${files.length} files` : `"${files[0].name}"`} has been uploaded!`,
        error: 'Your files could not be uploaded. Please try again!',
      });
    }
  }

  function uploadDocumentFile(file: File) {
    if (!user || !objectId) return Promise.resolve(null);

    return upload(user.keyCustomerId, objectId, file);
  }

  if (!user) return null;

  return (
    <>
      <input multiple className='hidden' type='file' accept={acceptedMimeTypes} ref={fileUploadInputRef} onChange={uploadFile} />
      <Button ref={ref} variant='outline' size='sm' className={cn(className)} disabled={uploadingFiles} onClick={() => fileUploadInputRef.current?.click()}>
        {uploadingFiles ? <Spinner show light={false} /> : <PlusIcon />}
        <span className='@sm:hidden'>Add</span>
        <span className='@sm:inline-block hidden'>Add documents</span>
      </Button>
    </>
  )
});
