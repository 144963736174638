import React, { useEffect, useState } from "react";
import type { Document } from 'admin/src/types';
import { useDocumentsContext } from "./DocumentsContext";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "shared/src/components/ui/new";
import { useDocumentStatus } from "./useDocumentStatus";
import { toast } from "sonner";

export function DocumentPreview({
  open,
  onOpenChange,
  document
}: { open: boolean, onOpenChange: (open: boolean) => void, document: Document }) {
  const [fileData, setShowFile] = useState<string>();
  const { downloadDocument, disableToast } = useDocumentsContext();
  const [, isPdf] = useDocumentStatus(document);

  useEffect(() => {
    if (open) downloadAndShow();
  }, [open]);

  function downloadAndShow() {
    const downloadFilePromise = downloadDocument(document)
    .then((resp) => {
      const [success] = resp;

      if (!success && !disableToast) {
        onOpenChange(false);
        throw new Error();
      }

      return resp;
    })
    .then(([success,, fileData]) => success && setShowFile(fileData));

    if (!disableToast) {
      toast.promise(downloadFilePromise, {
        loading: 'Generating preview...',
        error: 'This file could not be found',
      });
    }
  }

  return (
    <Dialog open={open && !!fileData} onOpenChange={onOpenChange} size={isPdf ? 'full' : 'lg'}>
      <DialogContent className='gap-0'>
        <DialogHeader>
          <DialogTitle>{document.documentName}</DialogTitle>
        </DialogHeader>
        <div className={isPdf ? 'h-full' : 'px-3 pb-3'}>
          {
            isPdf ? (
              <iframe data-testid='iframe-preview' title={`Document ${document.documentName}`} className="w-full h-full" src={fileData}/>
            ) : (
              <img data-testid='img-preview' alt={`Document ${document.documentName}`} src={fileData} className='pt-5'/>
            )
          }
        </div>
      </DialogContent>
    </Dialog>
  );
}
