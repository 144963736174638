import React from "react";
import {useDispatch, useSelector} from "react-redux";
import { AppState } from 'admin/src/store';
import {
  BuildingLibraryIcon,
  CalendarIcon,
  ChatBubbleLeftRightIcon, CheckIcon,
  PencilSquareIcon, ViewfinderCircleIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import DataDisplayWithClickToCopy from "../utils/DataDisplayWithClickToCopy";
import {classNames} from "shared/src/utils/classNames";
import Tippy from "@tippyjs/react";
import TippyContent from "shared/src/components/TippyContent";
import {dateFormatter, phoneNumberFormatter} from "shared/src/utils/formatters";
import {setPollworkerToEdit} from "admin/src/reducers/pollworker/grid";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import { Text } from "shared/src/components/SectionHeading";
import {Nomenclature} from "../../Nomenclature";

export default function PollworkerInfoPanel({readOnly}: {readOnly: boolean}) {
  const showPollworkerDetails = useSelectedPollworker();
  const roleTypes = useSelector((state: AppState) => state.pollworker.grid.roleTypes);
  const dispatch = useDispatch();

  function dispatchPollworkerToEdit(column: string): () => void {
    return () => dispatch(setPollworkerToEdit({pollworker: showPollworkerDetails, column}));
  }

  if (!showPollworkerDetails) return null;

  return (
    <div className='px-2'>
      <div>
        <h3 className="font-semibold text-sm text-gray-900 my-2">Status</h3>
        <div className="grid grid-cols-2 gap-px bg-gray-200">
          <div className="flex items-center justify-between py-2 pr-2 bg-white text-sm w-full space-x-1">
            <span className='truncate' title='SMS?'>SMS?</span>
            <Tippy content={(<TippyContent classes="scale-75 origin-bottom">SMS {showPollworkerDetails.userInfo.okToSendSMS ? 'Allowed' : 'Disallowed'}</TippyContent>)}>
              <ChatBubbleLeftRightIcon className={classNames(showPollworkerDetails.userInfo.okToSendSMS ? 'text-green-500' : 'text-ev-red', "h-4 w-4")} />
            </Tippy>
          </div>
          <div className="flex items-center justify-between pl-2 py-2 bg-white text-sm w-full space-x-1">
            <span className='truncate' title='Election Day Worker'>Election Day Worker</span>
            <Tippy content={(<TippyContent classes="scale-75 origin-bottom">{showPollworkerDetails.pollworkerInfo.electionDayWorker ? 'Is Election Day Worker' : 'Not Election Day Worker'}</TippyContent>)}>
              <CalendarIcon className={classNames(showPollworkerDetails.pollworkerInfo.electionDayWorker ? 'text-green-500' : 'text-ev-red', "h-4 w-4")} />
            </Tippy>
          </div>
          <div className="flex items-center justify-between py-2 pr-2 bg-white text-sm w-full space-x-1">
            <span className='truncate' title='Training'>Training</span>
            <Tippy delay={0} content={(<TippyContent classes="scale-75 origin-bottom">{showPollworkerDetails.assignedToClass ? 'Assigned to class' : 'Not assigned to class'}</TippyContent>)}>
              <BuildingLibraryIcon className={classNames(showPollworkerDetails.assignedToClass ? 'text-green-500' : 'text-ev-red', "h-4 w-4")} />
            </Tippy>
          </div>
          <div className="flex items-center justify-between pl-2 py-2 bg-white text-sm w-full space-x-1">
            <span className='truncate' title='Early Voting Worker'><Nomenclature nomenclatureId="EarlyVotingDisplayName"/> Worker</span>
            <Tippy delay={0} content={(<TippyContent classes="scale-75 origin-bottom">{showPollworkerDetails.pollworkerInfo.earlyVotingWorker ? 'Early Voting Worker' : 'Not Early Voting Worker'}</TippyContent>)}>
              {
                showPollworkerDetails.pollworkerInfo.earlyVotingWorker ? (
                  <CheckIcon className="text-green-500 h-4 w-4" />
                ) : (
                  <XMarkIcon className="text-ev-red h-4 w-4" />
                )
              }
            </Tippy>
          </div>
          <div className="flex items-center justify-between py-2 pr-2 bg-white text-sm w-full space-x-1">
            <span className='truncate' title='Work Confirmation'>Work Confirmation</span>
            <span className="font-semibold">
              {
                showPollworkerDetails._workAssignmentConfirmation.value === 1 ? 'Yes' : 'No'
              }
            </span>
          </div>

          <div className="flex items-center justify-between pl-2 py-2 bg-white text-sm w-full space-x-1">
            <span className='truncate' title='Employee ID'><Nomenclature nomenclatureId="EmployeeDisplayName"/> ID</span>
            {
              showPollworkerDetails.userInfo.employeeNumber ? (
                <span className="font-semibold">{showPollworkerDetails.userInfo.employeeNumber}</span>
              ) : (
                <span className="text-gray-700 italic">Blank</span>
              )
            }
          </div>

          <div className="flex items-center justify-between py-2 pr-2 bg-white text-sm w-full space-x-1">
            <span className='truncate' title='Online Learning ID'>Online Learning ID</span>
            {
              showPollworkerDetails.pollworkerInfo.onlineLearningId ? (
                <span className="font-semibold">{showPollworkerDetails.pollworkerInfo.onlineLearningId}</span>
              ) : (
                <span className="text-gray-700 italic">Blank</span>
              )
            }
          </div>

          <div className="flex items-center justify-between py-2 pl-2 bg-white text-sm w-full space-x-1">
            <span className='truncate' title='VR #'><Nomenclature nomenclatureId="VoterRegNumberDisplayName"/></span>
            {
              showPollworkerDetails.userInfo.voterRegistrationNumber ? (
                <span className="font-semibold">{showPollworkerDetails.userInfo.voterRegistrationNumber}</span>
              ) : (
                <span className="text-gray-700 italic">Blank</span>
              )
            }
          </div>
        </div>
      </div>
      <div>
        <h3 className="font-semibold text-sm text-gray-900 mt-2">Info</h3>
        <dl className="mt-1 divide-y divide-gray-200">
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-sm font-medium text-gray-500">User ID</dt>
            <dd className="text-sm text-gray-900">
              <DataDisplayWithClickToCopy data={showPollworkerDetails.pollworkerInfo.userInfo.userId}>
                { ({ show }) => null }
              </DataDisplayWithClickToCopy>
            </dd>
          </div>
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="text-sm text-gray-900">
              <DataDisplayWithClickToCopy data={showPollworkerDetails.userInfo.emailAddress}>
                {
                  ({show}) => {
                    return (readOnly ? null : <PencilSquareIcon onClick={dispatchPollworkerToEdit('pollworkerInfo.userInfo.emailAddress')} className="h-5 w-5" />)
                  }
                }
              </DataDisplayWithClickToCopy>
            </dd>
          </div>
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-sm font-medium text-gray-500">Cell Phone</dt>
            <dd className="text-sm text-gray-900">
              <DataDisplayWithClickToCopy data={phoneNumberFormatter({value: showPollworkerDetails.userInfo.cellPhone})}>
                {
                  ({show}) => {
                    return (readOnly ? null : <PencilSquareIcon onClick={dispatchPollworkerToEdit('pollworkerInfo.userInfo.cellPhone')} className="h-5 w-5" />)
                  }
                }
              </DataDisplayWithClickToCopy>
            </dd>
          </div>
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-sm font-medium text-gray-500">Home Phone</dt>
            <dd className="text-sm text-gray-900">
              <DataDisplayWithClickToCopy data={phoneNumberFormatter({value: showPollworkerDetails.userInfo.homePhone})}>
                {
                  ({show, data}) => {
                    return (readOnly ? null : <PencilSquareIcon onClick={dispatchPollworkerToEdit('pollworkerInfo.userInfo.homePhone')} className="h-5 w-5" />)
                  }
                }
              </DataDisplayWithClickToCopy>
            </dd>
          </div>
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-sm font-medium text-gray-500">Date of Birth</dt>
            <dd className="text-sm text-gray-900">
              <DataDisplayWithClickToCopy data={dateFormatter({value: showPollworkerDetails.pollworkerInfo.dateOfBirth})}>
                {
                  ({show}) => {
                    return (readOnly ? null : <PencilSquareIcon onClick={dispatchPollworkerToEdit('pollworkerInfo.dateOfBirth')} className="h-5 w-5" />)
                  }
                }
              </DataDisplayWithClickToCopy>
            </dd>
          </div>
          <div className="py-3 text-sm font-medium">
            <dt className="text-sm font-medium text-gray-500">Notes</dt>
            <dd className="mt-1 max-w-prose space-y-5 text-sm text-gray-900">
              <div className="border-l-4 pl-2">
                <DataDisplayWithClickToCopy data={showPollworkerDetails.pollworkerInfo.notes}>
                  {
                    ({show}) => {
                      return show && !readOnly ? (<PencilSquareIcon onClick={dispatchPollworkerToEdit('pollworkerInfo.notes')} className="h-5 w-5" />) : null
                    }
                  }
                </DataDisplayWithClickToCopy>
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div className="mt-4">
        <h3 className="font-semibold text-sm text-gray-900 mt-2">Associations</h3>
        <dl className="mt-1 divide-y divide-gray-200">
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Role</dt>
            <dd className="text-gray-900">
              <DataDisplayWithClickToCopy data={roleTypes.find(type => type.id === showPollworkerDetails.pollworkerInfo.keyRoleId)?.roleName}>
                {({show, data}) => {
                  if (readOnly) return;

                  return (
                    <ViewfinderCircleIcon onClick={() => dispatch(setPollworkerToEdit({
                      pollworker: showPollworkerDetails,
                      column: 'electionDayRole.roleName'
                    }))} className="h-5 w-5"/>
                  )
                }}
              </DataDisplayWithClickToCopy>
            </dd>
          </div>
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Vote Location</dt>
            <dd className="text-gray-900">
              <DataDisplayWithClickToCopy data={showPollworkerDetails.locationInfo?.locationName}>
                {
                  ({show}) => {
                    return (readOnly ? null : <ViewfinderCircleIcon onClick={dispatchPollworkerToEdit('electionDayLocation.locationName')} className="h-5 w-5" />)
                  }
                }
              </DataDisplayWithClickToCopy>
            </dd>
          </div>
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Party</dt>
            <dd className="text-gray-900">
              <DataDisplayWithClickToCopy data={showPollworkerDetails.pollworkerInfo.party}>
                {
                  ({show}) => {
                    return (readOnly ? null : <PencilSquareIcon onClick={dispatchPollworkerToEdit('pollworkerInfo.party')} className="h-5 w-5" />)
                  }
                }
              </DataDisplayWithClickToCopy>
            </dd>
          </div>
          <div className="flex items-center justify-between py-3 text-sm font-medium">
            <dt className="text-gray-500">Reports To</dt>
            <dd className="text-gray-900">
              <DataDisplayWithClickToCopy data={showPollworkerDetails._reportsTo?._fullName ? `${showPollworkerDetails._reportsTo._fullName}` : null}>
                {
                  ({show}) => {
                    return (readOnly ? null : <PencilSquareIcon onClick={dispatchPollworkerToEdit('_reportsTo.id')} className="h-5 w-5" />)
                  }
                }
              </DataDisplayWithClickToCopy>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  )
}
