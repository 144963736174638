import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import { AgGridReact } from "ag-grid-react";
import {
  PollworkerWorkHistory,
} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import {
  getPartiesForCustomer,
  getPrecincts,
} from "admin/src/fetchers";
import dayjs from "dayjs";
import LoadingOverlay from "../ui/LoadingOverlay";
import generateOptionsMenu from "./utils/contextMenu";
import {agGridContextMenuAdapter} from "./utils/adapters";
import useKeyboardJs from "react-use/lib/useKeyboardJs";
import {
  setDisplayedRowCount,
  setIsFiltered,
  setParties,
  setPrecincts,
  setSelectedRowsPollworkerIds
} from "admin/src/reducers/pollworker/grid";
import {getPollworkerSelector, getRoleTypes, getShowPollworkerDetails} from "admin/src/selectors/pollworker";
import {agGridColDefsSelector, moduleInfoSelector} from "admin/src/selectors/pollworker/grid";
import { AppState } from 'admin/src/store';
import { isDetailsPanelsStacked } from "../../selectors/pollworker/detailsUiStack";
import { Slide, toast } from "react-toastify";
import useHandlePollworkers from "../../hooks/useHandlePollworkers";
import { useAppDispatch, useAppSelector } from 'admin/src/hooks';
import { CellValueChangedEvent, GetContextMenuItemsParams, MenuItemDef, IRowNode, SelectionChangedEvent, SideBarDef, CellDoubleClickedEvent, RowClassParams, FilterChangedEvent, StateUpdatedEvent, CellSelectionModule, CellStyleModule, CheckboxEditorModule, ClientSideRowModelApiModule, ClipboardModule, ColumnApiModule, ColumnMenuModule, ColumnsToolPanelModule, ContextMenuModule, CustomFilterModule, DateEditorModule, DateFilterModule, DefaultMenuItem, ExcelExportModule, FiltersToolPanelModule, GroupFilterModule, HighlightChangesModule, ModuleRegistry, MultiFilterModule, NumberFilterModule, QuickFilterModule, RichSelectModule, RowApiModule, RowGroupingModule, RowSelectionModule, RowStyleModule, ScrollApiModule, SetFilterModule, SideBarModule, TextEditorModule, TextFilterModule, TooltipModule, themeBalham, ClientSideRowModelModule, } from 'ag-grid-enterprise';

import 'admin/src/App.css';

ModuleRegistry.registerModules([
  CellSelectionModule,
  CellSelectionModule,
  ClientSideRowModelModule,
  CellStyleModule,
  CheckboxEditorModule,
  ClientSideRowModelApiModule,
  ClipboardModule,
  ColumnApiModule,
  ColumnMenuModule,
  ColumnsToolPanelModule,
  ContextMenuModule,
  CustomFilterModule,
  DateEditorModule,
  DateFilterModule,
  ExcelExportModule,
  FiltersToolPanelModule,
  GroupFilterModule,
  HighlightChangesModule,
  MultiFilterModule,
  NumberFilterModule,
  QuickFilterModule,
  RichSelectModule,
  RowApiModule,
  RowGroupingModule,
  RowSelectionModule,
  RowStyleModule,
  ScrollApiModule,
  SetFilterModule,
  SideBarModule,
  TextEditorModule,
  TextFilterModule,
  TooltipModule,
]);

dayjs.extend(require('dayjs/plugin/relativeTime'));

const sideBarConfig: SideBarDef = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      toolPanel: 'agColumnsToolPanel',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanelParams: {
        suppressPivotMode: true,
      }
    },
    'filters',
  ],
  defaultToolPanel: '',
  position: 'left',
};

const GRID_ID = 'assigned';

export type PollworkerColDefContext = {
  type?: string,
  isKeyUpdate?: boolean,
  keyPathToUpdate?: string,
  getValue?: (pollworker: PollworkerWorkHistory, newValue: string) => any
}

function saveGridStateToLs(params: StateUpdatedEvent) {
  window.localStorage.setItem('grid_state:assigned', JSON.stringify(params.state));
}

const initialGridState = JSON.parse(window.localStorage.getItem('grid_state:assigned') || 'null') || undefined;

export default function PollworkerGrid({
  update,
  resetRef,
  reload,
}: {
  update: (pollworker: PollworkerWorkHistory, updateValue: any, updateDef: PollworkerColDefContext) => void,
  resetRef?: React.MutableRefObject<any>
  reload: () => void,
}) {
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const [gridReady, setGridReady] = useState<boolean>(false);
  const [loadingPollworkers] = useHandlePollworkers();
  const pollworkers = useAppSelector(getPollworkerSelector);
  const columnDefs = useAppSelector(agGridColDefsSelector);
  const moduleInfo = useAppSelector(moduleInfoSelector);
  const roleTypes = useAppSelector(getRoleTypes);
  const quickFilter = useAppSelector((state: AppState) => state.pollworker.grid.quickFilter);
  const pollworkerToEdit = useAppSelector((state: AppState) => state.pollworker.grid.pollworkerToEdit);
  const highlightPollworker = useAppSelector(getShowPollworkerDetails);
  const settings = useAppSelector((state: AppState) => state.pollworker.settings);
  const isStacked = useAppSelector(isDetailsPanelsStacked);
  const [escape] = useKeyboardJs('esc');

  useEffect(() => {
    if (!gridRef.current || !gridReady) return;

    resetRef!.current = {
      reset: {
        resetColumnState: () => gridRef.current!.api.resetColumnState(),
        resetColumnGroupState: () => gridRef.current!.api.resetColumnGroupState(),
        resetFilters: () => gridRef.current?.api?.setFilterModel(null),
        resetAll: () => {
          gridRef.current!.api.resetColumnState();
          gridRef.current!.api.resetColumnGroupState();
          gridRef.current!.api.setFilterModel(null);
          gridRef.current!.api.resetQuickFilter();
        }
      }
    }
  }, [gridRef, gridReady]);

  useEffect(() => {
    if (!escape) return;
    // @ts-ignore
    gridRef.current!.api.deselectAll();
  }, [escape]);

  const getRowClass = useMemo(() => {
    return (params: RowClassParams) => {
      if (!params.node.id || !params.node.displayed) return;

      return !!(params.rowIndex % 2) ? 'bg-white' : 'bg-gray-100';
    }
  }, []);

  useEffect(() => {
    if (!gridRef.current?.api) return;

    gridRef.current.api.setGridOption('quickFilterText', quickFilter || '');
    quickFilter && gridRef.current.api.expandAll();
  }, [gridRef, quickFilter]);

  useEffect(() => {
    if (!gridRef.current || quickFilter || !highlightPollworker) return;

    const node = findRowNodeFromPollworker(highlightPollworker);

    if (!node?.rowIndex) return;

    gridRef.current!.api.ensureIndexVisible(node.rowIndex, 'middle');
  }, [highlightPollworker, gridRef]);

  useEffect(() => {
    if (!moduleInfo) return;

    getPrecincts().then((resp) => {
      dispatch(setPrecincts(JSON.parse(atob(resp.data))));
    });

    getPartiesForCustomer(moduleInfo?.Election?.KeyCustomerId).then((parties) => {
      dispatch(setParties(parties));
    });
  }, [moduleInfo]);

  const onFilterChanged = useMemo(() => {
    return (props: FilterChangedEvent) => {
      dispatch(setDisplayedRowCount(props.api.getDisplayedRowCount()));
      dispatch(setIsFiltered(Object.keys(props.api.getFilterModel()).length));
    }
  }, []);

  const onCellFocused = useCallback(() => {
    if (!isStacked) return;

    toast.info(() => {
      return (
        <>
          <div className='text-md text-gray-800 mb-3'>Active Editing</div>
          <div className='text-sm'>You cannot change pollworkers while editing.</div>
        </>
      )
    }, {
      autoClose: 1500,
      hideProgressBar: true,
      position: 'bottom-center',
      transition: Slide,
    });
  }, [isStacked]);

  const getContextMenuItems = useCallback((params: GetContextMenuItemsParams): (DefaultMenuItem | MenuItemDef)[] | Promise<(DefaultMenuItem | MenuItemDef)[]> => {
    let selectedRows = params.api.getSelectedRows();

    // You can have a row selected and get the context menu when right-clicking on a different row
    // We assume you are looking for the context menu of the row you right-clicked not necessarily
    // the actively selected row. This is NOT done, if multiple rows are selected
    if (selectedRows.length === 1 && params.node && selectedRows[0].id !== params.node.data.id) {
      selectedRows = [params.node.data];
    }

    if (!selectedRows) return [];

    return agGridContextMenuAdapter(generateOptionsMenu(selectedRows, reload, moduleInfo, dispatch)) as (DefaultMenuItem | MenuItemDef)[] | Promise<(DefaultMenuItem | MenuItemDef)[]>;
  }, [reload, moduleInfo, dispatch]);

  const onCellEditingStopped = useCallback(async (event: CellValueChangedEvent) => {
    update(event.data, event.value, event.colDef.context.update);
  }, [roleTypes]);

  function findRowNodeFromPollworker(pollworker?: PollworkerWorkHistory): IRowNode | undefined {
    if (!pollworker || !gridRef.current?.api) return;

    let targetNode: IRowNode | undefined;

    gridRef.current!.api.forEachNode((node, index) => {
      if (node.data?.keyEVUserId === pollworker.keyEVUserId) {
        targetNode = node;
      }
    }, false);

    return targetNode;
  }

  useEffect(() => {
    if (!pollworkerToEdit || !gridRef.current) return;

    let targetNode = findRowNodeFromPollworker(pollworkerToEdit.pollworker);

    function editCell(idx: number, column: string) {
      const targetColumn = gridRef.current!.api.getColumn(column);

      if (targetColumn) gridRef.current!.api.setColumnsVisible([targetColumn.getId()], true);

      gridRef.current!.api.ensureIndexVisible(idx, 'middle');
      gridRef.current!.api.ensureColumnVisible(column, 'middle');

      if (targetNode) pollworkerToEdit?.column && gridRef.current!.api.flashCells({rowNodes: [targetNode], columns: [pollworkerToEdit?.column]});

      gridRef.current!.api.setFocusedCell(idx, column);
      gridRef.current!.api.startEditingCell({
        rowIndex: idx,
        colKey: column,
      });
    }

    if (!targetNode || !`${targetNode.rowIndex}`) return;

    targetNode.rowIndex !== null && editCell(targetNode.rowIndex, pollworkerToEdit.column);
  }, [pollworkerToEdit]);

  const onSelectionChange = useMemo(() => {
    return (event: SelectionChangedEvent) => {
      let nodes = event.api.getSelectedNodes();

      const ids = nodes.filter(node => !node.group).map(node => node.data.id);

      dispatch(setSelectedRowsPollworkerIds(ids));
    };
  }, []);

  const handleDoubleClick = useMemo(() => {
    return (args: CellDoubleClickedEvent) => {
      if (args.colDef.editable || !settings.openNativeDetailsDialogOnDoubleClick) return;
      window.chrome.webview.postMessage(`Dialog:PollworkerDetails:Open:${args.data.keyEVUserId}`);
    }
  }, [settings]);

  if (loadingPollworkers) {
    return (
      <div className="absolute z-50 top-0 left-0 w-full h-full bg-white/20 backdrop-blur-sm">
        <div className="flex flex-col justify-center items-center relative h-full">
          <LoadingOverlay animate infoText="Loading poll workers..."/>
        </div>
      </div>
    );
  }

  return (
    <div className="ag-theme-balham w-full h-full">
      <AgGridReact
        ref={gridRef}
        gridId={GRID_ID}
        theme={themeBalham}
        gridOptions={{
          rowSelection: {
            mode: 'multiRow',
            selectAll: 'filtered',
            enableClickSelection: !isStacked,
            headerCheckbox: true,
          }
        }}
        getRowId={params => params.data.id}
        onGridReady={() => setGridReady(true)}
        loadingOverlayComponentParams={{animate: true, infoText: 'Loading poll workers...'}}
        loadingOverlayComponent={LoadingOverlay}
        allowContextMenuWithControlKey={true}
        getContextMenuItems={getContextMenuItems}
        groupDisplayType='groupRows'
        onSelectionChanged={onSelectionChange}
        noRowsOverlayComponentParams={{isPreview: false, animate: false, infoText: `No poll workers found${moduleInfo?.Election ? ` for the ${moduleInfo.Election.ElectionName} election` : ''}.`}}
        noRowsOverlayComponent={LoadingOverlay}
        getRowClass={getRowClass}
        onStateUpdated={saveGridStateToLs}
        initialState={initialGridState}
        onFilterChanged={onFilterChanged}
        onCellFocused={onCellFocused}
        maintainColumnOrder
        animateRows={false}
        rowData={pollworkers}
        onCellDoubleClicked={handleDoubleClick}
        defaultColDef={{
          sortable: true,
          resizable: true,
          enableCellChangeFlash: true,
          suppressHeaderMenuButton: true,
        }}
        columnDefs={columnDefs}
        enableBrowserTooltips={true}
        sideBar={sideBarConfig}
        onCellValueChanged={onCellEditingStopped}
      />
    </div>
  );
}
