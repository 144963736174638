import { EmployeeInfo } from "../../../types/User";
import { Party, RoleInfo, Schedule, VotingLocationSchedule } from 'admin/src/types';
import { eachDayOfInterval, format } from "date-fns";
import dayjs from "dayjs";

export type PartyCalc = { [partyCode: string]: number };

export enum NodeType {
  assigned = 'assigned',
  overprovisioned = 'overprovisioned',
  scheduled = 'scheduled',
}

export type LocationAssignmentSlot = {
  party: string,
  assigneeParty?: string,
  nodeType: NodeType
  pollworkerInfo?: EmployeeInfo,
  keyEVUserId: string,
  schedule: Partial<Schedule>,
  role: {
    id: string,
    roleName: string,
  },
  meta: AssignmentMeta,
}

export type OverProvisionedSlot = Omit<LocationAssignmentSlot, 'role' & 'party'>

export type AssignmentMeta = {
  hasUnrequestedParty?: boolean,
  overProvisionedIndex?: number,
  isUnassignedDate?: boolean,
  overRepresentedParty?: boolean,
  isMatched?: boolean,
  isUnrequestedParty?: boolean,
  isGlobalRole?: boolean,
}

export type LocationAssignments = {
  slots: AnySlot[],
  partyCalc: PartyCalc,
  workerCountByRole: WorkerCountByRole,
  workerCountByDateByRoleByParty: any,
  originalPartyCalc: PartyCalc,
  pollworkerInfo?: EmployeeInfo,
  history: Omit<LocationAssignmentSlot, 'meta'>[],
  meta?: AssignmentMeta,
}

export type AnySlot = LocationAssignmentSlot | OverProvisionedSlot;

export type LocationAssignmentsByDate = {
  dates: {
    [date: string]: LocationAssignmentsForDate
  }
}

export type LocationAssignmentsForDate = {
  roles: {
    [roleName: string]: LocationAssignments
  },
  meta: AssignmentMeta,
};

export type PartyCalcByRole = {
  [roleName: string]: PartyCalc
}

export type PartyCalcByDate = {
  [date: string]: {
    [roleName: string]: number,
  },
}

export type WorkerCountByRole = {
  [roleName: string]: number
}

export type WorkerCountByDateByRole = {
  [date: string]: WorkerCountByRole
}

export function getDatesBetween(start: string, end: string) {
  const dates = eachDayOfInterval({
    start: new Date(start),
    end: new Date(end)
  });

  return dates.map(date => format(date, 'yyyy-MM-dd'));
}

export type VotingLocationAssignment = {
  assignedDate: string,
  assignedPartyId: string,
  pollworkerLocationRoleId: string,
  createdAt: string,
  id: string,
  updatedAt: string,
  workerCount: number,
  deleted: boolean,
}

export type ScheduleByDate = { [key: string]: VotingLocationSchedule[] }
export type VotingLocationWithAssignments = {
  assignments: VotingLocationAssignment[],
  keyCustomerId: string,
  keyLocationId: string,
  keyRoleId: string,
  roleInfo: RoleInfo,
  workerCount: number,
  deleted: boolean,
  createdAt: string,
  id: string,
}

export type PartiesById = {
  [partyId: string]: Party,
}

export type PartiesByCode = {
  [partyCode: string]: Party,
}

export default function generate(pollworkerSchedulesForLocation: VotingLocationSchedule[], roleWithAssignments: VotingLocationWithAssignments[], partiesById: PartiesById): LocationAssignmentsByDate {
  const assignmentsByLocationByDate: LocationAssignmentsByDate = {dates: {}};
  const partyCalc: PartyCalcByDate = {};
  const workerCountByRole: WorkerCountByDateByRole = {};
  const workerCountByDateByRoleByParty: any = {};

  const buildAssignments = (date: string, assignment: VotingLocationAssignment, roleInfo: RoleInfo, defaultMeta: AssignmentMeta = {}) => {
    // worker count must be at least 1, otherwise, what are we doing here?
    // Desktop app does not do properly validate so bad data is everywhere.
    const workerCount = Math.max(assignment.workerCount, 1);
    assignmentsByLocationByDate.dates[date] ||= {
      meta: { isUnassignedDate: false },
      roles: {}
    };

    assignmentsByLocationByDate.dates[date].roles[roleInfo.roleName] ||= {
      slots: [],
      meta: {...defaultMeta},
      history: [],
      workerCountByDateByRoleByParty: {},
      partyCalc: {},
      originalPartyCalc: {},
      workerCountByRole: {},
    };

    workerCountByRole[date] ||= {};
    workerCountByRole[date][roleInfo.roleName] ||= 0;
    workerCountByRole[date][roleInfo.roleName] += workerCount;

    const targetParty = partiesById[assignment.assignedPartyId]?.partyCode;

    workerCountByDateByRoleByParty[date] ||= {};
    workerCountByDateByRoleByParty[date][roleInfo.roleName] ||= {};
    workerCountByDateByRoleByParty[date][roleInfo.roleName][targetParty] ||= 0;
    workerCountByDateByRoleByParty[date][roleInfo.roleName][targetParty] += workerCount;

    partyCalc[date] ||= {};
    partyCalc[date][targetParty] ||= 0;
    partyCalc[date][targetParty] += 1;

    const keyObj = {
      nodeType: NodeType.assigned,
      party: targetParty,
      role: {
        id: roleInfo.id,
        roleName: roleInfo.roleName
      },
      meta: { isMatched: false }
    } as LocationAssignmentSlot;

    new Array(workerCount).fill(1).forEach(() => assignmentsByLocationByDate.dates[date].roles[roleInfo.roleName].slots.push(keyObj));
    assignmentsByLocationByDate.dates[date].roles[roleInfo.roleName].slots = assignmentsByLocationByDate.dates[date].roles[roleInfo.roleName].slots.sort((a, b) => {
      return a.role.roleName > b.role.roleName ? 1 : -1;
    });

    assignmentsByLocationByDate.dates[date].roles[roleInfo.roleName].workerCountByRole = {...workerCountByRole[date]};
    assignmentsByLocationByDate.dates[date].roles[roleInfo.roleName].partyCalc = partyCalc[date];
    assignmentsByLocationByDate.dates[date].roles[roleInfo.roleName].originalPartyCalc = {...partyCalc[date]};
    assignmentsByLocationByDate.dates[date].roles[roleInfo.roleName].workerCountByDateByRoleByParty = workerCountByDateByRoleByParty[date][roleInfo.roleName];
  }

  const globalRoles = roleWithAssignments.filter((role: VotingLocationWithAssignments) => {
    const assignmentsWithoutDate = role.assignments.filter((assignment: VotingLocationAssignment) => {
      return !assignment.assignedDate;
    });

    return !!assignmentsWithoutDate.length;
  });

  roleWithAssignments.forEach((role: VotingLocationWithAssignments) => {
    const {roleInfo, assignments} = role;

    assignments.forEach((assignment: VotingLocationAssignment) => {
      if (!assignment.assignedDate) return;

      buildAssignments(assignment.assignedDate, assignment, roleInfo);
    });
  });

  globalRoles.forEach((role: VotingLocationWithAssignments) => {
    Object.entries(assignmentsByLocationByDate.dates).forEach(([date, locationAssignments]) => {
      const {roleInfo, assignments} = role;

      assignments.forEach((assignment) => {
        buildAssignments(date, assignment, roleInfo, { isGlobalRole: true });
      });
    });
  });

  pollworkerSchedulesForLocation.forEach((schedule) => {
    const {
      id: scheduleId,
      pollworkerBaseInfo: {
        party: partyCode,
      },
      pollworkerInfo,
      keyEVUserId,
      createdAt,
      startTime,
      endTime,
      workDate,
      roleInfo: {
        roleName: targetRoleName,
      } = {},
    } = schedule;

    if (!targetRoleName) return;

    const date = workDate;

    const currentLocationAssignments = assignmentsByLocationByDate;

    if (!currentLocationAssignments) return;

    let currentAssignments = currentLocationAssignments.dates[date]?.roles?.[targetRoleName] as LocationAssignments;

    if (!currentAssignments) {
      // console.log('Does not match the location\'s date we have', keyVotingLocationId, date);
      currentLocationAssignments.dates[date] ||= { roles: {}, meta: {} };
      currentLocationAssignments.dates[date].roles[targetRoleName] = {
        slots: [],
        partyCalc: {},
        workerCountByDateByRoleByParty: {},
        originalPartyCalc: {},
        workerCountByRole: {},
        history: [],
        meta: {
          isUnassignedDate: true,
        }
      } as LocationAssignments;

      currentLocationAssignments.dates[date].meta.isUnassignedDate = true;

      currentAssignments = currentLocationAssignments.dates[date].roles[targetRoleName];
    }

    // a key used to find an assigned slot
    const searchObj = {
      assigneeParty: partyCode,
      pollworkerInfo,
      keyEVUserId,
      schedule: {
        id: scheduleId,
        startTime,
        createdAt,
        endTime,
      },
      nodeType: NodeType.scheduled,
      role: {
        id: schedule.roleInfo.id,
        roleName: schedule.roleInfo.roleName
      }
    };

    const matchingSlotIdx = currentAssignments.slots.findIndex((slot: AnySlot) => {
      const isOpenSlot = !slot?.party; // determines that this slot, doesn't care about party
      const isAssignedNodeType = slot.nodeType === NodeType.assigned;
      const isSameParty = isOpenSlot || slot?.party === partyCode;
      const isSameRole = searchObj.role.id === slot?.role?.id;
      const isMatched = slot?.meta?.isMatched;

      return isAssignedNodeType && isSameParty && isSameRole && !isMatched;
    });

    let isUnrequestedParty = currentAssignments.partyCalc[partyCode] === undefined;

    currentAssignments.meta ||= {};

    if (matchingSlotIdx < 0) {
      const searchIdx = currentAssignments.slots.length - 1;
      const hasOverProvisionedIndexBeenSet = currentAssignments.meta.overProvisionedIndex !== undefined;

      if (currentAssignments.slots[searchIdx]?.meta?.isMatched && !hasOverProvisionedIndexBeenSet) {
        currentAssignments.slots.push({
          nodeType: NodeType.overprovisioned,
          meta: {}
        } as OverProvisionedSlot);

        const overProvisionedIndex = Math.max(searchIdx + 1, 0);
        currentAssignments.meta.overProvisionedIndex = overProvisionedIndex;
        currentLocationAssignments.dates[date].meta.overProvisionedIndex = overProvisionedIndex;
      }

      currentAssignments.slots.push({
        ...searchObj,
        meta: {
          isUnrequestedParty,
          overRepresentedParty: !isUnrequestedParty,
          isMatched: false
        }
      } as AnySlot);
    } else {
      const slotToReplace = currentAssignments.slots[matchingSlotIdx];
      isUnrequestedParty = !!slotToReplace.party ? isUnrequestedParty : false;

      currentAssignments.slots[matchingSlotIdx] = {
        ...slotToReplace,
        ...searchObj,
        meta: {
          isUnrequestedParty,
          isMatched: true,
        }
      } as AnySlot;
    }

    currentAssignments.meta.hasUnrequestedParty ||= isUnrequestedParty;
    currentLocationAssignments.dates[date].meta.hasUnrequestedParty ||= isUnrequestedParty;

    // Update the party calc
    currentAssignments.partyCalc ||= {}
    currentAssignments.partyCalc[partyCode] ||= 0;
    currentAssignments.partyCalc[partyCode] -= 1;

    // Debug: keep track of matches for this location and date
    currentAssignments.history ||= [];
    currentAssignments.history.push({ ...searchObj } as AnySlot);

    assignmentsByLocationByDate.dates[date].roles[targetRoleName] = currentAssignments;
  });

  return assignmentsByLocationByDate;
}

export function filterAndSortGeneratedSchedule(generatedSchedule: LocationAssignmentsByDate, electionDates: string[] = [], showOnlyElectionDates: boolean = false, hidePastDates: boolean = false) {
  const today = dayjs();

  return Object.entries(generatedSchedule.dates).filter(([date]) => {
    if (!electionDates.length) return true;
    const dateAsDate = dayjs(date);
    let canShowDate = hidePastDates ? today.isSame(dateAsDate) || today.isBefore(dateAsDate) : true;

    if (showOnlyElectionDates && canShowDate) {
      const electionStartDate = dayjs(electionDates[0]);
      const electionEndDate = dayjs(electionDates[electionDates.length - 1]);
      const isElectionDate = (dateAsDate.isAfter(electionStartDate) || dateAsDate.isSame(electionStartDate)) && (dateAsDate.isBefore(electionEndDate) || dateAsDate.isSame(electionEndDate));
      canShowDate = canShowDate && isElectionDate;
    }

    return canShowDate;
  })
  .sort(([dateA], [dateB]) => {
    return dayjs(dateA).isBefore(dateB) ? -1 : 1;
  });
}
