import { useEffect, useMemo } from "react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { useGridContext } from "./DataGridContext";
import { throttle } from "lodash";
import {
  RowSelectionOptions,
  SizeColumnsToFitGridStrategy,
  ColumnAutoSizeModule,
  MultiFilterModule,
  SetFilterModule,
  AllCommunityModule,
  ClientSideRowModelModule,
  ModuleRegistry, ICellRendererParams,
} from "ag-grid-enterprise";
import LoadingOverlay from "admin/src/components/ui/LoadingOverlay";
import { classNames } from "shared/src/utils";
import './DataGrid.css';
import dayjs from "dayjs";

ModuleRegistry.registerModules([
  AllCommunityModule,
  ClientSideRowModelModule,
  ColumnAutoSizeModule,
  SetFilterModule,
  MultiFilterModule,
]);

function DefaultCellRenderer(params: ICellRendererParams) {
  if (!params.value || params.value === '') {
    return (
      <span className='text-muted-foreground italic'>Empty</span>
    );
  }

  if (params.colDef?.cellRenderer !== DefaultCellRenderer) {
    const value = params.colDef?.cellRenderer(params);

    if (!value) {
      return (
        <span className="text-muted-foreground italic">Empty</span>
      );
    }

    return value;
  }

  if (params.value instanceof Date) {
    return dayjs(params.value).format('MM-DD-YYYY');
  }

  return params.value;
}

const autoSizeStrategy: SizeColumnsToFitGridStrategy = { type: 'fitGridWidth' };
const rowSelection: RowSelectionOptions = { mode: 'multiRow', enableClickSelection: true };
const _defaultColDef = {
  suppressHeaderMenuButton: true,
  resizable: false,
  cellRenderer: DefaultCellRenderer,
}

interface DataGridProps {
  rowData: any[],
  loading: boolean,
  className?: string
  loadingMessage?: string,
  gridId: AgGridReactProps['gridId'],
  getRowId?: AgGridReactProps['getRowId']
  columnDefs: AgGridReactProps['columnDefs'],
  rowSelection?: AgGridReactProps['rowSelection'],
  defaultColDef?: AgGridReactProps['defaultColDef'],
  loadingOverlayComponentsParams?: AgGridReactProps['loadingOverlayComponentParams'],
  loadingOverlayComponent?: AgGridReactProps['loadingOverlayComponent'],
  rowHeight?: AgGridReactProps['rowHeight'],
  autoSizeStrategy?: AgGridReactProps['autoSizeStrategy'],
}

export function DataGrid({
  className,
  loadingMessage = 'Loading...',
  ...props
}: DataGridProps) {
  const { agGridRef, setGridApi, gridApi } = useGridContext();

  useEffect(() => {
    const onResize = throttle(() => gridApi?.sizeColumnsToFit(), 200);

    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, [gridApi]);

  const defaultColDef = useMemo(() => ({
    ..._defaultColDef,
    ...(props.defaultColDef || {}),
  }), []);

  return (
    <div data-testid={gridApi?.getGridId() ? `${gridApi.getGridId()}-ready` : ''} className={classNames('ag-theme-quartz h-full border-none', className)}>
      <AgGridReact
        rowSelection={rowSelection}
        loadingOverlayComponentParams={{ animate: true, isPreview: false, infoText: loadingMessage }}
        loadingOverlayComponent={LoadingOverlay}
        rowHeight={40}
        autoSizeStrategy={autoSizeStrategy}
        suppressContextMenu
        {...props}
        ref={agGridRef}
        defaultColDef={defaultColDef}
        onFirstDataRendered={(params) => setGridApi(params.api)}
      />
    </div>
  );
}
