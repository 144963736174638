import { VotingLocation } from 'admin/src/types/VotingLocation';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import VotingLocationImage from '../Panels/PollworkerVLImage';
import { useEffect, useState } from 'react';
import { Input } from 'shared/src/components/ui';
import { cn } from 'shared/src/utils';

export default VotingLocationList;

export interface VotingLocationListProps {
  locations: VotingLocation[];
  selectedLocationId: string | null;
  onLocationSelected: (selectedLocation: VotingLocation) => void | Promise<void>;
}

function VotingLocationList({ locations, selectedLocationId, onLocationSelected }: VotingLocationListProps) {
  const [filterText, setFilterText] = useState<string>('');
  const [filteredList, setFilteredList] = useState<VotingLocation[]>(locations);

  useEffect(() => {
    setFilterText('');
  }, [locations]);

  useEffect(() => {
    if (!!filterText) {
      setFilteredList(
        locations.filter(location => location.locationName.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
      );
    } else {
      setFilteredList(locations);
    }
  }, [filterText]);

  return (
    <div className="flex flex-col grow w-full mx-0">
      <div className="flex items-center justify-between p-2 w-full border-b-2 space-x-2">
        <Input placeholder="Search..." value={filterText} onChange={(event) => setFilterText(event.target.value)} />
      </div>
      <div className="grow overflow-auto">
        {filteredList.map((location) => (
          <div
            key={location.id}
            onClick={() => onLocationSelected(location)}
            className={cn(location.id === selectedLocationId ? 'font-semibold bg-neutral-200' : 'cursor-pointer bg-white font-medium', 'flex items-center justify-between p-2 hover:bg-gray-100')}
          >
            <div className="flex items-center space-x-2">
              <VotingLocationImage votingLocation={location}/>
              <div>
                <div className="text-md">{location.locationName}</div>
                {/*<div className="flex space-x-6">
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center justify-center w-10 h-8 rounded-full bg-green-100">
                      <ClockIcon className="h-5 w-5"/>
                    </div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center justify-center w-10 h-8 rounded-full bg-red-500">
                      <FlagIcon className="h-5 w-5" />
                    </div>
                  </div>
                  <div className="flex items-center space-x-1">
                    <div className="flex items-center justify-center w-10 h-8 rounded-full bg-yellow-100">
                      <AdjustmentsHorizontalIcon className="h-5 w-5" />
                    </div>
                  </div>
                </div>*/}
              </div>
            </div>
            <ChevronRightIcon className="h-5 w-5" />
          </div>
        ))}
      </div>
    </div>
  );
};
