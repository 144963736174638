import React, { useEffect, useState } from "react";
import { useParams } from "wouter";
import { getPollworkerApplicant } from "../../../fetchers";
import { getForm, getPollworkerSettings } from "../../../fetchers/setupAndAdmin";
import PollworkerApplicationForm from "shared/src/components/PollworkerApplicationForm";
import { SectionSubSubHeading } from "shared/src/components/SectionHeading";
import { CalendarIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { Flexor, Loading } from "shared/src/components";
import { Button } from "shared/src/components/ui/Button";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { AppState } from 'admin/src/store';

export default function PollworkerApplicationSubmissionViewer() {
  const [formSubmission, setFormSubmission] = useState();
  const [formSchema, setFormSchema] = useState();
  const [pollworkerApplicant, setPollworkerApplicant] = useState<any>();
  const [agreementText, setAgreementText] = useState<string>();
  const customerId = useSelector((state: AppState ) => state.user?.account?.id);
  const { applicantId } = useParams();

  useEffect(() => {
    if (!applicantId) return;

    getPollworkerSettings().then(([{prospectAgreement}]) => {
      setAgreementText(prospectAgreement);
    });

    getPollworkerApplicant(applicantId).then(async (resp: any) => {
      const {
        keyFormId,
        formDataJson,
        ...applicant
      } = JSON.parse(atob(resp.data));

      setPollworkerApplicant(applicant);

      setFormSubmission(JSON.parse(formDataJson));
      const { data: formData } = await getForm(keyFormId);
      const { compiledFormJson } = JSON.parse(atob(formData));
      setFormSchema(JSON.parse(compiledFormJson));
    });
  }, [applicantId]);

  if (!formSchema || !pollworkerApplicant) {
    return (
      <div className='h-full w-full flex flex-col justify-center'>
        <Loading loadingMessage='Loading pollworker applicant...' />
      </div>
    );
  }

  return (
    <div>
      <Flexor className='mb-3 p-4 bg-white shadow sticky top-0 z-40'>
        <div>
          <SectionSubSubHeading className='flex items-center space-x-2'>
            <UserCircleIcon className='h-5 w-5' />
            <div>{pollworkerApplicant.firstName} {pollworkerApplicant.lastName}</div>
          </SectionSubSubHeading>
          <Flexor justify='start' className='space-x-2 mt-2 text-gray-700'>
            <CalendarIcon className='h-5 w-5' />
            <SectionSubSubHeading className='text-sm'>{dayjs(pollworkerApplicant.createdAt).format('MM/DD/YYYY hh:mm A')}</SectionSubSubHeading>
          </Flexor>
        </div>
        <Flexor>
          <Button onClick={() => window.print()}>Print</Button>
        </Flexor>
      </Flexor>
      <div className='p-4'>
        <PollworkerApplicationForm
          onValidate={() => {}}
          surveyFormSchema={formSchema}
          readOnly
          data={formSubmission}
          onComplete={() => Promise.resolve(true)}
          customerId={customerId}
          agreementText={agreementText}
        />
      </div>
    </div>
  )
}
