import {BuildingStorefrontIcon} from "@heroicons/react/24/outline";
import React from "react";
import {useLocation} from "wouter";
import {classNames} from "shared/src/utils/classNames";

export default function ButtonGoToStore({flip = false}) {
  const [, navigate] = useLocation();

  return (
    <button
      type="button"
      onClick={() => navigate('/store')}
      className={`${classNames(flip ? 'flex-row-reverse' : '')} relative inline-flex items-center gap-x-1.5 rounded-md bg-ev-red px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-ev-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-ev-red`}
    >
      <BuildingStorefrontIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
      Store
    </button>
  );
}
