import {ArrowPathIcon} from "@heroicons/react/24/outline";
import React, {useState} from "react";
import {classNames} from "shared/src/utils/classNames";
import {useKeyPress} from "react-use";

export default function GridReloadButton({onClick}: {onClick: () => void}) {
  const [hover, setHover] = useState<boolean>(false);
  const [metaKeyPressed] = useKeyPress('Control');

  return (
    <button
      title="Control+Click to refresh the entire page"
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      onClick={() => {
        if (!metaKeyPressed) return onClick();
        window.location.reload();
      }}
      className="flex items-center justify-center p-1 h-full text-sm space-x-0.5 text-gray-800 px-2"
    >
      <ArrowPathIcon className={classNames(hover ? 'animate-spin' : '', "h-4 w-4")} />
      <span>Reload</span>
    </button>
  );
}
