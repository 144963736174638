import React from "react";
import { Flexor } from "shared/src/components";
import { Alert, AlertDescription, Button } from "shared/src/components/ui";
import { clearFilters } from "../../../reducers/pollworker/scheduler";
import { useDispatch } from "react-redux";

export default function PollworkerVotingLocationNoSchedules({show, refresh}: {show: boolean, refresh: () => void}) {
  const dispatch = useDispatch();

  if (!show) return null;

  return (
    <div className="mx-auto space-y-2">
      <div>No schedules were found</div>
      <Flexor>
        <Button onClick={() => dispatch(clearFilters())} variant="quaternary">Clear Filters</Button>
        <Button variant="quaternary" onClick={refresh}>Refresh</Button>
      </Flexor>
      <Alert variant="information">
        <AlertDescription>
          A polling place must have at least 1 role with a date in order to schedule pollworkers. Use the Voting Location Positions tool to schedule roles.
        </AlertDescription>
      </Alert>
    </div>
  )
}
