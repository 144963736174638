import React, { useState } from "react";
import { Button, DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuTrigger } from "shared/src/components/ui/new";
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid";
import { ArrowDownOnSquareIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useDocumentsContext } from "./DocumentsContext";
import type { Document } from 'admin/src/types';
import { DocumentPreview } from "./DocumentPreview";
import { useDocumentStatus } from "./useDocumentStatus";

export function DocumentsActionMenu({ document }: { document: Document }) {
  const { deleteDocument, downloadDocumentToFs } = useDocumentsContext();
  const [showFile, setShowFile] = useState(false);
  const [canView] = useDocumentStatus(document);

  return (
    <>
      <DocumentPreview open={showFile} onOpenChange={setShowFile} document={document} />
      <DropdownMenu modal={false}>
        <DropdownMenuTrigger data-testid={`actions-menu-btn-${document.id}`} asChild>
          <Button variant='ghost'>
            <EllipsisVerticalIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent data-testid={`actions-menu-${document.id}`} align='end'>
          <DropdownMenuGroup>
            <DropdownMenuItem data-testid={`action-view-btn-${document.id}`} disabled={!canView} onClick={() => setShowFile(true)}>
              <EyeIcon />
              View
            </DropdownMenuItem>
            <DropdownMenuItem data-testid={`action-download-btn-${document.id}`} onClick={() => downloadDocumentToFs(document)}>
              <ArrowDownOnSquareIcon />
              Download
            </DropdownMenuItem>
            <DropdownMenuSeparator />
            <DropdownMenuItem data-testid={`action-delete-btn-${document.id}`} onClick={() => deleteDocument(document)}>
              <TrashIcon />
              Delete
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}
