import React, { type FormEvent, useState } from 'react';
import DeprecatedModal from "./DeprecatedModal";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import EasyVoteLogo from './EasyVoteLogo'
import { Input, Button, Alert, Label, AlertDescription } from "./ui";
import { Heading } from "./SectionHeading";
import Spinner from "./Spinner";

type LoginProps = {
  logo?: string,
  errorMessage?: string,
  onForgotPassword?: () => void,
  login: (username: string, password: string) => Promise<any>,
}

export default function Login({login, errorMessage, onForgotPassword, logo}: LoginProps) {
  const [username, setUsername] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showForgotAlert, setShowForgotAlert] = useState<boolean>(false);

  function handleLogin(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!username || !password) return;

    setIsLoggingIn(true);

    login(username, password).finally(() => {
      setIsLoggingIn(false);
    });
  }

  return (
    <>
      <DeprecatedModal onClose={() => setShowForgotAlert(false)} title="Notice" icon={<ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" />} iconColor="bg-yellow-100" open={showForgotAlert}>
        If you forgot your password, please use the EasyVote desktop app to reset it.
      </DeprecatedModal>
      <div className="flex min-h-full flex-col justify-center py-12 xs:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md text-center -mt-20">
          <EasyVoteLogo className="mx-auto h-14 mb-5 w-auto" />
          <Heading>Sign in to your account</Heading>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {
              !!errorMessage && (
                <Alert className='mb-2' variant='information'>
                  <ExclamationCircleIcon className='size-5' />
                  <AlertDescription>{errorMessage}</AlertDescription>
                </Alert>
              )
            }
            <form onSubmit={handleLogin} className="space-y-6">
              <div>
                <Label htmlFor="email">
                  Username
                </Label>
                <div className="mt-2">
                  <Input
                    id="email"
                    name="email"
                    type="text"
                    autoComplete="email"
                    required
                    onChange={({target: {value}}) => setUsername(value)}
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="password">
                  Password
                </Label>
                <div className="mt-2">
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    onChange={({target: {value}}) => setPassword(value)}
                    autoComplete="current-password"
                    required
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-ev-red focus:ring-ev-red"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <Button type="button" variant='quaternary' onClick={() => {
                    if (onForgotPassword) {
                      onForgotPassword();
                    } else {
                      setShowForgotAlert(true);
                    }
                  }} tabIndex={-1}>
                    Forgot your password?
                  </Button>
                </div>
              </div>

              <Button data-testid='login-submit' disabled={isLoggingIn} type="submit" className='w-full flex items-center space-x-2'>
                <Spinner show={isLoggingIn} light />
                <span>Sign in</span>
              </Button>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
