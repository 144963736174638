import * as React from "react"
import { DayPicker } from "react-day-picker";
import { cn } from "../../utils";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import Flexor from "../Flexor";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

const calendarVariant = cva(
  'space-y-4 font-bold bg-white rounded p-8 px-6', {
  variants: {
    variant: {
      default: 'border border-neutral-300',
      borderless: 'border-none'
    }
  },
  defaultVariants: {
    variant: 'default',
  }
});

export type CalendarProps = React.ComponentProps<typeof DayPicker> & VariantProps<typeof calendarVariant>;

function Calendar({
  className,
  classNames,
  variant,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const [currentMonth, setCurrentMonth] = useState(props.month || new Date());

  useEffect(() => {
    if (!props.month) return;
    setCurrentMonth(props.month);
  }, [props.month]);

  function goToMonth(month: number): (event: React.MouseEvent) => void {
    return (event: React.MouseEvent) => {
      event.stopPropagation();
      const january = new Date();
      january.setMonth(month);
      setCurrentMonth(january)
    }
  }

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('m-0', className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: twMerge(cn(calendarVariant({ variant }))),
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-bold",
        nav: "space-x-1 flex items-center",
        nav_button: "h-7 w-7 text-black border-none hover:!bg-transparent",
        nav_button_previous: "absolute left-1 ring-0 p-0",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex border-b border-neutral-300 pb-4",
        head_cell: "text-neutral-900 rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "size-9 text-center text-sm text-neutral-900 p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-md first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: "h-9 w-9 border-[1px] border-white text-neutral-900 p-0 font-normal aria-selected:opacity-100 hover:!bg-state-hover hover:text-white aria-selected:text-white aria-selected:bg-action-info rounded",
        day_selected: "bg-action-info text-white hover:bg-primary hover:text-white focus:bg-primary focus:text-primary-foreground",
        day_today: "font-bold",
        day_outside: "text-neutral-600",
        day_disabled: "text-state-disabled",
        day_range_start: "bg-action-info text-white rounded-r-none border-none day-range-start",
        day_range_end: "bg-action-info text-white rounded-l-none border-none day-range-end",
        day_range_middle: "rounded-none aria-selected:bg-action-info border-none",
        day_hidden: "invisible",
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => (
          <Flexor className='space-x-1'>
            <ChevronDoubleLeftIcon onClick={goToMonth(0)} className="size-4 shrink-0 stroke-2 text-action-info" />
            <ChevronLeftIcon className="size-4 shrink-0 stroke-2 text-action-info" />
          </Flexor>
        ),
        IconRight: ({ ...props }) => (
          <Flexor className='space-x-1'>
            <ChevronRightIcon className="size-4 shrink-0 stroke-2 text-action-info" />
            <ChevronDoubleRightIcon onClick={goToMonth(11)} className="size-4 shrink-0 stroke-2 text-action-info" />
          </Flexor>
        ),
      }}
      {...props}
      onMonthChange={(date: Date) => {
        setCurrentMonth(date);
        props.onMonthChange && props.onMonthChange(date);
      }}
      month={currentMonth}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }

