import { call, select, takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from "@reduxjs/toolkit";

import { createOrder, getCart, getCartItems, getCustomerLogo, getConsumables } from 'admin/src/fetchers';
import { UserState } from "admin/src/reducers/user";
import { UserLoginInfo } from "admin/src/types/User";
import { Order } from "admin/src/types/Order";
import {
  loadedCart,
  loadingCart,
  failedLoadingCart,
  addCartItems,
  setUser,
  setLogo,
  fetchConsumables,
  failedLoadingConsumables,
  loadingConsumables,
  loadedConsumables,
  addConsumables,
} from 'admin/src/actions';
import { selectUserState } from 'admin/src/selectors';
import { ConsumableItem } from "admin/src/types";

export function* easyOrderSaga() {
  yield takeLatest(setUser.type, handleLoggedInUserChanged);
  yield takeLatest(fetchConsumables.type, handleFetchConsumables);
}

export function* handleLoggedInUserChanged(action: PayloadAction<UserLoginInfo>) {
  const userState: UserState = yield select(selectUserState);

  // If the newly logged-in user has EasyOrder, we need to prepare the UI for that
  if (userState.enabled && userState.orderConfigs?.find((config) => config.enabled)) {
    // We do nothing with the cart and order
    const [cart, order, customerLogo]: [unknown, Order, string] = yield call(getAllEasyOrderInitialData, userState.parentAccount?.id);

    yield put(setLogo(customerLogo));
    yield call(handleFetchCart);
  }
}

export async function getAllEasyOrderInitialData(parentAccountId: string | undefined): Promise<[unknown, Order, string]> {
  return await Promise.all([
    getCart(),
    createOrder(),
    getCustomerLogo(parentAccountId as string),
  ]);
}

export function* handleFetchConsumables() {
  const userState: UserState = yield select(selectUserState);

  if (userState.enabled) {
    yield put(loadingConsumables());

    try {
      const consumables: ConsumableItem[] = yield call(getConsumables);

      yield put(addConsumables(consumables));
      yield call(handleFetchCart);
    } catch (error: any) {
      console.error('Error getting consumables', error);
      yield put(failedLoadingConsumables(error.message));
    } finally {
      yield put(loadedConsumables());
    }
  }
}

export function* handleFetchCart() {
  try {
    yield put(loadingCart())
    const { data, success, msg } = yield call(getCartItems);

    if (!success) {
      yield put(failedLoadingCart(msg));
    } else {
      yield put(addCartItems(data));
    }
  } catch (error: any) {
    yield put(failedLoadingCart(error.message))
  } finally {
    yield put(loadedCart())
  }
}
