import {PlusCircleIcon} from "@heroicons/react/24/solid";
import React, {MouseEventHandler, ReactNode} from "react";
import {Button} from "shared/src/components/ui";

export default function AddToCartButton({children, onClick, disabled, icon}: {icon?: ReactNode, children: ReactNode, onClick: MouseEventHandler<HTMLButtonElement>, disabled?: boolean}) {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
    >
      {icon || <PlusCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" /> }
      {children}
    </Button>
  )
}
