import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Router } from 'wouter';
import { ErrorBoundary, NestedRoutes } from "shared/src/components";
import * as Sentry from "shared/src/setupSentry";
import dayjs from 'dayjs';
import Utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import WebApp from "./WebApp";
import DesktopApp from "./DesktopApp";
import { LicenseManager, ModuleRegistry, ValidationModule } from "ag-grid-enterprise";

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY!);
if (process.env.NODE_ENV !== 'production') ModuleRegistry.registerModules([ValidationModule]);

Sentry.initialSetup();
dayjs.extend(Utc);
dayjs.extend(LocalizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <Router>
    <ErrorBoundary>
      <NestedRoutes base="/app">
        <WebApp />
      </NestedRoutes>
      <NestedRoutes base="/admin">
        <DesktopApp />
      </NestedRoutes>
    </ErrorBoundary>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
