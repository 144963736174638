import { useGridContext } from "./";
import { useEffect, useState } from "react";
import { Input } from "shared/src/components/ui/new/Input";
import { classNames } from "shared/src/utils";
import { useDebounce } from "react-use";
import { FilterChangedEvent } from "ag-grid-enterprise";

export function DataGridSearch() {
  const { gridApi } = useGridContext();
  const [search, setSearch] = useState('');

  useDebounce(() => {
    if (!gridApi) return;

    gridApi.setGridOption('quickFilterText', search);
  }, 500, [gridApi, search]);

  useEffect(() => {
    if (!gridApi) return;

    function onFilterChanged(event: FilterChangedEvent) {
      if (event.source !== 'quickFilter') return;

      setSearch(gridApi?.getQuickFilter() || '');
    }

    gridApi.addEventListener('filterChanged', onFilterChanged);

    return () => {
      if (gridApi.isDestroyed()) return;
      gridApi.removeEventListener('filterChanged', onFilterChanged);
    }
  }, [gridApi]);

  return (
    <Input
      role='searchbox'
      className={classNames(search.length ? 'border-enhancetitle' : '', 'bg-gray-50/40 lg:w-1/5 md:w-1/4 sm:w-1/2 w-full mt-[1px] ml-[1px]')}
      placeholder='Search'
      autoFocus
      value={search}
      onChange={(event) => {
        setSearch(event.target.value);
      }}
    />
  );
}
