import {combineReducers} from 'redux';
import cartReducer from "./cart";
import toastReducer from "./toast";
import ordersReducer from "./order";
import consumablesReducer from './consumable';
import userReducer from "./user";
import themeReducer from "./theme";
import modalReducer from "./modal";
import pollworkerReducer from "./pollworker";
import pollworkerPublicReducer from "../../../pollworker/src/state/reducers";
import inventoryReducer from "./inventory";
import { default as featureFlagsReducer } from "./featureFlags";
import setupAndAdminReducer from './setupAndAdmin';
import electionsAppReducer from './electionsApp';

export default combineReducers({
  cart: cartReducer,
  consumables: consumablesReducer,
  featureFlags: featureFlagsReducer,
  inventory: inventoryReducer,
  modals: modalReducer,
  orders: ordersReducer,
  pollworker: pollworkerReducer,
  pollworkerPublic: pollworkerPublicReducer,
  setupAndAdmin: setupAndAdminReducer,
  electionsApp: electionsAppReducer,
  theme: themeReducer,
  toast: toastReducer,
  user: userReducer,
});
