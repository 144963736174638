import React, {ReactNode} from 'react';

export default function DisabledAppCoverall({message, children}: {
  children?: ReactNode,
  message: string | undefined,
}) {
  if (!message) return null;

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-5">
      <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex flex-col items-center justify-center">
        {message}
        {children}
      </div>
    </div>
  )
}
