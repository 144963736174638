import React, { useCallback, useEffect, useRef } from "react";
import useNativeMessageListenerEffect from "shared/src/hooks/useNativeMessageListenerEffect";
import { createPollworkerWorkHistoryWorkConfirmation, getPollworkerById, getPollworkerCustomFields, getPollworkerEvUser, getPollworkers, getPollworkerWorkHistoryAssignmentConfirmationsForElection, updatePollworker, updatePollworkerEvUser, updatePollworkerWorkHistory, updatePollworkerWorkHistoryWorkConfirmation } from "admin/src/fetchers";
import { PollworkerWorkHistory, UserInfo } from "admin/src/types/Pollworker/PollworkerWorkHistory";
import PollworkerGrid, { PollworkerColDefContext } from "./PollworkerGrid";
import { Flexor } from "shared/src/components";
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import { useDispatch, useSelector } from "react-redux";
import DialogPollworkerSetStatus from "./DialogPollworkerSetStatus";
import { AppState } from 'admin/src/store';
import { setPollworkerCustomFieldsData, setPollworkerWorkAssignmentConfirmationsForElection, setQuickFilter, setShowPollworkerDetailsId, updatePollworker as updatePollworkerState } from "admin/src/reducers/pollworker/grid";
import { getPollworkerSelector, getShowPollworkerDetails } from "admin/src/selectors/pollworker";
import GridResetDropdown from "./GridResetDropdown";
import DialogBulkSetSms from "./DialogBulkSetSms";
import DialogPollworkerBulkSendSms from "./DialogPollworkerBulkSendSms";
import DialogPollworkerSetDisplayFlags from "./DialogPollworkerSetDisplayFlags";
import { getSelectedPollworkers } from "admin/src/selectors/pollworker/grid";
import { setPollworkerSettings } from "admin/src/reducers/pollworker/settings";
import GridSettingsDropdown from "./GridSettingsDropdown";
import GridReloadButton from "./GridReloadButton";
import PollworkerDetails from "./Panels/PollworkerDetailsPanel";
import { ArrowDownTrayIcon, CursorArrowRaysIcon, FunnelIcon, IdentificationIcon, UsersIcon } from "@heroicons/react/16/solid";
import { toast, ToastContainer, ToastOptions } from 'react-toastify';
import { classNames } from "shared/src/utils/classNames";
import ModuleInfoChangeManager from "./utils/ModuleInfoChangeManager";
import { CalendarDaysIcon, ClockIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { ImperativePanelHandle, Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Link } from "wouter";
import useHandlePollworkers from "admin/src/hooks/useHandlePollworkers";
import { FeatureFlagCheck } from 'admin/src/components/ui';
import { Feature } from 'admin/src/enums';

import 'react-toastify/dist/ReactToastify.css';

const saveToastCompleteConfig = {
  render: 'Saved',
  type: 'success',
  isLoading: false,
  autoClose: 2000,
  position: 'bottom-center',
} as ToastOptions;

const saveToastLoadingConfig = {
  position: 'bottom-center',
  autoClose: 2000,
} as ToastOptions;

function Pollworker() {
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const reportsTo = useSelector((state: AppState) => state.pollworker.grid.reportsTo);
  const roleTypes = useSelector((state: AppState) => state.pollworker.grid.roleTypes);
  const quickFilter = useSelector((state: AppState) => state.pollworker.grid.quickFilter);
  const displayedRowsCount = useSelector((state: AppState) => state.pollworker.grid.displayedRowCount);
  const isFiltered = useSelector((state: AppState) => state.pollworker.grid.isFiltered);
  const settings = useSelector((state: AppState) => state.pollworker.settings);
  const selectedRows = useSelector(getSelectedPollworkers);
  const workers = useSelector(getPollworkerSelector);
  const showPollworkerDetails = useSelector(getShowPollworkerDetails);
  const searchInputRef = useRef(null);
  const gridRef = useRef<any>(null);
  const detailsPanelRef = useRef<ImperativePanelHandle>(null);
  const [isSearchPressed] = useKeyboardJs('ctrl + k');
  const dispatch = useDispatch();
  const [, setLoadingPollworkerData, handlePollworkers] = useHandlePollworkers();

  useEffect(() => {
    if (selectedRows.length === 1) {
      dispatch(setShowPollworkerDetailsId(selectedRows[0].id));
    } else if (showPollworkerDetails && selectedRows.length) {
      const currentPollworkerDetailsIsInList = selectedRows.find((row: PollworkerWorkHistory) => row.keyEVUserId === showPollworkerDetails.keyEVUserId);

      if (!currentPollworkerDetailsIsInList) {
        dispatch(setShowPollworkerDetailsId(currentPollworkerDetailsIsInList.id));
      }
    }
  }, [selectedRows, workers]);

  useEffect(() => {
    if (!isSearchPressed) return;
    // @ts-ignore
    searchInputRef.current?.focus();
  }, [isSearchPressed]);

  useEffect(() => {
    if (!searchInputRef.current || quickFilter) return;

    // @ts-ignore
    searchInputRef.current.value = '';
  }, [searchInputRef, quickFilter]);

  useNativeMessageListenerEffect((from: string, eventName: string, eventValue: string, responseData: string) => {
    if (from === 'System') {
      switch (eventName) {
        case 'Dialog':
          setLoadingPollworkerData(false);
          if (!!responseData && moduleInfo) {
            const workerIds = responseData.split(',');

            if (workerIds.length > 3) {
              handlePollworkers();
              return;
            }

            workerIds.forEach(() => refreshPollworkerWorkHistory(moduleInfo.ElectionId, responseData));
          } else {
            handlePollworkers();
          }
          break;
        case 'Settings':
          if (eventValue !== 'Response') return;
          setPollworkerSettings(JSON.parse(atob(responseData)));
      }
    }
  }, [moduleInfo, workers]);

  useEffect(() => {
    if (!moduleInfo?.ElectionId) return;

    handlePollworkers();
  }, [moduleInfo?.ElectionId]);

  async function update(pollworker: PollworkerWorkHistory, updateValue: any, updateDef: PollworkerColDefContext) {
    if (updateDef.type === 'userInfo') {
      const toastId = toast.loading('Saving...', { autoClose: false, position: 'bottom-center' });
      const evUser = await getPollworkerEvUser(pollworker.userInfo.id);
      const updatedEvUser: UserInfo = {
        ...evUser,
        ...pollworker.pollworkerInfo.userInfo,
      };

      await updatePollworkerEvUser(updatedEvUser);
      await refreshPollworkerWorkHistory(pollworker.keyElectionId, pollworker.keyEVUserId);

      toast.update(toastId, saveToastCompleteConfig);
    }

    if (updateDef.type === 'pollworkerInfo') {
      const toastId = toast.loading('Saving...', saveToastLoadingConfig);
      const { data } = await getPollworkers(pollworker.keyElectionId, pollworker.workerLevel, pollworker.userInfo.lastName[0].toLowerCase());

      const worker = data.workerList.find((worker: PollworkerWorkHistory) => worker.keyEVUserId === pollworker.keyEVUserId) as PollworkerWorkHistory;

      const { pollworkerInfo: latestPollworkerInfo } = worker;

      let deltaPollworkerInfo = {};
      if (updateDef.isKeyUpdate && updateDef.getValue) {
        deltaPollworkerInfo = updateDef.getValue(pollworker, updateValue)
      }

      const merged = {
        ...latestPollworkerInfo,
        ...pollworker.pollworkerInfo,
        ...deltaPollworkerInfo,
      };

      // @ts-expect-error
      await updatePollworker(merged);
      await refreshPollworkerWorkHistory(pollworker.keyElectionId, pollworker.keyEVUserId);

      toast.update(toastId, saveToastCompleteConfig);
    }

    if (updateDef.type === 'roleInfo2') {
      const toastId = toast.loading('Saving...', saveToastLoadingConfig);
      const reportTo = reportsTo.find(rt => rt.id === updateValue);

      if (!reportTo) return;

      const latestPollworker = await getPollworkerById(pollworker);

      const updatePayload = {
        id: latestPollworker.id,
        reportsToId: reportTo.id,
      };

      await updatePollworkerWorkHistory(updatePayload);
      await refreshPollworkerWorkHistory(latestPollworker.keyElectionId, latestPollworker.keyEVUserId);

      toast.update(toastId, saveToastCompleteConfig);
    }

    if (updateDef.type === 'workConfirmation') {
      if (!moduleInfo) return;

      const toastId = toast.loading('Saving...', saveToastLoadingConfig);

      if (pollworker._workAssignmentConfirmation.id) {
        await updatePollworkerWorkHistoryWorkConfirmation(
          pollworker._workAssignmentConfirmation,
          updateValue,
        );
      } else {
        await createPollworkerWorkHistoryWorkConfirmation(moduleInfo?.ElectionId, pollworker.id, updateValue);
      }

      const pollworkerWorkAssignmentConfirmationsForElection = await getPollworkerWorkHistoryAssignmentConfirmationsForElection(moduleInfo.ElectionId);
      dispatch(setPollworkerWorkAssignmentConfirmationsForElection(pollworkerWorkAssignmentConfirmationsForElection));

      await refreshPollworkerWorkHistory(pollworker.keyElectionId, pollworker.keyEVUserId);

      toast.update(toastId, { ...saveToastCompleteConfig, render: 'Work confirmation set!' });
    }

    if (updateDef.type === 'roleInfo') {
      const toastId = toast.loading('Saving...', saveToastLoadingConfig);

      const latestPollworker = await getPollworkerById(pollworker);

      const selectedRoleType = roleTypes.find(rt => rt.roleName === updateValue);

      const updatePayload = {
        id: latestPollworker.id,
        keyRoleId: selectedRoleType?.id,
        reportsToId: latestPollworker.reportsToId,
      };

      await updatePollworkerWorkHistory(updatePayload);

      toast.update(toastId, { ...saveToastCompleteConfig, render: 'Role set!' });
    }
  }

  const refreshPollworkerWorkHistory = useCallback(async (electionId: string, evUserId: string) => {
    if (!electionId) return;

    const pollworker = workers.find((worker: PollworkerWorkHistory) => [worker.keyPollworkerId, worker.keyEVUserId].includes(evUserId));

    return getPollworkerById(pollworker).then(async (updatedPollworker) => {
      const customFields = await getPollworkerCustomFields(pollworker.userInfo.keyCustomerId);
      dispatch(setPollworkerCustomFieldsData(customFields));
      dispatch(updatePollworkerState(updatedPollworker));
    });
  }, [moduleInfo?.ElectionId, workers]);

  if (!moduleInfo?.Election) return null;

  return (
    <div className="relative h-full">
      <DialogPollworkerSetStatus onSave={() => handlePollworkers()} />
      <DialogPollworkerSetDisplayFlags onSave={() => handlePollworkers()} />
      <DialogBulkSetSms onSave={() => handlePollworkers()} />
      <DialogPollworkerBulkSendSms />
      <div className="h-full flex flex-col justify-between">
        <Flexor className="relative z-50 w-full border-b-0 divide-x divide-gray-400/80 bg-white border border-gray-400/80">
          <Flexor>
            <Flexor className='text-sm px-2 space-x-2 max-w-32'>
              {isFiltered ? <FunnelIcon className='h-3 w-3 shrink-0' /> : <UsersIcon className='h-4 w-4 shrink-0' /> }
              <div className='grow'>{isFiltered ? `${displayedRowsCount} / ${workers.length}` : workers.length}</div>
            </Flexor>
            <Flexor className='text-sm px-2 space-x-2 max-w-20 h-full'>
              <CursorArrowRaysIcon className='h-4 w-4 shrink-0' />
              <div className='grow'>{selectedRows.length}</div>
            </Flexor>
          </Flexor>
          <FeatureFlagCheck dbFlag={Feature.MODERN_POLLWORKER_SCHEDULER}>
            <Flexor className='space-x-2 px-2'>
              <Link to='/scheduler'>
                <button>
                  <Flexor className='space-x-2 h-full'>
                    <CalendarDaysIcon className='size-4' />
                    <span className='text-sm'>Scheduler</span>
                  </Flexor>
                </button>
              </Link>
            </Flexor>
          </FeatureFlagCheck>
          <FeatureFlagCheck flag="pollworker-timeclock">
            <Flexor className='space-x-2 px-2'>
              <Link to='/tracker'>
                <button>
                  <Flexor className='space-x-2 h-full'>
                    <ClockIcon className='size-4' />
                    <span className='text-sm'>Tracker</span>
                  </Flexor>
                </button>
              </Link>
            </Flexor>
          </FeatureFlagCheck>
          <div className="relative flex-grow flex items-center">
            <input
              type="text"
              id="search"
              name="search"
              autoFocus={false}
              ref={searchInputRef}
              placeholder="Quick search"
              onChange={({target: {value}}) => dispatch(setQuickFilter(value))}
              className="block w-full py-1.5 pr-14 text-gray-900 shadow-sm border-0 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-red-600 sm:text-sm sm:leading-6"
            />
            {
              quickFilter?.length ? (
                <div className="absolute right-0.5 flex px-2 py-1.5 border-l border-gray-200">
                  <button onClick={() => dispatch(setQuickFilter(''))}>
                    <XCircleIcon className="h-5 w-5 hover:text-gray-800 text-gray-700 transition-colors" />
                  </button>
                </div>
              ) : null
            }
            <div className={`absolute inset-y-0 ${quickFilter?.length ? 'right-11' : 'right-0'} flex py-1.5 pr-1.5`}>
              <kbd className="inline-flex items-center border border-gray-200 px-1 font-sans text-xs text-gray-400">
                Ctrl+K
              </kbd>
            </div>
          </div>
          <GridReloadButton onClick={() => handlePollworkers()} />
          <GridResetDropdown resetRef={gridRef}/>
          <GridSettingsDropdown />
        </Flexor>
        <PanelGroup direction="horizontal">
          <Panel defaultSize={66.6} minSize={20}>
            <PollworkerGrid
              reload={handlePollworkers}
              update={update}
              resetRef={gridRef}
            />
          </Panel>
          {
            showPollworkerDetails && settings.showDetailsView ? (
              <>
                <PanelResizeHandle disabled />
                <Panel ref={detailsPanelRef} collapsible collapsedSize={0} defaultSize={33.4}>
                  {
                    settings.showDetailsView ? (
                      <div className={`overflow-x-hidden h-full overflow-y-auto border-t bg-white border border-gray-400/80 border-l-0`}>
                        <PollworkerDetails />
                      </div>
                    ) : null
                  }
                </Panel>
              </>
            ) : null
          }
          {
            !showPollworkerDetails || !settings.showDetailsView ? (
              <div
                className={classNames(!settings.showDetailsView ? 'cursor-pointer': '', "bg-white flex flex-col align-top pt-3 border border-gray-400/80 border-l-0 h-full")}
                onClick={() => dispatch(setPollworkerSettings({showDetailsView: !settings.showDetailsView}))}
              >
                {!settings.showDetailsView ? <ArrowDownTrayIcon className="transform rotate-90 h-4 w-4 mb-1 ml-0.5" /> : <IdentificationIcon className="transform rotate-90 h-4 w-4 mb-1 ml-0.5" />}
                <span className="block text-xs px-0.5" style={{writingMode: "vertical-lr", textOrientation: "mixed"}}>
                  {settings.showDetailsView ? 'Select a pollworker to view details' : 'Click to enable pollworker details'}
                </span>
              </div>
            ) : null
          }
        </PanelGroup>
      </div>
      <ToastContainer />
    </div>
  )
}

export default function PollworkerWithModuleInfoManager() {
  return (
    <>
      <ModuleInfoChangeManager />
      <Pollworker />
    </>
  )
}
