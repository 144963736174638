import React, { useRef } from "react";
import PanelHeader from "./ui/PanelHeader";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import { Documents, DocumentsList, DocumentsUpload, useDocumentsContext } from "../../ui/Documents";
import { toast } from "react-toastify";
import type { UploadedDocumentFileResponse } from "admin/src/types";
import { uploadDocument } from "admin/src/utils/documents";
import { getFileFromSecureStorage } from "admin/src/fetchers";

export default function PollworkerDocumentsPanel() {
  const pollworker = useSelectedPollworker();

  function onUpload(uploadedFiles: UploadedDocumentFileResponse[]) {
    const [firstOrOnlyFile] = uploadedFiles;
    toast.success(uploadedFiles.length > 1 ? `${uploadedFiles.length} files uploaded!` : `"${firstOrOnlyFile.file.name}" was uploaded!`);
  }

  return (
    <Documents
      disableToast
      onUpload={onUpload}
      upload={uploadDocument}
      download={getFileFromSecureStorage}
      objectId={pollworker.keyEVUserId}
      className='space-y-none'
    >
      <Header />
      <DocumentsList />
    </Documents>
  );
}

function Header() {
  const { loadDocuments, loadingDocuments } = useDocumentsContext();
  const documentUploadRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <DocumentsUpload className='hidden' ref={documentUploadRef} />
      <PanelHeader title='Documents' loading={loadingDocuments} reload={loadDocuments} onAdd={() => documentUploadRef.current?.click()} />
    </>
  )
}
