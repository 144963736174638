import dayjs from "dayjs";
import { useSelector } from "react-redux";
import generate, { filterAndSortGeneratedSchedule, LocationAssignmentsByDate, LocationAssignmentsForDate, PartiesById, VotingLocationWithAssignments } from "../components/Pollworker/Panels/pollworkerVotingLocationDetailsUtils";
import { loadFullListForLocationWithAssignments, loadPollworkerSchedulesForLocation } from "../fetchers";
import { VotingLocationSchedule } from "../types/Pollworker/PollworkerWorkHistory";
import { AppState } from 'admin/src/store';
import { useEffect, useMemo, useState } from "react";
import { useElectionDates } from "./useElectionDates";
import { getModuleInfo } from "../selectors/pollworker";

export default function useVotingLocationData(parties?: PartiesById, votingLocationId?: string, autoLoad: boolean = true): [boolean, boolean, [string, LocationAssignmentsForDate][], () => void] {
  const [loading, setLoading] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [generatedSchedule, setGeneratedSchedule] = useState<LocationAssignmentsByDate>({ dates: {} });
  const {hidePastDates, showOnlyElectionDates} = useSelector((state: AppState) => state.pollworker.scheduler.filters);
  const moduleInfo = useSelector(getModuleInfo);
  const electionDates = useElectionDates();

  useEffect(() => {
    if (!autoLoad) return;

    getVotingLocationData();
  }, [autoLoad]);

  async function getVotingLocationData() {
    if (!moduleInfo?.ElectionId || !parties || !votingLocationId) return [loading, hasLoaded, generatedSchedule];

    setLoading(true);

    const locationWithAssignments: VotingLocationWithAssignments[] = await loadFullListForLocationWithAssignments(votingLocationId).then((resp) => resp.data);
    const pollworkerSchedulesForLocation: VotingLocationSchedule[] = await loadPollworkerSchedulesForLocation(votingLocationId, moduleInfo.ElectionId).then((resp) => {
      const schedules = resp.data;

      return schedules.sort((a: VotingLocationSchedule, b: VotingLocationSchedule) => {
        return dayjs(a.createdAt).isBefore(b.createdAt) ? 1 : -1;
      })
    });

    setLoading(false);
    setHasLoaded(true);
    setGeneratedSchedule(generate(pollworkerSchedulesForLocation, locationWithAssignments, parties));
  }

  const scheduleDates = useMemo(() => {
    return filterAndSortGeneratedSchedule(generatedSchedule, electionDates, showOnlyElectionDates, hidePastDates);
  }, [generatedSchedule.dates, hidePastDates, showOnlyElectionDates, electionDates]);

  return [loading, hasLoaded, scheduleDates, getVotingLocationData];
}
