import React, { useEffect, useState } from "react";
import { Select } from "shared/src/components/ui";
import { STATES } from "shared/src/utils/data/usStates";
import { DistributionCenter } from "../../../types/DistributionCenter";
import EmployeeSelect from "../../../components/ui/EmployeeSelect";
import { Textarea, Switch, Button, Input, Label } from "shared/src/components/ui/new";
import PhoneInput from "react-phone-number-input/input";

type DistributionCenterModalProps = {
  distributionCenter?: DistributionCenter,
  onSubmit: ((param?: DistributionCenter) => Promise<void>)
}

export default function DistributionCenterForm({ distributionCenter, onSubmit }: DistributionCenterModalProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [formData, setFormData] = useState({
    locationName: '',
    mainWarehouse: false,
    manager: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    contactName: '',
    cellPhone: '',
    email: '',
    contactTitle: '',
    notes: '',
  });

  useEffect(() => {
    if (distributionCenter) {
      return setFormData({
        locationName: distributionCenter.centerName || '',
        mainWarehouse: distributionCenter.isDefaultWarehouse || false,
        manager: distributionCenter.keyManagerEVUserId || '',
        address1: distributionCenter.address1 || '',
        address2: distributionCenter.address2 || '',
        city: distributionCenter.city || '',
        state: distributionCenter.state || '',
        zip: distributionCenter.zip || '',
        contactName: distributionCenter.contactName || '',
        cellPhone: distributionCenter.contactCellPhone || '',
        email: distributionCenter.contactEmail || '',
        contactTitle: distributionCenter.contactType || '',
        notes: distributionCenter.notes || '',
      });
    }

    setFormData({
      locationName: '',
      mainWarehouse: false,
      manager: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      contactName: '',
      cellPhone: '',
      email: '',
      contactTitle: '',
      notes: '',
    });
  }, [distributionCenter]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const {
      id,
      value,
      type
    } = e.target;
    const checked = type === 'checkbox' ? (e.target as HTMLInputElement).checked : undefined;

    setFormData((prev) => ({
      ...prev,
      [id]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleManagerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFormData((prev) => ({
      ...prev,
      manager: e.target.value,
    }));
  };

  useEffect(() => {
    const requiredFields: (keyof typeof formData)[] = [
      'locationName',
    ];

    const isComplete = requiredFields.every((field) => {
      const value = formData[field];
      return typeof value === 'string' ? value.trim() !== '' : !!value;
    });

    setIsSubmitDisabled(!isComplete);
  }, [formData]);

  const handleSubmitButton = () => {
    const transformedData: DistributionCenter = {
      id: distributionCenter?.id || '',
      centerName: formData.locationName,
      keyCustomerId: distributionCenter?.keyCustomerId || '',
      address1: formData.address1,
      address2: formData.address2,
      state: formData.state,
      city: formData.city,
      zip: formData.zip,
      longitude: distributionCenter?.longitude || '',
      latitude: distributionCenter?.latitude || '',
      contactName: formData.contactName,
      contactCellPhone: formData.cellPhone,
      contactEmail: formData.email,
      contactType: formData.contactTitle,
      notes: formData.notes,
      coreId: distributionCenter?.coreId || '',
      isDefaultWarehouse: formData.mainWarehouse,
      keyManagerEVUserId: formData.manager,
      managerEVUser: null,
    };

    onSubmit(transformedData);
  };

  return (
    <>
      <div className="divide-y space-y-5 [&>div]:pt-5">
        <div className="space-y-3">
          <h2 className="font-semibold">General</h2>
          <div>
            <Label htmlFor="locationName">Name</Label>
            <Input
              id="locationName"
              type="text"
              placeholder="Distribution center name"
              value={formData.locationName}
              onChange={handleChange}
            />
          </div>
          <div className="flex items-center space-x-2">
            <Switch
              id="mainWarehouse"
              checked={formData.mainWarehouse}
              onCheckedChange={(checked) => setFormData({...formData, mainWarehouse: checked})}
            />
            <Label htmlFor="mainWarehouse">Main Warehouse (used as default for Packages)</Label>
          </div>
          <div>
            <Label htmlFor="managerSelect">Manager</Label>
            <EmployeeSelect
              id="managerSelect"
              value={formData.manager}
              onChange={handleManagerChange}
              className=""
              placeholder="Select a manager"
            />
          </div>
          <div>
            <Label htmlFor="notes">Notes</Label>
            <Textarea
              id="notes"
              placeholder="Write any extra details about this distribution center"
              value={formData.notes}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="space-y-3">
          <h2 className="font-semibold">Address</h2>
          <div>
            <Label htmlFor="address1">Street address</Label>
            <Input
              id="address1"
              type="text"
              placeholder="Street address"
              value={formData.address1}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="address2">Address 2</Label>
            <Input
              id="address2"
              type="text"
              placeholder="Street address 2"
              value={formData.address2}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="city">City</Label>
            <Input
              id="city"
              type="text"
              placeholder="Enter city"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              value={formData.city}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor="state">State</Label>
            <Select
              id="state"
              value={formData.state}
              onChange={handleChange}
            >
              <option value="">Select a state</option>
              {STATES.map((state) => (
                <option key={state.abbr} value={state.abbr}>
                  {state.state} ({state.abbr})
                </option>
              ))}
            </Select>
          </div>
          <div>
            <Label htmlFor="zip">Zip</Label>
            <Input
              id="zip"
              type="text"
              placeholder="Enter zip"
              value={formData.zip}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="space-y-2">
          <h2 className="font-semibold">Primary contact</h2>
          <div>
            <Label htmlFor="contactName">Name</Label>
            <Input
              id="contactName"
              type="text"
              placeholder="Full name"
              value={formData.contactName}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label htmlFor='cellPhone'>Cell</Label>
            <PhoneInput
              country="US"
              id="cellPhone"
              placeholder="(___)-___-____"
              withCountryCallingCode={false}
              defaultCountry='US'
              value={'+1' + formData.cellPhone.replaceAll(/\D/g, '')}
              inputComponent={Input}
              onChange={(value) => setFormData({
                ...formData,
                cellPhone: value?.toString()?.substring(2) || '', // pop country code
              })}
            />
          </div>
          <div>
            <Label id='email'>Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <Label id='contactTitle'>Title</Label>
            <Input
              id="contactTitle"
              type="text"
              placeholder="Enter title"
              value={formData.contactTitle}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            disabled={isSubmitDisabled}
            onClick={handleSubmitButton}
          >
            {distributionCenter ? 'Update' : 'Create'}
          </Button>
        </div>
      </div>
    </>
  );
}
