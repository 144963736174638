import {ConsumableItem} from "../../types/ConsumableItem";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getItemInCart} from "../../selectors/selectors";
import {useClickAway} from "react-use";
import UserOnly from "../../components/UserOnly";
import {classNames} from "shared/src/utils/classNames";
import EditItemQuantityForm from "../user/EditItemQuantityForm";
import { Flexor } from "shared/src/components"
import Tippy from "@tippyjs/react";
import TippyContent from "../../../../shared/src/components/TippyContent";
import AddToCartButton from "../../components/user/AddToCartButton";
import {PencilSquareIcon, PlusCircleIcon} from "@heroicons/react/24/solid";
import {CheckIcon} from "@heroicons/react/24/outline";
import {addToCart, removeFromCart} from "../../fetchers";
import { showToast } from "admin/src/actions/toast";
import { CART_ACTION_TYPES, removeItem } from "admin/src/actions/cart";
import { fetchConsumables } from "admin/src/actions";

export default function EditItemQuantityInStore({item}: { item: ConsumableItem }) {
  const editToggleRef = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  const cartItem = useSelector(getItemInCart(item?.id));
  const [selectedQuantity, setSelectedQuantity] = useState<number>(cartItem?.quantity || 0);
  useClickAway(editToggleRef, () => {
    setIsEditing(false);
  });

  useEffect(() => {
    if (!isEditing) {
      setSelectedQuantity(cartItem?.quantity || 0);
      return;
    }

    inputRef?.current?.focus();
  }, [isEditing]);

  useEffect(() => {
    setSelectedQuantity(cartItem?.quantity || 0);
  }, [cartItem?.quantity]);

  return (
    <UserOnly>
      <div className="absolute bottom-0 right-4" ref={editToggleRef}>
        {cartItem?.quantity && <div title="Current quantity in your cart" className={classNames("text-center ring-ev-red bg-white rounded-full absolute bottom-7 -right-3 text-xs font-semibold p-0.5 shadow px-1 ring")}>{cartItem?.quantity}</div>}
        {
          isEditing ? (
            <EditItemQuantityForm
              ref={inputRef}
              className="mb-4 bg-white/80 active:bg-white transition-all w-full font-bold"
              value={selectedQuantity}
              min={0}
              onChange={(event) => {
                setSelectedQuantity(Number.parseInt(event.target.value));
              }}
              max={item.countOnHand}
            />
          ) : null
        }

        <Flexor justify="end">
          <Tippy placement="left" arrow content={(
            <>
              {!isEditing ? <TippyContent>Click to add items</TippyContent> : null }
            </>
          )}>
            <div className="rounded w-10 flex justify-center hover:ring-2 ring-white inline-block overflow-hidden">
              <AddToCartButton
                icon={(
                  <div className="transition hover:scale-125 ease-in-out">
                    {
                      !isEditing ? (
                          cartItem?.quantity ? <PencilSquareIcon className={classNames(cartItem.quantity ? 'translate-y-0.5 -translate-x-0.5' : '', "h-5 w-5")} /> : <PlusCircleIcon className="h-5 w-5" />
                        ) :
                        <CheckIcon className="h-5 w-5" />
                    }

                  </div>
                )}
                onClick={() => {
                  if (!isEditing) {
                    setIsEditing(true);
                    return;
                  }

                  if (!inputRef.current) return;

                  const isValid = inputRef.current.checkValidity();

                  // @ts-ignore
                  if (isValid && selectedQuantity) {
                    addToCart(item, selectedQuantity).then((data => {
                      let isUpdate = !!data.updatedAt;

                      dispatch(showToast({content: `You have ${isUpdate ? 'updated' : 'added'} an item in the cart.`, undoAction: CART_ACTION_TYPES.UNDO}));
                      dispatch(fetchConsumables());
                    }));
                  }
                  // @ts-ignore
                  if (isValid && !selectedQuantity) {
                    removeFromCart(cartItem!).then((success) => {
                      if (success) {
                        dispatch(removeItem(cartItem!));
                        dispatch(showToast({content: `${cartItem!.inventoryEquipType.equipTypeName} (${cartItem!.quantity}) has been removed.`, undoAction: undefined}))
                      }
                    });
                  }

                  setIsEditing(false);
                }}
              >
                {''}
              </AddToCartButton>
            </div>
          </Tippy>
        </Flexor>
      </div>
    </UserOnly>
  );
}
