import React from "react";
import { useDocumentsContext } from "./DocumentsContext";

export function DocumentsLoading() {
  const { documents, loadingDocuments } = useDocumentsContext();

  if (!documents?.length) {
    return (
      <div className='h-36 flex items-center justify-center text-sm'>
        { loadingDocuments ? 'Loading documents...' : 'No documents found' }
      </div>
    );
  }

  return null;
}
