import {createAction} from "@reduxjs/toolkit";
import {ConsumableItem} from "../types/ConsumableItem";

export const CONSUMABLE_ACTION_TYPES = {
  CLEAR: 'CLEAR',
  ADD_CONSUMABLE_ITEMS: 'ADD_CONSUMABLE_ITEMS',
  LOADED_CONSUMABLES: 'LOADED_CONSUMABLES',
  LOADING_CONSUMABLES: 'LOADING_CONSUMABLES',
  FAILED_LOADING_CONSUMABLES: 'FAILED_LOADING_CONSUMABLES',
  FETCH_CONSUMABLES: 'FETCH_CONSUMABLES',
}

export const clearConsumables = createAction(CONSUMABLE_ACTION_TYPES.CLEAR);
export const addConsumables = createAction<ConsumableItem[]>(CONSUMABLE_ACTION_TYPES.ADD_CONSUMABLE_ITEMS);
export const loadingConsumables = createAction(CONSUMABLE_ACTION_TYPES.LOADING_CONSUMABLES);
export const failedLoadingConsumables = createAction<string | undefined>(CONSUMABLE_ACTION_TYPES.FAILED_LOADING_CONSUMABLES);
export const loadedConsumables = createAction(CONSUMABLE_ACTION_TYPES.LOADED_CONSUMABLES);
export const fetchConsumables = createAction(CONSUMABLE_ACTION_TYPES.FETCH_CONSUMABLES);
