import {AnyAction} from "@reduxjs/toolkit";
import {MODAL_ACTION_TYPES} from "../actions/modal";

const defaultModalState = {};

export default function modalReducer(state: any = defaultModalState, action: AnyAction) {
  switch (action.type) {
    case MODAL_ACTION_TYPES.SHOW:
      return {
        ...state,
        open: action.payload,
      }
    case MODAL_ACTION_TYPES.HIDE:
      return {
        ...state,
        open: undefined,
      }
      // return {...state, logo: action.payload ? `data:image/png;base64,${action.payload}` : null};
    default:
      // console.log('Theme reducer: Missed action', action);
      return state;
  }
}
