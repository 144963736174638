import React, {
  forwardRef,
  Fragment, InputHTMLAttributes,
  useEffect,
  useRef,
  useState
} from "react";
import {useSelector} from "react-redux";
import { AppState } from 'admin/src/store';
import {getShowPollworkerDetails} from "admin/src/selectors/pollworker";
import {
  createPollworkerEmergencyContact,
  deletePollworkerEmergencyContact,
  loadPollworkerContacts,
  patchPollworkerEmergencyContact
} from "admin/src/fetchers";
import dayjs from "dayjs";
import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import Spinner from "shared/src/components/Spinner";
import {
  AtSymbolIcon,
  ChevronRightIcon,
  HomeIcon,
  NoSymbolIcon, PencilSquareIcon, PhoneIcon,
  TrashIcon, UserIcon
} from "@heroicons/react/24/outline";
import {EllipsisVerticalIcon} from "@heroicons/react/24/solid";
import {PollworkerContact} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import {classNames} from "shared/src/utils/classNames";
import {Menu, Transition} from "@headlessui/react";
import { z, ZodSchema } from 'zod';
import {FieldError, useForm} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import PhoneInput from 'react-phone-number-input/input';
import {STATES} from "shared/src/utils/data/usStates";
import {Flexor} from "shared/src/components";
import {Button} from "shared/src/components/ui";
import {phoneNumberFormatter} from "shared/src/utils/formatters";
import PanelHeader from "./ui/PanelHeader";
import PanelSlideout, { PanelSlideoutHeader } from "./ui/PanelSlideout";
import { Input, Select, Label, Textarea } from 'shared/src/components/ui';
import { StickyFooter } from "./ui/StickyFooter";

interface Contact {
  name: {
    prefix?: string
    firstName?: string
    lastName?: string
    suffix?: string
  }
  residenceAddress: {
    address1?: string
    address2?: string
    city?: string
    state?: string
    zipCode?: string
  }
  mailingAddress: {
    address1?: string
    address2?: string
    city?: string
    state?: string
    zipCode?: string
  },
  contactInfo: {
    homePhone?: string
    cellPhone?: string
    email?: string
  },
  notes?: string,
}

function ErrorLabel({error}: {error?: FieldError}) {
  if (!error) return null;

  return (<div className="text-ev-red text-xs">{error?.message}</div>);
}

type AddEditEmergencyContactSlideoutProps = {
  show: boolean,
  hide: () => void,
  contact?: PollworkerContact,
  save: (contact: Contact) => void,
}

const addressSchema = (rootErrorMessage: string) => z.object({
  address1: z.string().optional(),
  address2: z.string().optional(),
  city: z.string().optional(),
  state: z.string().refine(state => !state || state.length === 2, {
    message: "State must be 2 characters long."
  }),
  zipCode: z.string().optional().refine(zipCode => !zipCode || zipCode.length === 5, {
    message: "Zip Code must be 5 characters long."
  }),
}).refine(data => {
  const isAnyFieldFilled = data && Object.values(data).some(value => value !== undefined && value !== '');

  if (isAnyFieldFilled) {
    return data && data.address1 && data.city && data.state && data.zipCode;
  }

  return true;
}, {
  message: rootErrorMessage
});

const emergencyContactSchema: ZodSchema = z.object({
  name: z.object({
    prefix: z.string().optional(),
    firstName: z.string().min(2, 'Must be at least two characters'),
    lastName: z.string().min(2, 'Must be at least two characters'),
    suffix: z.string().optional()
  }),
  contactInfo: z.object({
    homePhone: z.string().optional(),
    cellPhone: z.string().optional(),
    email: z.string().optional().refine(email => !email || z.string().email().safeParse(email).success, {
      message: 'Valid email required.',
    }),
  }).refine(data => {
    console.log('validating contact info', data, !!data.homePhone && !!data.cellPhone);
    return !!data.homePhone || !!data.cellPhone;
  }, {
    message: "Either home phone or cell phone must be provided."
  }),
  residenceAddress: addressSchema("All fields in residence address must be filled if any are filled."),
  mailingAddress: addressSchema("All fields in mailing address must be filled if any are filled."),
  notes: z.string().optional()
});

function AddEditEmergencyContact({contact, save}: AddEditEmergencyContactSlideoutProps) {
  const formRef = useRef<HTMLFormElement>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Contact>({
    defaultValues: {
      name: {
        prefix: contact?.prefix,
        firstName: contact?.firstName,
        lastName: contact?.lastName,
        suffix: contact?.suffix,
      },
      contactInfo: {
        cellPhone: contact?.cellPhone,
        homePhone: contact?.homePhone,
        email: contact?.emailAddress,
      },
      residenceAddress: {
        address1: contact?.address1,
        address2: contact?.address2,
        city: contact?.city,
        zipCode: contact?.zip,
        state: contact?.state,
      },
      mailingAddress: {
        address1: contact?.mailingAddress1,
        address2: contact?.mailingAddress2,
        city: contact?.mailingCity,
        zipCode: contact?.mailingZip,
        state: contact?.mailingState,
      },
      notes: contact?.notes,
    },
    resolver: zodResolver(emergencyContactSchema),
  });

  return (
    <form ref={formRef} onSubmit={handleSubmit((contact) => save(contact))}>
      <div>
        <div className="p-2">
          <SectionSubSubHeading className='-ml-2 mb-2'>
            <span className="bg-white px-2">Name</span>
          </SectionSubSubHeading>
          <div>
            <Label htmlFor="prefix">
              Prefix
            </Label>
            <Input
              type="text"
              id="prefix"

              placeholder='Mr. Mrs. Ms.'
              {...register('name.prefix')}
            />
            <ErrorLabel error={errors.name?.suffix} />
          </div>
          <div>
            <Label htmlFor="firstName">
              First name
            </Label>
            <div>
              <Input
                type="text"
                id="firstName"
                placeholder='John'
                {...register('name.firstName')}
              />
            </div>
            <ErrorLabel error={errors.name?.firstName} />
          </div>
          <div>
            <Label htmlFor="lastName">
              Last name
            </Label>
            <div>
              <Input
                type="text"
                id="lastName"
                placeholder='Smith'
                {...register('name.lastName')}
              />
            </div>
            <ErrorLabel error={errors.name?.lastName} />
          </div>
          <div>
            <Label htmlFor="suffix">
              Suffix
            </Label>
            <div>
              <Input
                type="text"
                id="suffix"
                placeholder='Sr. Jr. III IV'
                {...register('name.suffix')}
              />
            </div>
            <ErrorLabel error={errors.name?.suffix} />
          </div>
        </div>
        <div className="p-2">
          <SectionSubSubHeading className='-ml-2 mb-2 pt-0.5'>
            <span className="bg-white px-2">Contact</span>
          </SectionSubSubHeading>
          <ErrorLabel error={errors.contactInfo?.root} />
          <div className="sm:col-span-4">
            <Label htmlFor="contactInfo.cellPhone">
              Cell Phone
            </Label>
            <div>
              <PhoneInput
                country="US"
                placeholder='(___)-___-____'
                id='contactInfo.cellPhone'
                withCountryCallingCode={false}
                {...register('contactInfo.cellPhone')}
                inputComponent={forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
                  return <Input ref={ref} {...props} />
                })}
                onChange={(value) => setValue('contactInfo.cellPhone', value)}
              />
            </div>
            <ErrorLabel error={errors.contactInfo?.cellPhone} />
          </div>
          <div className="sm:col-span-4">
            <Label htmlFor="contactInfo.homePhone">
              Home Phone
            </Label>
            <div>
              <PhoneInput
                country="US"
                placeholder='(___)-___-____'
                withCountryCallingCode={false}
                id="contactInfo.homePhone"
                {...register('contactInfo.homePhone')}
                inputComponent={forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>((props, ref) => {
                  return <Input ref={ref} {...props} />
                })}
                onChange={(value) => setValue('contactInfo.homePhone', value)}
              />
            </div>
            <ErrorLabel error={errors.contactInfo?.homePhone} />
          </div>
          <div className="sm:col-span-4">
            <Label htmlFor="contactInfo.email">
              Email address
            </Label>
            <div>
              <Input
                id="contactInfo.email"
                type="email"
                placeholder='john@example.com'
                {...register('contactInfo.email')}
              />
            </div>
            <ErrorLabel error={errors.contactInfo?.email} />
          </div>
        </div>
      </div>

      <div>
        <div className="p-2">
          <SectionSubSubHeading className='mb-2 pt-0.5'>
            Residence Address
          </SectionSubSubHeading>
          <div className="col-span-full">
            <Label htmlFor="residenceAddress.address1">
              Street address
            </Label>
            <div className='mb-2'>
              <Input
                placeholder='123 Main St.'
                type="text"
                id="residenceAddress.address1"
                {...register('residenceAddress.address1')}
              />
              <ErrorLabel error={errors.residenceAddress?.address1} />
            </div>
            <div>
              <Input
                type="text"
                placeholder='Apt 2'
                name="street-address"
                id="street-address"
              />
            </div>
            <ErrorLabel error={errors.residenceAddress?.address2} />
          </div>

          <div className="mt-1">
            <div className="grow">
              <Label htmlFor="residenceAddress.city">
                City
              </Label>
              <div className="">
                <Input
                  type="text"
                  placeholder='New York City'
                  id="residenceAddress.city"
                  {...register('residenceAddress.city')}
                />
                <ErrorLabel error={errors.residenceAddress?.city} />
              </div>
            </div>
            <Flexor className='space-x-2'>
              <div className='w-full'>
                <Label htmlFor="residenceAddress.state">
                  State
                </Label>
                <div>
                  <Select
                    id='residenceAddress.state'
                    defaultValue=''
                    {...register('residenceAddress.state')}
                  >
                    <option value='' disabled>State</option>
                    {
                      STATES.map((state) => {
                        return (<option key={state.abbr} value={state.abbr}>{state.abbr}</option>)
                      })
                    }
                  </Select>
                  <ErrorLabel error={errors.residenceAddress?.state} />
                </div>
              </div>
              <div className="w-2/5">
                <Label htmlFor="residenceAddress.zipCode">
                  ZIP
                </Label>
                <div>
                  <Input
                    type="text"
                    id="residenceAddress.zipCode"
                    placeholder='12345'
                    {...register('residenceAddress.zipCode')}
                  />
                  <ErrorLabel error={errors.residenceAddress?.zipCode} />
                </div>
              </div>
            </Flexor>
          </div>
        </div>
        <div className="p-2 space-y-2">
          <SectionSubSubHeading className='mb-2 pt-0.5'>Mailing Address</SectionSubSubHeading>
          <div className='space-y-2'>
            <div>
              <Label htmlFor="mailingAddress.address1">
                Street address
              </Label>
              <Input
                type="text"
                id="mailingAddress.address1"
                placeholder='567 Freedom St.'
                {...register('mailingAddress.address1')}
              />
              <ErrorLabel error={errors.mailingAddress?.address1} />
            </div>
            <div>
              <Input
                type="text"
                id="street-address"
                name="street-address"
                placeholder='Suite 2'
              />
              <ErrorLabel error={errors.mailingAddress?.address2} />
            </div>
          </div>

          <div className="space-y-2">
            <div>
              <Label htmlFor="mailingAddress.city">
                City
              </Label>
              <div className="">
                <Input
                  id="mailingAddress.city"
                  type="text"
                  placeholder='Los Angles'
                  {...register('mailingAddress.city')}
                />
                <ErrorLabel error={errors.mailingAddress?.city} />
              </div>
            </div>
            <Flexor className='space-x-2'>
              <div className='w-full'>
                <Label htmlFor="mailingAddress.state">
                  State
                </Label>
                <Select
                  id='mailingAddress.state'
                  defaultValue=''
                  {...register('mailingAddress.state')}
                >
                  <option value='' disabled>State</option>
                  {
                    STATES.map((state) => {
                      return (<option key={state.abbr} value={state.abbr}>{state.abbr}</option>)
                    })
                  }
                </Select>
                <ErrorLabel error={errors.mailingAddress?.state} />
              </div>
              <div className="w-2/5">
                <Label htmlFor="mailingAddress.zipCode">
                  ZIP
                </Label>
                <div className="">
                  <Input
                    type="text"
                    id="mailingAddress.zipCode"
                    placeholder='98765'
                    {...register('mailingAddress.zipCode')}
                  />
                  <ErrorLabel error={errors.mailingAddress?.zipCode} />
                </div>
              </div>
            </Flexor>
          </div>
        </div>
      </div>

      {errors.residenceAddress?.root || errors.mailingAddress?.root ? (
        <div className='mt-1 mb-2 w-full flex items-center'>
          <div className='w-1/2'><ErrorLabel error={errors.residenceAddress?.root} /></div>
          <div className='w-1/2 pl-2'><ErrorLabel error={errors.mailingAddress?.root} /></div>
        </div>
      ) : null }

      <div className='p-2'>
        <Label htmlFor="notes">
          Notes
        </Label>
        <div>
          <Textarea
            id="notes"
            rows={3}
            placeholder='Describe this contact and their relationship to the Poll Worker.'
            {...register('notes')}
            defaultValue={''}
          />
        </div>
      </div>
      <StickyFooter>
        <Flexor className="bg-gray-100 p-2" justify='end'>
          <Button type="submit">
            Save
          </Button>
        </Flexor>
      </StickyFooter>
    </form>
  );
}

export default function PollworkerContactsPanel() {
  const [contacts, setContacts] = useState<PollworkerContact[]>([]);
  const [expandedContactId, setExpandedContactId] = useState<string>();
  const [loadingContacts, setLoadingContacts] = useState<boolean>(false);
  const [contactToEdit, setContactToEdit] = useState<PollworkerContact>();
  const [showCreateContactModal, setShowCreateContactModal] = useState<boolean>(false);
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const pollworker = useSelector(getShowPollworkerDetails);

  useEffect(() => {
    if (!moduleInfo || !pollworker) return;

    loadContacts();
  }, [moduleInfo, pollworker]);

  function loadContacts() {
    if (!pollworker || !moduleInfo?.ElectionId) return;

    setLoadingContacts(true);
    loadPollworkerContacts(pollworker.keyEVUserId)
      .then(({data}) => {
        setContacts(JSON.parse(atob(data)).sort((a: PollworkerContact, b: PollworkerContact) => {
          return dayjs(a.updatedAt) > dayjs(b.updatedAt) ? -1 : 1;
        }));
      })
      .finally(() => setLoadingContacts(false));
  }

  function deleteContact(contact: PollworkerContact) {
    if (!window.confirm('Are you sure you want to delete this contact?')) return;

    deletePollworkerEmergencyContact(contact.id).finally(loadContacts);
  }

  function save(contact: Contact) {
    if (!pollworker) return;

    const existingContactId = contactToEdit?.id;

    const pollworkerContact = {
      id: existingContactId,
      address1: contact.residenceAddress.address1,
      address2: contact.residenceAddress.address2,
      city: contact.residenceAddress.city,
      state: contact.residenceAddress.state,
      zip: contact.residenceAddress.zipCode,
      cellPhone: contact.contactInfo.cellPhone,
      emailAddress: contact.contactInfo.email,
      homePhone: contact.contactInfo.homePhone,
      prefix: contact.name.prefix,
      firstName: contact.name.firstName,
      lastName: contact.name.lastName,
      suffix: contact.name.suffix,
      mailingAddress1: contact.mailingAddress.address1,
      mailingAddress2: contact.mailingAddress.address2,
      mailingCity: contact.mailingAddress.city,
      mailingState: contact.mailingAddress.state,
      mailingZip: contact.mailingAddress.zipCode,
      keyEVUserId: pollworker.pollworkerInfo.keyEVUserId,
      notes: contact.notes,
    } as Partial<PollworkerContact>;

    if (existingContactId) {
      patchPollworkerEmergencyContact(pollworkerContact).finally(loadContacts);
    } else {
      createPollworkerEmergencyContact(pollworkerContact).finally(loadContacts);
    }

    setShowCreateContactModal(false);
  }

  return (
    <div>
      <PanelSlideout show={showCreateContactModal}>
        <PanelSlideoutHeader close={() => setShowCreateContactModal(false)}>
          <Flexor className='space-x-1'>
            <UserIcon className='h-4 w-4' />
            <SectionSubSubHeading>{contactToEdit ? 'Edit' : 'New'} Contact</SectionSubSubHeading>
          </Flexor>
        </PanelSlideoutHeader>
        <AddEditEmergencyContact show={showCreateContactModal} hide={() => setShowCreateContactModal(false)} save={save} contact={contactToEdit} />
      </PanelSlideout>
      <PanelHeader title='Contacts' loading={loadingContacts} reload={loadContacts} onAdd={() => setShowCreateContactModal(true)} />
      {
        loadingContacts ? (
          <Flexor justify="center" className="space-x-2 mt-10">
            <Spinner show />
            <SectionSubSubHeading>Loading contacts...</SectionSubSubHeading>
          </Flexor>
        ) : (
          <>
            {
              !contacts.length ? (
                <Flexor justify="center" className="space-x-2 mt-10">
                  <NoSymbolIcon className="h-6 w-6" />
                  <SectionSubSubHeading>No contacts found</SectionSubSubHeading>
                </Flexor>
              ) : null
            }
            <ul className="divide-y divide-gray-100 mt-3">
              {
                contacts.map((contact, idx) => {
                  const expanded = expandedContactId === contact.id;

                  return (
                    <li key={contact.id}>
                      <dl key={contact.id} className={classNames("hover:bg-gray-100 pb-2 divide-y pl-2")}>
                        <Flexor justify="start" className="space-x-2" items="start">
                          <div className="py-4" onClick={() => setExpandedContactId(expanded ? undefined : contact.id)}>
                            <ChevronRightIcon className={classNames(expanded ? 'rotate-90' : '', "transition-transform h-4 w-4")} />
                          </div>
                          <div className="transition-all w-full">
                            <div className="w-full flex items-center justify-between py-3 text-sm font-medium">
                              <dt className="font-medium text-gray-700 w-full space-y-2">
                                <Flexor justify="start" className="space-x-2 w-full truncate break-all">
                                  <UserIcon className="h-4 w-4" />
                                  <div className="block">{[contact.prefix, contact.firstName, contact.lastName, contact.suffix].join(' ')}</div>
                                </Flexor>
                                <Flexor justify="start" className="space-x-2 w-full truncate break-all">
                                  <PhoneIcon className="h-4 w-4" />
                                  <span className={classNames(contact.cellPhone ? '' : 'font-normal italic')}>{phoneNumberFormatter({value: contact.cellPhone}) || 'Empty'}</span>
                                </Flexor>
                                <Flexor justify="start" className="space-x-2 w-full truncate break-all">
                                  <HomeIcon className="h-4 w-4" />
                                  <span className={classNames(contact.homePhone ? '' : 'font-normal italic')}>{phoneNumberFormatter({value: contact.homePhone}) || 'Empty'}</span>
                                </Flexor>
                                <Flexor justify="start" className="space-x-2 w-full truncate break-all">
                                  <AtSymbolIcon className="h-4 w-4" />
                                  <span className={classNames(contact.emailAddress ? '' : 'font-normal italic')}>{contact.emailAddress || 'Empty'}</span>
                                </Flexor>
                              </dt>
                              <dd className="text-sm text-gray-900 flex items-center justify-end">
                                <Menu as="div" className="flex-none">
                                  <Menu.Button className="block p-2.5 text-gray-500 hover:text-gray-900">
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items className="rounded-md absolute right-5 z-10 mt-0 w-32 origin-top-right bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active ? 'bg-gray-50' : 'text-gray-800',
                                              'flex items-center space-x-2 block px-3 py-1 text-sm leading-6'
                                            )}
                                            onClick={() => {
                                              setContactToEdit(contact);
                                              setShowCreateContactModal(true);
                                            }}
                                          >
                                            <PencilSquareIcon className="h-5 w-5" />
                                            <span>Edit<span className="sr-only">, {contact.id}</span></span>
                                          </a>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item disabled={false}>
                                        {({ active }) => (
                                          <a
                                            href="#"
                                            className={classNames(
                                              active ? 'bg-ev-red text-white' : 'bg-white text-gray-900',
                                              'flex items-center space-x-2 block px-3 py-1 text-sm leading-6'
                                            )}
                                            onClick={() => deleteContact(contact)}
                                          >
                                            <TrashIcon className="h-5 w-5" />
                                            <span>Delete<span className="sr-only">, {contact.id}</span></span>
                                          </a>
                                        )}
                                      </Menu.Item>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </dd>
                            </div>
                            {
                              expanded ? (
                                <div className="space-y-2">
                                  <div className="text-sm">
                                    <span className="leading-6 text-gray-800 font-semibold">Notes</span>
                                    <div className="border-l-2 border-gray-400 pl-2">{contact.notes}</div>
                                  </div>
                                </div>
                              ) : null
                            }
                          </div>
                        </Flexor>
                      </dl>
                    </li>
                  );
                })
              }
            </ul>
          </>
        )
      }
    </div>
  )
}
