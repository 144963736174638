import * as React from "react";

import { cn } from "shared/src/utils";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";

const selectVariant = cva(
  '',
  {
    variants: {
      variant: {
        default: `
          w-full
          rounded-md
          border-0
          pl-3
          pr-10
          text-gray-900
          ring-1
          ring-inset
          ring-gray-400
          focus:ring-2
          focus:ring-ev-red
          sm:text-sm
        `,
        new: `
          w-full
          flex
          py-1.5
          rounded-t
          text-action
          border-[0.5px]
          border-b-2
          border-action
          bg-background
          px-3
          text-sm
          focus:ring-0
          file:border-0
          file:bg-transparent
          file:text-sm
          file:font-medium
          placeholder:text-action
          focus-visible:outline-none
          disabled:cursor-not-allowed
          disabled:text-state-disabled
        `
      },
    },
    defaultVariants: {
      variant: 'default',
    }
  }
);

export interface SelectProps extends React.SelectHTMLAttributes<HTMLSelectElement>, VariantProps<typeof selectVariant> {
}

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  ({
    variant,
    className,
    ...props
  }, ref) => {
    return (
      <select
        className={twMerge(cn(selectVariant({ variant, className })))}
        ref={ref}
        {...props}
      />
    );
  }
);

export default Select;
