import { useMemo } from "react";
import type { DistributionCenter } from "admin/src/types/DistributionCenter";
import { ClientSideRowModelModule, type ColDef, ModuleRegistry } from "ag-grid-enterprise";
import { FolderOpenIcon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { phoneNumberFormatter } from "shared/src/utils";
import { DataGridProvider, DataGrid, DataGridToolbar, DataGridSearch, DataGridRowDetailSidebar, useGridSelectedRow } from "admin/src/components/ui/DataGrid";
import { Button } from "shared/src/components/ui/new";
import { Documents, DocumentsFilters, DocumentsFilterType, DocumentsFilterUpdatedAt, DocumentsFilterUploadedBy, DocumentsList, DocumentsToolbar } from "admin/src/components/ui/Documents";
import { Flexor } from "shared/src/components";
import { uploadDocument } from "admin/src/utils/documents";
import { getFileFromSecureStorage } from "admin/src/fetchers";

ModuleRegistry.registerModules([
  ClientSideRowModelModule,
]);

type DistributionCenterListProps = {
  electionId: string | null,
  loading: boolean,
  centers: DistributionCenter[],
  onEditClick: (center: DistributionCenter) => void,
  onDeleteClick: (center: DistributionCenter) => void,
}

const defaultColDef = {
  sortable: false,
  resizable: false,
  filter: false,
  suppressHeaderMenuButton: true,
};

export default function DistributionCenterGrid({ loading, centers, onEditClick, onDeleteClick }: DistributionCenterListProps) {
  const colDefs = useMemo((): ColDef[] => [
    {
      field: 'centerName',
      width: 300,
    },
    {
      field: 'address',
      width: 400,
      valueGetter: ({ data: { address1, address2, state, city, zip } }: { data: DistributionCenter }) => {
        return [address1, address2, state, city, zip].filter(p => !!p).join(', ');
      },
    },
    {
      field: 'contactName',
      width: 200,
    },
    {
      field: 'contactCellPhone',
      width: 200,
      valueGetter: (properties: { data: DistributionCenter }) => `${phoneNumberFormatter(properties.data.contactCellPhone)}`,
    },
    {
      field: 'manager',
      width: 400,
      valueGetter: (properties: { data: DistributionCenter }) => {
        return properties.data.managerEVUser ? `${properties.data.managerEVUser?.firstName} ${properties.data.managerEVUser?.lastName}` : ''
      },
    },
    {
      field: 'actions',
      headerName: '',
      pinned: 'right',
      width: 175,
      cellRenderer: (properties: { data: DistributionCenter }) => (
        <div className="flex h-full items-center justify-end w-full">
          <Button variant="ghost" data-testid={`location-view-documents-${properties.data.id}`}>
            <FolderOpenIcon />
          </Button>
          <Button variant='ghost' onClick={() => onEditClick(properties.data)}>
            <PencilIcon role="button" data-testid={`center-edit-${properties.data.id}`}/>
          </Button>
          <Button variant='ghost' onClick={() => onDeleteClick(properties.data)}>
            <TrashIcon role="button" data-testid={`center-delete-${properties.data.id}`}/>
          </Button>
        </div>
      ),
    }
  ], [onEditClick, onDeleteClick]);

  return (
    <DataGridProvider>
      <DataGridToolbar allowShare={false} persistState={false}>
        <DataGridSearch />
      </DataGridToolbar>
      <Flexor className='md:flex block grow min-h-0 w-full transition-all duration-200 ease-in-out' items='start'>
        <DataGrid
          loading={loading}
          className='grow'
          gridId="distribution-centers-table"
          loadingMessage='Loading distribution centers...'
          getRowId={params => params.data.id }
          rowData={centers}
          columnDefs={colDefs}
          defaultColDef={defaultColDef}
        />
        <DataGridRowDetailSidebar className="ml-4 lg:w-1/4 w-1/2 overflow-y-auto h-full">
          <DistributionCenterDocuments/>
        </DataGridRowDetailSidebar>
      </Flexor>
    </DataGridProvider>
  );
}

function DistributionCenterDocuments() {
  const { selectedRow: selectedCenter } = useGridSelectedRow<DistributionCenter>();

  if (!selectedCenter) return;

  return (
    <>
      <div className="font-semibold text-lg">{selectedCenter?.centerName}</div>
      <Documents
        upload={uploadDocument}
        download={getFileFromSecureStorage}
        objectId={selectedCenter.id}
      >
        <DocumentsToolbar/>
        <DocumentsFilters>
          <DocumentsFilterType/>
          <DocumentsFilterUpdatedAt/>
          <DocumentsFilterUploadedBy/>
        </DocumentsFilters>
        <DocumentsList/>
      </Documents>
    </>
  );
}
