import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FeatureFlagCheck } from 'admin/src/types/FeatureFlagCheck';


export type FeatureFlagState = {
  [flag: string]: FeatureFlagCheck
}

const initialFeatureFlagState: FeatureFlagState = {};

const slice = createSlice({
  name: 'featureFlags',
  initialState: initialFeatureFlagState,
  reducers: {
    setFeatureFlagCheck: (state, action: PayloadAction<FeatureFlagCheck>) => {
      state[action.payload.flag] = action.payload;
    },
    clearFeatureFlagCheck: (state, action: PayloadAction<FeatureFlagCheck>) => {
      delete state[action.payload.flag];
    },
    // This triggers the saga to check the feature flag. See admin/src/sagas/featureFlags.ts
    checkFeatureFlag: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        [action.payload]: {
          flag: action.payload,
          loading: true,
          enabled: false
        }
      };
    }
  }
})

export default slice.reducer;

export const {
  checkFeatureFlag,
  setFeatureFlagCheck,
  clearFeatureFlagCheck
} = slice.actions;
