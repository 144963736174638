export interface IWorkerStatus { [key: string]: number }

export const WorkerStatus: IWorkerStatus = {
  NOT_SPECIFIED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
  RETIRED: 3,
  DO_NOT_USE: 4,
  INCOMPLETE_REGISTRATION: 5,
  REGISTRATION_COMPLETED: 6,
  PROSPECT: 7,
}

export const WorkerStatusByValue = Object.keys(WorkerStatus).reduce((acc: { [key: number]: string }, key: string) => {
  return {...acc, [WorkerStatus[key]]: key};
}, {});
