import React, {Fragment, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import { AppState } from 'admin/src/store';
import {getShowPollworkerDetails} from "admin/src/selectors/pollworker";
import useSelectedPollworker from "admin/src/hooks/useSelectedPollworker";
import {
  deleteSkillRating,
  loadPollworkerSkills,
  saveSkillRating, updateSkillRating
} from "admin/src/fetchers";
import dayjs from "dayjs";
import { Flexor } from "shared/src/components"
import {SectionSubSubHeading} from "shared/src/components/SectionHeading";
import Spinner from "shared/src/components/Spinner";
import {
  CheckBadgeIcon,
  ChevronUpDownIcon,
  NoSymbolIcon, PencilSquareIcon,
  StarIcon as EmptyStarIcon, TrashIcon,
} from "@heroicons/react/24/outline";
import {
  EllipsisVerticalIcon,
  StarIcon as SolidStarIcon
} from "@heroicons/react/24/solid";
import {EmployeeInfo} from "admin/src/types/User";
import {UserInfo} from "admin/src/types/Pollworker/PollworkerWorkHistory";
import {SkillFromModuleInfo} from "admin/src/types/Pollworker/PollworkerModuleInfo";
import PanelModal from "./ui/PanelModal";
import {Button, Label} from "shared/src/components/ui";
import {Listbox, Menu, Switch, Transition} from "@headlessui/react";
import {classNames} from "shared/src/utils/classNames";
import PanelHeader from "./ui/PanelHeader";

type SkillFromTableApi = {
  active: boolean,
  deleted: boolean,
  ratingType: number,
  keyCustomerId: string,
  skillName: string,
  createdAt: string,
  id: string,
  updatedAt: string,
}

enum SkillType {
  Stars = 1,
  PassFail = 2,
}

interface PollworkerSkill {
  updatedAt: string,
  id: string,
  createdAt: string,
  deleted: boolean,
  rating: number,
  "notes": string,
  keySkillId: string,
  keyRatedByEVUserId: string,
  keyEVUserId: string,
  keyElectionId: string,
  dateRated: string,
  "electionInfo": any,
  "reviewerInfo": UserInfo,
  skillInfo: SkillFromTableApi,
  pollworkerInfo: EmployeeInfo,
}

export default function PollworkerSkillsPanel() {
  const [skills, setSkills] = useState<PollworkerSkill[]>([]);
  const [loadingSkills, setLoadingSkills] = useState<boolean>(false);
  const [showSkillEditor, setShowSkillEditor] = useState<boolean>(false);
  const [savingSkill, setSavingSkill] = useState<boolean>(false);
  const [currentSkills, setCurrentSkills] = useState<{[key: string]: boolean}>({});
  const [selectedSkillValue, setSelectedSkillValue] = useState<number>(0);
  const [selectedSkill, setSelectedSkill] = useState<SkillFromModuleInfo>();
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const pollworker = useSelector(getShowPollworkerDetails);
  const showPollworkerDetails = useSelectedPollworker();

  useEffect(() => {
    if (!moduleInfo || !pollworker) return;

    loadSkills();
  }, [moduleInfo, pollworker]);

  useEffect(() => {
    if (showSkillEditor) return;

    setSelectedSkill(undefined);
    setSelectedSkillValue(0);
    setSavingSkill(false);
  }, [showSkillEditor]);

  function loadSkills() {
    if (!pollworker || !moduleInfo?.ElectionId) return;

    setLoadingSkills(true);
    loadPollworkerSkills(moduleInfo.ElectionId, pollworker.keyEVUserId)
      .then((data: PollworkerSkill[]) => {
        setCurrentSkills(data.reduce((acc: {[key: string]: boolean}, skill) => {
          acc[skill.keySkillId] = true;
          return acc;
        }, {}));

        data = data.sort((a, b) => {
          return dayjs(a.dateRated) < dayjs(b.dateRated) ? 1 : -1;
        });

        setSkills(data);
      })
      .finally(() => setLoadingSkills(false));
  }

  function saveSkill() {
    if (!moduleInfo?.ElectionId || !selectedSkill || selectedSkillValue === undefined) return;

    setSavingSkill(true);

    let skillPromise;

    const pollworkerSkill = skills.find((s) => s.keySkillId === selectedSkill.id);

    if (pollworkerSkill?.id) {
      skillPromise = updateSkillRating(pollworkerSkill.id, moduleInfo.ElectionId, showPollworkerDetails.keyEVUserId, pollworkerSkill.keySkillId, selectedSkillValue, pollworkerSkill.dateRated)
    } else {
      skillPromise = saveSkillRating(moduleInfo.ElectionId, showPollworkerDetails.keyEVUserId, selectedSkill.id, selectedSkillValue);
    }

    skillPromise.then((resp) => {
      setSelectedSkill(undefined);
      setSelectedSkillValue(0);
      setShowSkillEditor(false);
    })
    .finally(() => {
      setSavingSkill(false);
      loadSkills();
    });
  }

  return (
    <div>
      <PanelHeader title='Skills' loading={loadingSkills} reload={loadSkills} onAdd={() => setShowSkillEditor(true)} />
      <PanelModal title={`${selectedSkill ? 'Edit' : 'Add'} Skill`} show={showSkillEditor} hide={() => setShowSkillEditor(false)}>
        <div className="border-b border-gray-300 my-2"></div>
        <div className="my-1">
          <Listbox value={selectedSkill} onChange={(value) => {
            setSelectedSkill(value);
            setSelectedSkillValue(0);
          }}>
            {({ open }) => (
              <>
                <Listbox.Label as={Label}>Type</Listbox.Label>
                <div className="relative mt-2">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-ev-red sm:text-sm sm:leading-6">
                    <span className="inline-flex justify-between w-full truncate">
                      <span className="truncate">{selectedSkill?.SkillName || 'Select a skill...'}</span>
                      <span className="ml-2 truncate text-gray-500">{selectedSkill?.RatingType ? SkillType[selectedSkill?.RatingType] : selectedSkill?.RatingType}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {
                        (moduleInfo?.SkillsList || []).map((skill: SkillFromModuleInfo) => {
                          if (currentSkills[skill.id]) return null;

                          return (
                            <Listbox.Option
                              key={skill.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-ev-red text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={skill}
                            >
                              {({ selected, active }) => (
                                <>
                                  <div className="flex justify-between">
                                  <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'truncate')}>
                                    {skill.SkillName}
                                  </span>
                                  <span className={classNames(active ? 'text-white font-semibold' : 'text-gray-500', 'ml-2 truncate')}>
                                    {SkillType[skill.RatingType] || skill.RatingType}
                                  </span>
                                  </div>
                                </>
                              )}
                            </Listbox.Option>
                          )
                        })
                      }
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          <Flexor>
            { selectedSkill ? <div className="block text-sm font-medium leading-6 text-gray-900">Score</div> : null }
            {
              selectedSkill?.RatingType === SkillType.PassFail ? (
                <Switch.Group as="div" className="flex items-center justify-between">
                  <Switch
                    checked={!!selectedSkillValue}
                    onChange={(value) => {
                      setSelectedSkillValue(value ? 1 : 0);
                    }}
                    className={classNames(
                      selectedSkillValue ? 'bg-green-500' : 'bg-ev-red',
                      selectedSkillValue ? 'focus:ring-green-500' : 'focus:ring-gray-300',
                      'relative inline-flex h-6 w-14 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2'
                    )}
                  >
                    <span className={classNames(
                      selectedSkillValue ? 'translate-x-1' : 'translate-x-8',
                      'transform w-0 text-xs text-white font-bold mt-0.5'
                    )}>{selectedSkillValue ? 'Yes' : 'No'}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        selectedSkillValue ? 'translate-x-8' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                      )}
                    />
                  </Switch>
                </Switch.Group>
              ) : null
            }
            {
              selectedSkill?.RatingType === SkillType.Stars ? (
                <div className="flex justify-around items-center">
                  {
                    [1,2,3,4,5].map((val) => {
                      return (
                        <div onMouseOver={() => setSelectedSkillValue(val)}>
                          {selectedSkillValue && val <= selectedSkillValue ? <SolidStarIcon className="text-yellow-500 h-5 w-5 " /> : <EmptyStarIcon className="text-yellow-500 h-5 w-5" />}
                        </div>
                      )
                    })
                  }
                </div>
              ) : null
            }
          </Flexor>
        </div>
        <Flexor className="mt-5 border-t pt-3">
          <Button size='sm' variant='tertiary' onClick={() => setShowSkillEditor(false)}>Cancel</Button>
          <Button size='sm' onClick={saveSkill} disabled={savingSkill || selectedSkillValue === undefined}>
            <Spinner show={savingSkill} />
            <span>Save</span>
          </Button>
        </Flexor>
      </PanelModal>
      {
        loadingSkills ? (
          <Flexor justify="center" className="space-x-2 mt-10">
            <Spinner show />
            <SectionSubSubHeading>Loading skills...</SectionSubSubHeading>
          </Flexor>
        ) : (
          <>
            {
              !skills.length ? (
                <Flexor justify="center" className="space-x-2 mt-10">
                  <NoSymbolIcon className="h-6 w-6" />
                  <SectionSubSubHeading>No skills found</SectionSubSubHeading>
                </Flexor>
              ) : null
            }
            <ul className="divide-y divide-gray-100 px-2">
              {
                skills.map((skill, idx) => {
                  return (
                    <dl key={skill.id} className="mt-1 divide-y divide-gray-200">
                      <div className="flex items-center justify-between py-3 text-sm font-medium">
                        <dt className="text-sm font-medium text-gray-700">
                          <div>{skill.skillInfo.skillName} · <span className="font-normal">{skill.reviewerInfo.firstName} {skill.reviewerInfo.lastName}</span></div>
                          <div className="font-normal text-xs text-gray-500">{dayjs(skill.dateRated).format('MM-DD-YYYY hh:MM A')}</div>
                        </dt>
                        <dd className="text-sm text-gray-900 flex items-center">
                          {
                            skill.skillInfo.ratingType === SkillType.Stars ? new Array(5).fill(0).map((_val, idx) => {
                              return (idx < skill.rating ? <SolidStarIcon className="h-5 w-5 text-yellow-500" /> : <EmptyStarIcon className="h-5 w-5" />)
                            }) : (
                              skill.rating === 1 ? <CheckBadgeIcon className="h-5 w-5 text-green-500" /> : <NoSymbolIcon className="h-5 w-5 text-ev-red" />
                            )
                          }
                          <Menu as="div" className="relative flex-none ml-2">
                            <Menu.Button className="-m-2.5 block p-2.5 text-gray-500 hover:text-gray-900">
                              <span className="sr-only">Open options</span>
                              <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                            </Menu.Button>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active ? 'bg-gray-50' : '',
                                        'flex items-center space-x-2 block px-3 py-1 text-sm leading-6 text-gray-900'
                                      )}
                                      onClick={() => {
                                        const moduleSkill = moduleInfo?.SkillsList?.find((mSkill: SkillFromModuleInfo) => mSkill.id === skill.keySkillId)
                                        setSelectedSkill(moduleSkill);
                                        setSelectedSkillValue(skill.rating);
                                        setShowSkillEditor(true);
                                      }}
                                    >
                                      <PencilSquareIcon className="h-5 w-5" />
                                      <span>Edit<span className="sr-only">, {skill.skillInfo.skillName}</span></span>
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="#"
                                      className={classNames(
                                        active ? 'bg-ev-red text-white' : 'text-gray-900',
                                        'flex items-center space-x-2 block px-3 py-1 text-sm leading-6'
                                      )}
                                      onClick={() => {
                                        if (!window.confirm('Are you sure you want to delete this skill?')) return;

                                        deleteSkillRating(skill.id)
                                          .then((resp) => console.info(resp))
                                          .finally(loadSkills);
                                      }}
                                    >
                                      <TrashIcon className="h-5 w-5" />
                                      <span>Delete<span className="sr-only">, {skill.skillInfo.skillName}</span></span>
                                    </a>
                                  )}
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </dd>
                      </div>
                    </dl>
                  )
                })
              }
            </ul>
          </>
        )
      }
    </div>
  )
}
