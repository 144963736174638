// See PollworkerLevel.cs
export const WorkerLevel: IWorkerLevel = {
  NoneOrNotAssigned: { id: 0, displayName: 'None / Not Assigned' },
  Pollworker: { id: 1, displayName: 'Pollworker' },
  Standby: { id: 2, displayName: 'Standby' },
  Trainee: { id: 3, displayName: 'Trainee' },
};

interface IWorkerLevel {
  [key: string]: WorkerLevelData
}

interface WorkerLevelData {
  id: number,
  displayName: string,
}

export const WorkerLevelById = Object.keys(WorkerLevel).reduce((acc: {[key: number]: WorkerLevelData}, level: string) => {
  return {
    ...acc,
    [WorkerLevel[level].id]: WorkerLevel[level],
  }
}, {});
