import {hideModal, MODALS} from "admin/src/actions/modal";
import { AppState } from 'admin/src/store';
import {useDispatch, useSelector} from "react-redux";
import {
  ChatBubbleLeftRightIcon,
  NoSymbolIcon,
} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import { Flexor } from "shared/src/components"
import {patchBulkUpdatePollworkerSms} from "admin/src/fetchers";
import {RadioGroup, RadioGroupItem, Label, Button, Dialog} from "shared/src/components/ui";
import Spinner from "shared/src/components/Spinner";
import {getSelectedPollworkers} from "admin/src/selectors/pollworker/grid";
import {PollworkerWorkHistory} from "admin/src/types/Pollworker/PollworkerWorkHistory";

export default function DialogBulkSetSms({onSave}: {onSave: () => void}) {
  const open = useSelector((state: AppState) => state.modals.open === MODALS.SET_SMS);
  const [filteredSelectedRows, setFilteredSelectedRows] = useState<PollworkerWorkHistory[]>([]);
  const [ok, setOk] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const selectedPollworkers = useSelector(getSelectedPollworkers);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) return;

    setFilteredSelectedRows([]);
    setOk(false);
  }, [open]);

  useEffect(() => {
    setFilteredSelectedRows([...selectedPollworkers]);
  }, [selectedPollworkers, open]);

  function removeIndexFromSelectedRows(idx: number) {
    const newFiltered = [...filteredSelectedRows];
    newFiltered.splice(idx, 1);
    setFilteredSelectedRows(newFiltered);
  }

  function hide() {
    dispatch(hideModal(MODALS.SET_SMS));
  }

  function saveSmsStatus() {
    const pollworkerIds = filteredSelectedRows.map((row) => row.keyPollworkerId);

    setSaving(true);

    patchBulkUpdatePollworkerSms(pollworkerIds, ok)
    .then(({data, success}) => {
      if (success) {
        onSave();
        hide();
      }
    })
    .finally(() => setSaving(false));
  }

  return (
    <Dialog.Dialog open={open} onOpenChange={hide}>
      <Dialog.DialogContent>
        <Dialog.DialogTitle>
          Set SMS
        </Dialog.DialogTitle>
        <Dialog.DialogDescription>
          Are these users allowed to receive SMS (text message) communications?
        </Dialog.DialogDescription>
        <div>
          <div className="mt-5">
            <fieldset className="mt-4">
              <legend className="sr-only">Notification method</legend>
              <div className="space-y-4 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                <RadioGroup defaultValue='no' onValueChange={(val) => setOk(val === 'yes')}>
                  <Flexor justify='start'>
                    <RadioGroupItem id="yes" value='yes' />
                    <Label htmlFor="yes" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                      Yes
                    </Label>
                  </Flexor>
                  <Flexor justify='start'>
                    <RadioGroupItem id="no" value='no' />
                    <Label htmlFor="no" className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                      No
                    </Label>
                  </Flexor>
                </RadioGroup>
              </div>
            </fieldset>
          </div>
          <div className="mt-5 text-sm font-medium leading-6 text-gray-900">
            <Flexor>
              <div>Pollworkers</div>
              <div>{filteredSelectedRows.length}</div>
            </Flexor>
          </div>
          <ul role="list" className="divide-y divide-gray-100 overflow-y-auto max-h-80">
            {
              filteredSelectedRows.map((row, idx) => {
                const icon = row.userInfo.okToSendSMS ? (<ChatBubbleLeftRightIcon className="h-5 w-5 text-green-500" />) : (<NoSymbolIcon className="h-5 w-5 text-ev-red" />)
                const content = row.userInfo.okToSendSMS ? 'Allowed' : 'Disallowed';

                return (
                  <li key={`modal:${row.id}`} className="flex items-center justify-between gap-x-6 py-3">
                    <div className="flex min-w-0 gap-x-4">
                      <Flexor className="h-12 w-12 flex-none border border-gray-200 bg-gray-50 flex-col text-green-500" justify="center" >
                        <div className="scale-[115%]">{icon}</div>
                      </Flexor>
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {row.pollworkerInfo.userInfo.firstName}
                          {' '}
                          {row.pollworkerInfo.userInfo.lastName}
                        </p>
                        <p className="mt-1 truncate text-xs leading-5 text-gray-500">{content || 'Empty'}</p>
                      </div>
                    </div>
                    <Button variant='primary' onClick={() => removeIndexFromSelectedRows(idx)}>Remove</Button>
                  </li>
                )
              })
            }
          </ul>
        </div>
        <Dialog.DialogFooter>
          <div className="mt-5 sm:mt-6">
            <Button data-testid='save' variant='primary' onClick={saveSmsStatus} disabled={saving || !filteredSelectedRows.length}>
              <div className="mx-auto">
                <Flexor justify="center" className="space-x-2">
                  {saving ? <Spinner /> : null }
                  <div className="w-full text-center">Save</div>
                </Flexor>
              </div>
            </Button>
          </div>
        </Dialog.DialogFooter>
      </Dialog.DialogContent>
    </Dialog.Dialog>
  )
}
