type ZumoTokenArg = string | null;

export function handleZumoAuth(token: ZumoTokenArg, storageEngine: Storage) {
  if (!token) return;

  const [, data] = token.split('.');
  const [userId, customerId] = JSON.parse(atob(data)).sub.split('|');

  storageEngine.setItem('userId', userId);
  storageEngine.setItem('customerId', customerId);
  storageEngine.setItem('zumo', token);
}

export default function handleZumoAuthFactory(storageEngine: Storage): (token: ZumoTokenArg) => void {
  return (token: ZumoTokenArg): void => handleZumoAuth(token, storageEngine);
}
