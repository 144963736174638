import { useEffect, useState } from "react";
import useNativeMessageListenerEffect from "shared/src/hooks/useNativeMessageListenerEffect";
import { PollworkerModuleInfo, Precinct, VotingLocation } from 'admin/src/types';
import { printPollWorkerAssignmentStatusReport, printPollWorkerSignInSheetReport } from "../../fetchers/setupAndAdmin";
import { classNames, downloadDocument } from "shared/src/utils";
import { Button, ButtonGroup, Checkbox, Dialog } from "shared/src/components/ui";
import { useDispatch, useSelector } from "react-redux";
import { hideModal, MODALS } from "../../actions/modal";
import { AppState } from 'admin/src/store';

export interface PollWorkerSignInSheetReportProps {
  precincts: Precinct[],
  locations: VotingLocation[],
}

export default function PollWorkerSignInSheetReport({ precincts, locations }: PollWorkerSignInSheetReportProps) {
  const [moduleInfo, setModuleInfo] = useState<PollworkerModuleInfo>();
  const [selectedPrecincts, setSelectedPrecincts] = useState<Precinct[]>([]);
  const [selectedLocations, setSelectedLocations] = useState<VotingLocation[]>([]);
  const [includeAgeData, setIncludeAgeData] = useState(false);
  const [includePartyData, setIncludePartyData] = useState(false);
  const [reportIsRunning, setReportIsRunning] = useState<boolean>(false);
  const [selectAllPrecinctChecked, setSelectAllPrecinctChecked] = useState(false);
  const [selectAllLocationChecked, setSelectAllLocationChecked] = useState(false);
  const [reportType, setReportType] = useState<'precinct' | 'location'>('precinct');
  const open = useSelector((state: AppState) => state.modals.open === MODALS.PW_SIGNIN_SHEET_REPORT);
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) return;
    setSelectedPrecincts([]);
    setSelectedLocations([]);
  }, [open]);

  function hide() {
    dispatch(hideModal(MODALS.PW_SIGNIN_SHEET_REPORT));
  }

  useEffect(() => {
    window?.chrome?.webview?.postMessage('System:Init');
  }, []);

  useNativeMessageListenerEffect((from: string, eventName: string, eventValue: string, responseData: string) => {
    if (from === 'System' && eventName === 'Init' && eventValue === 'Response') {
      const info = JSON.parse(atob(responseData));
      setModuleInfo(info);
    }
  });

  const handlePrecinctCheckboxChange = (precinct: Precinct) => {
    setSelectedPrecincts((prevSelected: Precinct[] | any) => {
      if (prevSelected.includes(precinct)) {
        return prevSelected.filter((current: Precinct) => current !== precinct);
      } else {
        return [...(prevSelected || []), precinct];
      }
    });
  };

  function handleAllPrecinctCheckboxChange() {
    const newVal = !selectAllPrecinctChecked;
    setSelectAllPrecinctChecked(newVal);
    setSelectedPrecincts(newVal ? [...precincts] : []);
  }

  const handleLocationCheckboxChange = (location: VotingLocation) => {
    setSelectedLocations((prevSelected: VotingLocation[] | any) => {
      if (prevSelected.includes(location)) {
        return prevSelected.filter((current: VotingLocation) => current !== location);
      } else {
        return [...(prevSelected || []), location];
      }
    });
  };

  function handleAllLocationCheckboxChange() {
    const newVal = !selectAllLocationChecked;
    setSelectAllLocationChecked(newVal);
    setSelectedLocations(newVal ? [...locations] : []);
  }

  const handleDownloadReportButtonClicked = () => {
    setReportIsRunning(true);
    const locationId: string[] = reportType === 'precinct'
      ? selectedPrecincts.map((location) => location.id)
      : selectedLocations.map((location) => location.id);

    printPollWorkerSignInSheetReport(locationId, moduleInfo?.ElectionId!)
      .then(responseBody => {
        downloadDocument('PollworkerSignInSheetReport', '.pdf')(responseBody);
      }).finally(() => {
        setReportIsRunning(false);
      });
  };

  return (
    <Dialog.Dialog open={open} onOpenChange={hide}>
      <Dialog.DialogContent>
        <Dialog.DialogTitle>Poll Worker Sign-In Sheet Report</Dialog.DialogTitle>
        <div className="mt-5 text-sm font-medium leading-6 text-gray-900">
          <p>Current selected Election: {moduleInfo?.Election?.ElectionName}</p>
          <p className="mt-2">
            Select the Precincts <strong>OR</strong> Locations you wish to report on to view the workers in the grid.
          </p>
        </div>

        <ButtonGroup className='bg-gray-400 border-2 border-gray-400'>
          <Button
            className='w-full border-none'
            onClick={() => setReportType('precinct')}
            variant={(reportType === 'precinct' ? 'primary' : 'secondary')}
          >
            Precincts
          </Button>
          <Button
            className='w-full border-none'
            onClick={() => setReportType('location')}
            variant={(reportType === 'location' ? 'primary' : 'secondary')}
          >
            Locations
          </Button>
        </ButtonGroup>

        {
          reportType === 'precinct' ? (
            <div className="mt-4 overflow-y-auto max-h-64">
              <table className="min-w-full table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border">Precinct</th>
                    <th className="px-4 py-2 border">
                      <Checkbox
                        checked={selectAllPrecinctChecked}
                        onCheckedChange={handleAllPrecinctCheckboxChange}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {precincts.map((precinct, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 border">{precinct.precinctName}</td>
                      <td className="px-4 py-2 border text-center">
                        <Checkbox
                          checked={selectedPrecincts.includes(precinct)}
                          onCheckedChange={() => handlePrecinctCheckboxChange(precinct)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null
        }
        {
          reportType === 'location' ? (
            <div className="mt-4 overflow-y-auto max-h-64">
              <table className="min-w-full table-auto">
                <thead>
                  <tr>
                    <th className="px-4 py-2 border">Locations</th>
                    <th className="px-4 py-2 border">
                      <Checkbox
                        checked={selectAllLocationChecked}
                        onCheckedChange={handleAllLocationCheckboxChange}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {locations.map((location, index) => (
                    <tr key={index}>
                      <td className="px-4 py-2 border">{location.locationName}</td>
                      <td className="px-4 py-2 border text-center">
                        <Checkbox
                          checked={selectedLocations.includes(location)}
                          onCheckedChange={() => handleLocationCheckboxChange(location)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null
        }

        <Dialog.DialogFooter>
          <div className="mt-5 sm:mt-4 flex justify-end">
            <Button
              variant="primary"
              disabled={reportIsRunning}
              onClick={handleDownloadReportButtonClicked}
            > Download PDF
            </Button>
          </div>
        </Dialog.DialogFooter>
      </Dialog.DialogContent>
    </Dialog.Dialog>
  );

}
