export enum Feature {
  EASY_TRACKING = 101,
  EASY_ORDER = 102,
  MODERN_REPORTS = 103,
  MODERN_POLLWORKER = 104,
  MODERN_CAMPAIGN_FINANCE = 105,
  MODERN_INVENTORY = 106,
  MODERN_POLLWORKER_SCHEDULER = 107,
}

export default Feature;
