import {useSelector} from "react-redux";
import {AppState} from 'admin/src/store';
import {NomenclatureBase, NomenclatureProps} from "shared/src/components/NomenclatureBase";

export function Nomenclature({nomenclatureId}: NomenclatureProps) {
  const customerAccount = useSelector((state: AppState) => state.user?.account);

  return <NomenclatureBase
    nomenclatureId={nomenclatureId}
    customer={customerAccount} />;
}
