import tw from "tailwind-styled-components";

export const NavItem = tw.a<{ $active: boolean }>`
  ${({$active}) => $active ? 'border-ev-red text-gray-900' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'} 
  inline-flex
  cursor-pointer
  items-center
  border-b-2
  px-1
  pt-1
  text-sm
  font-medium
`;
