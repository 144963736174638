import { useCopyToClipboard } from "react-use";
import { useState } from "react";
import { Button, Tooltip, TooltipContent, TooltipProvider } from "shared/src/components/ui/new";
import { TooltipTrigger } from "@radix-ui/react-tooltip";
import { GlobeAmericasIcon, LinkIcon } from "@heroicons/react/24/outline";
import { Flexor } from "shared/src/components";
import { useGridContext } from "./DataGridContext";

export function DataGridShareButton() {
  useGridContext();
  const [, copy] = useCopyToClipboard();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  function copyCurrentUrl() {
    copy(window.location.href);
    setTooltipOpen(true);
    setTimeout(() => setTooltipOpen(false), 3000);
  }

  return (
    <TooltipProvider>
      <Tooltip open={tooltipOpen}>
        <TooltipTrigger asChild>
          <Button size='sm' variant='outline' onClick={copyCurrentUrl}>
            <GlobeAmericasIcon />
            Share
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <Flexor className='space-x-1'>
            <LinkIcon className='text-success size-4' />
            <span>Link copied!</span>
          </Flexor>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}
