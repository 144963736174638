export const WorkConfirmationStatus: IWorkConfirmationStatus = {
  NoResponse: { id: undefined, displayName: 'Reset' },
  Accepted: { id: true, displayName: 'Accept' },
  Declined: { id: false, displayName: 'Decline' },
};

export const WorkConfirmationStatusById: { [key: string]: WorkConfirmationStatusValue } = {
  '0': WorkConfirmationStatus.NoResponse,
  '1': WorkConfirmationStatus.Accpeted,
  '2': WorkConfirmationStatus.Declined,
}

interface IWorkConfirmationStatus {
  [key: string]: WorkConfirmationStatusValue
}

export interface WorkConfirmationStatusValue {
  id: boolean | undefined,
  displayName: string,
}
