import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Employee } from "../../types/User";

export type EmployeeState = {
  employees: Employee[]
}

const initialState: EmployeeState = {
  employees: []
}

const slice = createSlice({
  name: "employees",
  initialState: initialState,
  reducers: {
    setEmployees: (state, action: PayloadAction<Employee[]>) => {
      state.employees = action.payload
    }
  }
})

export const { setEmployees } = slice.actions;

export default slice.reducer;