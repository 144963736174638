import Flexor from "./Flexor"
import { BuildingStorefrontIcon, ShoppingBagIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import React, { ForwardRefExoticComponent, RefAttributes, SVGProps } from "react";

type HeroIcon = ForwardRefExoticComponent<Omit<SVGProps<SVGSVGElement>, "ref"> & { title?: string | undefined; titleId?: string | undefined; } & RefAttributes<SVGSVGElement>>
export type LoadingProps = { icons?: [HeroIcon, HeroIcon, HeroIcon], loadingMessage: string, className?: string }

export default function Loading({
  loadingMessage,
  className,
  icons = [
    ShoppingCartIcon,
    BuildingStorefrontIcon,
    ShoppingBagIcon,
  ],
}: LoadingProps) {
  const [Icon1, Icon2, Icon3] = icons;

  return (
    <div className={className}>
      <Flexor justify="center" items="end" className="space-x-2">
        <Icon1 className="h-7 w-7 animate-pulse -rotate-12" />
        <Icon2 className="h-10 w-10 animate-pulse" />
        <Icon3 className="h-7 w-7 animate-pulse rotate-12" />
      </Flexor>
      <p className="text-center text-sm text-gray-800 mt-3">{loadingMessage}</p>
    </div>
  )
}
