import { ChevronDownIcon, InformationCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import { StarIcon } from "@heroicons/react/24/solid";
import React, { useState, useEffect, useMemo } from "react";
import { Flexor, Spinner } from "shared/src/components";
import { Badge, Button, Dialog, DialogBody, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle, DialogTrigger, DropdownMenu, DropdownMenuContent, DropdownMenuGroup, DropdownMenuItem, DropdownMenuTrigger, Input, Label, RadioGroup, RadioGroupItem, Switch, Toaster, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "shared/src/components/ui/new";
import { classNames } from "shared/src/utils";
import { Link, useLocation, useParams } from "wouter";
import { Feature, ModuleSecurityLevel } from "../../../enums";
import { UserType } from "../../../enums/userType";
import { createSecurityLevel as createNewSecurityLevel, createSecurityLevelPermission, deleteSecurityLevel, getSecurityLevelPermissions, getSecurityLevels, getUsersAssignedToLevel, updateSecurityLevel, updateSecurityLevelPermission } from "../../../fetchers/setupAndAdmin";
import { useAppSelector } from "../../../hooks";
import type { SecurityLevel, SecurityLevelPermission, SecurityLevelUser } from "../../../types";
import LoadingAdministration from "./LoadingAdministration";
import { toast } from "sonner";

type SecurityLevelPermissionsById = { [key: string]: SecurityLevelPermission }

export default function Security() {
  const user = useAppSelector(state => state.user);
  const easyTrackingFeature = useAppSelector(state => state.user.features?.find(({ feature }) => feature === Feature.EASY_TRACKING));
  const [securityLevels, setSecurityLevels] = useState<SecurityLevel[]>([]);
  const [updatingSecurityLevel, setUpdatingSecurityLevel] = useState(false);
  const [editName, setEditName] = useState(false);
  const [showNewSecurityLevel, setShowNewSecurityLevel] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedSecurityLevel, setSelectedSecurityLevel] = useState<SecurityLevel>();
  const [selectedSecurityLevelUsers, setSelectedSecurityLevelUsers] = useState<SecurityLevelUser[]>([]);
  const [easyTrackingPermissionLevel, setEasyTrackingPermissionLevel] = useState<ModuleSecurityLevel>(ModuleSecurityLevel.NoAccess);
  const [newSecurityLevelName, setNewSecurityLevelName] = useState('');
  const [securityLevelPermissionById, setSecurityLevelsPermissionById] = useState<SecurityLevelPermissionsById>();
  const { securityLevelId: defaultSecurityLevelId } = useParams();
  const [, navigate] = useLocation();

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    const level = securityLevels.find((level) => {
      if (!defaultSecurityLevelId) return level.systemAdmin;

      return level.id === defaultSecurityLevelId;
    });

    if (level) changeSecurityLevel(level);
  }, [defaultSecurityLevelId, securityLevels]);

  useEffect(() => {
    if (!selectedSecurityLevel || !securityLevelPermissionById) return;

    const easyTrackingPermissionLevel = securityLevelPermissionById[selectedSecurityLevel.id];

    setEasyTrackingPermissionLevel(easyTrackingPermissionLevel ? easyTrackingPermissionLevel.permissionLevel : ModuleSecurityLevel.NoAccess);
  }, [selectedSecurityLevel, securityLevelPermissionById]);

  const currentSystemAdministrator = useMemo(() => securityLevels.find((level) => level.systemAdmin), [securityLevels]);
  const isSystemAdministrator = useMemo(() => selectedSecurityLevel?.systemAdmin, [selectedSecurityLevel]);
  const canDelete = useMemo(() => !selectedSecurityLevelUsers.length && !isSystemAdministrator, [isSystemAdministrator, selectedSecurityLevelUsers]);

  async function loadData() {
    setLoadingData(true);

    const securityLevelsPromise = getSecurityLevels()
    .then((resp) => {
      const secLevels = resp.sort((a: SecurityLevel, b: SecurityLevel) => {
        if (a.systemAdmin) return -1;
        return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
      });

      setSecurityLevels(secLevels);

      if (selectedSecurityLevel?.id) {
        changeSecurityLevel(secLevels.find((level: SecurityLevel) => level.id === selectedSecurityLevel.id));
        return;
      }

      const defaultSecurityDefaultLevel = secLevels.find((level: SecurityLevel) => level.id === defaultSecurityLevelId);
      if (defaultSecurityDefaultLevel) {
        changeSecurityLevel(defaultSecurityDefaultLevel);
        return;
      }

      const sysAdminLevel = secLevels.find((level: SecurityLevel) => level.systemAdmin);
      const [firstLevel] = secLevels;
      changeSecurityLevel(sysAdminLevel || firstLevel);
    });

    const securityLevelPermissionsPromise = getSecurityLevelPermissions()
    .then((resp) => {
      const secLevelPermsById = resp.reduce((acc: SecurityLevelPermissionsById, levelPermission: SecurityLevelPermission) => {
        acc[levelPermission.keySecurityLevelId] = levelPermission;
        return acc;
      });

      setSecurityLevelsPermissionById(secLevelPermsById);
    });

    await Promise.all([securityLevelsPromise, securityLevelPermissionsPromise]);

    setLoadingData(false);
  }

  async function handleCreateNewSecLevel() {
    const newSecLevel = await createNewSecurityLevel(newSecurityLevelName).then(changeSecurityLevel);

    if (!newSecLevel) {
      toast.error('Failed to create new security level');
      return;
    }

    createSecurityLevelPermission(newSecLevel.id, easyTrackingPermissionLevel)
    .then(() => setShowNewSecurityLevel(false))
    .then(loadData)
    .finally(() => toast.success('Created new security level'));
  }

  async function handleUpdateSecurityLevel() {
    if (!selectedSecurityLevel) return;

    setUpdatingSecurityLevel(true);

    const secLevelPermission = securityLevelPermissionById?.[selectedSecurityLevel.id];

    if (secLevelPermission) {
      await updateSecurityLevelPermission({...secLevelPermission, permissionLevel: easyTrackingPermissionLevel});
    } else {
      await createSecurityLevelPermission(selectedSecurityLevel.id, easyTrackingPermissionLevel);
    }

    return updateSecurityLevel(selectedSecurityLevel)
    .then(loadData)
    .finally(() => {
      setUpdatingSecurityLevel(false);
      toast.success('Updated security level');
    });
  }

  function handleDeleteSecurityLevel() {
    if (!selectedSecurityLevel) return;
    if (!window.confirm(`Are you sure you want to delete "${selectedSecurityLevel.securityLevelName}"?`)) return;

    deleteSecurityLevel(selectedSecurityLevel.id)
    .then(() => navigate(`/security/${currentSystemAdministrator?.id}`))
    .then(loadData)
    .finally(() => toast.success('Deleted security level'));
  }

  function handleGetUsersAssignedToLevel(level: SecurityLevel) {
    getUsersAssignedToLevel(level.id)
    .then((resp) => JSON.parse(atob(resp.data)))
    .then((users) => {
      const usersSorted = users.sort((a: SecurityLevelUser, b: SecurityLevelUser) => {
        const fullnameA = `${a.userInfo.firstName} ${a.userInfo.lastName}`;
        const fullnameB = `${b.userInfo.firstName} ${b.userInfo.lastName}`;

        return fullnameA > fullnameB ? -1 : 1;
      });

      setSelectedSecurityLevelUsers(usersSorted);
    });
  }

  function changeSecurityLevel(level: SecurityLevel) {
    if (level === selectedSecurityLevel) return;

    setSelectedSecurityLevel(level);
    handleGetUsersAssignedToLevel(level);
    setEasyTrackingPermissionLevel(ModuleSecurityLevel.NoAccess);

    return level;
  }

  if (loadingData && (!securityLevels.length || !securityLevelPermissionById || !user.account)) {
    return (
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
        <LoadingAdministration loadingMessage='Loading security levels...'/>
      </div>
    );
  }

  return (
    <>
      <Toaster />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className='xl:hidden mb-5' variant='outline'>
            Change security levels
            <ChevronDownIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='start'>
          <DropdownMenuGroup className='w-full'>
            {
              securityLevels.map((level) => {
                return (
                  <DropdownMenuItem key={level.id} onClick={() => changeSecurityLevel(level)}>
                    <Flexor className='space-x-1'>
                      {
                        level.systemAdmin ? (
                          <StarIcon className='size-4 text-foreground/80' />
                        ) : null
                      }
                      <span className='font-semibold'>{level.securityLevelName}</span>
                    </Flexor>
                  </DropdownMenuItem>
                )
              })
            }
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
      <div className='xl:flex justify-start items-start h-full'>
        <div className="xl:block hidden w-1/4 h-full xl:border-r border-border overflow-y-auto">
          <Flexor className='pr-3 pb-5'>
            <div className='font-semibold'>Security levels</div>
            <Dialog open={showNewSecurityLevel} onOpenChange={(open) => setShowNewSecurityLevel(open)}>
              <DialogTrigger asChild>
                <Button size='sm'>
                  <PlusIcon />
                  New
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>New security level</DialogTitle>
                </DialogHeader>
                <DialogBody className="my-5">
                  <Label htmlFor="name">Name</Label>
                  <Input autoFocus onChange={({ target: { value } }) => setNewSecurityLevelName(value)} id="name" placeholder="Name of security level"/>
                </DialogBody>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button variant="ghost">Cancel</Button>
                  </DialogClose>
                  <Button onClick={handleCreateNewSecLevel}>Create</Button>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </Flexor>
          <div className='divide-y'>
            {
              securityLevels.map((level) => {
                return (
                  <Link key={level.id} to={`/security/${level.id}`}>
                    <div className={classNames(selectedSecurityLevel?.id === level.id ? 'bg-muted border-r-2 border-r-foreground' : 'hover:bg-muted', 'transition-all py-5 p-3 flex justify-between items-center text-sm cursor-pointer')}>
                      <Flexor className='space-x-1'>
                        <SystemAdminLevelIcon level={level} />
                        <span className='font-semibold'>{level.securityLevelName}</span>
                      </Flexor>
                      <Badge variant={level.active ? 'default' : 'secondary'}>{level.active ? 'Active' : 'Inactive'}</Badge>
                    </div>
                  </Link>
                );
              })
            }
          </div>
        </div>
        {
          selectedSecurityLevel ? (
            <div className='xl:pl-5 space-y-5 xl:w-1/2 w-full grow h-full overflow-y-auto'>
              <Flexor>
                {
                  editName ? (
                    <Flexor justify='start' className='space-x-3 w-full'>
                      <Input autoFocus defaultValue={selectedSecurityLevel.securityLevelName} onChange={({ target: { value } }) => setSelectedSecurityLevel({
                        ...selectedSecurityLevel,
                        securityLevelName: value
                      })}/>
                      <Button size='sm' onClick={() => setEditName(false)}>Done</Button>
                    </Flexor>
                  ) : (
                    <>
                      <Flexor className='space-x-1'>
                        <SystemAdminLevelIcon level={selectedSecurityLevel} />
                        <div className='text-lg font-semibold'>{selectedSecurityLevel.securityLevelName}</div>
                      </Flexor>
                      <Flexor className='space-x-1'>
                        <Button onClick={() => setEditName(true)} className='text-xs' variant='secondary'>Rename</Button>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div>
                                <Button disabled={!canDelete} size='sm' variant='destructive' onClick={handleDeleteSecurityLevel}>Delete</Button>
                              </div>
                            </TooltipTrigger>
                            {
                              !canDelete ? (
                                <TooltipContent>
                                  You cannot delete security levels that has assigned users
                                </TooltipContent>
                              ) : null
                            }
                          </Tooltip>
                        </TooltipProvider>
                      </Flexor>
                    </>
                  )
                }
              </Flexor>
              <Flexor justify="start" className='space-x-5'>
                <Switch id='active' disabled={selectedSecurityLevel.systemAdmin} onCheckedChange={(checked) => setSelectedSecurityLevel({
                  ...selectedSecurityLevel,
                  active: checked
                })} checked={selectedSecurityLevel.active} />
                <Flexor className='space-x-1'>
                  <Label htmlFor='active'>Active</Label>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Flexor>
                          <InformationCircleIcon className="text-gray-500 h-5"/>
                        </Flexor>
                      </TooltipTrigger>
                      <TooltipContent>
                        Anyone with this type can access the system
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Flexor>
              </Flexor>
              <div className='font-semibold'>Behaviors</div>
              <Flexor justify="start" className='space-x-5'>
                <Switch id='driver' disabled={selectedSecurityLevel.systemAdmin} onCheckedChange={(checked) => setSelectedSecurityLevel({
                  ...selectedSecurityLevel,
                  isDeploymentDriver: checked
                })} checked={selectedSecurityLevel.isDeploymentDriver}/>
                <Flexor className='space-x-1'>
                  <Label htmlFor='driver'>Driver</Label>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Flexor>
                          <InformationCircleIcon className="text-gray-500 h-5"/>
                        </Flexor>
                      </TooltipTrigger>
                      <TooltipContent>
                        Drives a truck during deployment
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Flexor>
              </Flexor>
              <Flexor justify="start" className='space-x-5'>
                <Switch id='easyfocus-auto-assignee' disabled={selectedSecurityLevel.systemAdmin} onCheckedChange={(checked) => setSelectedSecurityLevel({
                  ...selectedSecurityLevel,
                  defaultSurveyTaskUser: checked
                })} checked={selectedSecurityLevel.defaultSurveyTaskUser}/>
                <Flexor className='space-x-1'>
                  <Label htmlFor='easyfocus-auto-assignee'>EasyFocus auto assignee</Label>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Flexor>
                          <InformationCircleIcon className="text-gray-500 h-5"/>
                        </Flexor>
                      </TooltipTrigger>
                      <TooltipContent>
                        Auto assigned all EasyFocus items coming from Managers
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Flexor>
              </Flexor>
              <Flexor justify="start" className='space-x-5'>
                <Switch onCheckedChange={(checked) => setSelectedSecurityLevel({
                  ...selectedSecurityLevel,
                  isHardwareTechnician: checked
                })} checked={selectedSecurityLevel.isHardwareTechnician}/>
                <Flexor className='space-x-1'>
                  <Label>Hardware technician on Election Day</Label>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <Flexor>
                          <InformationCircleIcon className="text-gray-500 h-5"/>
                        </Flexor>
                      </TooltipTrigger>
                      <TooltipContent>
                        Hardware technician on Election Day
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </Flexor>
              </Flexor>
              <div className="font-semibold">App permissions</div>
              <div className='space-y-5 w-full xl:w-3/5'>
                {
                  user.account?.easyFileClient ? (
                    <div className='space-y-1'>
                      <div className='text-sm font-semibold'>EasyCampaignFinance</div>
                      <RadioGroup disabled={selectedSecurityLevel.systemAdmin} onValueChange={(val) => setSelectedSecurityLevel({
                        ...selectedSecurityLevel,
                        easyFileLevel: Number(val),
                      })} value={selectedSecurityLevel.easyFileLevel.toString()} orientation="horizontal" className="flex justify-between space-x-5">
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id='easycampaignfinance-no-access' value={ModuleSecurityLevel.NoAccess.toString()} />
                          <Label htmlFor='easycampaignfinance-no-access'>No access</Label>
                        </div>
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id='easycampaignfinance-view-only' value={ModuleSecurityLevel.ViewOnly.toString()} />
                          <Label htmlFor='easycampaignfinance-view-only'>View</Label>
                        </div>
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id='easycampaignfinance-edit' value={ModuleSecurityLevel.Edit.toString()} />
                          <Label htmlFor='easycampaignfinance-edit'>Edit</Label>
                        </div>
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id='easycampaignfinance-administrator' value={ModuleSecurityLevel.Administrator.toString()} />
                          <Label htmlFor='easycampaignfinance-administrator'>Administrator</Label>
                        </div>
                      </RadioGroup>
                    </div>
                  ) : null
                }
                {
                  user.account?.easyInventoryClient ? (
                    <>
                      <div className="space-y-1">
                        <div className="text-sm font-semibold">EasyInventory</div>
                        <RadioGroup disabled={selectedSecurityLevel.systemAdmin} onValueChange={(val) => setSelectedSecurityLevel({
                          ...selectedSecurityLevel,
                          easyInventoryLevel: Number(val),
                        })} value={selectedSecurityLevel.easyInventoryLevel.toString()} orientation="horizontal" className="flex justify-between space-x-5">
                          <div className="flex space-x-1 justify-start items-center">
                            <RadioGroupItem id="easyinventory-no-access" value={ModuleSecurityLevel.NoAccess.toString()} />
                            <Label htmlFor="easyinventory-no-access">No access</Label>
                          </div>
                          <div className="flex space-x-1 justify-start items-center">
                            <RadioGroupItem id="easyinventory-view-only" value={ModuleSecurityLevel.ViewOnly.toString()} />
                            <Label htmlFor="easyinventory-view-only">View</Label>
                          </div>
                          <div className="flex space-x-1 justify-start items-center">
                            <RadioGroupItem id="easyinventory-edit" value={ModuleSecurityLevel.Edit.toString()} />
                            <Label htmlFor="easyinventory-edit">Edit</Label>
                          </div>
                          <div className="flex space-x-1 justify-start items-center">
                            <RadioGroupItem id="easyinventory-administrator" value={ModuleSecurityLevel.Administrator.toString()} />
                            <Label htmlFor="easyinventory-administrator">Administrator</Label>
                          </div>
                        </RadioGroup>
                      </div>
                      <div className="space-y-1">
                        <div className="text-sm font-semibold">EasyInventory Mobile</div>
                        <RadioGroup disabled={selectedSecurityLevel.systemAdmin} onValueChange={(val) => setSelectedSecurityLevel({
                          ...selectedSecurityLevel,
                          easyInventoryMobileLevel: Number(val),
                        })} value={selectedSecurityLevel.easyInventoryMobileLevel.toString()} orientation="horizontal" className="flex justify-between space-x-5">
                          <div className="flex space-x-1 justify-start items-center">
                            <RadioGroupItem id="easyinventory-no-access" value={ModuleSecurityLevel.NoAccess.toString()} />
                            <Label htmlFor="easyinventory-no-access">No access</Label>
                          </div>
                          <div className="flex space-x-1 justify-start items-center">
                            <RadioGroupItem id="easyinventory-view-only" value={ModuleSecurityLevel.ViewOnly.toString()} />
                            <Label htmlFor="easyinventory-view-only">View</Label>
                          </div>
                          <div className="flex space-x-1 justify-start items-center">
                            <RadioGroupItem id="easyinventory-edit" value={ModuleSecurityLevel.Edit.toString()} />
                            <Label htmlFor="easyinventory-edit">Edit</Label>
                          </div>
                          <div className="flex space-x-1 justify-start items-center">
                            <RadioGroupItem id="easyinventory-administrator" value={ModuleSecurityLevel.Administrator.toString()} />
                            <Label htmlFor="easyinventory-administrator">Administrator</Label>
                          </div>
                        </RadioGroup>
                      </div>
                      {
                        easyTrackingFeature?.enabled ? (
                          <div className="space-y-1">
                            <div className="text-sm font-semibold">EasyTracking</div>
                            <RadioGroup disabled={selectedSecurityLevel.systemAdmin} onValueChange={(val) => {
                              setEasyTrackingPermissionLevel(Number(val));
                            }} value={easyTrackingPermissionLevel?.toString()} orientation="horizontal" className="flex justify-between space-x-5">
                              <div className="flex space-x-1 justify-start items-center">
                                <RadioGroupItem id="easytracking-no-access" value={ModuleSecurityLevel.NoAccess.toString()} />
                                <Label htmlFor="easytracking-no-access">No access</Label>
                              </div>
                              <div className="flex space-x-1 justify-start items-center">
                                <RadioGroupItem disabled id="easytracking-view-only" value={ModuleSecurityLevel.ViewOnly.toString()} />
                                <Label aria-disabled htmlFor="easytracking-view-only">View</Label>
                              </div>
                              <div className="flex space-x-1 justify-start items-center">
                                <RadioGroupItem disabled id="easytracking-edit" value={ModuleSecurityLevel.Edit.toString()} />
                                <Label aria-disabled htmlFor="easytracking-edit">Edit</Label>
                              </div>
                              <div className="flex space-x-1 justify-start items-center">
                                <RadioGroupItem id="easytracking-administrator" value={ModuleSecurityLevel.Administrator.toString()} />
                                <Label htmlFor="easytracking-administrator">Administrator</Label>
                              </div>
                            </RadioGroup>
                          </div>
                        ) : null
                      }
                    </>
                  ) : null
                }
                {
                  user.account?.easyPollWorkerClient ? (
                    <div className="space-y-1">
                      <div className="text-sm font-semibold">EasyPollWorker</div>
                      <RadioGroup disabled={selectedSecurityLevel.systemAdmin} onValueChange={(val) => setSelectedSecurityLevel({
                        ...selectedSecurityLevel,
                        easyPollworkerLevel: Number(val),
                      })} value={selectedSecurityLevel.easyPollworkerLevel.toString()} orientation="horizontal" className="flex justify-between space-x-5">
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id="easypollworker-no-access" value={ModuleSecurityLevel.NoAccess.toString()} />
                          <Label htmlFor="easypollworker-no-access">No access</Label>
                        </div>
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id="easypollworker-view-only" value={ModuleSecurityLevel.ViewOnly.toString()} />
                          <Label htmlFor="easypollworker-view-only">View</Label>
                        </div>
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id="easypollworker-edit" value={ModuleSecurityLevel.Edit.toString()} />
                          <Label htmlFor="easypollworker-edit">Edit</Label>
                        </div>
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id="easypollworker-administrator" value={ModuleSecurityLevel.Administrator.toString()} />
                          <Label htmlFor="easypollworker-administrator">Administrator</Label>
                        </div>
                      </RadioGroup>
                    </div>
                  ) : null
                }
                {
                  user.account?.easyFocusClient ? (
                    <div className='space-y-1'>
                      <div className='text-sm font-semibold'>EasyFocus</div>
                      <RadioGroup disabled={selectedSecurityLevel.systemAdmin} onValueChange={(val) => setSelectedSecurityLevel({
                        ...selectedSecurityLevel,
                        easyFocusLevel: Number(val),
                      })} value={selectedSecurityLevel.easyFocusLevel.toString()} orientation="horizontal" className="flex justify-between space-x-5">
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id="easyfocus-no-access" value={ModuleSecurityLevel.NoAccess.toString()} />
                          <Label htmlFor="easyfocus-no-access">No access</Label>
                        </div>
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id="easyfocus-view-only" value={ModuleSecurityLevel.ViewOnly.toString()} />
                          <Label htmlFor="easyfocus-view-only">View</Label>
                        </div>
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id="easyfocus-edit" value={ModuleSecurityLevel.Edit.toString()} />
                          <Label htmlFor="easyfocus-edit">Edit</Label>
                        </div>
                        <div className="flex space-x-1 justify-start items-center">
                          <RadioGroupItem id="easyfocus-administrator" value={ModuleSecurityLevel.Administrator.toString()} />
                          <Label htmlFor="easyfocus-administrator">Administrator</Label>
                        </div>
                      </RadioGroup>
                    </div>
                  ) : null
                }
              </div>
              <div className='space-y-3'>
                <Flexor>
                  <div className='font-semibold'>Assigned users</div>
                  {selectedSecurityLevelUsers.length ? <div className='text-sm'>{selectedSecurityLevelUsers.length} user{selectedSecurityLevelUsers.length ? 's' : ''}</div> : null}
                </Flexor>
                <ul className='max-h-96 overflow-y-auto rounded divide-y border border-border'>
                  {
                    !selectedSecurityLevelUsers.length ? (
                      <Flexor items='center' justify='center' className='flex-col min-h-32 w-full rounded bg-muted text-sm space-y-3'>
                        <div>No users are assigned to this security level</div>
                        <Link to='/users'>
                          <Button variant='ghost'>
                            View users
                          </Button>
                        </Link>
                      </Flexor>
                    ) : null
                  }
                  {
                    selectedSecurityLevelUsers.map(({
                      userInfo,
                    }) => {
                      return (
                        <li key={userInfo.id} className='p-5 py-3 text-sm'>
                          <Flexor>
                            <div>
                              <div>{userInfo.firstName} {userInfo.lastName}</div>
                              <div className='text-muted-foreground'>{userInfo.emailAddress || userInfo.userId}</div>
                            </div>
                            <Badge variant='secondary'>{UserType[userInfo.userType]}</Badge>
                          </Flexor>
                        </li>
                      );
                    })
                  }
                </ul>
              </div>
              <Flexor justify='end' className='sticky bottom-0 bg-background py-2.5'>
                <Button disabled={updatingSecurityLevel || isSystemAdministrator} onClick={() => handleUpdateSecurityLevel()}>
                  <Spinner light show={updatingSecurityLevel} />
                  Update
                </Button>
              </Flexor>
            </div>
          ) : null
        }
      </div>
    </>
  );
}

function SystemAdminLevelIcon({ level }: { level: SecurityLevel }) {
  if (!level.systemAdmin) return null;

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <StarIcon className='size-4 text-foreground/80' />
        </TooltipTrigger>
        <TooltipContent>This security level is marked as System Administrator</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
