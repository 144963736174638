import React, { useEffect, useState } from "react";
import { Button, Input, Label, Switch, Textarea } from "shared/src/components/ui/new";
import { STATES } from "shared/src/utils/data/usStates";
import { PollingLocation } from "../../../types/PollingLocation";
import { Select } from "shared/src/components/ui";
import PhoneInput from "react-phone-number-input/input";
import { Flexor } from "shared/src/components";
import EmployeeSelect from "../../../components/ui/EmployeeSelect";

type PollingLocationAddEditModalProps = {
  pollingLocation: PollingLocation | null,
  onSubmit: ((param: PollingLocation | null) => Promise<void>)
}

const defaultFormData = {
  locationName: '',
  manager: '',
  address1: '',
  address2: '',
  city: '',
  state: '',
  zip: '',
  contactName: '',
  cellPhone: '',
  usedForEarlyVoting: false,
  email: '',
  contactTitle: '',
  notes: '',
  locationCode: '',
  roomNumberName: '',
  waitTimePerMachine: 0,
  votingMachineCount: 0,
  outOfCounty: false,
  coreId: '',
  distributionCenterKeyId: '',
};

export default function PollingLocationForm({ pollingLocation, onSubmit }: PollingLocationAddEditModalProps) {
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    setFormData({
      locationName: pollingLocation?.locationName || '',
      manager: pollingLocation?.pollworkerManagerEVUserId || '',
      address1: pollingLocation?.address1 || '',
      address2: pollingLocation?.address2 || '',
      city: pollingLocation?.city || '',
      state: pollingLocation?.state || '',
      zip: pollingLocation?.zip || '',
      contactName: pollingLocation?.contactName || '',
      cellPhone: pollingLocation?.contactCellPhone || '',
      email: pollingLocation?.contactEmail || '',
      usedForEarlyVoting: pollingLocation?.usedForEarlyVoting || false,
      contactTitle: pollingLocation?.contactType || '',
      notes: pollingLocation?.notes || '',
      locationCode: pollingLocation?.locationCode || '',
      roomNumberName: formData.roomNumberName || '',
      waitTimePerMachine: formData.waitTimePerMachine || 0,
      votingMachineCount: formData.votingMachineCount || 0,
      outOfCounty: formData.outOfCounty || false,
      coreId: pollingLocation?.coreId! || '',
      distributionCenterKeyId: pollingLocation?.distributionCenterKeyId || '',
    });
  }, [pollingLocation]);

  useEffect(() => {
    const requiredFields: (keyof typeof formData)[] = [
      'locationName',
    ];

    const isComplete = requiredFields.every((field) => {
      const value = formData[field];
      return typeof value === 'string' ? value.trim() !== '' : !!value;
    });

    setIsSubmitDisabled(!isComplete);
  }, [formData]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
    const { id, value, type } = e.target;
    const checked = type === 'checkbox' ? (e.target as HTMLInputElement).checked : undefined;
    setFormData((prev) => ({
      ...prev,
      [id]: type === 'checkbox' ? checked : type === 'number' ? Number(value) || 0 : value,
    }));
  }

  function handleManagerChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setFormData((prev) => ({
      ...prev,
      manager: e.target.value,
    }));
  }

  const handleSubmitButton = () => {
    const transformedData: PollingLocation = {
      id: pollingLocation?.id || '',
      locationName: formData.locationName,
      locationCode: formData.locationCode,
      keyCustomerId: pollingLocation?.keyCustomerId || '',
      address1: formData.address1,
      address2: formData.address2,
      state: formData.state,
      city: formData.city,
      zip: formData.zip,
      contactName: formData.contactName,
      contactCellPhone: formData.cellPhone,
      contactEmail: formData.email,
      contactType: formData.contactTitle,
      usedForEarlyVoting: formData.usedForEarlyVoting,
      notes: formData.notes,
      keyElectionId: pollingLocation?.keyElectionId,
      coreId: pollingLocation?.coreId || '',
      pollworkerManagerEVUserId: formData.manager,
      pollworkerManagerInfo: null,
      waitTimePerMachine: formData.waitTimePerMachine,
      votingMachineCount: formData.votingMachineCount,
      roomNumberName: formData.roomNumberName,
      outOfCounty: formData.outOfCounty,
    };

    onSubmit(transformedData);
  };

  return (
    <div className="divide-y space-y-5 [&>div]:pt-5">
      <div className="space-y-3">
        <h2 className="font-semibold">General</h2>
        <div>
          <Label htmlFor="locationName">Name</Label>
          <Input
            id="locationName"
            type="text"
            placeholder="Voting location name"
            value={formData.locationName}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="locationCode">Code</Label>
          <Input
            id="locationCode"
            type="text"
            placeholder="Voting location code"
            value={formData.locationCode}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="roomNumberName">Room name</Label>
          <Input
            id="roomNumberName"
            type="text"
            placeholder="Voting location code"
            value={formData.roomNumberName}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="address1">Street address 1</Label>
          <Input
            id="address1"
            type="text"
            placeholder="Street address 1"
            value={formData.address1}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="address2">Street address 2</Label>
          <Input
            id="address2"
            type="text"
            placeholder="Street address 2"
            value={formData.address2}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="address2">City</Label>
          <Input
            id="city"
            type="text"
            placeholder="City"
            value={formData.city}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="state">State</Label>
          <Select
            id="state"
            value={formData.state}
            onChange={handleChange}
          >
            <option value="">Select a state</option>
            {STATES.map((state) => (
              <option key={state.abbr} value={state.abbr}>
                {state.state} ({state.abbr})
              </option>
            ))}
          </Select>
        </div>
        <div>
          <Label htmlFor="zip">Zip</Label>
          <Input
            id="zip"
            type="text"
            maxLength={5}
            placeholder="Enter zip"
            value={formData.zip}
            onChange={handleChange}
          />
        </div>
        <div className="flex items-center space-x-2">
          <Switch
            id="mainWarehouse"
            checked={formData.outOfCounty}
            onCheckedChange={(checked) => setFormData({
              ...formData,
              outOfCounty: checked,
            })}
          />
          <Label htmlFor="mainWarehouse">Located out of county?</Label>
        </div>
        <div className="flex items-center space-x-2">
          <Switch
            id="mainWarehouse"
            checked={formData.usedForEarlyVoting}
            onCheckedChange={(checked) => setFormData({
              ...formData,
              usedForEarlyVoting: checked,
            })}
          />
          <Label htmlFor="mainWarehouse">Used for early voting?</Label>
        </div>
        <div>
          <Label htmlFor="notes">Notes</Label>
          <Textarea
            id="notes"
            placeholder="Write any extra details about this voting location"
            value={formData.notes}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="manager">Manager</Label>
          <EmployeeSelect
            id="manager"
            placeholder="Select a manager"
            value={formData.manager}
            onChange={handleManagerChange}
          />
        </div>
      </div>
      <div className="space-y-2">
        <h2 className="font-semibold">Primary contact</h2>
        <div>
          <Label htmlFor="contactName">Name</Label>
          <Input
            id="contactName"
            type="text"
            placeholder="Full name"
            value={formData.contactName}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label id="contactTitle">Title</Label>
          <Input
            id="contactTitle"
            type="text"
            placeholder="Enter title"
            value={formData.contactTitle}
            onChange={handleChange}
          />
        </div>
        <div>
          <Label htmlFor="cellPhone">Cell</Label>
          <PhoneInput
            country="US"
            id="cellPhone"
            placeholder="(___)-___-____"
            withCountryCallingCode={false}
            defaultCountry="US"
            value={'+1' + formData.cellPhone.replaceAll(/\D/g, '')}
            inputComponent={Input}
            onChange={(value) => setFormData({
              ...formData,
              cellPhone: value?.toString()
              ?.substring(2) || '', // pop country code
            })}
          />
        </div>
        <div>
          <Label id="email">Email</Label>
          <Input
            id="email"
            type="email"
            placeholder="Enter email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
      </div>
      <Flexor justify="end" className="w-full">
        <Button disabled={isSubmitDisabled} onClick={handleSubmitButton}>{pollingLocation ? 'Update' : 'Save'}</Button>
      </Flexor>
    </div>
  );
}
