import { fetchApi } from 'shared/src/fetchers';

export const downloadTrackerExportCSV = async (electionId: string, startDate: Date, endDate: Date, timeZone: string) => {
  const response
    = await fetchApi(`/api/ReportsApi/pollworker/timeclock/${electionId}?startDate=${startDate?.toISOString().split('T')[0]}&endDate=${endDate?.toISOString().split('T')[0]}&timeZone=${timeZone}`, {
    method: 'GET'
  });

  return await response.blob();
};
