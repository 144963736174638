import { Precinct } from 'admin/src/types/Precinct';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { cn } from 'shared/src/utils';
import { Input } from 'shared/src/components/ui';
import { useEffect, useState } from 'react';

export default PrecinctList;

export interface PrecinctListProps {
  precincts: Precinct[];
  selectedPrecinctId: string | null;
  onPrecinctSelected: (selectedPrecinct: Precinct) => void | Promise<void>;
}

function PrecinctList({ precincts, selectedPrecinctId, onPrecinctSelected }: PrecinctListProps) {
  const [filterText, setFilterText] = useState<string>('');
  const [filteredList, setFilteredList] = useState<Precinct[]>(precincts);

  useEffect(() => {
    setFilterText('');
  }, [precincts]);

  useEffect(() => {
    if (!!filterText) {
      setFilteredList(
        precincts.filter(precinct => precinct.precinctName.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
      );
    } else {
      setFilteredList(precincts);
    }
  }, [filterText]);

  return (
    <div className="flex flex-col grow w-full mx-0">
      <div className="flex items-center justify-between p-2 w-full border-b-2 space-x-2">
        <Input placeholder="Search..." value={filterText} onChange={(event) => setFilterText(event.target.value)} />
      </div>
      <div className="grow overflow-auto">
        {filteredList.map((precinct: Precinct) => (
          <div
            onClick={() => onPrecinctSelected(precinct)}
            className={cn(precinct.id === selectedPrecinctId ? 'font-semibold bg-neutral-200' : 'cursor-pointer bg-white font-medium', 'flex items-center justify-between p-3 hover:bg-gray-100')}>
            <div className="flex items-center space-x-2">
              <div className="text-md">{precinct.precinctName}</div>
            </div>
            <ChevronRightIcon className="h-5 w-5" />
          </div>
      ))}
      </div>
    </div>
  )
}
