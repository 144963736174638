import {createSlice} from "@reduxjs/toolkit";

export type DetailsUiStack = {
  stack: ('model' | 'slideover')[],
};

export const defaultDetailsUiStack: DetailsUiStack = {
  stack: [],
};

const detailsUiStackSlice = createSlice({
  name: 'detailsUiStack',
  initialState: defaultDetailsUiStack,
  reducers: {
    pushDetailUi: (state, action) => {
      state.stack.push(action.payload);
      return state;
    },
    popDetailUi: (state, action) => {
      if (state.stack[state.stack.length - 1] === action.payload) {
        state.stack.pop();
      } else {
        throw new Error('Attempt to pop detail UI out of order.');
      }

      return state;
    },
  }
})

export const {
  pushDetailUi,
  popDetailUi,
} = detailsUiStackSlice.actions;

export default detailsUiStackSlice.reducer;
