import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { AppState } from 'admin/src/store';
import {PollworkerModuleInfo} from "admin/src/types/Pollworker/PollworkerModuleInfo";
import useNativeMessageListenerEffect from "shared/src/hooks/useNativeMessageListenerEffect";
import {classNames} from "shared/src/utils/classNames";
import {Button} from "shared/src/components/ui";
import {ArrowPathIcon} from "@heroicons/react/24/outline";
import {setModuleInfo} from "admin/src/actions/pollworker/moduleInfo";

/**
 * This is a special component!
 *
 * Its use is to prevent the application it is used in from reloading, or refreshing when module info changes
 * while the user is not interacting with the application.
 *
 * The scenario is if someone changes the current election after having initialized the containing webview
 * but the webview is not currently displayed. In this situation, we do not want to refresh or start making
 * web requests putting additional strain on the backend.
 *
 * If the user changes the election but a mouse event is not detected within a set amount of time, this component
 * will display an overlay asking the user to "load" the new module info (election) data. If they switch the
 * election and (before the timer runs out) interacts (mouse event) with the webview application, it will refresh
 * as it would to refresh the application with the updated module info.
 */
export default function ModuleInfoChangeManager() {
  const [showModuleInfoHasChangedOverlay, setShowModuleInfoHasChangedOverlay] = useState(false);
  const [showInvisibleOverlay, setShowInvisibleOverlay] = useState(false);
  const [targetModuleInfo, setTargetModuleInfo] = useState<PollworkerModuleInfo>();
  const moduleInfo = useSelector((state: AppState) => state.pollworker.moduleInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!moduleInfo?.ElectionId) {
      window.chrome.webview.postMessage(`System:GetPollworkerModuleInfo`);
      return;
    }
  }, [moduleInfo?.ElectionId]);

  useNativeMessageListenerEffect((from: string, eventName: string, eventValue: string, responseData: string) => {
    console.log('New message', {from, eventName, eventValue, responseData});
    if (from === 'System' && eventValue === 'Response' && eventName === 'ModuleInfo') {
      const info = JSON.parse(atob(responseData)) as PollworkerModuleInfo;

      if (!moduleInfo?.Election?.id && info.Election?.id) {
        // This is interesting, sometimes when the module info is sent over some properties (`Pollworker`) are set to null
        dispatch(setModuleInfo({...moduleInfo || info, Election: info.Election} as PollworkerModuleInfo));
        return;
      }

      // If the current moduleInfo's election is set and the incoming module info doesn't have an election property then do nothing
      if (!info?.Election?.id || (moduleInfo?.Election?.id && !info?.Election?.id)) return;

      // console.log('Module Info', {moduleInfo, info});
      // console.log(moduleInfo?.Election?.id, info.Election?.id, {overlay: !!moduleInfo?.Election?.id && moduleInfo?.Election?.id !== info.Election.id});

      const electionHasChanged = !!moduleInfo?.Election?.id && moduleInfo?.Election?.id !== info.Election.id;

      setTargetModuleInfo(info);
      setShowInvisibleOverlay(electionHasChanged);

      setTimeout(() => {
        setShowModuleInfoHasChangedOverlay(electionHasChanged);
      }, 5000);
    }
  }, [moduleInfo]);

  if (!showInvisibleOverlay) return null;

  return (
    <div
      className={classNames(showModuleInfoHasChangedOverlay ? '' : 'animate-pulse', 'z-50 absolute top-0 left-0 w-full h-full bg-white/50')}
      onMouseEnter={() => !showModuleInfoHasChangedOverlay && window.location.reload()}
    >
      {
        showModuleInfoHasChangedOverlay ? (
          <div
            className='z-50 absolute top-0 left-0 w-full h-full bg-black/75 text-white flex flex-col items-center justify-center'
          >
            <Button
              onClick={() => window.location.reload()}
              className='text-white text-lg'>Load {targetModuleInfo?.Election?.ElectionName} <ArrowPathIcon className='h-5 w-5' /></Button>
          </div>
        ) : null
      }
    </div>
  )
}
