import { type ReactNode, useEffect, useMemo, useState } from "react";
import { DropdownMenuItem, DropdownMenuRadioGroup, DropdownMenuSeparator, DropdownMenuGroup, DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger, Input, Button, Badge, DropdownMenuRadioItem } from 'shared/src/components/ui/new';
import { useDocumentsContext } from "./DocumentsContext";
import { getDocumentExtension } from "./Documents";
import { Flexor } from "shared/src/components";
import dayjs from "dayjs";
import { cn } from "shared/src/utils";
import { UserAvatar } from "../Shell/ShellHeader";
import type { UserInfo } from "../../../types";

export function DocumentsFilters({children, className}: {children: ReactNode, className?: string}) {
  return (
    <div className={cn('w-full overflow-x-auto space-x-2 flex justify-start items-center grow', className)}>
      {children}
    </div>
  )
}

export function DocumentsSearch() {
  const { setFilter } = useDocumentsContext();

  return (
    <Input className='@lg:w-1/2 @2xl:w-1/3 w-full h-8 relative z-10' autoFocus placeholder="Search documents..." onChange={({ target: { value } }) => {
      setFilter({ predicate: 'documentName', subject: value });
    }} />
  );
}

export function DocumentsFilterType() {
  const [selected, setSelected] = useState(new Set<string>());
  const [types, setTypes] = useState<string[]>();
  const { setFilter, clearFilter, filters, documents } = useDocumentsContext();

  useEffect(() => {
    if (types || !documents?.length) return;

    const newTypes = documents.reduce((acc, doc) => {
      const ext = getDocumentExtension(doc);

      if (!ext) return acc;

      acc.add(ext);

      return acc;
    }, new Set<string>());

    setTypes(Array.from(newTypes));
  }, [types, documents]);

  useEffect(() => {
    if (!filters) return;

    setSelected(new Set(filters?.documentType));
  }, [filters]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size='sm' disabled={!types?.length} variant='outline'>
          Type
          {
            selected.size ? (
              <Flexor className='space-x-1'>
                { Array.from(selected).map((type) => <Badge key={`type_${type}`}>{type}</Badge>) }
              </Flexor>
            ) : null
          }
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start'>
        <DropdownMenuGroup>
          {
            types?.map((type) => {
              if (!type) return null;

              return (
                <DropdownMenuCheckboxItem
                  key={type}
                  checked={selected.has(type)}
                  onCheckedChange={(checked) => {
                    const newTypes = new Set(filters?.documentType);
                    checked ? newTypes.add(type) : newTypes.delete(type);

                    setFilter({
                      predicate: 'documentType',
                      subject: Array.from(newTypes),
                    });
                  }}
                >
                  {type}
                </DropdownMenuCheckboxItem>
              );
            })
          }
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem className='justify-center' onClick={() => clearFilter('documentType')}>Clear filter</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export function DocumentsFilterUpdatedAt() {
  const predicate = 'createdAt';
  const { setFilter, clearFilter, filters } = useDocumentsContext();

  function selectDateRange(rangeType: string) {
    switch(rangeType) {
    case 'Today':
      setFilter({ predicate, subject: [rangeType, dayjs().startOf('day'), dayjs().endOf('day')] });
      break;
    case 'This week':
      setFilter({ predicate, subject: [rangeType, dayjs().startOf('week'), dayjs()] });
      break;
    case 'Last week':
      setFilter({ predicate, subject: [rangeType, dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')] });
      break;
    case 'This month':
      setFilter({ predicate, subject: [rangeType, dayjs().startOf('month'), dayjs()] });
      break;
    case 'Last month':
      setFilter({ predicate, subject: [rangeType, dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] });
      break;
    }
  }

  const [uploadedAtName] = useMemo(() => filters?.createdAt || [], [filters]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size='sm' variant='outline'>
          Date
          {uploadedAtName ? (<Badge>{uploadedAtName}</Badge>) : null}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start'>
        <DropdownMenuRadioGroup value={uploadedAtName} onValueChange={selectDateRange}>
          <DropdownMenuRadioItem value='Today'>Today</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value='This week'>This week</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value='Last week'>Last week</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value='This month'>This month</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value='Last month'>Last month</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem className='justify-center' onClick={() => clearFilter('createdAt')}>Clear filter</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
export function DocumentsFilterUploadedBy() {
  const [selected, setSelected] = useState(new Set<string>());
  const [allUsersById, setAllUsersById] = useState<{[key: string]: Partial<UserInfo>}>();
  const { setFilter, filters, documents, clearFilter } = useDocumentsContext();

  useEffect(() => {
    setSelected(new Set(filters?.uploadedBy));
  }, [filters]);

  useEffect(() => {
    if (allUsersById || !documents?.length) return;

    const newUsers = documents.reduce((acc: {[key: string]: Partial<UserInfo>}, doc) => {
      if (!doc.ownerInfo.id) return acc;

      acc[doc.ownerInfo.id] = doc.ownerInfo;

      return acc;
    }, {});

    setAllUsersById(newUsers);
  }, [allUsersById, documents]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size='sm' variant='outline' disabled={!allUsersById || !Object.values(allUsersById).length}>
          Added by
          {
            selected.size && allUsersById ? (
              <Flexor className='space-x-1'>
                { Array.from(selected).map((userId) => <Badge key={`user_${userId}`}>{allUsersById[userId].firstName} {allUsersById[userId].lastName}</Badge>) }
              </Flexor>
            ) : null
          }
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start'>
        <DropdownMenuGroup>
          {
            allUsersById ? Object.values(allUsersById).map((user) => {
              return (
                <DropdownMenuCheckboxItem
                  key={user.id}
                  checked={selected.has(user.id as string)}
                  onCheckedChange={(checked) => {
                    const userId = user.id as string;
                    const newSelected = new Set(filters?.uploadedBy);

                    checked ? newSelected.add(userId) : newSelected.delete(userId);

                    setFilter({
                      subject: Array.from(newSelected),
                      predicate: 'uploadedBy',
                    });
                  }}
                  className='flex items-center space-x-1'
                >
                  <UserAvatar className='size-6' firstName={user.firstName} lastName={user.lastName} />
                  <span>{user.firstName} {user.lastName}</span>
                </DropdownMenuCheckboxItem>
              );
            }) : null
          }
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem className='justify-center' onClick={() => clearFilter('uploadedBy')}>Clear filter</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
