import React, { useEffect, useState } from "react";
import DistributionCenterGrid from "admin/src/screens/admin/SetupAndAdmin/DistributionCenterGrid";
import type { DistributionCenter } from "admin/src/types/DistributionCenter";
import { Button } from "shared/src/components/ui";
import DistributionCenterForm from "admin/src/screens/admin/SetupAndAdmin/DistributionCenterForm";
import { addOrEditDistributionCenter, deleteDistributionCenter, getDistributionCenters } from "admin/src/fetchers/setupAndAdmin";
import { PlusIcon } from "@heroicons/react/24/outline";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "shared/src/components/ui/new";
import { DataGridProvider } from "../../../components/ui/DataGrid";

export default function DistributionCenters() {
  const [showAddDistributionCenter, setShowAddDistributionCenter] = useState(false);

  return (
    <>
      <div className="my-2">
        <div className="flex justify-between items-center text-md px-2">
          <div className="font-semibold">Distribution Centers</div>
          <Button size="sm" data-testid="dcAddButton" onClick={() => setShowAddDistributionCenter(true)}>
            <PlusIcon className="size-4"/>
            Add
          </Button>
        </div>
        <div className="mt-1 h-[1px] bg-gray-300 w-full" />
      </div>
      <div className='p-2 h-full'>
        <DistributionCentersList onHideAdd={() => setShowAddDistributionCenter} showAdd={showAddDistributionCenter} />
      </div>
    </>
  );
}

export function DistributionCentersList({
  electionId,
  showAdd,
  onHideAdd,
}: { electionId?: string, showAdd: boolean, onHideAdd: () => void }) {
  const [distributionCenters, setDistributionCenters] = useState<DistributionCenter[]>([]);
  const [dcLoading, setDcLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCenter, setSelectedCenter] = useState<DistributionCenter>();

  useEffect(() => {
    fetchDistributionCenters();
  }, []);

  useEffect(() => {
    if (!showAdd) return;

    handleAddDistributionCenter();
  }, [showAdd]);

  useEffect(() => {
    if (!isOpen) onHideAdd();
  }, [isOpen, onHideAdd]);


  const fetchDistributionCenters = async () => {
    setDcLoading(true);
    try {
      const centers = await getDistributionCenters(electionId);
      setDistributionCenters(centers);
    } catch (error) {
      console.error("Error fetching distribution centers:", error);
    } finally {
      setDcLoading(false);
    }
  };

  function handleAddDistributionCenter() {
    setSelectedCenter(undefined);
    setIsOpen(true);
  }

  function handleEditDistributionCenter(center: DistributionCenter) {
    if (center) {
      setSelectedCenter(center);
      setIsOpen(true);
    }
  }

  const handleDeleteDistributionCenter = (center: DistributionCenter) => {
    if (window.confirm(`Are you sure you want to delete ${center.centerName}?`)) {
      handleDeleteApiCall(center.id);
    }
  };

  const handleSubmitApiCall = async (dc?: DistributionCenter) => {
    if (dc) {
      try {
        await addOrEditDistributionCenter(dc);
        await fetchDistributionCenters();
      } catch (error) {
        console.error("Error saving distribution center:", error);
      } finally {
        setIsOpen(false);
      }
    }
  };

  const handleDeleteApiCall = async (id: string) => {
    try {
      await deleteDistributionCenter(id);
      await fetchDistributionCenters();
    } catch (error) {
      console.error("Error deleting distribution center:", error);
    }
  };

  return (
    <>
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetContent>
          <SheetHeader className="flex justify-between">
            <SheetTitle>
              {selectedCenter ? 'Edit' : 'Add'} distribution center
            </SheetTitle>
          </SheetHeader>
          <DistributionCenterForm
            distributionCenter={selectedCenter}
            onSubmit={handleSubmitApiCall}
          />
        </SheetContent>
      </Sheet>

      <DataGridProvider className='space-y-2'>
        <DistributionCenterGrid
          electionId={null}
          loading={dcLoading}
          centers={distributionCenters}
          onEditClick={(center) => handleEditDistributionCenter(center)}
          onDeleteClick={(center) => handleDeleteDistributionCenter(center)}
        />
      </DataGridProvider>
    </>
  );
}
