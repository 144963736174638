import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {UserLogin} from 'shared/src/types';

export interface AuthState {
  token: string | null;
  isAuthenticated: boolean;
  loading: boolean;
}

const createDefaultAuthState = (): AuthState => ({
  token: null,
  isAuthenticated: false,
  loading: false,
});

const initialState = createDefaultAuthState();

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // No-op in redux, this triggers the login flow in /sagas/auth.ts
    login: (state, action: PayloadAction<UserLogin>) => {},
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isAuthenticated = true;
    },
    // Reset state to default and triggers the logout flow in /sagas/auth.ts
    logout: (state) => {
      state = createDefaultAuthState();
    },
    // Note: This should only be used when authenticating within the desktop app. You probably don't need this.
    desktopAuth: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }
  }
});

export default authSlice.reducer;

export const {
  setIsLoading,
  setToken,
  login,
  logout,
  desktopAuth
} = authSlice.actions;

export const selectIsLoading = (state: { sharedAuth: AuthState }) => state.sharedAuth.loading;
export const selectIsAuthenticated = (state: { sharedAuth: AuthState }) => state.sharedAuth.isAuthenticated;
