import { InventoryEquipTypeSummary } from 'admin/src/types/Inventory';


export const SET_INVENTORY_EQUIPMENT_TYPE_SUMMARIES = 'SET_INVENTORY_EQUIPMENT_TYPE_SUMMARIES';

export const setInventoryEquipmentTypeSummaries = (customerId: string, summaries: InventoryEquipTypeSummary[]) => ({
  type: SET_INVENTORY_EQUIPMENT_TYPE_SUMMARIES,
  payload: {
    customerId,
    summaries
  }
});
