import type { ReactNode } from "react";
import { useMemo } from "react";
import { useGridSelectedRow } from "./DataGridContext";
import useIsMobile from "shared/src/hooks/useIsMobile";
import { Sheet, SheetContent } from "shared/src/components/ui/new";
import { classNames } from "shared/src/utils";

export function DataGridRowDetailSidebar({
  children,
  className,
}: { className?: string, children: ReactNode }) {
  const { selectedRow, deselectRow } = useGridSelectedRow();
  const isMobile = useIsMobile();

  const sheetOpen = useMemo(() => isMobile && !!selectedRow, [isMobile, selectedRow]);

  if (isMobile) {
    return (
      <Sheet open={sheetOpen} onOpenChange={deselectRow}>
        <SheetContent>
          {children}
        </SheetContent>
      </Sheet>
    );
  }

  if (!selectedRow) return null;

  return (
    <aside className={classNames(className, 'space-y-5')}>
      {children}
    </aside>
  );
}
