import React, { useEffect } from "react"
import { Select } from "shared/src/components/ui"
import { useAppDispatch, useAppSelector } from "../../hooks"
import { Employee } from "../../types/User"
import { fetchEmployees } from "../../fetchers"
import { setEmployees } from "../../actions"

export interface EmployeeSelectProps {
  id?: string | undefined,
  name?: string | undefined,
  value: string | undefined,
  className?: string | undefined,
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void,
  placeholder?: string | undefined
}

export default function EmployeeSelect(props: EmployeeSelectProps) {
  const dispatch = useAppDispatch();
  const employees: Employee[] = useAppSelector((state) => state.setupAndAdmin.employees.employees);

  useEffect(() => {
    if (employees.length < 1){
      fetchEmployees().then((employees) => {
        dispatch(setEmployees(employees));
      })
    }
  }, [])

  return (
  <Select
    id={props.id}
    name={props.name}
    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
    value={props.value}
    onChange={props.onChange}
  >
    <option value="">Select a manager</option>
    {employees.map(employee => (
      <option key={employee.keyEVUserId} value={employee.keyEVUserId}>
        {employee.evUser.firstName} {employee.evUser.lastName}
      </option>
    ))}
  </Select>)
}
