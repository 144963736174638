import { combineReducers } from "@reduxjs/toolkit";
import employeesReducer, { type EmployeeState } from "./employees";

export type SetupAndAdminState = {
  employees: EmployeeState
};

export default combineReducers({
  employees: employeesReducer,
})
