import React, {Fragment, Suspense, useState} from "react";
import {Bars3Icon, BuildingOfficeIcon, UserCircleIcon, XMarkIcon} from "@heroicons/react/24/outline";
import {Link, useLocation} from "wouter";
import logov from '../../logo-v.png';
import {useSelector} from "react-redux";
import { AppState } from 'admin/src/store';
import {BlobImage} from "../store/StoreItemImage";
import {classNames} from "shared/src/utils/classNames";
import Spinner from "../../../../shared/src/components/Spinner";
import {Dialog, Menu, Transition} from "@headlessui/react";

const navigation = [
  {href: '/dashboard', name: 'Dashboard'},
  {href: '/inventory', name: 'Inventory'},
  {href: '/store', name: 'Store'},
];

export default function EasyOrderShell(props: any) {
  const theme = useSelector((state: AppState) => state.theme);
  const user = useSelector((state: AppState) => state.user);
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);
  const [location] = useLocation();

  return (
    <div className='flex flex-col justify-between'>
      <header className="sticky inset-x-0 top-0 z-30 flex h-16 border-b border-gray-900/10 bg-white">
        <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
          <div className="flex flex-1 items-center gap-x-6">
            <button type="button" className="-m-3 p-3 md:hidden" onClick={() => setShowMobileMenu(!showMobileMenu)}>
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-5 w-5 text-gray-900" aria-hidden="true" />
            </button>
            <img
              className="h-8 w-auto"
              src={logov}
              alt="Your Company"
            />
          </div>
          <nav className="hidden md:flex md:gap-x-11 md:text-sm md:font-semibold md:leading-6 md:text-gray-700 h-full justify-center">
            {
              navigation.map((item, itemIdx) => {
                const active = item.href === location;

                return (
                  <Link href={item.href} key={itemIdx}>
                    <div className={classNames(active ? 'border-b-2 border-ev-red' : '', 'flex flex-col items-center justify-center cursor-pointer')}>
                      {item.name}
                    </div>
                  </Link>
                )
              })
            }
          </nav>
          <div className="flex flex-1 items-center justify-end gap-x-8">
            {
              theme.logo ? (
                  <a href="#" className="-m-1.5 p-1.5">
                    <span className="sr-only">Your profile</span>
                    <img
                      className="block h-14 mr-3 p-1 w-auto"
                      src={theme.logo}
                    />
                  </a>
              ) : (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-ev-red focus:ring-offset-2 focus:ring-offset-gray-100">
                      <span className="sr-only">Profile picture that opens window</span>
                      <Suspense fallback={(<Spinner large={false} show />)}>
                        <BlobImage
                          entityType='employees'
                          alt="Your profile picture"
                          className="object-cover h-10 w-10 rounded-full"
                          id={`${user?.user?.id}.jpg`}
                          imageNotFoundFallback={(
                            <UserCircleIcon className={`scale-75 text-gray-400`} />
                          )}
                        />
                      </Suspense>
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-4 py-3 flex items-center space-x-2">
                        <UserCircleIcon className="h-5 w-5" />
                        <span>{user?.user?.firstName} {user?.user?.lastName}</span>
                      </div>
                      <div className="px-4 py-3 flex items-center space-x-2">
                        <BuildingOfficeIcon className="h-5 w-5" />
                        <span>{user?.account?.customerName}</span>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )
            }
          </div>
        </div>
        <Dialog as="div" className="lg:hidden" open={showMobileMenu} onClose={() => setShowMobileMenu(!showMobileMenu)}>
          <div className="fixed inset-0 z-50" />
          <Dialog.Panel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
            <div className="-ml-0.5 flex h-16 items-center gap-x-6">
              <button type="button" className="-m-2.5 p-2.5 text-gray-700" onClick={() => setShowMobileMenu(false)}>
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="-ml-0.5">
                {
                  theme.logo ? (
                    <a href="#" className="-m-1.5 p-1.5">
                      <span className="sr-only">Your profile</span>
                      <img
                        className="block h-14 mr-3 p-1 w-auto"
                        src={theme.logo}
                      />
                    </a>
                  ) : null
                }
              </div>
            </div>
            <div className="mt-2 space-y-2">
              {
                navigation.map((item) => {
                  const active = item.href === location;

                  return (
                    <Link href={item.href} key={item.name}>
                      <div
                        className={classNames(active ? 'border-ev-red' : 'border-transparent', "border-l-4 -mx-3 cursor-pointer block px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50")}>
                        {item.name}
                      </div>
                    </Link>
                  )
                })
              }
            </div>
          </Dialog.Panel>
        </Dialog>
      </header>
      <div className="">
        {props.children}
      </div>
    </div>
  )
}
