import {AnyAction} from "@reduxjs/toolkit";
import {PollworkerModuleInfo} from "../../types/Pollworker/PollworkerModuleInfo";
import {MODULE_INFO_ACTION_TYPES} from "../../actions/pollworker/moduleInfo";

const defaultModuleInfoReducer: PollworkerModuleInfo | null = null;

export default function moduleInfoReducer(state: any = defaultModuleInfoReducer, action: AnyAction) {
  switch (action.type) {
    case MODULE_INFO_ACTION_TYPES.SET_MODULE_INFO:
      return action.payload;
    default:
      return state;
  }
}
