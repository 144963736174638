import React, {ReactNode} from "react";

export default function Blink({on = false, children}: {on: boolean, children: ReactNode}) {
  if (on) {
    return (<span className="animate-pulse">{children}</span>);
  }

  return (
    <>{children}</>
  );
}