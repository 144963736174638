export function compileFormSchema(form: any) {
  const {
    json: formPages,
    templateBeforeJson,
    templateAfterJson,
    templateCompileType,
    templateSettingsJson
  } = form;

  const settings = JSON.parse(templateSettingsJson);
  const beforePages = JSON.parse(templateBeforeJson);
  const workingPages = JSON.parse(formPages);
  const afterPages = JSON.parse(templateAfterJson);

  const schema = {
    ...settings,
    pages: [
      ...beforePages,
      ...afterPages,
    ],
  };

  switch(templateCompileType) {
  case 'before':
    schema.pages.unshift(...workingPages);
    break;
  case 'after':
    schema.pages.push(...workingPages);
    break;
  case 'between':
  default:
    schema.pages.splice(1, 0, ...workingPages);
    break;
  }

  return schema;
}

export function generateAsyncFormQuestionHostname() {
  const url = new URL(process.env.REACT_APP_POLLWORKER_DOMAIN!);
  return [url.protocol, url.host].join('//');
}
