import {AnyAction} from "@reduxjs/toolkit";
import {Toast, TOAST_ACTION_TYPES} from "../actions/toast";

export default function toastReducer(state: Toast = { content: "", undoAction: undefined }, action: AnyAction): Toast {
  switch (action.type) {
    case TOAST_ACTION_TYPES.SHOW:
      return action.payload;
    case TOAST_ACTION_TYPES.POP:
      if (!state.content) return state;
      return {content: "", undoAction: undefined};
    default:
      return state;
  }
}
