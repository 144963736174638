import type { ReactNode } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { type DocumentContextProps, DocumentsContext } from "./DocumentsContext";
import { deleteDocument, getDocumentsForObject } from "admin/src/fetchers";
import dayjs from "dayjs";
import type { Document, DocumentFilter, DocumentFilters, UploadedDocumentFileResponse } from 'admin/src/types';
import { toast } from "sonner";
import { cn } from "shared/src/utils";
import { Toaster } from "shared/src/components/ui/new";
import type { DataburstResponse } from "admin/src/types/DataburstResponse";

const IMAGE_MIME_TYPES: {[key: string]: string} = {
  '.png': 'image/png',
  '.jpg': 'image/jpg',
  '.jpeg': 'image/jpeg',
}

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Guides/MIME_types/Common_types
export const EXTENSION_TO_MIME_TYPE: {[key: string]: string} = {
  ...IMAGE_MIME_TYPES,
  '.csv': 'text/csv',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.doc': 'application/msword',
  '.ppt': 'application/vnd.ms-powerpoint',
  '.pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.pdf': 'application/pdf',
};

export function getDocumentExtension(document?: Document) {
  if (!document) return;

  return document.url.split('.').pop();
}

export type DocumentsProps = {
  children: ReactNode
  className?: string
  objectId?: string
  enableSearch?: boolean
  enableUpload?: boolean
  onDownload?: (err: string | null, document: Document) => void
  onUpload?: (uploadedFiles: UploadedDocumentFileResponse[]) => void
  disableToast?: boolean
  upload: DocumentContextProps['upload'],
  download: (url: string) => Promise<DataburstResponse<string>>,
};

export function Documents({
  children,
  className,
  objectId,
  onUpload,
  onDownload,
  upload,
  download,
  enableSearch = true,
  enableUpload = true,
  disableToast = false,
}: DocumentsProps) {
  const [documents, setDocuments] = useState<Document[]>([]);
  const [filters, setFilters] = useState<DocumentFilters>({});
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);

  useEffect(() => {
    if (!objectId) return;

    setFilters({});
    setDocuments([]);
    loadDocuments();
  }, [objectId]);

  const filteredDocuments = useMemo(() => {
    const filterEntries = Object.entries(filters);
    if (!filterEntries.length) return documents;

    return documents.filter((doc) => {
      const filtered = Object.entries(filters).map(([predicate, subject]) => {
        switch (predicate) {
        case 'documentName':
          return doc[predicate].toLowerCase().includes([subject].flat().join(',').toLowerCase());
        case 'documentType':
          return subject.length ? subject.includes(getDocumentExtension(doc) || '') : true;
        case 'createdAt':
          if (!Array.isArray(subject) || !subject.length) return true;
          return dayjs(doc.createdAt).isBetween(subject[1], subject[2]);
        case 'uploadedBy':
          if (!Array.isArray(subject) || !subject.length || !doc.ownerInfo?.id) return true;
          return subject.includes(doc.ownerInfo.id);
        default:
          return true;
        }
      });

      return !filtered.includes(false);
    });
  }, [filters, documents]);

  const downloadDocument = useCallback(async (doc: Document): Promise<[boolean, string?, string?]> => {
    return download(doc.url).then((resp) => {
      if (!resp.success) return [false];

      const ext = getDocumentExtension(doc);
      const mime = EXTENSION_TO_MIME_TYPE[`.${ext}`];
      const dataUrl = `data:${mime};base64,${JSON.parse(atob(resp.data)).data}`;
      return [true, ext, dataUrl];
    });
  }, [download]);

  const downloadDocumentToFs = useCallback((doc: Document) => {
    downloadDocument(doc).then(([success, ext, dataUrl]) => {
      if (!success) {
        if (onDownload) onDownload('File could not be downloaded', doc);
        return;
      }

      if (!success || !dataUrl) return;

      const download = ext && doc.documentName.endsWith(ext) ? doc.documentName : `${doc.documentName}.${ext}`;

      const link = document.createElement('a');
      link.href = dataUrl;

      link.download = download;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      if (onDownload) onDownload(null, doc);
    });
  }, [downloadDocument, onDownload]);

  const delDocument = useCallback((document: Document) => {
    if (!window.confirm(`Are you sure you want to delete "${document.documentName}" forever?`)) return;

    deleteDocument(document.id)
    .then(({status}) => {
      if (!disableToast) {
        if (status === 204) return toast.success(`"${document.documentName}" has been deleted`);
        toast.error(`"${document.documentName}" could not be deleted`);
      }
    })
    .finally(loadDocuments);
  }, [disableToast]);

  const loadDocuments = useCallback(() => {
    if (!objectId) return;

    setLoadingDocuments(true);
    getDocumentsForObject(objectId).then(({data}) => {
      const docs = JSON.parse(atob(data))
      .map((d: Document) => {
        const mime = EXTENSION_TO_MIME_TYPE[`.${getDocumentExtension(d)}`];
        return {
          ...d,
          mime
        };
      })
      .sort((a: Document, b: Document) => {
        return dayjs(a.dateCreated) < dayjs(b.dateCreated) ? 1 : -1;
      });

      setDocuments(docs);
    }).finally(() => setLoadingDocuments(false));
  }, [objectId]);

  const setFilter = useCallback((filter: DocumentFilter) => {
    setFilters((filters) => ({...filters, [filter.predicate]: filter.subject}));
  }, []);

  const clearFilter = useCallback((predicate: keyof DocumentFilters) => {
    const newFilters = { ...filters };
    delete newFilters[predicate];
    setFilters(newFilters);
  }, [filters]);

  if (!objectId) {
    return (
      <div className='h-20 flex justify-center items-center'>
        No documents found
      </div>
    );
  }

  return (
    <DocumentsContext.Provider value={{
      objectId,
      downloadDocument,
      downloadDocumentToFs,
      deleteDocument: delDocument,
      loadDocuments,
      loadingDocuments,
      filters,
      setFilter,
      clearFilter,
      upload,
      onUpload,
      documents: filteredDocuments || documents,
      enableSearch,
      enableUpload,
      disableToast,
    }}>
      <Toaster position='bottom-center' />
      <div className={cn('@container space-y-2', className)}>
        {children}
      </div>
    </DocumentsContext.Provider>
  );
}
