import React, {Suspense, useContext, useState} from "react";
import {ConsumableItem} from "../../types/ConsumableItem";
import StoreItemImage, {StoreItemImageFallback} from "./StoreItemImage";
import {Button} from "shared/src/components/ui";
import {
  ExclamationTriangleIcon,
  InformationCircleIcon
} from "@heroicons/react/24/outline";
import { PencilSquareIcon } from "@heroicons/react/24/solid";
import Tippy from "@tippyjs/react";
import { Flexor } from "shared/src/components"
import ItemDetails from "../admin/ItemDetails";
import DeprecatedModal from "shared/src/components/DeprecatedModal";
import {getConsumables} from "../../fetchers";
import {useDispatch} from "react-redux";
import AdminOnly from "../../components/AdminOnly";
import {AdminContext} from 'shared/src/contexts/AdminContext';
import TippyContent from "../../../../shared/src/components/TippyContent";
import {classNames} from "shared/src/utils/classNames";
import EasyVoteButton, {EasyAdminButton} from "../../components/admin/EasyVoteButton";
import EditItemQuantityInStore from "./EditItemQuantityInStore";

type StoreItemProps = {
  item: ConsumableItem;
  onShowItemDetails: Function;
}

export default function StoreItem({item, onShowItemDetails}: StoreItemProps) {
  const [showEditItemModal, setShowEditItemModal] = useState<boolean>(false);
  const [showFullDescription, setShowFullDescription] = useState<boolean>(false);
  const {isAdmin} = useContext(AdminContext);
  const dispatch = useDispatch();

  if (!isAdmin && !item.active) return null;

  return (
    <>
      <DeprecatedModal title={item.equipTypeName} open={showEditItemModal} onClose={() => setShowEditItemModal(false)}>
        <>
          <ItemDetails params={{itemId: item.id, enablePostMessage: false, onClose: () => {
            // @ts-ignore
            dispatch(getConsumables());
            setShowEditItemModal(false);
          }}} />
          <div className="h-[3em]"></div>
        </>
      </DeprecatedModal>
      <a key={item.id} className="group">
        <div className="relative z-0">
          <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
            <Suspense fallback={<StoreItemImageFallback />}>
              <StoreItemImage item={item} className='h-full w-full object-cover object-center group-hover:opacity-75' />
            </Suspense>
          </div>
          <AdminOnly>
            <div className="absolute bottom-2 right-2 z-40 w-1/2 flex flex-col justify-end items-end space-y-1.5">
              <EasyAdminButton onClick={() => setShowEditItemModal(true)} icon={<PencilSquareIcon className="size-4" />} label="Edit" />
              <EasyVoteButton eventToSend={`Dialog:EditEquipmentType:Open:${item.id}`} />
            </div>
          </AdminOnly>
          <div className="absolute bottom-2 left-2 z-40 w-full">
            <Flexor className='w-full'>
              <Button onClick={() => onShowItemDetails(item)} variant='icon'>
                <InformationCircleIcon className="size-6 shrink-0" />
              </Button>
              <EditItemQuantityInStore item={item} />
            </Flexor>
          </div>
        </div>
        <Flexor>
          <div className="w-full mt-2">
            <Flexor items="center" className="space-x-2">
              <div className="w-full">
                <Flexor justify="between" className="break-words w-full text-lg font-semibold sm:text-sm truncate text-gray-700">
                  <div className="whitespace-pre-wrap break-words w-1/2 text-md shrink">{item.equipTypeName}</div>
                  <div className="flex flex-col justify-start">
                    <div className="text-lg break-none font-medium text-gray-900">
                      {
                        !item.countOnHand ? (
                          <span className="text-sm text-gray-800">Out of stock</span>
                        ) : (
                          <div className="space-x-1">
                            <span>{item.countOnHand}</span>
                            <span className="text-sm text-gray-600">Available</span>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </Flexor>
                <p onClick={() => setShowFullDescription(!showFullDescription)} className="sm:mt-1 mt-2 text-sm text-gray-600">
                  {
                    item.description ? (
                      <Tippy placement="bottom" content={(
                        <TippyContent>Click to {showFullDescription ? 'hide' : 'show'} the full description.</TippyContent>
                      )}>
                        <span className={classNames(showFullDescription ? '' : 'line-clamp-2', "italic cursor-pointer")}>{item.description}</span>
                      </Tippy>
                    ) : (
                      <AdminOnly>
                        <span className="italic">No description</span>
                      </AdminOnly>
                    )
                  }
                </p>
              </div>
                <div className="shrink space-x-1 flex items-center justify-end">
                  <AdminOnly>
                    {!item.active && (
                      <Tippy content={(
                        <TippyContent>
                          <p>This item is not shown in the store.</p>
                          <p>It must be set to <b>active</b>.</p>
                        </TippyContent>
                      )}>
                        <ExclamationTriangleIcon className="h-5 w-5 text-orange-600" />
                      </Tippy>
                    )}
                  </AdminOnly>
                </div>

            </Flexor>
          </div>
        </Flexor>
      </a>
    </>
  );
}
