import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {fetchEmployees} from '../../../fetchers';
import {debounce} from "lodash";
import {createSelector} from 'reselect';
import {classNames} from 'shared/src/utils/classNames';
import {getAccount} from '../../../selectors';
import AddEditUser from './AddEditUser';
import {Flexor, ScreenReaderOnly} from 'shared/src/components';
import { Button, DialogBody, DialogHeader, Input } from 'shared/src/components/ui/new';
import {Dialog, DialogTrigger, DialogClose, DialogContent, DialogFooter, DialogTitle} from "shared/src/components/ui/new";
import { PlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import Spinner from "shared/src/components/Spinner";
import { AppState } from "admin/src/store";
import { deleteUserInfo } from "admin/src/fetchers/setupAndAdmin";
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "shared/src/components/ui/new";

export default function Users() {
  const [showAddEditDialog, setShowAddEditDialog] = useState(false);

  return (
    <>
      <UserGrid showAddEditDialog={showAddEditDialog} />
      <Sheet open={showAddEditDialog} onOpenChange={(open) => !open && setShowAddEditDialog(false)}>
        <SheetContent className='sm:w-[540px] sm:max-w-none overflow-y-auto'>
          <SheetHeader>
            <SheetTitle>New user</SheetTitle>
          </SheetHeader>
          <AddEditUser onComplete={() => setShowAddEditDialog(false)} />
        </SheetContent>
      </Sheet>
    </>
  );
}

function UserGrid({showAddEditDialog}: {
  showAddEditDialog?: boolean,
}) {
  const ssoEnabled = useSelector(selectCustomerHasSSO);
  const [usersData, setUsersData]: [any[], (user: any[]) => void] = React.useState([] as any[]);
  const [filteredUsers, setFilteredUsers]: [any[], (user: any[]) => void] = React.useState([] as any[]);
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const [showEditUserForm, setShowEditUserForm] = useState();
  const currentUserId = useSelector((state: AppState) => state.user?.user?.id);
  const [deleting, setDeleting] = useState(false);

  useEffect(() => {
    if (showAddEditDialog) return;
    loadEmployees();
  }, [showAddEditDialog]);

  function loadEmployees() {
    fetchEmployees().then((data) => {
      // TODO might need to add some state to track the `search` text and filter when this returns, or block the UI from rendering until this is loaded to prevent weird cases of users filtering before the response
      setUsersData(data);
      setFilteredUsers(data);
    });
  }

  const debouncedOnSearchChange = useMemo(() => {
    return debounce((search: string) => {
      if (!search.length) {
        return setFilteredUsers(usersData);
      }

      setFilteredUsers(usersData.filter((user) => {
        return fullName(user).toLowerCase().includes(search.toLowerCase()) ||
          user.evUser?.email?.toLowerCase().includes(search.toLowerCase()) ||
          user.evUser?.userId?.toLowerCase().includes(search.toLowerCase());
      }));
    }, 500);
  }, [usersData]);

  const fullName = (employee: any): string => {
    return `${employee.evUser?.firstName} ${employee.evUser?.lastName}`;
  };

  function confirmDeleteUser(evUserId: string) {
    setDeleting(true);
    deleteUserInfo(evUserId)
      .finally(() => {
        setDeleting(false);
        loadEmployees();
      });
  }

  return (
    <>
      <div className="sm:flex sm:justify-between sm:items-center">
        <Input
          type="text"
          placeholder="Search..."
          className='w-1/4'
          onChange={(event) => debouncedOnSearchChange(event.target.value)}
        />
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Sheet open={showNewUserForm} onOpenChange={setShowNewUserForm}>
            <SheetTrigger asChild>
              <Button>
                <PlusIcon />
                Add user
              </Button>
            </SheetTrigger>
            <SheetContent className='sm:w-[540px] sm:max-w-none overflow-y-auto'>
              <SheetHeader>
                <Flexor className=''>
                  <SheetTitle>Add user</SheetTitle>
                </Flexor>
              </SheetHeader>
              <AddEditUser onComplete={() => setShowNewUserForm(false)} />
            </SheetContent>
          </Sheet>
        </div>
      </div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr className='[&>th:first-child]:pl-0 [&>th:last-child]:pr-0'>
            <TableHeader>
              User ID
            </TableHeader>
            <TableHeader>
              Name
            </TableHeader>
            <TableHeader>
              Security Role
            </TableHeader>
            <TableHeader>
              Cell #
            </TableHeader>
            <TableHeader>
              Email
            </TableHeader>
            <TableHeader>
              Title
            </TableHeader>
            {ssoEnabled && (
              <TableHeader>
                SSO Enabled
              </TableHeader>
            )}
            <TableHeader>
              <ScreenReaderOnly>Actions</ScreenReaderOnly>
            </TableHeader>
          </tr>
        </thead>
        <tbody className="max-h-32 divide-y divide-gray-200">
        {
          filteredUsers.map((employee) => (
            <tr className='[&>td:first-child]:pl-0 [&>td:last-child]:pr-0' key={employee.id}>
              <TableCell>
                {employee.evUser.userId}
              </TableCell>
              <TableCell>
                {employee.evUser.firstName} {employee.evUser.lastName}
              </TableCell>
              <TableCell>{employee?.securityLevel?.securityLevelName}</TableCell>
              <TableCell>{formatPhone(employee.evUser.cellPhone)}</TableCell>
              <TableCell>{employee.evUser?.emailAddress}</TableCell>
              <TableCell>{employee.title}</TableCell>
              {ssoEnabled && (
                <TableCell>
                  {employee.evUser?.ssoUser?.ssoState ? 'Yes' : 'No'}
                </TableCell>
              )}
              <TableCell className='space-x-1 float-right'>
                <Sheet open={showEditUserForm && showEditUserForm === employee.evUserId} onOpenChange={(open) => !open && setShowEditUserForm(undefined)}>
                  <SheetTrigger asChild>
                    <Button size='sm' variant='ghost'>
                      Edit<ScreenReaderOnly>, {employee.evUser.firstName} {employee.evUser.lastName}</ScreenReaderOnly>
                    </Button>
                  </SheetTrigger>
                  <SheetContent className='sm:w-[540px] sm:max-w-none overflow-y-auto'>
                    <SheetHeader>
                      <Flexor className=''>
                        <SheetTitle>Edit user</SheetTitle>
                      </Flexor>
                    </SheetHeader>
                    <AddEditUser evUserId={employee.keyEVUserId} onComplete={() => setShowEditUserForm(undefined)} />
                  </SheetContent>
                </Sheet>
                <Dialog size="sm">
                  <DialogTrigger asChild>
                    <Button size='sm' variant='outline' data-testid={`delete-user-button-${employee.evUser.id}`}>
                      Delete<ScreenReaderOnly>, {employee.evUser.firstName} {employee.evUser.lastName}</ScreenReaderOnly>
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Are you sure?</DialogTitle>
                    </DialogHeader>
                    <DialogBody>
                      {
                        currentUserId === employee.evUser.id ? (
                          <>
                            <div className="py-4" data-testid='cannot-delete-msg'>You cannot delete your own account</div>
                            <DialogFooter>
                              <DialogClose asChild>
                                <Button className="text-sm font-semibold"  variant="ghost">Close</Button>
                              </DialogClose>
                            </DialogFooter>
                          </>
                        ) : (
                          <>
                            <div className="py-4" data-testid='delete-confirm-msg'>
                              Deleting this user is a permanent action and the user will no longer be able to
                              access the system once they are deleted.
                            </div>
                            <div>Are you sure you want to delete <span className='font-semibold'>{employee.evUser.firstName} {employee.evUser.lastName}</span>?</div>
                          </>
                        )
                      }
                    </DialogBody>
                    <DialogFooter>
                      <div className="flex justify-between items-center">
                        <DialogClose asChild>
                          <Button data-testid='delete-user-cancel' variant="ghost">Cancel</Button>
                        </DialogClose>
                        <Button
                          variant='destructive'
                          onClick={() => confirmDeleteUser(employee.evUser.id)}
                          className="justify-center"
                          disabled={deleting}
                        >
                          {!deleting ? <TrashIcon className="w-5 h-5"/> : <Spinner show/>}
                          <span>Delete User</span>
                        </Button>
                      </div>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </TableCell>
            </tr>
          ))
        }
        </tbody>
      </table>
    </>
  );
}

const selectCustomerHasSSO = createSelector(getAccount, (account: any) => account?.ssoEnabled ?? false);

interface TableProps {
  className?: string | undefined;
  children: React.ReactNode;
}

const TableHeader = ({
  className,
  children
}: TableProps) => {
  return (
    <th scope="col"
        className={classNames(className, "py-3.5 pl-4 pr-3 px-4 text-left text-sm font-semibold text-gray-900")}>
      {children}
    </th>
  );
};

const TableCell = ({
  className,
  children
}: TableProps) => {
  return (
    <td className={classNames(className, "whitespace-nowrap py-4 px-4 text-sm text-left")}>
      {children}
    </td>
  );
};

function formatPhone(phone: string) {
  const cleaned = ('' + phone).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return null;
}
