import { useMemo } from "react";
import { getDocumentExtension } from "./Documents";
import type { Document } from 'admin/src/types';
import type { DefaultExtensionType } from "react-file-icon";

export function useDocumentStatus(document: Document) {
  return useMemo(() => {
    if (!document) return [];

    const ext = getDocumentExtension(document) as DefaultExtensionType;
    const canView = ['jpg', 'png', 'pdf', 'jpeg'].includes(ext.toLowerCase());
    const isPdf = ext === 'pdf';

    return [canView, isPdf, ext];
  }, [document]);
}
