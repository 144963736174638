import dayjs from "dayjs";
import {patternFormatter} from "react-number-format";

export function dateFormatter(params: any) {
  if (!params.value) return '';
  return dayjs(params.value).format('MM-DD-YYYY');
}

export function phoneNumberFormatter(params: string | {value: string}) {
  if (typeof(params) !== "string" && !params?.value) return '';
  // remove non digits and remove country code by selecting the last 10 digits and replacing any preceding tokens with an empty string
  const str = typeof(params) === "string"? params : params.value;
  const value = str.replaceAll(/[^\d]/g, '').replaceAll(/(.*)(\d{10})/g, '$2');
  return patternFormatter(value, {format: '(###) ###-####', mask: '_'});
}

export function phoneNumberFormatRemover(params: string) {
  if (!params || params.length < 10) return "";
  // removes formatting and country code, leaving only the 10 digits required by the backend for some fields
  const value = params.replaceAll(/\D/g, "").replaceAll(/(.*)(\d{10})/g, "$2");
  return patternFormatter(value, { format: "##########", mask: "_" });
}

export const phoneRegEx= /^((\(\d{3}\))|(\d{3}))(\s|-|\.)?\d{3}(-|.\s)?\d{4}$/;
