export function downloadDocument(fileName: string, fileExtension: string) {
  return (blobContent?: Blob) => {
    if (!blobContent) return;

    const blob = new Blob([blobContent]);
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.href = url;
    link.dataset.testid = 'document-download-link';
    link.setAttribute('download', (fileName + fileExtension));
    link.setAttribute('aria-hidden', 'true');
    link.target = '_blank';

    document.body.appendChild(link);
    link.click();
  }
}

export function openPdfToPrint(response: Blob) {
  const blob = new Blob([response], {type: 'application/pdf'});
  const url = window.URL.createObjectURL(blob);
  window.open(url, '_print');
}

export function openPdfToDownload(filename: string, response: Blob) {
  const blob = new Blob([response], {type: 'application/pdf'});
  const url = window.URL.createObjectURL(blob);

}

export function getFileExtension(fileNameOrUrl: string) {
  return fileNameOrUrl.substring(fileNameOrUrl.indexOf("."))
}
