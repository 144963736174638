import React, { useMemo } from "react";
import { toast } from "react-toastify";
import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
import { SurveyModel } from "survey-core";
import { MARKDOWN_TEXT_QUESTION_TYPE, POLITICAL_PARTY_QUESTION_TYPE } from "shared/src/components/QuestionMarkdownText";

import 'shared/src/components/surveyJsCustomConfiguration';

const creatorOptions = {
  showLogicTab: true,
  isAutoSave: true,
  showSurveyTitle: false,
  questionsOnPageMode: 'singlePage',
  questionTypes: ['text', 'comment', 'checkbox', 'expression', 'radiogroup', 'dropdown', 'matrixdynamic', 'signaturepad', 'panel', MARKDOWN_TEXT_QUESTION_TYPE],
};

type SurveyCreatorWidgetProps = {
  readOnly: boolean,
  model: SurveyModel,
  onChange: (saveNo: number, schema: any) => Promise<any>
};

export function SurveyCreatorWidget({readOnly, model, onChange}: SurveyCreatorWidgetProps) {
  const creator = useMemo(() => {
    const creator = new SurveyCreator(creatorOptions);

    creator.text = JSON.stringify({ ...model });
    creator.showSidebar = false;
    creator.readOnly = readOnly;

    creator.onSurveyPropertyValueChanged.add((sender, options) => {
      if (options.propertyName === 'title') {
        // You cannot change the name of the `party` (political party) custom question type
        if (options.obj.getType() === POLITICAL_PARTY_QUESTION_TYPE) {
          options.obj.setPropertyValue('name', POLITICAL_PARTY_QUESTION_TYPE);
          return;
        }

        // camelCase the name of the question
        let name = options.value
          .replace(/[^A-Za-z0-9\s]/g, '')
          .replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number) => {
            return !index ? word.toLowerCase() : word.toUpperCase();
          })
          .replace(/\s+/g, '');

        options.obj.setPropertyValue('name', name);
      }
    });

    creator.onShowingProperty.add(function(sender, options){
      // Prevent some property panel options from being seen or used
      switch (options.obj.getType()) {
        case MARKDOWN_TEXT_QUESTION_TYPE:
          options.canShow = ['readOnly', 'isRequired', 'description', 'descriptionLocation', 'errorLocation', 'title'].indexOf(options.property.name) < 0;
          break;
        case POLITICAL_PARTY_QUESTION_TYPE:
          options.canShow = ['choices', 'choicesFromQuestion', 'choicesFromQuestionMode', 'choiceValuesFromQuestion', 'choiceTextsFromQuestion', 'otherPlaceholder', 'showOtherItem', 'otherText'].findIndex(op => op === options.property.name) < 0;
          break;
        default:
      }
    });

    creator.onQuestionAdded.add((sender, options) => {
      if (options.question.getType() === POLITICAL_PARTY_QUESTION_TYPE) {
        const alreadyHasParty = creator.survey.getAllQuestions().filter((q) => q.getType() === POLITICAL_PARTY_QUESTION_TYPE).length;

        if (alreadyHasParty > 1) {
          console.log(options.question);
          options.question.delete();
          toast.error('You cannot add more than one "Political Party" question.');
          return;
        }

        options.question.name = POLITICAL_PARTY_QUESTION_TYPE;
      }
    });

    creator.saveSurveyFunc = async (saveNo: number, callback: (saveNo: number, hasSaved: boolean) => void) => {
      await onChange(saveNo, JSON.parse(creator.text))
      .then(() => callback(saveNo, true))
      .catch(() => callback(saveNo, false));
    };

    return creator;
  }, [model]);

  return (
    <SurveyCreatorComponent creator={creator} />
  )
}

