import {OrderItem} from "../../types/Order";
import {FocusEventHandler, useState} from "react";
import EditItemQuantityForm from '../user/EditItemQuantityForm';

export function OrderItemQuantityEditForm({className, item, onBlur}: {className: string; onBlur?: FocusEventHandler<HTMLInputElement>, item: OrderItem}) {
  const [newQuantity, setNewQuantity] = useState<number>();

  return (
    <EditItemQuantityForm
      min={1}
      autoFocus={false}
      onBlur={(event) => {
        const {target: {value, validity: {valid: isValid}}} = event;
        setNewQuantity(Number.parseInt(value));
        if (onBlur) onBlur(event);
      }}
      onChange={({target: {value, validity: {valid: isValid}}}) => {
        setNewQuantity(Number.parseInt(value));
      }}
      className={className}
      max={item.inventoryEquipType.countOnHand < item.quantity ? item.quantity : item.inventoryEquipType.countOnHand}
      value={newQuantity || item.quantity}
    />
  );
}
