import React, { useEffect, useState } from 'react';
import { Bar, BarChart, XAxis, YAxis } from 'recharts';

import {DeploymentDashboardEquipmentSummary} from 'admin/src/types/Inventory';
import { ChartContainer, ChartTooltip, ChartTooltipContent } from 'shared/src/components/ui';

export interface DeploymentEquipmentSummaryChartProps {
  summaries: DeploymentDashboardEquipmentSummary[]
}

export default function DeploymentEquipmentSummaryChart({ summaries }: DeploymentEquipmentSummaryChartProps) {
  const [summariesByType, setSummariesByType] = useState<DeploymentDashboardEquipmentSummary[]>([]);
  const [chartInterval, setChartInterval] = useState<number>(0);

  const chartConfig = {
    available: {
      label: 'Available',
      color: '#D4A9C6'
    },
    assembled: {
      label: 'Assembled',
      color: '#086FB8'
    },
    deploymentOnTruck: {
      label: 'On Truck',
      color: '#C98734'
    },
    delivered: {
      label: 'Delivered',
      color: '#93D183'
    },
    pickupOnTruck: {
      label: 'On Truck for Return',
      color: '#00548F'
    },
    returnedToWarehouse: {
      label: 'Returned',
      color: '#318721'
    }
  }

  useEffect(() => {
    const byTypeSummaries: Map<string, DeploymentDashboardEquipmentSummary> = summaries.filter(summary => !summary.requiresChainOfCustody).reduce((acc: Map<string, DeploymentDashboardEquipmentSummary>, summary: DeploymentDashboardEquipmentSummary) => {
      const curr = acc.get(summary.equipmentTypeId);
      if (curr) {
        curr.assembled += summary.assembled;
        curr.deploymentOnTruck += summary.deploymentOnTruck;
        curr.delivered += summary.delivered;
        curr.pickupOnTruck += summary.pickupOnTruck;
        curr.returnedToWarehouse += summary.returnedToWarehouse;
      }
      else {
        acc.set(summary.equipmentTypeId, { ...summary });
      }

      return acc;
    }, new Map());

    const values: DeploymentDashboardEquipmentSummary[] = Array.from(byTypeSummaries.values());

    setSummariesByType(values);
    setChartInterval(Math.floor(values.length / 10));
  }, [summaries])

  return (
    <div data-testid='equipment-summary-chart' className="h-full">
      <ChartContainer className="h-full" config={chartConfig}>
        <BarChart accessibilityLayer data={summariesByType} height={500} width={500} layout="vertical">
          <XAxis hide axisLine={false} type="number" />
          <YAxis
            dataKey="equipmentTypeName"
            interval={chartInterval}
            tickLine={false}
            tickMargin={4}
            axisLine={false}
            type="category"
            width={100}
          />
          <ChartTooltip content={<ChartTooltipContent />} />
          <Bar
            dataKey="available"
            stackId="a"
            fill="var(--color-available)"
            radius={[4, 0, 0, 4]}
          />
          <Bar
            dataKey="assembled"
            stackId="a"
            fill="var(--color-assembled)"
            radius={[0, 0, 0, 0]}
          />
          <Bar
            dataKey="deploymentOnTruck"
            stackId="a"
            fill="var(--color-deploymentOnTruck)"
            radius={[0, 0, 0, 0]}
          />
          <Bar
            dataKey="delivered"
            stackId="a"
            fill="var(--color-delivered)"
            radius={[0, 0, 0, 0]}
          />
          <Bar
            dataKey="pickupOnTruck"
            stackId="a"
            fill="var(--color-pickupOnTruck)"
            radius={[0, 0, 0, 0]}
          />
          <Bar
            dataKey="returnedToWarehouse"
            stackId="a"
            fill="var(--color-returnedToWarehouse)"
            radius={[0, 4, 4, 0]}
          />
        </BarChart>
      </ChartContainer>
    </div>
  )
}
