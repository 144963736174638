import { useAppDispatch } from "./useAppDispatch";
import { setPollworkerSettings as setPollworkerGlobalSettings } from "../reducers/pollworker/settings";
import { getPollworkerCustomFields, getPollworkerLoadCustomFields, getPollworkerRoleTypes, getPollworkers, getPollworkerWorkHistoryAssignmentConfirmationsForElection, getPollworkerWorkHistoryReportsTo } from "../fetchers";
import { WorkerLevel } from "../components/enums/WorkerLevelEnum";
import { setCustomFields, setLoadingPollworkerData, setPollworkerCustomFieldsData, setPollworkers, setPollworkerWorkAssignmentConfirmationsForElection, setReportsTo, setRoleTypes } from "../reducers/pollworker/grid";
import { useAppSelector } from "./useAppSelector";

export default function useHandlePollworkers(): [boolean, (isLoading: boolean) => void, (force?: boolean) => void] {
  const moduleInfo = useAppSelector((state) => state.pollworker.moduleInfo);
  const loadingPollworkerData = useAppSelector((state) => state.pollworker.grid.loadingPollworkerData);
  const pollworkers = useAppSelector((state) => state.pollworker.grid.pollworkers);
  const dispatch = useAppDispatch();

  function handlePollworkers(force: boolean = true) {
    if (!moduleInfo || !moduleInfo.Election) return;

    if (Object.values(pollworkers).some(list => list.length) && !force) return;

    dispatch(setLoadingPollworkerData(true));

    const settings = window.localStorage.getItem('pollworker:settings');
    dispatch(setPollworkerGlobalSettings(settings ? JSON.parse(settings) : {}));

    Promise.all([
      getPollworkers(moduleInfo.ElectionId, WorkerLevel.Pollworker.id),
      getPollworkers(moduleInfo.ElectionId, WorkerLevel.Standby.id),
      getPollworkers(moduleInfo.ElectionId, WorkerLevel.Trainee.id),
      getPollworkerRoleTypes(),
      getPollworkerLoadCustomFields(),
      getPollworkerCustomFields(moduleInfo.Election.KeyCustomerId),
      getPollworkerWorkHistoryAssignmentConfirmationsForElection(moduleInfo.ElectionId),
      getPollworkerWorkHistoryReportsTo(moduleInfo.ElectionId),
    ])
    .then(([pollworkers, pollworkersStandby, pollworkersTrainee, roleTypes, customFields, pollworkerCustomFieldsData, pollworkerWorkAssignmentConfirmationsForElection, reportsTo]) => {
      let pwPollworker = pollworkers.data;
      let pwStandby = pollworkersStandby.data;
      let pwTrainee = pollworkersTrainee.data;

      const pw = {
        workerList: [...pwPollworker.workerList, ...pwStandby.workerList, ...pwTrainee.workerList],
        precinctList: [...pwPollworker.precinctList, ...pwStandby.precinctList, ...pwTrainee.precinctList],
        locationList: [...pwPollworker.locationList, ...pwStandby.locationList, ...pwTrainee.locationList],
      };

      dispatch(setRoleTypes(roleTypes));
      dispatch(setCustomFields(customFields));
      dispatch(setReportsTo(reportsTo));
      dispatch(setPollworkerCustomFieldsData(pollworkerCustomFieldsData));
      dispatch(setPollworkerWorkAssignmentConfirmationsForElection(pollworkerWorkAssignmentConfirmationsForElection));
      dispatch(setPollworkers(pw));
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      dispatch(setLoadingPollworkerData(false));
    });
  }

  return [loadingPollworkerData, setLoadingPollworkerData, handlePollworkers];
}
