import { useEffect } from 'react';

import { checkFeatureFlag } from 'admin/src/actions';
import { selectFeatureFlags } from 'admin/src/selectors';
import { AppState } from 'admin/src/store';

import { useAppDispatch, useAppSelector } from './index';
import { Feature } from 'admin/src/enums';

export const useIsFeatureFlagEnabled = (flag: string | null | undefined, dbFlag?: Feature | null | undefined): boolean => {
  if (!flag && !dbFlag) {
    throw new Error('You must provide a flag or dbFlag');
  }

  const dispatch = useAppDispatch();
  const flags = useAppSelector(selectFeatureFlags);
  const currentUser = useAppSelector((state: AppState) => state.user);

  useEffect(() => {
    if (flag) {
      if (flags[flag] === undefined) {
        dispatch(checkFeatureFlag(flag));
      }
    }
  }, [currentUser]);

  if (flag) {
    return (flags[flag] && flags[flag]?.enabled) as boolean;
  }

  return !!(currentUser?.features || []).find(({ feature }) => feature === dbFlag);
}
