import { Survey } from "survey-react-ui";
import { useEffect, useState } from "react";
import { Model, settings, SurveyModel } from "survey-core";
import { pollworkerSurveyJsTheme } from "./pollworkerSurveyJsTheme";
import { Alert, AlertDescription } from "./ui";
import './surveyJsCustomConfiguration';
import 'survey-core/defaultV2.min.css';
import '../PollworkerSettings.css';

type FormViewerProps = {
  surveyFormSchema: any
  readOnly?: boolean,
  allowSubmit?: boolean,
  onValidate: (formDataJson: { [key: string]: any; }, valid: (isValid: boolean) => void) => void
  data?: any,
  onComplete: (sender: SurveyModel) => Promise<boolean>,
  customerId?: string,
  agreementText?: string,
}

let canComplete = true;

export default function PollworkerApplicationForm({
  data,
  onValidate,
  onComplete,
  customerId,
  agreementText,
  readOnly = false,
  allowSubmit = true,
  surveyFormSchema: compiledForm,
}: FormViewerProps) {
  const [model, setModel] = useState<SurveyModel>();
  const [globalValidationError, setGlobalValidationError] = useState<string>();

  useEffect(() => {
    if (!agreementText || !customerId) return;

    settings.web.encodeUrlParams = false;

    const url = new URL(process.env.REACT_APP_POLLWORKER_DOMAIN!);
    const hostname = [url.protocol, url.host].join('//');

    const model = new Model(compiledForm);

    if (readOnly) model.getAllQuestions().forEach((q) => q.readOnly = true);

    model.setVariable('iAgreeCopy', agreementText);
    model.setVariable('pollworkerWebsiteApiHostname', hostname);
    model.setVariable('customerId', customerId);

    if (data) model.data = data;

    model.showCompletedPage = true;
    model.showQuestionNumbers = false;
    model.questionsOnPageMode = 'singlePage';
    model.focusFirstQuestionAutomatic = false;
    model.showCompleteButton = !readOnly;

    model.onServerValidateQuestions.add((survey, { data, complete }) => {
      model.validate();

      onValidate(data, (isValid) => {
        canComplete = isValid;

        if (!isValid) {
          window.scroll(0, 0);
          setGlobalValidationError('We could not process your application. Please try again.');
        }

        complete();
      });
    });

    model.onCompleting.add((sender, options) => {
      options.allow = canComplete;

      if (canComplete && allowSubmit) {
        onComplete(sender)
        .then((success) => {
          if (success) return;

          model.clear(false); // Reinit the form but keep the user's data
        });
      }
    });

    model.css = pollworkerSurveyJsTheme;

    setModel(model);
  }, [agreementText, customerId]);

  if (!model) return null;

  return (
    <>
      { globalValidationError ? (
        <Alert variant="error" className='mb-4'>
          <AlertDescription>{globalValidationError}</AlertDescription>
        </Alert>
      ) : null }
      <Survey model={model} />
    </>
  )
}
