import i18next from "i18next";
import {useEffect} from "react";
import {initReactI18next, useTranslation} from "react-i18next";
import {CustomerNomenclature} from "../types/CustomerNomenclature";

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        nomenclature: {
          "VotingLocationDisplayName": "Voting Location",
          "PrecinctDisplayName": "Precinct",
          "EmployeeDisplayName": "Employee",
          "TaskDisplayName": "Task",
          "ProjectDisplayName": "Project",
          "InventoryDisplayName": "Inventory",
          "DeploymentWorkerDisplayName": "Deployment Worker",
          "PollWorkerDisplayName": "PollWorker",
          "VoterDisplayName": "Voter",
          "LnaDisplayName": "L & A",
          "LnaTestDisplayName": "L & A Test",
          "EfProjectContactDisplayName": "Ef Project Contact",
          "OfficerDisplayName": "Officer",
          "FilingClerkDisplayName": "Filing Clerk",
          "CampaignFinanceDisplayName": "Campaign Finance",
          "AbsenteeByMailDisplayName": "Absentee By Mail",
          "EarlyVotingDisplayName": "Early Voting",
          "PwTrainingDisplayName": "Poll Worker Training",
          "PwSkillsDisplayName": "Poll Worker Skills",
          "PwRolesDisplayName": "Poll Worker Roles",
          "ThirdPartyDisplayName": "Third Party",
          "PollworkerElectionDayDisplayName": "Poll Worker Election Day",
          "PollworkerStandbyDisplayName": "Poll Worker Standby",
          "PollworkerTraineeDisplayName": "Poll Worker Trainee",
          "PollworkerProspectDisplayName": "Poll Worker Prospect",
          "PollworkerApplicantDisplayName": "Poll Worker Applicant",
          "PollworkerEmployeeIdDisplayName": "Poll Worker Employee Id",
          "PollworkerOnlineSystemDisplayName": "Poll Worker Online System",
          "DistrictComboNumberDisplayName": "District Combo Number",
          "CardStyleDisplayName": "Card Style",
          "EquipGroupDisplayName": "Equipment Group",
          "PrecinctCodeDisplayName": "Precinct Code",
          "PrecinctManagerDisplayName": "Precinct Manager",
          "VoterRegNumberDisplayName": "Voter Reg Number",
          "BallotAcceptedDisplayName": "Ballot Accepted",
          "FilerTypeDisplayName": "Filer Type",
          "CandidateDisplayName": "Candidate",
          "CFVoterRegNoDisplayName": "CF Voter Reg No",
          "CommitteeDisplayName": "Committee",
          "ChairPersonDisplayName": "Chair Person",
          "CommitteeExpirationDateDisplayName": "Committee Expiration Date",
          "OfficeTypeDisplayName": "Office Type",
          "FilerIdDisplayName": "Filer Id",
          "PINDisplayName": "PIN",
          "FirstElectionYearDisplayName": "First Election Year",
          "CFDateApprovedDisplayName": "CF Date Approved",
        }
      }
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export interface NomenclatureProps {
    nomenclatureId: string;
}

export interface NomenclatureBaseProps {
    customer?: CustomerNomenclature;
}

export function NomenclatureBase({ nomenclatureId, customer } : NomenclatureProps & NomenclatureBaseProps) {
    const { t } = useTranslation();

    useEffect(() => {
        i18next.addResources('en', 'nomenclature', {'VotingLocationDisplayName': customer?.votingLocationDisplayName});
        i18next.addResources('en', 'nomenclature', {'PrecinctDisplayName': customer?.precinctDisplayName});
        i18next.addResources('en', 'nomenclature', {'EmployeeDisplayName': customer?.employeeDisplayName});
        i18next.addResources('en', 'nomenclature', {'TaskDisplayName': customer?.taskDisplayName});
        i18next.addResources('en', 'nomenclature', {'ProjectDisplayName': customer?.projectDisplayName});
        i18next.addResources('en', 'nomenclature', {'InventoryDisplayName': customer?.inventoryDisplayName});
        i18next.addResources('en', 'nomenclature', {'DeploymentWorkerDisplayName': customer?.deploymentWorkerDisplayName});
        i18next.addResources('en', 'nomenclature', {'PollWorkerDisplayName': customer?.pollWorkerDisplayName});
        i18next.addResources('en', 'nomenclature', {'VoterDisplayName': customer?.voterDisplayName});
        i18next.addResources('en', 'nomenclature', {'LnaDisplayName': customer?.lnaDisplayName});
        i18next.addResources('en', 'nomenclature', {'LnaTestDisplayName': customer?.lnaTestDisplayName});
        i18next.addResources('en', 'nomenclature', {'EfProjectContactDisplayName': customer?.efProjectContactDisplayName});
        i18next.addResources('en', 'nomenclature', {'OfficerDisplayName': customer?.officerDisplayName});
        i18next.addResources('en', 'nomenclature', {'FilingClerkDisplayName': customer?.filingClerkDisplayName});
        i18next.addResources('en', 'nomenclature', {'CampaignFinanceDisplayName': customer?.campaignFinanceDisplayName});
        i18next.addResources('en', 'nomenclature', {'AbsenteeByMailDisplayName': customer?.absenteeByMailDisplayName});
        i18next.addResources('en', 'nomenclature', {'EarlyVotingDisplayName': customer?.earlyVotingDisplayName});
        i18next.addResources('en', 'nomenclature', {'PwTrainingDisplayName': customer?.pwTrainingDisplayName});
        i18next.addResources('en', 'nomenclature', {'PwSkillsDisplayName': customer?.pwSkillsDisplayName});
        i18next.addResources('en', 'nomenclature', {'PwRolesDisplayName': customer?.pwRolesDisplayName});
        i18next.addResources('en', 'nomenclature', {'ThirdPartyDisplayName': customer?.thirdPartyDisplayName});
        i18next.addResources('en', 'nomenclature', {'PollworkerElectionDayDisplayName': customer?.pollworkerElectionDayDisplayName});
        i18next.addResources('en', 'nomenclature', {'PollworkerStandbyDisplayName': customer?.pollworkerStandbyDisplayName});
        i18next.addResources('en', 'nomenclature', {'PollworkerTraineeDisplayName': customer?.pollworkerTraineeDisplayName});
        i18next.addResources('en', 'nomenclature', {'PollworkerProspectDisplayName': customer?.pollworkerProspectDisplayName});
        i18next.addResources('en', 'nomenclature', {'PollworkerApplicantDisplayName': customer?.pollworkerApplicantDisplayName});
        i18next.addResources('en', 'nomenclature', {'PollworkerEmployeeIdDisplayName': customer?.pollworkerEmployeeIdDisplayName});
        i18next.addResources('en', 'nomenclature', {'PollworkerOnlineSystemDisplayName': customer?.pollworkerOnlineSystemDisplayName});
        i18next.addResources('en', 'nomenclature', {'DistrictComboNumberDisplayName': customer?.districtComboNumberDisplayName});
        i18next.addResources('en', 'nomenclature', {'CardStyleDisplayName': customer?.cardStyleDisplayName});
        i18next.addResources('en', 'nomenclature', {'EquipGroupDisplayName': customer?.equipGroupDisplayName});
        i18next.addResources('en', 'nomenclature', {'PrecinctCodeDisplayName': customer?.precinctCodeDisplayName});
        i18next.addResources('en', 'nomenclature', {'PrecinctManagerDisplayName': customer?.precinctManagerDisplayName});
        i18next.addResources('en', 'nomenclature', {'VoterRegNumberDisplayName': customer?.voterRegNumberDisplayName});
        i18next.addResources('en', 'nomenclature', {'BallotAcceptedDisplayName': customer?.ballotAcceptedDisplayName});
        i18next.addResources('en', 'nomenclature', {'FilerTypeDisplayName': customer?.filerTypeDisplayName});
        i18next.addResources('en', 'nomenclature', {'CandidateDisplayName': customer?.candidateDisplayName});
        i18next.addResources('en', 'nomenclature', {'CFVoterRegNoDisplayName': customer?.cFVoterRegNoDisplayName});
        i18next.addResources('en', 'nomenclature', {'CommitteeDisplayName': customer?.committeeDisplayName});
        i18next.addResources('en', 'nomenclature', {'ChairPersonDisplayName': customer?.chairPersonDisplayName});
        i18next.addResources('en', 'nomenclature', {'CommitteeExpirationDateDisplayName': customer?.committeeExpirationDateDisplayName});
        i18next.addResources('en', 'nomenclature', {'OfficeTypeDisplayName': customer?.officeTypeDisplayName});
        i18next.addResources('en', 'nomenclature', {'FilerIdDisplayName': customer?.filerIdDisplayName});
        i18next.addResources('en', 'nomenclature', {'PINDisplayName': customer?.pINDisplayName});
        i18next.addResources('en', 'nomenclature', {'FirstElectionYearDisplayName': customer?.firstElectionYearDisplayName});
        i18next.addResources('en', 'nomenclature', {'CFDateApprovedDisplayName': customer?.cFDateApprovedDisplayName});
    }, [customer]);

    return (
        <>{t(nomenclatureId, {ns: 'nomenclature'})}</>
    );
}
