import { Toaster as Sonner, toast as toaster } from "sonner"
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, InformationCircleIcon } from "@heroicons/react/24/solid";
import Spinner from "../../Spinner";

type ToasterProps = React.ComponentProps<typeof Sonner>

const Toaster = ({ ...props }: ToasterProps) => {
  return (
    <Sonner
      icons={{
        success: <CheckCircleIcon />,
        info: <InformationCircleIcon />,
        warning: <ExclamationTriangleIcon />,
        error: <ExclamationCircleIcon />,
        loading: <Spinner />,
      }}
      className="toaster group"
      position='bottom-center'
      toastOptions={{
        classNames: {
          icon: '!size-5',
          toast: "group toast group-[.toaster]:bg-background group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
          description: "group-[.toast]:text-muted-foreground",
          actionButton: "group-[.toast]:!bg-enhancetitle group-[.toast]:text-primary-foreground",
          cancelButton: "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
        },
      }}
      {...props}
    />
  )
}

export { Toaster, toaster }
