import { AnyAction } from '@reduxjs/toolkit';

import { SET_ACTIVE_DEPLOYMENTS } from '../../actions';
import {Deployment} from "admin/src/types/Inventory/Deployment";

export type DeploymentState = {
  activeDeployments: Deployment[],
}

const initialState = {
  activeDeployments: []
}

function deploymentReducer(state: DeploymentState = initialState, action: AnyAction) {
  switch(action.type) {
    case SET_ACTIVE_DEPLOYMENTS:
      return {
        ...state,
        activeDeployments: action.payload as Deployment[]
      }
    default:
      return state;
  }
}

export default deploymentReducer;
